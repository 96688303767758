import React, { useState, useRef, useContext, useEffect } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Input,
  Typography,
  Image,
  Popconfirm,
  message,
  Row,
  Col,
  Form,
} from "antd";
import dayjs from "dayjs";
import {
  ClockCircleOutlined,
  EditOutlined,
  PauseCircleOutlined,
  RedoOutlined,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";
import AddMedicineModal from "./AddMedicineModal";
import {
  dosageTiming,
  medicineFrequency,
  medicineIcons,
} from "../../../helpers/medicineKeys";
import {
  medicationPrescribeAPI,
  updateMedicationPrescribeAPI,
} from "../../../services/patient.services";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import AddMedicineForm from "./AddMedicineForm";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID } from "../../../constants/defaultKeys";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import AddInitialMedicineModal from "./AddMedicine/AddInitialMedicineModal";
const { Paragraph, Text } = Typography;

const AddMedicineTable = ({ data, refreshPage, loading, initial = false }) => {
  const location = useLocation();
  const actionsEnabled = !location?.pathname?.includes("physiotherapist");
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const { userDetails } = useContext(UserContext);
  const [form] = Form.useForm();
  const [initialModal, setInitialModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showAddMedicine, setShowAddMedicine] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const searchInput = useRef(null);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [timers, setTimers] = useState({});
  useEffect(() => {
    const timerIds = {};

    data?.forEach((record) => {
      const updatedAt = dayjs(record.updated_at);
      const now = dayjs();
      const secondsElapsed = now.diff(updatedAt, "second");
      const secondsLeft = Math.max(60 - secondsElapsed, 0);

      if (secondsLeft > 0) {
        timerIds[record.id] = setInterval(() => {
          setTimers((prevTimers) => ({
            ...prevTimers,
            [record.id]: Math.max(prevTimers[record.id] - 1, 0),
          }));
        }, 1000);
      }
    });

    return () => {
      Object.values(timerIds).forEach(clearInterval);
    };
  }, [data]);

  const handleSearch = (confirm) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({
                closeDropdown: true,
              });
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
          fontSize: "20px",
        }}
      />
    ),
    onFilter: (value, record) =>
      (record.name &&
        record.name.toString().toLowerCase().includes(value.toLowerCase())) ||
      (record.medicine_name &&
        record.medicine_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())),
    onFilterDropdownVisibleChange: (visible) => {},
  });

  const actionMedicine = (id, value) => {
    updateMedicationPrescribeAPI(id, value)
      .then((res) => {
        message.success(res?.data?.message);
        refreshPage();
      })
      .catch((err) => message.error(err?.response?.data?.message));
  };
  console.log(selectedId);
  const onFinish = (values) => {
    const times = values?.schedule?.times.map((time) =>
      dayjs(time)?.format("HH")
    );

    const { schedule, ...restValues } = values;

    const formData = {
      facility: Number(getCookie(FacilityID)) || userDetails?.currFacility,
      ip_admission: Number(params?.patient),
      medicine: selectedId,
      is_initial: initial,
      days_left_initial: values?.days_left_initial
        ? values?.days_left_initial
        : values?.duration || null,
      schedule: {
        times: times,
      },
      ...restValues,
    };
    console.log(formData);
    if (selectedId) {
      medicationPrescribeAPI(formData)
        .then((res) => {
          message.success(res?.data?.message);
          refreshPage();
          setShowAddMedicine(false);
          form.resetFields();
          setSelectedId(null);
          setSelectedFrequency(null);
        })
        .catch((err) => message.error(err?.response?.data?.message));
    } else {
      message.error("Please choose medicine from the list");
    }
  };
  const formatTime = (time) => {
    const parsedTime = dayjs()
      .set("hour", parseInt(time, 10))
      .set("minute", 0)
      .set("second", 0);

    return parsedTime.format("hh A");
  };
  const columns = [
    {
      title: "Name",
      render: (record) => (
        <Space>
          {/* <Image
            src={medicineIcons[record?.form]}
            preview={false}
            width="20px"
          /> */}
          <Typography>{record?.name || record?.medicine_name}</Typography>
        </Space>
      ),
      ...getColumnSearchProps("name"),
      width: "250px",
      fixed: "left",
    },

    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
      width: "200px",
      render: (value, record) =>
        value ? (
          <>
            <Typography>
              {medicineFrequency?.find((med) => med?.value === value)?.label ||
                "-"}
            </Typography>
            {record?.schedule?.times?.map((time) => (
              <Tag color="purple" style={{ marginBottom: 8 }}>
                <Space>
                  <ClockCircleOutlined />
                  {time && formatTime(time)}
                </Space>
              </Tag>
            ))}
          </>
        ) : (
          "-"
        ),
    },
    {
      title: "Timing",
      dataIndex: "dosage_timing",
      key: "dosage_timing",
      width: "150px",
      render: (value, record) =>
        value ? (
          <>
            <Tag
              color={
                value === "after_food"
                  ? "orange"
                  : value === "before_food"
                  ? "magenta"
                  : "green"
              }
            >
              {dosageTiming?.find((med) => med?.value === value)?.title || "-"}
            </Tag>
          </>
        ) : (
          "-"
        ),
    },
    {
      title: "Route",
      dataIndex: "route",
      key: "route",
      width: "120px",
      render: (value, record) =>
        value ? <>{value && <Tag>{value}</Tag>}</> : "-",
    },
    {
      title: initial ? "Days Left" : "Duration",
      dataIndex: "duration",
      key: "duration",
      width: "120px",
      render: (value, record) => {
        return (
          <>
            {record?.days_left_adjusted && (
              <Tag color="purple">
                {record?.days_left_adjusted} {initial ? null : "days left"}
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Notes",
      dataIndex: "comments",
      key: "comments",
      width: "200px",
      render: (value) =>
        value ? (
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: "more",
            }}
            style={{ width: 200 }}
          >
            {value}
          </Paragraph>
        ) : (
          "-"
        ),
    },
    // location?.pathname?.includes[("doctor", "nurse")] &&
  ];

  if (actionsEnabled) {
    columns.push({
      title: "Action",
      width: "150px",
      fixed: "right",
      render: (record) => {
        const now = dayjs();
        const updatedAt = dayjs(record.updated_at);
        const secondsElapsed = now.diff(updatedAt, "second");
        const secondsLeft = Math.max(60 - secondsElapsed, 0);
        const showEditButton = secondsLeft > 0;

        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                {record?.status === "Started" ? (
                  <Space>
                    <>
                      {showEditButton && (
                        <>
                          <EditOutlined
                            onClick={() => {
                              setAddModal(true);
                              setEditData(record);
                            }}
                          />
                          <Text type="secondary">{`${secondsLeft}s`}</Text>
                        </>
                      )}
                    </>

                    {!initial && (
                      <PauseCircleOutlined
                        onClick={() =>
                          actionMedicine(record?.id, { status: "Withheld" })
                        }
                      />
                    )}

                    <Popconfirm
                      title="Sure to stop"
                      onConfirm={() =>
                        actionMedicine(record?.id, { status: "Stopped" })
                      }
                    >
                      <StopOutlined />
                    </Popconfirm>
                  </Space>
                ) : record?.status === "Withheld" ? (
                  <RedoOutlined
                    onClick={() =>
                      actionMedicine(record?.id, { status: "Started" })
                    }
                  />
                ) : (
                  record?.status === "Stopped" && (
                    <Button
                      onClick={() => {
                        setShowAddMedicine(true);
                        setEditData(record);
                      }}
                      style={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "5px",
                      }}
                    >
                      Add Again
                    </Button>
                  )
                )}
              </div>
              <div>
                {record?.status === "Stopped" ? (
                  <Tag color="red">Stopped</Tag>
                ) : (
                  record?.status === "Withheld" && (
                    <Tag color="yellow">Withheld</Tag>
                  )
                )}
              </div>
            </div>
          </>
        );
      },
    });
  }
  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          pagination={false}
          bordered
          scroll={{ y: "calc(100vh - 390px)", x: "400px" }}
          footer={() => (
            <>
              {showAddMedicine ? (
                <Row gutter={[2, 2]}>
                  <AddMedicineForm
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                    form={form}
                    selectedFrequency={selectedFrequency}
                    setSelectedFrequency={setSelectedFrequency}
                    initial={initial}
                    editData={editData}
                  />
                  <Col
                    span={2}
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "",
                    }}
                  >
                    <CheckOutlined
                      style={{
                        fontSize: "1.5em",
                        color: "green",
                        marginLeft: "10px",
                        marginRight: "20px",
                      }}
                      onClick={() => {
                        form.submit();
                      }}
                    />
                    <CloseOutlined
                      style={{ fontSize: "1.5rem", color: "red" }}
                      onClick={() => {
                        setShowAddMedicine(false);
                        form.resetFields();
                        setSelectedFrequency(null);
                      }}
                    />
                  </Col>
                </Row>
              ) : (
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {actionsEnabled && (
                    <Button
                      type="primary"
                      // className={btnStyles.mdBtn}
                      onClick={() => {
                        setShowAddMedicine(true);
                        form.resetFields();
                        setEditData(null);
                      }}
                    >
                      {"Add Medicine"}
                    </Button>
                  )}
                </Col>
              )}
            </>
          )}
        />
      </Form>

      {addModal && (
        <AddMedicineModal
          open={addModal}
          setOpen={setAddModal}
          refreshPage={refreshPage}
          editData={editData}
          initial={editData?.is_initial}
        />
      )}
      {initialModal && (
        <AddInitialMedicineModal
          open={initialModal}
          setOpen={setInitialModal}
          refreshPage={refreshPage}
          editData={editData}
        />
      )}
    </>
  );
};

export default AddMedicineTable;
