import React, { useState } from "react";
import { noYesOptions } from "../../../../Options/commonOptions";
import {
  contractureAreas,
  leftRightOptions,
} from "../../../../Options/systemicExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const Contractures = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <DeselectableRadioGroup
        name={"contractures"}
        label={"Contractures"}
        options={noYesOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("contractures") === "yes" && (
        <div className="subsection-card">
          {contractureAreas.map((area) => (
            <DeselectableRadioGroup
              name={`contractures_${area.toLowerCase()}`}
              label={area}
              options={leftRightOptions}
              form={form}
            />
          ))}
        </div>
      )}
    </>
  );
};
export default Contractures;
