import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Statistic,
  Space,
  Tooltip,
  Divider,
  Image,
} from "antd";
import CommonGraph from "./CommonGraph";
import eyeIcon from "../../../../../assets/images/patientIcons/eye.svg";
import verbalIcon from "../../../../../assets/images/patientIcons/verbal.svg";
import motorIcon from "../../../../../assets/images/patientIcons/motor.svg";
import vitalsIcon from "../../../../../assets/images/nurseIcons/heart-rate.svg";

const Graphs = ({ data }) => {
  const [noData, setNoData] = useState(false);
  const graphHeadings = [
    {
      heading: "Pulse Rate",
      graphData: data?.pulse_rate,
      lineColor: "#4169E1",
    },
    {
      heading: "Systolic BP",
      graphData: data?.["systolic BP"],
      lineColor: "#FF6977",
    },
    {
      heading: "Diastolic BP",
      graphData: data?.["diastolic BP"],
      lineColor: "#2DB181",
    },
    {
      heading: "Temp",
      graphData: data?.temperature,
      lineColor: "#6941C6",
    },
    {
      heading: "Respiratory Care",
      graphData: data?.respiratory_rate,
      lineColor: "#FDD330",
    },
    {
      heading: "SpO2",
      graphData: data?.oxygen_saturation,
      lineColor: "#31A7FB",
    },
  ];

  console.log('data',data)

  useEffect(() => {
    const checkGraphData = graphHeadings.every(
      (item) => item.graphData?.length > 0
    );

    const gcsScalesEmpty =
      (!data?.gcs_scales?.eye_opening_response ||
        data.gcs_scales.eye_opening_response === "0") &&
      (!data?.gcs_scales?.verbal_response ||
        data.gcs_scales.verbal_response === "0") &&
      (!data?.gcs_scales?.motor_response ||
        data.gcs_scales.motor_response === "0");

    const inputOutputEmpty =
      data?.["input/output"]?.input === 0 &&
      data?.["input/output"]?.output === 0 &&
      data?.["input/output"]?.balance === 0;

    setNoData(
      !checkGraphData &&
        gcsScalesEmpty &&
        inputOutputEmpty &&
        data?.grbs?.length === 0
    );
  }, [data]);

  const balance =
    data?.["input/output"]?.input - data?.["input/output"]?.output || 0;

  return (
    <>
      {noData ? (
        <Card
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "55px",
            marginTop: "10px",
          }}
        >
          <Image src={vitalsIcon} width={50} preview={false} />
          <h3 style={{ color: "#a3a2a2", fontWeight: "300" }}>
            Vitals not added
          </h3>
        </Card>
      ) : (
        <Row gutter={[8, 8]}>
          {graphHeadings.map((item, index) => (
            <Col span={8} key={index}>
              <CommonGraph
                title={item.heading}
                data={item.graphData}
                lineColor={item.lineColor}
              />
            </Col>
          ))}
          <Col span={8}>
            <Card
              style={{ height: "100%" }}
              size="small"
              className="tiny-graph-card"
              title={
                <Row>
                  <Col span={12}>
                    <Typography>IO Balance</Typography>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    <Statistic
                      value={balance}
                      valueStyle={{
                        color: balance < 0 ? "#FF4D4F" : "#52C41A",
                        fontWeight: 600,
                        fontSize: "24px",
                      }}
                    />
                  </Col>
                </Row>
              }
            >
              <Typography style={{ textAlign: "center" }}>
                Input / Output
              </Typography>
              <Space
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {data?.["input/output"]?.input &&
                  data?.["input/output"]?.output && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#52C41A",
                          fontWeight: 600,
                          fontSize: "24px",
                          marginRight: 4,
                        }}
                      >
                        {data?.["input/output"]?.input}
                      </Typography>
                      <Typography
                        style={{ fontSize: "24px", marginHorizontal: 4 }}
                      >
                        /
                      </Typography>
                      <Typography
                        style={{
                          color: "#FF4D4F",
                          fontWeight: 600,
                          fontSize: "24px",
                          marginLeft: 4,
                        }}
                      >
                        {data?.["input/output"]?.output}
                      </Typography>
                    </div>
                  )}
              </Space>
            </Card>
          </Col>
          <Col span={8}>
            <CommonGraph title="GRBS" data={data?.grbs} lineColor="#FD30E8" />
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card
              size="small"
              className="tiny-graph-card"
              style={{ height: "100%" }}
              title={<Typography>GCS</Typography>}
            >
              <Space
                size="middle"
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "nowrap", // Prevent wrapping
                  marginTop: "-20px",
                  padding: "0px 10px",
                  overflowX: "auto", // Allow horizontal scrolling if necessary
                }}
              >
                <Space
                  direction="vertical"
                  align="center"
                  style={{
                    maxWidth: "100px", // Set a maximum width for each column
                    textAlign: "center",
                    overflowWrap: "break-word", // Break long words if necessary
                  }}
                >
                  <Tooltip title="Eye Opening Response">
                    <h2 style={{ fontSize: "20px" }}>E</h2>
                  </Tooltip>
                  <Statistic
                    value={data?.gcs_scales?.eye_opening_response}
                    valueStyle={{
                      fontSize: "18px",
                      textOverflow: "ellipsis", // Handle overflowing text
                      overflow: "hidden", // Hide overflow
                      whiteSpace: "nowrap", // Prevent wrapping
                    }}
                  />
                </Space>
                <Divider type="vertical" />
                <Space
                  direction="vertical"
                  align="center"
                  style={{
                    maxWidth: "100px",
                    textAlign: "center",
                    overflowWrap: "break-word",
                  }}
                >
                  <Tooltip title="Verbal Response">
                    <h2 style={{ fontSize: "20px" }}>V</h2>
                  </Tooltip>
                  <Statistic
                    value={data?.gcs_scales?.verbal_response}
                    valueStyle={{
                      fontSize: "18px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  />
                </Space>
                <Divider type="vertical" />
                <Space
                  direction="vertical"
                  align="center"
                  style={{
                    maxWidth: "100px",
                    textAlign: "center",
                    overflowWrap: "break-word",
                  }}
                >
                  <Tooltip title="Motor Response">
                    <h2 style={{ fontSize: "20px" }}>M</h2>
                  </Tooltip>
                  <Statistic
                    value={data?.gcs_scales?.motor_response}
                    valueStyle={{
                      fontSize: "18px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  />
                </Space>
              </Space>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Graphs;
