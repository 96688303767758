import React from "react";
import CommonTable from "../CommonTable";
import dayjs from "dayjs";

const Dressing = ({ data, heading, type }) => {
  const dressingColumns = [
    { title: "Dressing", dataIndex: "dressing" },
    { title: "Saline Dressing", dataIndex: "dressing" },
    { title: "Medicated Dressing", dataIndex: "dressing" },
  ];
  const debridementColumns = [
    { title: "Debridement", dataIndex: "debridment" },
  ];
  const suturingColumns = [{ title: "Suturing", dataIndex: "suturing" }];
  const vacColumns = [
    { title: "Drinage in ml", dataIndex: "drainage" },
    { title: "Color", dataIndex: "color" },
    { title: "VAC Change", dataIndex: "vac_change" },
  ];
  const commonColumns = [
    {
      title: "Time Done",
      dataIndex: "process_time",
      key: "process_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];

  const finalColumns =
    type === "open_wound_care_dressing"
      ? [...dressingColumns, ...commonColumns]
      : type === "open_wound_care_debridement"
      ? [...debridementColumns, ...commonColumns]
      : type === "open_wound_care_suturing"
      ? [...suturingColumns, ...commonColumns]
      : [...vacColumns, ...commonColumns];
  return (
    <div>
      <CommonTable
        data={data?.nurse_data}
        columns={finalColumns}
        titleData={() => heading}
      />
    </div>
  );
};

export default Dressing;
