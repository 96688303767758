import React, { useState } from "react";
import { postureOptions } from "../../../../Options/physioOptions";
import SelectWithOtherInput from "../../../../../../blocks/FormItems/SelectWithOtherInput";

const Posture = ({ form }) => {
  const [refresh, setRefresh] = useState(false);
  return (
    <>
      <SelectWithOtherInput
        name={"posture"}
        label={"Posture"}
        form={form}
        options={postureOptions}
        otherLabel={" "}
        selectProps={{
          onChange: () => {
            setRefresh((prev) => !prev);
          },
        }}
      />
    </>
  );
};

export default Posture;
