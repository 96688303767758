import React, { useContext } from "react";
import {
  getLabel,
  getLabelTagInput,
  getLabelTagsArray,
} from "../constructTextFromValues";
import {
  cardiovascularOptions,
  neurologicalExaminationOptions,
  orthopedicOptions,
  respiratoryOptions,
  traumaOptions,
} from "../../Options/clinicalImpressionOptions";
import { Descriptions } from "antd";
import { FileDoneOutlined } from "@ant-design/icons";

const DrClinicalImpressionSummary = ({ context }) => {
  const { formData } = useContext(context);
  const clinicalImpressionSummaryItems = [];

  const constructClinicalImpressionSummaryItems = () => {
    if (
      formData?.clinical_diagnosis?.neurological_examination ||
      formData?.clinical_diagnosis?.neurological_examination_others
    ) {
      clinicalImpressionSummaryItems.push({
        key: "1",
        label: "Neurological System",
        children: (
          <>
            {getLabelTagsArray(
              neurologicalExaminationOptions,
              formData?.clinical_diagnosis?.neurological_examination
            )}
            {getLabelTagInput(
              "Other Details",
              formData?.clinical_diagnosis?.neurological_examination_others
            )}
          </>
        ),
      });
    }
    if (
      formData?.clinical_diagnosis?.respiratory_diagnosis ||
      formData?.clinical_diagnosis?.respiratory_others
    ) {
      clinicalImpressionSummaryItems.push({
        key: "2",
        label: "Respiratory",
        children: (
          <>
            {getLabelTagsArray(
              respiratoryOptions,
              formData?.clinical_diagnosis?.respiratory_diagnosis
            )}
            {getLabelTagInput(
              "Other Details",
              formData?.clinical_diagnosis?.respiratory_others
            )}
          </>
        ),
      });
    }
    if (
      formData?.clinical_diagnosis?.cardiovascular_diagnosis ||
      formData?.clinical_diagnosis?.cardiovascular_others
    ) {
      clinicalImpressionSummaryItems.push({
        key: "3",
        label: "Cardiovascular",
        children: (
          <>
            {getLabelTagsArray(
              cardiovascularOptions,
              formData?.clinical_diagnosis?.cardiovascular_diagnosis
            )}
            {getLabelTagInput(
              "Other Details",
              formData?.clinical_diagnosis?.cardiovascular_others
            )}
          </>
        ),
      });
    }
    if (formData?.clinical_diagnosis?.orthopedic_diagnosis) {
      clinicalImpressionSummaryItems.push({
        key: "4",
        label: "Orthopedic",
        children: getLabelTagsArray(
          orthopedicOptions,
          formData?.clinical_diagnosis?.orthopedic_diagnosis
        ),
      });
    }
    if (
      formData?.clinical_diagnosis?.trauma_diagnosis ||
      formData?.clinical_diagnosis?.trauma_others
    ) {
      clinicalImpressionSummaryItems.push({
        key: "5",
        label: "Trauma Diagnosis",
        children: (
          <>
            {getLabelTagsArray(
              traumaOptions,
              formData?.clinical_diagnosis?.trauma_diagnosis
            )}
            {getLabelTagInput(
              "Other Details",
              formData?.clinical_diagnosis?.trauma_others
            )}
          </>
        ),
      });
    }
    if (formData?.clinical_diagnosis?.clinical_diagnosis_notes) {
      clinicalImpressionSummaryItems.push({
        key: "6",
        label: "Clinical Diagonsis",
        children: getLabel(
          formData?.clinical_diagnosis?.clinical_diagnosis_notes
        ),
      });
    }
    if (formData?.clinical_diagnosis?.final_diagnosis_notes) {
      clinicalImpressionSummaryItems.push({
        key: "7",
        label: "Final Diagnosis",
        children: getLabel(formData?.clinical_diagnosis?.final_diagnosis_notes),
      });
    }
    return clinicalImpressionSummaryItems;
  };

  return (
    <div>
      <Descriptions
        style={{ textAlign: "left" }}
        bordered
        items={constructClinicalImpressionSummaryItems()}
        column={1}
        title={
          <span style={{ fontWeight: 500 }}>
            <FileDoneOutlined
              style={{ color: "#7F56D9", fontWeight: "bold" }}
            />{" "}
            Clinical Impression/Diagnosis
          </span>
        }
        className="initial-insp-desc"
      />
    </div>
  );
};

export default DrClinicalImpressionSummary;
