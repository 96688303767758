import React, { useContext, useEffect } from "react";
import SubjectiveAssessment from "./SubjectiveAssessment/SubjectiveAssessment";
import { Divider } from "antd";
import PhysioDailyActivities from "./DailyActivities/PhysioDailyActivities";
import Endurance from "./Endurance/Endurance";
import SpeechSwallowAssessment from "./SpeechSwallowAssessment/SpeechSwallowAssessment";
import { PhysioInitialContext } from "../../Physiotherapist/PhysiotherapistInitialInspection/PhysiotherapistInitialAssessmentProvider";

const PhysioFunctionalAssessment = ({ form }) => {
  const { formData } = useContext(PhysioInitialContext);
  useEffect(() => {
    form.setFieldsValue(formData?.functional_status);
  }, [formData]);
  return (
    <>
      <SubjectiveAssessment context={PhysioInitialContext} form={form} />
      <Divider />
      <PhysioDailyActivities context={PhysioInitialContext} form={form} />
      <Divider />
      <Endurance context={PhysioInitialContext} form={form} />
      <Divider />
      <SpeechSwallowAssessment form={form} />
    </>
  );
};

export default PhysioFunctionalAssessment;
