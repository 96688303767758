import React, { useContext, useEffect, useState } from "react";
import { NutritionistInitialContext } from "../../InspectionForms/HistoryForm/NutritionistInitialAssessmentProvider";
import { getNutritionistInspectionAPI } from "../../../../services/nutritionist.services";
import NutritionistInitialInspectionSummary from "./NutritionistInitialInspectionSummary";
import { Button, Col, Drawer, Row } from "antd";
import NutritionistInitialInspectionForm from "./NutritionistInitialInspectionForm";
import ClosePopup from "../../Doctor/InitialInspection/ClosePopup";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { EditOutlined } from "@ant-design/icons";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import assessmentIcon from "../../../../assets/images/nurseIcons/assessment.svg";
import { UserContext } from "../../../../context/UserContext";
import { getCookie } from "../../../../helpers/localStorage";
import { Role } from "../../../../constants/defaultKeys";

const NutritionistInitialInspection = ({
  refreshPage,
  editId,
  patientData,
}) => {
  const { userDetails } = useContext(UserContext);
  const [visible, setVisible] = useState(false);
  const { formData, setFormData } = useContext(NutritionistInitialContext);
  const [initialRefresh, setInitialRefresh] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const setInitialAssessmentRefresh = () => {
    setInitialRefresh((prev) => !prev);
  };

  const openEdit = async () => {
    if (editId) {
      try {
        const response = await getNutritionistInspectionAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    }
  };
  useEffect(() => {
    openEdit();
  }, [editId, initialRefresh]);

  const editRole = getCookie(Role) || userDetails?.currRole;
  return (
    <>
      {editRole === "Nutritionist" ? (
        <>
          {" "}
          {editId ? (
            <Row gutter={[12, 12]}>
              <Col span={24} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    setVisible(true);
                    setAddModal(false);
                  }}
                  className={`${btnStyles.lgBtn} inv-btn `}
                  style={{ fontWeight: "normal" }}
                >
                  <EditOutlined />
                  Edit
                </Button>
              </Col>
              <Col span={24} style={{ textAlign: "center" }}>
                <NutritionistInitialInspectionSummary editId={editId} />
              </Col>
            </Row>
          ) : (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "80vh",
              }}
            >
              <Col span={24} style={{ textAlign: "center" }}>
                <NoDataPage
                  title={"Initial Assessment Not Yet Submitted "}
                  subtitle={"Kindly, upload an initial assessment here"}
                  image={assessmentIcon}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    setVisible(true);
                    setAddModal(true);
                  }}
                  className={`${btnStyles.lgBtn} inv-btn `}
                  style={{ fontWeight: "normal" }}
                >
                  Initial Assessment Form
                </Button>
              </Col>
            </Row>
          )}
          {visible && (
            <Drawer
              open={visible}
              width="100%"
              footer={null}
              onClose={() => setVisible(false)}
              closeIcon={null}
              keyboard={false}
            >
              <NutritionistInitialInspectionForm
                visible={visible}
                setVisible={setVisible}
                refreshPage={refreshPage}
                initialRefresh={setInitialAssessmentRefresh}
                editId={editId}
                patientData={patientData}
                setConfirmModal={setConfirmModal}
              />

              {confirmModal && (
                <ClosePopup
                  open={confirmModal}
                  setOpen={setConfirmModal}
                  setVisible={setVisible}
                />
              )}
            </Drawer>
          )}
        </>
      ) : (
        <Col span={24} style={{ textAlign: "center" }}>
          {editId ? (
            <NutritionistInitialInspectionSummary editId={editId} />
          ) : (
            <NoDataPage
              title={"Nutritionist Initial Assessment Not Yet Submitted "}
              image={assessmentIcon}
            />
          )}
        </Col>
      )}
    </>
  );
};

export default NutritionistInitialInspection;
