import React, { useContext, useEffect, useState } from "react";
import { notAssessableAndAssessableOptions } from "../../../Options/commonOptions";
import RPESelection from "../../Examination/PhysioScales/RPEScaleComponents/RPESelection";
import SixMinWalkTestItems from "../../Examination/PhysioScales/SixMinWalkTest/SixMinWalkTestItems";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const Endurance = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context);

  const [states, setStates] = useState({});
  const calculateDistance = () => {
    const { gender, age, height, weight } = states;
    return gender === "male adult"
      ? 7.57 * height - 5.02 * age - 1.76 * weight - 309
      : 2.11 * height - 2.29 * weight - 5.78 * age + 667;
  };

  const handleChange = (key, value) => {
    setStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const predictedDistance = calculateDistance().toFixed(0);

  useEffect(() => {
    setStates({
      gender: formData?.functional_status?.endurance?.smwt?.gender,
      height: formData?.functional_status?.endurance?.smwt?.height,
      age: formData?.functional_status?.endurance?.smwt?.age,
      weight: formData?.functional_status?.endurance?.smwt?.weight,
    });
  }, [formData]);
  return (
    <>
      <DeselectableRadioGroup
        label={"Endurance"}
        name={["endurance", "status"]}
        options={notAssessableAndAssessableOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue(["endurance", "status"]) === "assessable" && (
        <>
          <RPESelection nameKey={"endurance"} form={form} />
          <SixMinWalkTestItems
            predictedDistance={predictedDistance}
            handleChange={handleChange}
            states={states}
            nameKey={"endurance"}
            form={form}
          />
        </>
      )}
    </>
  );
};

export default Endurance;
