import React, { useContext, useState, useEffect, useMemo } from "react";
import { Form, Radio } from "antd";
import CheckListFBYRadioItems from "../../../../../../blocks/FormItems/CheckListFBYRadioItems";
import {
  pupilOptions,
  pupilRadioOptions,
  pupilSideOptions,
} from "../../../../Options/genExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const Pupils = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);
  const [checkboxes, setCheckboxes] = useState([]);
  useEffect(() => {
    const initialCheckboxes = pupilSideOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        initialValues?.general_examination?.[checkbox.value] || false; // Initialize all checkboxes based on initialValues
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <DeselectableRadioGroup
        label={"Pupils"}
        name={"pupil_condition"}
        options={pupilOptions}
        setRefresh={setRefresh}
        form={form}
      />

      {form.getFieldValue("pupil_condition") === "abnormal" && (
        <div className="subsection-card">
          <CheckListFBYRadioItems
            checkBoxOptions={pupilSideOptions}
            radioOptions={pupilRadioOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            form={form}
          />
        </div>
      )}
    </>
  );
};

export default Pupils;
