import React from "react";
import { Card, Typography } from "antd";
import CommonTable from "../CommonTable";
import dayjs from "dayjs";
const Medications = ({ data }) => {
  const columns = [
    {
      title: "Time Done",
      dataIndex: "action_time",
      key: "action_time",
      render: (value, record) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];
  const filteredData = data?.filter(
    (item) => item?.frequency !== "If_Required"
  );
  const sosData = data?.filter((item) => item?.frequency === "If_Required");
  return (
    <div>
      {filteredData?.map((item) => (
        <Card title={item?.name} style={{ marginTop: "10px" }}>
          <CommonTable data={item?.actions} columns={columns} />
        </Card>
      ))}
      {sosData?.map((item) => (
        <Card title={item?.name} style={{ marginTop: "10px" }}>
          <CommonTable
            data={item?.actions?.[0]?.nurse_data}
            columns={columns}
          />
        </Card>
      ))}
    </div>
  );
};

export default Medications;
