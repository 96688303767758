import React, { useState } from "react";
import { beckDepressionOptions } from "../../Options/psychologistOptions";
import { Form, List, Typography, Input } from "antd";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const BeckDepression = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  const calculateAggregate = () => {
    const scaleValues = form.getFieldValue(["beck_depression", "scale"]) || {};
    const sum = Object?.values(scaleValues)?.reduce((acc, cur) => {
      if (typeof cur === "string") {
        cur = parseInt(cur[0], 10);
      }
      return acc + (isNaN(cur) ? 0 : cur);
    }, 0);
    form.setFieldsValue({ beck_depression: { aggregate: sum } });
    return sum;
  };

  const getDepressionLevelText = (aggregate) => {
    const interpretation =
      aggregate < 11
        ? "These ups and downs are considered normal"
        : aggregate < 17
        ? "Mild mood disturbance"
        : aggregate < 20
        ? "Borderline Depression"
        : aggregate < 31
        ? "Moderate Depression"
        : aggregate < 41
        ? "Severe Depression"
        : "Extreme Depression";
    form.setFieldsValue({
      beck_depression: { interpretation: interpretation },
    });
    return interpretation;
  };

  const aggregate = calculateAggregate();
  const depressionLevelText = getDepressionLevelText(aggregate);

  return (
    <>
      <Typography.Title level={5}>Beck Depression Inventory</Typography.Title>
      {beckDepressionOptions.map((option) => (
        <DeselectableRadioGroup
          label={option?.label}
          name={["beck_depression", "scale", option.value]}
          options={option?.options}
          form={form}
          setRefresh={setRefresh}
        />
      ))}

      <List bordered>
        <List.Item>
          <Typography.Text strong>
            Aggregate Score: {aggregate}{" "}
          </Typography.Text>
          <Form.Item name={["beck_depression", "aggregate"]} hidden>
            <Input />
          </Form.Item>
        </List.Item>
        <List.Item>
          <Typography.Text strong>
            Depression Level: {depressionLevelText}
          </Typography.Text>
          <Form.Item name={["beck_depression", "interpretation"]} hidden>
            <Input />
          </Form.Item>
        </List.Item>
      </List>
    </>
  );
};

export default BeckDepression;
