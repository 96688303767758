import React, { useState } from "react";
import {
  coordinationOptions,
  coordinationTests,
  intactImpairedOptions,
} from "../../../../Options/systemicExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const Coordination = ({ context, form }) => {
  const [refesh, setRefresh] = useState(false);

  return (
    <>
      <DeselectableRadioGroup
        label={"Co-ordination"}
        name={"coordination"}
        options={coordinationOptions}
        form={form}
        setRefresh={setRefresh}
      />

      {form.getFieldValue("coordination") === "abnormal" && (
        <div className="subsection-card">
          {coordinationTests.map((test) => (
            <DeselectableRadioGroup
              label={test.label}
              name={test.name}
              options={intactImpairedOptions}
              form={form}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default Coordination;
