import React from "react";
import { Space, Tag, Typography } from "antd";
import CommonTable from "../CommonTable";
import { pressureSoreThromboprophylaxisOptions } from "../../../IpEMR/Options/careItemsOptions";
import tagStyles from "../../../../styles/Custom/Tag.module.css";
import { capitalizeFirstLetter } from "../../../../helpers/utility";
import dayjs from "dayjs";
const Thrombo = ({ data }) => {
  const columns = [
    {
      title: "Time Done",
      dataIndex: "process_time",
      key: "process_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];
  return (
    <div>
      {data?.map((item) => (
        <CommonTable
          data={item?.actions?.flat()}
          columns={columns}
          titleData={() => (
            <Space>
              <Typography style={{ fontWeight: "lighter" }}>
                {pressureSoreThromboprophylaxisOptions?.find(
                  (option) => option?.value === item?.drug_name
                )?.label || "-"}
              </Typography>
              <Tag className={tagStyles.smRndTag} color="blue">
                Advised by {capitalizeFirstLetter(item?.advised_by)}
              </Tag>
            </Space>
          )}
        />
      ))}
    </div>
  );
};

export default Thrombo;
