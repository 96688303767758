import React, { useState, useCallback, useMemo, useEffect } from "react";
import IndirectSwallowingTest from "./SpeechSwallowComponents/IndirectSwallowingTest";
import { Divider, Form, Input, Typography } from "antd";
import DirectSwallowingTestSS from "./SpeechSwallowComponents/DirectSwallowingTestSS";
import DirectSwallowingTestLiquid from "./SpeechSwallowComponents/DirectSwallowingTestLiquid";
import DirectSwallowingTestSolid from "./SpeechSwallowComponents/DirectSwallowingTestSolid";

const GUSS = ({ context, form }) => {
  const [gussRefresh, setGussRefresh] = useState(false);
  const [indirect, setIndirect] = useState(0);
  const [semisolid, setSemisolid] = useState(0);
  const [liquid, setLiquid] = useState(0);
  const [solid, setSolid] = useState(0);

  const score = indirect + semisolid + liquid + solid;

  return (
    <>
      <Typography.Title level={5}>GUSS: {score}</Typography.Title>
      <Form.Item name="guss_score" hidden>
        <Input value={score} />
      </Form.Item>
      <IndirectSwallowingTest form={form} setIndirect={setIndirect} />
      <Divider />
      <DirectSwallowingTestSS form={form} setSemisolid={setSemisolid} />
      <Divider />
      <DirectSwallowingTestLiquid form={form} setLiquid={setLiquid} />
      <Divider />
      <DirectSwallowingTestSolid form={form} setSolid={setSolid} />
    </>
  );
};

export default GUSS;
