import { createContext, useState, useEffect } from "react";
import { Spin, message } from "antd";
import { getCookie, getItem, removeItem } from "../helpers/localStorage";
import {
  ACCESS_TOKEN,
  FacilityID,
  ORGANISATONID,
  Role,
} from "../constants/defaultKeys";
import { useLocation } from "react-router-dom";
import { userMetaDataApi } from "../services/auth.services";

export const UserContext = createContext({});

export function UserProvider({ children }) {
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const getUserData = async () => {
    try {
      let resp = await userMetaDataApi();
      const orgId = getCookie(ORGANISATONID);
      const activeOrg = orgId
        ? resp?.data?.organizations?.filter((org) => org?.org_id == orgId)?.[0]
        : {};
      const currFacility = getCookie(FacilityID)
        ? getCookie(FacilityID)
        : activeOrg?.roles?.[0]?.facility_id;
      const currRole = getCookie(Role)
        ? getCookie(Role)
        : activeOrg?.roles?.[0]?.role;
      setUserDetails({
        ...resp?.data,
        activeOrg,
        currFacility,
        currRole,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500); // Set loading to false after a delay of 0.5 second
    }
  };

  useEffect(() => {
    const myCookieValue = getCookie(ACCESS_TOKEN);
    const isLogoutPath = location.pathname === "/logout";
    if (getItem("customError")) {
      message.error(getItem("customError"));
      removeItem("customError");
    }
    if (myCookieValue && !isLogoutPath) {
      getUserData();
    } else {
      setUserDetails({});
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <div className="flex-center" style={{ height: "100vh" }}>
        <Spin />
      </div>
    );
  }

  return (
    <UserContext.Provider value={{ userDetails, setUserDetails }}>
      {children}
    </UserContext.Provider>
  );
}
