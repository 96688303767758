import React from "react";
import { Form, Space, Button, Typography, InputNumber, Select } from "antd";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import ServiceSearch from "./ServiceSearch";

const priceTagList = [
  { name: "%", id: true },
  { name: "₹", id: false },
];

const ServiceDetails = ({ form, totalPrice, setTotalPrice }) => {
  return (
    <div>
      <Typography
        style={{
          fontSize: "20px",
          fontWeignt: 500,
          color: "#000000",
          background: "#F0ECFE",
          padding: "8px",
          borderRadius: "10px",
          marginBottom: "10px",
        }}
      >
        Service Details
      </Typography>
      <Form.List name="services">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} style={{ display: "flex", marginBottom: 8 }}>
                <ServiceSearch
                  form={form}
                  name={name}
                  totalPrice={totalPrice}
                  setTotalPrice={setTotalPrice}
                />
                <Form.Item
                  {...restField}
                  label="Quantity"
                  name={[name, "quantity"]}
                  rules={[{ required: true, message: "Enter quantity" }]}
                >
                  <InputNumber
                    min={1}
                    placeholder="Quantity"
                    className={`${inputStyles.mdNumberInput}`}
                  />
                </Form.Item>
                <Form.Item {...restField} label="Price" name={[name, "price"]}>
                  <InputNumber
                    // disabled
                    style={{ width: "250px" }}
                    placeholder="Price"
                    prefix="Rs"
                    className={`${inputStyles.mdNumberInput}`}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Total Price"
                  name={[name, "totalservice_price"]}
                  // rules={[{ required: true, message: "Enter price" }]}
                >
                  <InputNumber
                    disabled
                    prefix="Rs"
                    className={`${inputStyles.mdNumberInput}`}
                    style={{ width: "200px" }}
                  />
                </Form.Item>
                <Space.Compact>
                  <Form.Item label="Discount" name={[name, "discount"]}>
                    <InputNumber
                      placeholder="Enter Discount"
                      className={`${inputStyles.mdNumberInput}`}
                      style={{ width: "200px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    label=" "
                    name={[name, "is_percentage"]}
                    style={{ width: "50%" }}
                  >
                    <Select
                      className={inputStyles.mdSelect}
                      defaultValue={false}
                    >
                      {priceTagList?.map((data) => {
                        return (
                          <Select.Option key={data?.id} value={data?.id}>
                            {data?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Space.Compact>

                <DeleteOutlined onClick={() => remove(name)} />
              </Space>
            ))}

            <Form.Item>
              <Button
                type="primary"
                ghost
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Another service
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default ServiceDetails;
