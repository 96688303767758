import React, { useEffect } from "react";
import LevelOfConsciousness from "./GeneralExaminationForm/GeneralExaminationComponents/LevelOfConsciousness";
import { Divider, Typography } from "antd";
import VitalsAdmission from "./GeneralExaminationForm/GeneralExaminationComponents/VitalsAdmission";
import PainScale from "./GeneralExaminationForm/GeneralExaminationComponents/PainScale";
import RespiratoryCondition from "./GeneralExaminationForm/GeneralExaminationComponents/RespiratoryCondition";
import Pulse from "./GeneralExaminationForm/GeneralExaminationComponents/Pulse";
import NursePressureSore from "./SystemicExaminationForm/SkinForm/NursePressureSore";
import SoftTissueInjury from "./SystemicExaminationForm/SkinForm/SoftTissueInjury";
import IVAccess from "./GeneralExaminationForm/GeneralExaminationComponents/IVAccess";
import FeedingTube from "./GeneralExaminationForm/GeneralExaminationComponents/FeedingTube";
import NurseDrain from "./GeneralExaminationForm/GeneralExaminationComponents/NurseDrain";
import UrinaryCatheter from "./GeneralExaminationForm/GeneralExaminationComponents/UrinaryCatheter";
import Ostomies from "./GeneralExaminationForm/GeneralExaminationComponents/Ostomies";
import Paralysis from "./GeneralExaminationForm/GeneralExaminationComponents/Paralysis";
import NurseFractures from "./GeneralExaminationForm/GeneralExaminationComponents/NurseFractures";
import { NurseInitialContext } from "../HistoryForm/NurseInitialAssessmentFormProvider";

const NurseExaminationForm = ({ form, formData }) => {
  useEffect(() => {
    form.setFieldsValue(formData?.examination);
  }, [formData]);
  return (
    <>
      <LevelOfConsciousness context={NurseInitialContext} form={form} />
      <Divider />
      <VitalsAdmission />
      <Divider />
      <PainScale context={NurseInitialContext} form={form} />
      <Divider />
      <RespiratoryCondition context={NurseInitialContext} form={form} />
      <Divider />
      <Pulse form={form} />
      <Divider />
      <NursePressureSore context={NurseInitialContext} form={form} />
      <Divider />
      <SoftTissueInjury context={NurseInitialContext} />
      <Divider />
      <IVAccess context={NurseInitialContext} form={form} />
      <Divider />
      <FeedingTube context={NurseInitialContext} form={form} />
      <Divider />
      <NurseDrain context={NurseInitialContext} />
      <Divider />
      <UrinaryCatheter context={NurseInitialContext} form={form} />
      <Divider />
      <Ostomies context={NurseInitialContext} form={form} />
      <Divider />
      <Paralysis context={NurseInitialContext} form={form} />
      <Divider />
      <NurseFractures context={NurseInitialContext} form={form} />
    </>
  );
};

export default NurseExaminationForm;
