import { Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { notAssessableAndAssessableOptions } from "../../../Options/commonOptions";
import BarthelIndexItems from "../../Examination/PhysioScales/BarthelIndexComponents/BarthelIndexItems";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const PhysioDailyActivities = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context);
  const [states, setStates] = useState({});

  useEffect(() => {
    setStates(
      Object.fromEntries(
        Object.entries(formData?.functional_status?.barthel_index || {}).filter(
          ([key]) =>
            [
              "feeding",
              "bathing",
              "grooming",
              "dressing",
              "bowels",
              "bladder",
              "toilet_use",
              "transfers",
              "mobility",
              "stairs",
            ].includes(key)
        )
      )
    );
  }, [formData]);

  const handleChange = (category, value) => {
    setStates((prev) => ({ ...prev, [category]: value }));
  };

  const score = Object.values(states).reduce((acc, cur) => acc + cur, 0);

  return (
    <>
      <DeselectableRadioGroup
        label={"Daily Activities"}
        name={"daily_activities"}
        options={notAssessableAndAssessableOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("daily_activities") === "assessable" && (
        <>
          <Typography>Score: {score}</Typography>
          <BarthelIndexItems
            states={states}
            handleChange={handleChange}
            nameKey={"barthel_index"}
          />
        </>
      )}
    </>
  );
};

export default PhysioDailyActivities;
