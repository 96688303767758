import React from "react";
import { nutritionistFunctionalOptions } from "../../../Options/historyOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const NutritionistFunctionalCapacity = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Functional Capacity"}
        name={"functional_capacity"}
        options={nutritionistFunctionalOptions}
        form={form}
      />
    </>
  );
};

export default NutritionistFunctionalCapacity;
