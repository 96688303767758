import React from "react";
import CommonTable from "./CommonTable";
import dayjs from "dayjs";

const OstomyCare = ({ data }) => {
  const columns = [
    {
      title: "Bag Emptying",
      dataIndex: "bag_emptying",
      key: "bag_emptying",
    },
    {
      title: "Local Appearance",
      dataIndex: "local_appearance",
      key: "local_appearance",
    },
    {
      title: "Bag Change",
      dataIndex: "bag_change",
      key: "bag_change",
    },
    {
      title: "Time Done",
      dataIndex: "process_time",
      key: "process_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];
  return (
    <div>
      {data?.map((item) => (
        <CommonTable data={item?.actions?.flat()} columns={columns} />
      ))}
    </div>
  );
};

export default OstomyCare;
