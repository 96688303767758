import React, { useEffect, useState } from "react";
import { Row, Col, DatePicker, Collapse } from "antd";
import { getPatientMonitoringChartsApi } from "../../../services/patient.services";
import VitalsTable from "./VitalsTable";
import GrbsTable from "./GrbsTable";
import UrinaryCatheter from "./UrinaryCatheter";
import Nebulization from "./Nebulization";
import IVFluids from "./IVFluids";
import BloodProducts from "./BloodProducts";
import ParentalNutrition from "./ParentalNutrition";
import RespiratoryCare from "./RespiratoryCare/RespiratoryCare";
import OpenWoundCare from "./OpenWoundCare/OpenWoundCare";
import PreventiveCare from "./PreventiveCare/PreventiveCare";
import EyeCare from "./EyeCare/EyeCare";
import FeedingTube from "./FeedingTube";
import PressureSore from "./PressureSore/PressureSore";
import DailyRoutine from "./DailyRoutine/DailyRoutine";
import OstomyCare from "./OstomyCare";
import Medications from "./Medications/Medications";
import Diet from "./Diet/Diet";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const MonitoringCharts = ({ patient }) => {
  const [data, setData] = useState(null);
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "d"),
    end_date: dayjs(),
  });

  useEffect(() => {
    getPatientMonitoringChartsApi(
      patient,
      `start_date=${dateFilter.start_date.format(
        dateFormat
      )}&end_date=${dateFilter.end_date.format(dateFormat)}`
    )
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [patient, dateFilter]);
  const commonStyle = {
    // background: "#fff",
    boxShadow:
      "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)",
  };
  function disabledDate(current) {
    return current && current > dayjs().endOf("day");
  }
  return (
    <Row gutter={[12, 12]}>
      <Col span={24} style={{ textAlign: "right" }}>
        <RangePicker
          allowClear={false}
          style={{ background: "#fff" }}
          disabledDate={disabledDate}
          onChange={(date) => {
            setDateFilter({
              ...dateFilter,
              start_date: date[0],
              end_date: date[1],
            });
          }}
          defaultValue={[dayjs().subtract(1, "d"), dayjs()]}
        />
      </Col>
      <Col span={24}>
        <Collapse
          expandIconPosition="right"
          className="custom-collapse"
          style={{ marginTop: "10px" }}
          defaultActiveKey={[
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
          ]}
        >
          <Collapse.Panel header="Vitals" style={commonStyle} key="1">
            <VitalsTable data={data?.data?.vitals} />
          </Collapse.Panel>
          <Collapse.Panel header="GRBS" style={commonStyle} key="2">
            <GrbsTable data={data?.data?.grbs} />
          </Collapse.Panel>
          <Collapse.Panel header="Medication" style={commonStyle} key="3">
            <Medications data={data?.data?.medications} />
          </Collapse.Panel>
          <Collapse.Panel header="Nebulization" style={commonStyle} key="4">
            <Nebulization data={data?.data?.nebulization} />
          </Collapse.Panel>
          <Collapse.Panel header="IV Fluids" style={commonStyle} key="5">
            <IVFluids data={data?.data?.iv_fluids} />
          </Collapse.Panel>
          <Collapse.Panel header="Blood Products" style={commonStyle} key="6">
            <BloodProducts data={data?.data?.blood_products} />
          </Collapse.Panel>
          <Collapse.Panel
            header="Parental Nutrition"
            style={commonStyle}
            key="7"
          >
            <ParentalNutrition data={data?.data?.parental_nutrition} />
          </Collapse.Panel>
          <Collapse.Panel header="Respiratory Care" style={commonStyle} key="8">
            <RespiratoryCare data={data} />
          </Collapse.Panel>
          <Collapse.Panel header="Urinary Catheter" style={commonStyle} key="9">
            <UrinaryCatheter data={data?.data?.urinary_catheter_care} />
          </Collapse.Panel>
          <Collapse.Panel header="Wound Care" style={commonStyle} key="10">
            <OpenWoundCare data={data?.data?.open_wound_care} />
          </Collapse.Panel>
          <Collapse.Panel header="Preventive Care" style={commonStyle} key="11">
            <PreventiveCare data={data} />
          </Collapse.Panel>

          <Collapse.Panel header="Eye Care" style={commonStyle} key="12">
            <EyeCare data={data} />
          </Collapse.Panel>
          <Collapse.Panel header="Feeding Tube" style={commonStyle} key="13">
            <FeedingTube data={data?.data?.feeding_tube} />
          </Collapse.Panel>
          <Collapse.Panel
            header="Pressure Sore Care"
            style={commonStyle}
            key="14"
          >
            <PressureSore data={data?.data?.pressure_sore_care} />
          </Collapse.Panel>
          <Collapse.Panel header="Ostomy Care" style={commonStyle} key="15">
            <OstomyCare data={data?.data?.ostomy_care} />
          </Collapse.Panel>
          <Collapse.Panel header="Diet" style={commonStyle} key="16">
            <Diet data={data?.data?.diet} />
          </Collapse.Panel>
          <Collapse.Panel header="Daily Routine" style={commonStyle} key="17">
            <DailyRoutine data={data?.data?.daily_routine} />
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default MonitoringCharts;
