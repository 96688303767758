import { Form, Input } from "antd";
import React from "react";
import { mentalStatusOptions } from "../../Options/RespiratoryTherapistOptions";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const RespiratoryMentalStatus = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Mental Status"}
        name={["mental_details", "status"]}
        options={mentalStatusOptions}
        form={form}
      />
      <Form.Item name={["mental_details", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default RespiratoryMentalStatus;
