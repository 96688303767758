const scalesConfig = {
  SixMinuteWalkTest: {
    shortName: "6MWT",
    expandedName: "Six Minute Walk Test",
    keys: {
      // gender: {
      //   name: "Gender",
      //   color: "#F39C12", // Yellow
      // },
      // height: {
      //   name: "Height",
      //   color: "#1ABC9C", // Green
      // },
      // weight: {
      //   name: "Weight",
      //   color: "#2980B9", // Blue
      // },
      heart_rate: {
        name: "Heart Rate",
        color: "#E74C3C", // Red
      },
      sbp: {
        name: "Systolic BP (SBP)",
        color: "#9B59B6", // Purple
      },
      dbp: {
        name: "Diastolic BP (DBP)",
        color: "#16A085", // Teal
      },
      spo2: {
        name: "SpO2",
        color: "#F39C12", // Orange
      },
      rr: {
        name: "Respiratory Rate (RR)",
        color: "#D35400", // Dark Orange
      },
      rpe: {
        name: "RPE (Rating of Perceived Exertion)",
        color: "#8E44AD", // Dark Purple
      },
      post_vital_heart_rate: {
        name: "Post-Vital Heart Rate",
        color: "#FF5733", // Red
      },
      post_vital_sbp: {
        name: "Post-Vital SBP",
        color: "#1E90FF", // Blue
      },
      post_vital_dbp: {
        name: "Post-Vital DBP",
        color: "#2980B9", // Light Blue
      },
      post_vital_spo2: {
        name: "Post-Vital SpO2",
        color: "#8E44AD", // Purple
      },
      post_vital_rr: {
        name: "Post-Vital RR",
        color: "#AF7AC5", // Lavender
      },
      post_vital_rpe: {
        name: "Post-Vital RPE",
        color: "#F39C12", // Yellow
      },
      no_of_laps: {
        name: "Number of Laps",
        color: "#3498DB", // Blue
      },
      distance_covered: {
        name: "Distance Covered (m)",
        color: "#E67E22", // Orange
      },
      final_lap_in_meters: {
        name: "Final Lap (m)",
        color: "#16A085", // Teal
      },
      mins_walked: {
        name: "Minutes Walked",
        color: "#9B59B6", // Purple
      },
      reason_for_immediate_stopping: {
        name: "Reason for Immediate Stopping",
        color: "#C0392B", // Dark Red
      },
      comments: {
        name: "Comments",
        color: "#7D3C98", // Violet
      },
    },
    type: "graph",
  },
  BarthelIndex: {
    shortName: "BI",
    expandedName: "Barthel Index",
    keys: {
      feeding: {
        name: "Feeding",
        color: "#3498DB", // Blue
      },
      bathing: {
        name: "Bathing",
        color: "#1ABC9C", // Green
      },
      grooming: {
        name: "Grooming",
        color: "#E74C3C", // Red
      },
      dressing: {
        name: "Dressing",
        color: "#F1C40F", // Yellow
      },
      bowels: {
        name: "Bowels",
        color: "#8E44AD", // Purple
      },
      bladder: {
        name: "Bladder",
        color: "#2980B9", // Dark Blue
      },
      toilet_use: {
        name: "Toilet Use",
        color: "#E67E22", // Orange
      },
      transfers: {
        name: "Transfers",
        color: "#27AE60", // Green
      },
      mobility: {
        name: "Mobility",
        color: "#C0392B", // Dark Red
      },
      stairs: {
        name: "Stairs",
        color: "#7D3C98", // Violet
      },
      score: {
        name: "Score",
        color: "#C0392B",
        format: "number",
      },
    },
    type: "graph",
  },
  TrunkControlTest: {
    shortName: "TCT",
    expandedName: "Trunk Control Test",
    keys: {
      sitting_balance: {
        name: "Sitting Balance",
        color: "#3498DB", // Blue
      },
      selective_movement_control: {
        name: "Selective Movement Control",
        color: "#1ABC9C", // Green
      },
      sitting_to_standing: {
        name: "Sitting to Standing",
        color: "#E74C3C", // Red
      },
      score: {
        name: "Score",
        color: "#C0392B",
        format: "number",
      },
    },
    type: "graph",
  },
  PeakFlowMeterTest: {
    shortName: "PFMT",
    expandedName: "Peak Flow Meter Test",
    keys: {
      predicted_PEFR: {
        name: "Predicted PEFR",
        color: "#FF5733", // Red
      },
      test_PEFR: {
        name: "Test PEFR",
        color: "#28B463", // Green
      },
      // gender: {
      //   name: "Gender",
      //   color: "#F39C12", // Yellow
      // },
      // age: {
      //   name: "Age",
      //   color: "#2980B9", // Light Blue
      // },
      // height: {
      //   name: "Height",
      //   color: "#8E44AD", // Purple
      // },
    },
    type: "graph",
  },
  FingerGripTest: {
    shortName: "FGT",
    expandedName: "Finger Grip Test",
    keys: {
      left_hand: {
        name: "Left Hand Strength",
        color: "#3498DB", // Blue
      },
      right_hand: {
        name: "Right Hand Strength",
        color: "#E74C3C", // Red
      },
    },
    type: "graph",
  },
  GCS: {
    shortName: "GCS",
    expandedName: "Glasgow Coma Scale",
    keys: {
      eye_opening_response: {
        name: "Eye Opening Response",
        color: "#1E90FF",
      },
      motor_response: {
        name: "Motor Response",
        score: 1,
        color: "#32CD32",
      },
      verbal_response: {
        name: "Verbal Response",
        color: "#FFD700",
      },
      score: {
        name: "Score",
        color: "#C0392B",
        format: "number",
      },
    },
    type: "graph",
  },
  NIHSS: {
    shortName: "NIHSS",
    expandedName: "National Institutes of Health Stroke Scale",
    keys: {
      best_gaze: {
        name: "Best Gaze",
        color: "#3498DB",
      },
      visual: {
        name: "Visual",
        color: "#1ABC9C",
      },
      facial_palsy: {
        name: "Facial Palsy",
        color: "#E74C3C",
      },
      motor_arm_left: {
        name: "Motor Arm Left",
        color: "#9B59B6",
      },
      motor_arm_right: {
        name: "Motor Arm Right",
        color: "#8E44AD",
      },
      motor_leg_left: {
        name: "Motor Leg Left",
        color: "#F39C12",
      },
      motor_leg_right: {
        name: "Motor Leg Right",
        color: "#D35400",
      },
      limb_ataxia: {
        name: "Limb Ataxia",
        color: "#16A085",
      },
      sensory: {
        name: "Sensory",
        color: "#2980B9",
      },
      best_language: {
        name: "Best Language",
        color: "#E67E22",
      },
      dysarthria: {
        name: "Dysarthria",
        color: "#C0392B",
      },
      extinction_and_inattention: {
        name: "Extinction and Inattention",
        color: "#7D3C98",
      },
      level_of_Consiousness: {
        name: "Level of Consciousness",
        color: "#5DADE2",
      },
      loc_commands: {
        name: "LOC Commands",
        color: "#AF7AC5",
      },
      loc_questions: {
        name: "LOC Questions",
        color: "#48C9B0",
      },
    },
    type: "graph",
  },
  MOCA: {
    shortName: "MOCA",
    expandedName: "Montreal Cognitive Assessment",
    keys: {
      visual_executive: {
        name: "Visual Executive",
        color: "#3498DB",
      },
      // visual_executive_data: {
      //   name: "Visual Executive Data",
      //   color: "#1ABC9C",
      // },
      naming: {
        name: "Naming",
        color: "#E74C3C",
      },
      // naming_data: {
      //   name: "Naming Data",
      //   color: "#9B59B6",
      // },
      memory: {
        name: "Memory",
        color: "#8E44AD",
      },
      // memory_data: {
      //   name: "Memory Data",
      //   color: "#F39C12",
      // },
      attention: {
        name: "Attention",
        color: "#D35400",
      },
      // attention_data: {
      //   name: "Attention Data",
      //   color: "#16A085",
      // },
      language: {
        name: "Language",
        color: "#2980B9",
      },
      // language_data: {
      //   name: "Language Data",
      //   color: "#E67E22",
      // },
      abstraction: {
        name: "Abstraction",
        color: "#C0392B",
      },
      // abstraction_data: {
      //   name: "Abstraction Data",
      //   color: "#7D3C98",
      // },
      delayed_recall: {
        name: "Delayed Recall",
        color: "#5DADE2",
      },
      // delayed_recall_data: {
      //   name: "Delayed Recall Data",
      //   color: "#AF7AC5",
      // },
      orientation: {
        name: "Orientation",
        color: "#48C9B0",
      },
      // orientation_data: {
      //   name: "Orientation Data",
      //   color: "#3498DB",
      // },
    },
    type: "graph",
  },
  RASS: {
    shortName: "RASS",
    expandedName: "Richmond Agitation-Sedation Scale",
    keys: {
      rass_scale: {
        name: "Rass Scale",
        color: "#3498DB",
      },
      score: {
        name: "Score",
        color: "#C0392B",
        format: "number",
      },
    },
    type: "graph",
  },
  VAS: {
    shortName: "VAS",
    expandedName: "Visual Analog Scale",
    keys: {
      pain: {
        name: "Pain",
        color: "#FF6347",
      },
    },
    type: "graph",
  },
  RPE: {
    shortName: "RPE",
    expandedName: "Rate of Perceived Exertion",
    keys: {
      exertion: {
        name: "Exertion",
        color: "#FF8C00",
      },
    },
    type: "graph",
  },
  ASIA: {
    shortName: "ASIA",
    expandedName: "American Spinal Injury Association",
    keys: {
      // motor_sensory_scale: {
      //   name: "Motor and Sensory Scores",
      //   subKeys: {
      //     ltl: {
      //       name: "Light Touch Left",
      //       color: "#3498DB", // Blue
      //     },
      //     ltr: {
      //       name: "Light Touch Right",
      //       color: "#1ABC9C", // Green
      //     },
      //     ppl: {
      //       name: "Pin Prick Left",
      //       color: "#F1C40F", // Yellow
      //     },
      //     ppr: {
      //       name: "Pin Prick Right",
      //       color: "#E74C3C", // Red
      //     },
      //   },
      //   type: "graph",
      // },
      // left_motor: {
      //   name: "Left Motor Function",
      //   color: "#9B59B6", // Purple
      // },
      // right_motor: {
      //   name: "Right Motor Function",
      //   color: "#8E44AD", // Dark Purple
      // },
      // sensory_left: {
      //   name: "Sensory Left",
      //   color: "#2980B9", // Light Blue
      // },
      // sensory_right: {
      //   name: "Sensory Right",
      //   color: "#1ABC9C", // Aqua
      // },
      // motor_left: {
      //   name: "Motor Left",
      //   color: "#2ECC71", // Green
      // },
      // motor_right: {
      //   name: "Motor Right",
      //   color: "#E74C3C", // Red
      // },
      // nerological_level_of_injury: {
      //   name: "Neurological Level of Injury",
      //   color: "#34495E", // Navy
      // },
      // zone_of_partial_preservation: {
      //   name: "Zone of Partial Preservation",
      //   color: "#D35400", // Orange
      // },
      asia_impairment_scale: {
        name: "ASIA Impairment Scale",
        color: "#5DADE2", // Sky Blue
      },
      // lower_extremity_motor_score: {
      //   name: "Lower Extremity Motor Score",
      //   color: "#27AE60", // Green
      // },
      // upper_extremity_motor_score: {
      //   name: "Upper Extremity Motor Score",
      //   color: "#16A085", // Teal
      // },
      // left_total_motor_score: {
      //   name: "Left Total Motor Score",
      //   color: "#8E44AD", // Purple
      // },
      // right_total_motor_score: {
      //   name: "Right Total Motor Score",
      //   color: "#C0392B", // Deep Red
      // },
      // left_total_sensory_score: {
      //   name: "Left Total Sensory Score",
      //   color: "#2980B9", // Light Blue
      // },
      // right_total_sensory_score: {
      //   name: "Right Total Sensory Score",
      //   color: "#1ABC9C", // Aqua
      // },
      total_lt: {
        name: "Total Light Touch",
        color: "#F1C40F", // Yellow
      },
      total_pp: {
        name: "Total Pin Prick",
        color: "#E74C3C", // Red
      },
    },
    type: "graph",
  },
  VCG: {
    shortName: "VCG",
    expandedName: "Voluntary Control Grading",
    keys: {
      upper_left_limb: {
        name: "Upper Left Limb",
        color: "#3498DB", // Blue
      },
      lower_left_limb: {
        name: "Lower Left Limb",
        color: "#1ABC9C", // Green
      },
      upper_right_limb: {
        name: "Upper Right Limb",
        color: "#E74C3C", // Red
      },
      lower_right_limb: {
        name: "Lower Right Limb",
        color: "#9B59B6", // Purple
      },
      comments: {
        name: "Comments",
        color: "#F39C12", // Orange
      },
    },
    type: "table",
  },
  ROM: {
    shortName: "ROM",
    expandedName: "Range of Motion",
    subItems: {
      shoulder_joint: {
        name: "SHOULDER JOINT",
        keys: {
          left_active_flexion: {
            name: "Left Active Flexion",
            color: "#FF5733", // Burnt Orange
          },
          left_passive_flexion: {
            name: "Left Passive Flexion",
            color: "#33C4FF", // Light Blue
          },
          right_active_flexion: {
            name: "Right Active Flexion",
            color: "#FFC300", // Mustard Yellow
          },
          left_active_abduction: {
            name: "Left Active Abduction",
            color: "#DAF7A6", // Light Green
          },
          left_active_adduction: {
            name: "Left Active Adduction",
            color: "#FF33A1", // Pink
          },
          left_active_extension: {
            name: "Left Active Extension",
            color: "#9B59B6", // Purple
          },
          right_passive_flexion: {
            name: "Right Passive Flexion",
            color: "#34495E", // Navy Blue
          },
          left_passive_abduction: {
            name: "Left Passive Abduction",
            color: "#F39C12", // Orange
          },
          left_passive_adduction: {
            name: "Left Passive Adduction",
            color: "#2ECC71", // Green
          },
          left_passive_extension: {
            name: "Left Passive Extension",
            color: "#E74C3C", // Red
          },
          right_active_abduction: {
            name: "Right Active Abduction",
            color: "#1ABC9C", // Teal
          },
          right_active_adduction: {
            name: "Right Active Adduction",
            color: "#7D3C98", // Dark Purple
          },
          right_active_extension: {
            name: "Right Active Extension",
            color: "#F4D03F", // Yellow
          },
          right_passive_abduction: {
            name: "Right Passive Abduction",
            color: "#5DADE2", // Sky Blue
          },
          right_passive_adduction: {
            name: "Right Passive Adduction",
            color: "#E59866", // Light Brown
          },
          right_passive_extension: {
            name: "Right Passive Extension",
            color: "#AF7AC5", // Lavender
          },
          left_active_medial_rotation: {
            name: "Left Active Medial Rotation",
            color: "#D35400", // Orange
          },
          left_active_lateral_rotation: {
            name: "Left Active Lateral Rotation",
            color: "#7F8C8D", // Gray
          },
          left_passive_medial_rotation: {
            name: "Left Passive Medial Rotation",
            color: "#117A65", // Emerald Green
          },
          right_active_medial_rotation: {
            name: "Right Active Medial Rotation",
            color: "#2980B9", // Blue
          },
          left_passive_lateral_rotation: {
            name: "Left Passive Lateral Rotation",
            color: "#7D6608", // Gold
          },
          right_active_lateral_rotation: {
            name: "Right Active Lateral Rotation",
            color: "#A93226", // Burgundy
          },
          right_passive_medial_rotation: {
            name: "Right Passive Medial Rotation",
            color: "#D4AC0D", // Yellow Gold
          },
          right_passive_lateral_rotation: {
            name: "Right Passive Lateral Rotation",
            color: "#2980B9", // Steel Blue
          },
        },
        type: "graph",
      },
      elbow_joint: {
        name: "ELBOW JOINT",
        keys: {
          left_active_flexion: {
            name: "Left Active Flexion",
            color: "#3498DB", // Sky Blue
          },
          left_passive_flexion: {
            name: "Left Passive Flexion",
            color: "#1ABC9C", // Teal Green
          },
          right_active_flexion: {
            name: "Right Active Flexion",
            color: "#F39C12", // Golden Orange
          },
          left_active_extension: {
            name: "Left Active Extension",
            color: "#E74C3C", // Bright Red
          },
          right_passive_flexion: {
            name: "Right Passive Flexion",
            color: "#9B59B6", // Violet
          },
          left_passive_extension: {
            name: "Left Passive Extension",
            color: "#34495E", // Navy Blue
          },
          right_active_extension: {
            name: "Right Active Extension",
            color: "#2ECC71", // Green
          },
          right_passive_extension: {
            name: "Right Passive Extension",
            color: "#E67E22", // Orange
          },
        },
      },
      forearm_joint: {
        name: "FOREARM JOINT",
        keys: {
          left_active_pronation: {
            name: "Left Active Pronation",
            color: "#3498DB", // Sky Blue
          },
          left_active_supination: {
            name: "Left Active Supination",
            color: "#1ABC9C", // Teal Green
          },
          left_passive_pronation: {
            name: "Left Passive Pronation",
            color: "#F39C12", // Golden Orange
          },
          right_active_pronation: {
            name: "Right Active Pronation",
            color: "#E74C3C", // Bright Red
          },
          left_passive_supination: {
            name: "Left Passive Supination",
            color: "#9B59B6", // Violet
          },
          right_active_supination: {
            name: "Right Active Supination",
            color: "#34495E", // Navy Blue
          },
          right_passive_pronation: {
            name: "Right Passive Pronation",
            color: "#2ECC71", // Green
          },
          right_passive_supination: {
            name: "Right Passive Supination",
            color: "#E67E22", // Orange
          },
        },
      },
      wrist_joint: {
        name: "WRIST JOINT",
        keys: {
          left_active_flexion: {
            name: "Left Active Flexion",
            color: "#1E90FF", // Dodger Blue
          },
          left_passive_flexion: {
            name: "Left Passive Flexion",
            color: "#32CD32", // Lime Green
          },
          right_active_flexion: {
            name: "Right Active Flexion",
            color: "#FFD700", // Gold
          },
          left_active_extension: {
            name: "Left Active Extension",
            color: "#FF6347", // Tomato Red
          },
          right_passive_flexion: {
            name: "Right Passive Flexion",
            color: "#8A2BE2", // Blue Violet
          },
          left_passive_extension: {
            name: "Left Passive Extension",
            color: "#FF4500", // Orange Red
          },
          right_active_extension: {
            name: "Right Active Extension",
            color: "#00CED1", // Dark Turquoise
          },
          right_passive_extension: {
            name: "Right Passive Extension",
            color: "#2ECC71", // Green
          },
          left_active_ulnar_deviation: {
            name: "Left Active Ulnar Deviation",
            color: "#E74C3C", // Bright Red
          },
          left_passive_ulnar_deviation: {
            name: "Left Passive Ulnar Deviation",
            color: "#F39C12", // Golden Orange
          },
          right_active_ulnar_deviation: {
            name: "Right Active Ulnar Deviation",
            color: "#3498DB", // Sky Blue
          },
          left_active_radical_deviation: {
            name: "Left Active Radial Deviation",
            color: "#9B59B6", // Violet
          },
          right_passive_ulnar_deviation: {
            name: "Right Passive Ulnar Deviation",
            color: "#34495E", // Navy Blue
          },
          left_passive_radical_deviation: {
            name: "Left Passive Radial Deviation",
            color: "#1ABC9C", // Teal Green
          },
          right_active_radical_deviation: {
            name: "Right Active Radial Deviation",
            color: "#E67E22", // Orange
          },
          right_passive_radical_deviation: {
            name: "Right Passive Radial Deviation",
            color: "#C0392B", // Deep Red
          },
        },
      },
      thumb_joint: {
        name: "THUMB JOINT",
        keys: {
          left_active_ip_flexion: {
            name: "Left Active IP Flexion",
            color: "#1E90FF", // Dodger Blue
          },
          left_active_cmc_flexion: {
            name: "Left Active CMC Flexion",
            color: "#32CD32", // Lime Green
          },
          left_passive_ip_flexion: {
            name: "Left Passive IP Flexion",
            color: "#FFD700", // Gold
          },
          right_active_ip_flexion: {
            name: "Right Active IP Flexion",
            color: "#FF6347", // Tomato Red
          },
          left_passive_cmc_flexion: {
            name: "Left Passive CMC Flexion",
            color: "#8A2BE2", // Blue Violet
          },
          right_passive_ip_flexion: {
            name: "Right Passive IP Flexion",
            color: "#FF4500", // Orange Red
          },
          left_active_cmc_abduction: {
            name: "Left Active CMC Abduction",
            color: "#00CED1", // Dark Turquoise
          },
          left_active_cmc_extension: {
            name: "Left Active CMC Extension",
            color: "#2ECC71", // Green
          },
          right_passive_cmc_flexion: {
            name: "Right Passive CMC Flexion",
            color: "#E74C3C", // Bright Red
          },
          left_passive_cmc_abduction: {
            name: "Left Passive CMC Abduction",
            color: "#F39C12", // Golden Orange
          },
          left_passive_cmc_extension: {
            name: "Left Passive CMC Extension",
            color: "#9B59B6", // Violet
          },
          right_active_cmc_abduction: {
            name: "Right Active CMC Abduction",
            color: "#34495E", // Navy Blue
          },
          right_active_cmc_extension: {
            name: "Right Active CMC Extension",
            color: "#1ABC9C", // Teal Green
          },
          right_passive_cmc_abduction: {
            name: "Right Passive CMC Abduction",
            color: "#E67E22", // Orange
          },
          right_passive_cmc_extension: {
            name: "Right Passive CMC Extension",
            color: "#C0392B", // Deep Red
          },
        },
      },
      metacarpel_joint: {
        name: "METACARPEL JOINT",
        keys: {
          left_active_flexion: {
            name: "Left Active Flexion",
            color: "#1E90FF", // Dodger Blue
          },
          left_passive_flexion: {
            name: "Left Passive Flexion",
            color: "#32CD32", // Lime Green
          },
          right_active_flexion: {
            name: "Right Active Flexion",
            color: "#FFD700", // Gold
          },
          left_active_abduction: {
            name: "Left Active Abduction",
            color: "#FF6347", // Tomato Red
          },
          right_passive_flexion: {
            name: "Right Passive Flexion",
            color: "#8A2BE2", // Blue Violet
          },
          left_passive_abduction: {
            name: "Left Passive Abduction",
            color: "#FF4500", // Orange Red
          },
          right_active_abduction: {
            name: "Right Active Abduction",
            color: "#00CED1", // Dark Turquoise
          },
          right_passive_abduction: {
            name: "Right Passive Abduction",
            color: "#2ECC71", // Green
          },
          left_active_hyperextension: {
            name: "Left Active Hyperextension",
            color: "#E74C3C", // Bright Red
          },
          left_passive_hyperextension: {
            name: "Left Passive Hyperextension",
            color: "#F39C12", // Golden Orange
          },
          right_active_hyperextension: {
            name: "Right Active Hyperextension",
            color: "#9B59B6", // Violet
          },
          right_passive_hyperextension: {
            name: "Right Passive Hyperextension",
            color: "#34495E", // Navy Blue
          },
        },
      },
      proximal_joint: {
        name: "PROXIMAL JOINT",
        keys: {
          left_active_flexion: {
            name: "Left Active Flexion",
            color: "#1E90FF", // Dodger Blue
          },
          left_passive_flexion: {
            name: "Left Passive Flexion",
            color: "#32CD32", // Lime Green
          },
          right_active_flexion: {
            name: "Right Active Flexion",
            color: "#FFD700", // Gold
          },
          right_passive_flexion: {
            name: "Right Passive Flexion",
            color: "#FF6347", // Tomato Red
          },
        },
      },
      disital_joint: {
        name: "DISITAL JOINT",
        keys: {
          left_active_flexion: {
            name: "Left Active Flexion",
            color: "#1E90FF", // Dodger Blue
          },
          left_passive_flexion: {
            name: "Left Passive Flexion",
            color: "#32CD32", // Lime Green
          },
          right_active_flexion: {
            name: "Right Active Flexion",
            color: "#FFD700", // Gold
          },
          right_passive_flexion: {
            name: "Right Passive Flexion",
            color: "#FF6347", // Tomato Red
          },
          left_active_hyperextension: {
            name: "Left Active Hyperextension",
            color: "#8A2BE2", // Blue Violet
          },
          left_passive_hyperextension: {
            name: "Left Passive Hyperextension",
            color: "#FF4500", // Orange Red
          },
          right_active_hyperextension: {
            name: "Right Active Hyperextension",
            color: "#00CED1", // Dark Turquoise
          },
          right_passive_hyperextension: {
            name: "Right Passive Hyperextension",
            color: "#FFD700", // Gold
          },
        },
      },
      hip_joint: {
        name: "HIP JOINT",
        keys: {
          left_active_flexion: {
            name: "Left Active Flexion",
            color: "#1E90FF", // Dodger Blue
          },
          left_passive_flexion: {
            name: "Left Passive Flexion",
            color: "#32CD32", // Lime Green
          },
          right_active_flexion: {
            name: "Right Active Flexion",
            color: "#FFD700", // Gold
          },
          left_active_abduction: {
            name: "Left Active Abduction",
            color: "#FF6347", // Tomato Red
          },
          left_active_adduction: {
            name: "Left Active Adduction",
            color: "#8A2BE2", // Blue Violet
          },
          left_active_extension: {
            name: "Left Active Extension",
            color: "#FF4500", // Orange Red
          },
          right_passive_flexion: {
            name: "Right Passive Flexion",
            color: "#00CED1", // Dark Turquoise
          },
          left_passive_abduction: {
            name: "Left Passive Abduction",
            color: "#FFD700", // Gold
          },
          left_passive_adduction: {
            name: "Left Passive Adduction",
            color: "#32CD32", // Lime Green
          },
          left_passive_extension: {
            name: "Left Passive Extension",
            color: "#1E90FF", // Dodger Blue
          },
          right_active_abduction: {
            name: "Right Active Abduction",
            color: "#FF6347", // Tomato Red
          },
          right_active_adduction: {
            name: "Right Active Adduction",
            color: "#8A2BE2", // Blue Violet
          },
          right_active_extension: {
            name: "Right Active Extension",
            color: "#FF4500", // Orange Red
          },
          right_passive_abduction: {
            name: "Right Passive Abduction",
            color: "#00CED1", // Dark Turquoise
          },
          right_passive_adduction: {
            name: "Right Passive Adduction",
            color: "#FFD700", // Gold
          },
          right_passive_extension: {
            name: "Right Passive Extension",
            color: "#32CD32", // Lime Green
          },
          left_active_medial_rotation: {
            name: "Left Active Medial Rotation",
            color: "#8A2BE2", // Blue Violet
          },
          left_active_lateral_rotation: {
            name: "Left Active Lateral Rotation",
            color: "#FF6347", // Tomato Red
          },
          left_passive_medial_rotation: {
            name: "Left Passive Medial Rotation",
            color: "#FFD700", // Gold
          },
          right_active_medial_rotation: {
            name: "Right Active Medial Rotation",
            color: "#1E90FF", // Dodger Blue
          },
          left_passive_lateral_rotation: {
            name: "Left Passive Lateral Rotation",
            color: "#32CD32", // Lime Green
          },
          right_active_lateral_rotation: {
            name: "Right Active Lateral Rotation",
            color: "#8A2BE2", // Blue Violet
          },
          right_passive_medial_rotation: {
            name: "Right Passive Medial Rotation",
            color: "#FF4500", // Orange Red
          },
          right_passive_lateral_rotation: {
            name: "Right Passive Lateral Rotation",
            color: "#FF6347", // Tomato Red
          },
        },
      },
      knee_joint: {
        name: "KNEE JOINT",
        keys: {
          left_active_flexion: {
            name: "Left Active Flexion",
            color: "#1E90FF", // Dodger Blue
          },
          left_passive_flexion: {
            name: "Left Passive Flexion",
            color: "#32CD32", // Lime Green
          },
          right_active_flexion: {
            name: "Right Active Flexion",
            color: "#FFD700", // Gold
          },
          left_active_extension: {
            name: "Left Active Extension",
            color: "#FF6347", // Tomato Red
          },
          right_passive_flexion: {
            name: "Right Passive Flexion",
            color: "#8A2BE2", // Blue Violet
          },
          left_passive_extension: {
            name: "Left Passive Extension",
            color: "#FF4500", // Orange Red
          },
          right_active_extension: {
            name: "Right Active Extension",
            color: "#00CED1", // Dark Turquoise
          },
          right_passive_extension: {
            name: "Right Passive Extension",
            color: "#FFD700", // Gold
          },
        },
      },
      ankle_joint: {
        name: "ANKLE JOINT",
        keys: {
          left_active_dorsiflexion: {
            name: "Left Active Dorsiflexion",
            color: "#1E90FF", // Dodger Blue
          },
          left_active_plantarflexion: {
            name: "Left Active Plantarflexion",
            color: "#32CD32", // Lime Green
          },
          left_passive_dorsiflexion: {
            name: "Left Passive Dorsiflexion",
            color: "#FFD700", // Gold
          },
          left_passive_plantarflexion: {
            name: "Left Passive Plantarflexion",
            color: "#FF6347", // Tomato Red
          },
          right_active_dorsiflexion: {
            name: "Right Active Dorsiflexion",
            color: "#8A2BE2", // Blue Violet
          },
          right_active_plantarflexion: {
            name: "Right Active Plantarflexion",
            color: "#FF4500", // Orange Red
          },
          right_passive_dorsiflexion: {
            name: "Right Passive Dorsiflexion",
            color: "#00CED1", // Dark Turquoise
          },
        },
      },
      foot_joint: {
        name: "FOOT JOINT",
        keys: {
          left_active_eversion: {
            name: "Left Active Eversion",
            color: "#1E90FF", // Dodger Blue
          },
          left_active_inversion: {
            name: "Left Active Inversion",
            color: "#32CD32", // Lime Green
          },
          left_passive_eversion: {
            name: "Left Passive Eversion",
            color: "#FFD700", // Gold
          },
          right_active_eversion: {
            name: "Right Active Eversion",
            color: "#8A2BE2", // Blue Violet
          },
          left_passive_inversion: {
            name: "Left Passive Inversion",
            color: "#FF6347", // Tomato Red
          },
          right_active_inversion: {
            name: "Right Active Inversion",
            color: "#FF4500", // Orange Red
          },
          right_passive_eversion: {
            name: "Right Passive Eversion",
            color: "#00CED1", // Dark Turquoise
          },
          right_passive_inversion: {
            name: "Right Passive Inversion",
            color: "#FFD700", // Gold
          },
        },
      },
      great_toe_joint: {
        name: "GREAT TOE JOINT",
        keys: {
          left_active_flexion: {
            name: "Left Active Flexion",
            color: "#1E90FF", // Dodger Blue
          },
          left_passive_flexion: {
            name: "Left Passive Flexion",
            color: "#32CD32", // Lime Green
          },
          right_active_flexion: {
            name: "Right Active Flexion",
            color: "#FFD700", // Gold
          },
          left_active_extension: {
            name: "Left Active Extension",
            color: "#FF6347", // Tomato Red
          },
          right_passive_flexion: {
            name: "Right Passive Flexion",
            color: "#8A2BE2", // Blue Violet
          },
          left_passive_extension: {
            name: "Left Passive Extension",
            color: "#FF4500", // Orange Red
          },
          right_active_extension: {
            name: "Right Active Extension",
            color: "#00CED1", // Dark Turquoise
          },
          right_passive_extension: {
            name: "Right Passive Extension",
            color: "#FFD700", // Gold
          },
        },
      },
      inter_phalangeal_joint: {
        name: "INTER PHALANGEAL JOINT",
        keys: {
          left_active_flexion: {
            name: "Left Active Flexion",
            color: "#1E90FF", // Dodger Blue
          },
          left_passive_flexion: {
            name: "Left Passive Flexion",
            color: "#32CD32", // Lime Green
          },
          right_active_flexion: {
            name: "Right Active Flexion",
            color: "#FFD700", // Gold
          },
          left_active_extension: {
            name: "Left Active Extension",
            color: "#FF6347", // Tomato Red
          },
          right_passive_flexion: {
            name: "Right Passive Flexion",
            color: "#8A2BE2", // Blue Violet
          },
          left_passive_extension: {
            name: "Left Passive Extension",
            color: "#FF4500", // Orange Red
          },
          right_active_extension: {
            name: "Right Active Extension",
            color: "#00CED1", // Dark Turquoise
          },
          right_passive_extension: {
            name: "Right Passive Extension",
            color: "#FFD700", // Gold
          },
        },
      },
      cervical_spine: {
        name: "CERVICAL SPINE",
        keys: {
          left_active_flexion: {
            name: "Left Active Flexion",
            color: "#1E90FF", // Dodger Blue
          },
          left_passive_flexion: {
            name: "Left Passive Flexion",
            color: "#32CD32", // Lime Green
          },
          right_active_flexion: {
            name: "Right Active Flexion",
            color: "#FFD700", // Gold
          },
          left_active_extension: {
            name: "Left Active Extension",
            color: "#FF6347", // Tomato Red
          },
          right_passive_flexion: {
            name: "Right Passive Flexion",
            color: "#8A2BE2", // Blue Violet
          },
          left_passive_extension: {
            name: "Left Passive Extension",
            color: "#FF4500", // Orange Red
          },
          right_active_extension: {
            name: "Right Active Extension",
            color: "#00CED1", // Dark Turquoise
          },
          right_passive_extension: {
            name: "Right Passive Extension",
            color: "#FFD700", // Gold
          },
          left_active_left_rotation: {
            name: "Left Active Left Rotation",
            color: "#FF6347", // Tomato Red
          },
          left_active_right_rotation: {
            name: "Left Active Right Rotation",
            color: "#32CD32", // Lime Green
          },
          left_passive_left_rotation: {
            name: "Left Passive Left Rotation",
            color: "#1E90FF", // Dodger Blue
          },
          right_active_left_rotation: {
            name: "Right Active Left Rotation",
            color: "#8A2BE2", // Blue Violet
          },
          right_active_right_rotation: {
            name: "Right Active Right Rotation",
            color: "#FFD700", // Gold
          },
          right_passive_left_rotation: {
            name: "Right Passive Left Rotation",
            color: "#FF4500", // Orange Red
          },
          right_passive_right_rotation: {
            name: "Right Passive Right Rotation",
            color: "#00CED1", // Dark Turquoise
          },
          left_active_left_lateral_flexion: {
            name: "Left Active Left Lateral Flexion",
            color: "#32CD32", // Lime Green
          },
          left_active_right_lateral_flexion: {
            name: "Left Active Right Lateral Flexion",
            color: "#FFD700", // Gold
          },
          left_passive_left_lateral_flexion: {
            name: "Left Passive Left Lateral Flexion",
            color: "#8A2BE2", // Blue Violet
          },
          right_active_left_lateral_flexion: {
            name: "Right Active Left Lateral Flexion",
            color: "#FF6347", // Tomato Red
          },
          left_passive_right_lateral_flexion: {
            name: "Left Passive Right Lateral Flexion",
            color: "#FF4500", // Orange Red
          },
          right_active_right_lateral_flexion: {
            name: "Right Active Right Lateral Flexion",
            color: "#00CED1", // Dark Turquoise
          },
          right_passive_left_lateral_flexion: {
            name: "Right Passive Left Lateral Flexion",
            color: "#FFD700", // Gold
          },
          right_passive_right_lateral_flexion: {
            name: "Right Passive Right Lateral Flexion",
            color: "#1E90FF", // Dodger Blue
          },
        },
      },
      thoraco_lumbar_spine: {
        name: "THORACO LUMBAR SPINE",
        keys: {
          left_active_flexion: {
            name: "Left Active Flexion",
            color: "#1E90FF", // Dodger Blue
          },
          left_passive_flexion: {
            name: "Left Passive Flexion",
            color: "#32CD32", // Lime Green
          },
          right_active_flexion: {
            name: "Right Active Flexion",
            color: "#FFD700", // Gold
          },
          left_active_extension: {
            name: "Left Active Extension",
            color: "#FF6347", // Tomato Red
          },
          right_passive_flexion: {
            name: "Right Passive Flexion",
            color: "#8A2BE2", // Blue Violet
          },
          left_passive_extension: {
            name: "Left Passive Extension",
            color: "#FF4500", // Orange Red
          },
          right_active_extension: {
            name: "Right Active Extension",
            color: "#00CED1", // Dark Turquoise
          },
          right_passive_extension: {
            name: "Right Passive Extension",
            color: "#FFD700", // Gold
          },
          left_active_left_rotation: {
            name: "Left Active Left Rotation",
            color: "#FF6347", // Tomato Red
          },
          left_active_right_rotation: {
            name: "Left Active Right Rotation",
            color: "#32CD32", // Lime Green
          },
          left_passive_left_rotation: {
            name: "Left Passive Left Rotation",
            color: "#1E90FF", // Dodger Blue
          },
          right_active_left_rotation: {
            name: "Right Active Left Rotation",
            color: "#8A2BE2", // Blue Violet
          },
          right_active_right_rotation: {
            name: "Right Active Right Rotation",
            color: "#FFD700", // Gold
          },
          right_passive_left_rotation: {
            name: "Right Passive Left Rotation",
            color: "#FF4500", // Orange Red
          },
          right_passive_right_rotation: {
            name: "Right Passive Right Rotation",
            color: "#00CED1", // Dark Turquoise
          },
          left_active_left_lateral_flexion: {
            name: "Left Active Left Lateral Flexion",
            color: "#32CD32", // Lime Green
          },
          left_active_right_lateral_flexion: {
            name: "Left Active Right Lateral Flexion",
            color: "#FFD700", // Gold
          },
          left_passive_left_lateral_flexion: {
            name: "Left Passive Left Lateral Flexion",
            color: "#8A2BE2", // Blue Violet
          },
          right_active_left_lateral_flexion: {
            name: "Right Active Left Lateral Flexion",
            color: "#FF6347", // Tomato Red
          },
          left_passive_right_lateral_flexion: {
            name: "Left Passive Right Lateral Flexion",
            color: "#FF4500", // Orange Red
          },
          right_active_right_lateral_flexion: {
            name: "Right Active Right Lateral Flexion",
            color: "#00CED1", // Dark Turquoise
          },
          right_passive_left_lateral_flexion: {
            name: "Right Passive Left Lateral Flexion",
            color: "#FFD700", // Gold
          },
          right_passive_right_lateral_flexion: {
            name: "Right Passive Right Lateral Flexion",
            color: "#1E90FF", // Dodger Blue
          },
        },
      },
    },
  },

  MAS: {
    shortName: "MAS",
    expandedName: "Modified Ashworth Scale",
    color: "#DC143C",
    keys: {
      upper_left_limb: {
        name: "Upper Left Limb",
        color: "#3498DB", // Blue
      },
      lower_left_limb: {
        name: "Lower Left Limb",
        color: "#1ABC9C", // Green
      },
      upper_right_limb: {
        name: "Upper Right Limb",
        color: "#E74C3C", // Red
      },
      lower_right_limb: {
        name: "Lower Right Limb",
        color: "#9B59B6", // Purple
      },
      comments: {
        name: "Comments",
        color: "#F39C12", // Orange
      },
    },
    type: "table",
  },
  MMT: {
    shortName: "MMT",
    expandedName: "Manual Muscle Testing",
    color: "#8B0000",
    keys: {
      upper_left_limb: {
        name: "Upper Left Limb",
        color: "#3498DB", // Blue
      },
      lower_left_limb: {
        name: "Lower Left Limb",
        color: "#1ABC9C", // Green
      },
      upper_right_limb: {
        name: "Upper Right Limb",
        color: "#E74C3C", // Red
      },
      lower_right_limb: {
        name: "Lower Right Limb",
        color: "#9B59B6", // Purple
      },
      comments: {
        name: "Comments",
        color: "#F39C12", // Orange
      },
    },
    type: "table",
  },
  CoordinationTest: {
    shortName: "CT",
    expandedName: "Coordination Test",
    color: "#FF7F50",
    keys: {
      finger_to_nose: {
        name: "Finger to Nose",
        color: "#3498DB", // Blue
      },
      finger_to_finger: {
        name: "Finger to Finger",
        color: "#1ABC9C", // Green
      },
      adiadochokinesia_or_dysdiadokokinesia: {
        name: "Adiadochokinesia or Dysdiadokokinesia",
        color: "#E74C3C", // Red
      },
      rebound_phenomenon: {
        name: "Rebound Phenomenon",
        color: "#9B59B6", // Purple
      },
      walking_along_straight_line: {
        name: "Walking Along Straight Line",
        color: "#F39C12", // Orange
      },
      rombergs_test: {
        name: "Romberg's Test",
        color: "#16A085", // Teal
      },
      heel_to_shin: {
        name: "Heel to Shin",
        color: "#2980B9", // Light Blue
      },
      comments: {
        name: "Comments",
        color: "#F39C12", // Orange
      },
    },
    type: "table",
  },
};

export default scalesConfig;
