import React, { useState } from "react";
import { Form, InputNumber, DatePicker } from "antd";
import {
  feedingTubeOptions,
  feedingTubeTypeOptions,
  tubeAspirationOptions,
  tubePositionOptions,
} from "../../../../Options/genExamOptions";
import dayjs from "dayjs";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const FeedingTube = ({ dailyReview = false, context, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <DeselectableRadioGroup
        label={"Feeding Tube"}
        name={"feeding_tube"}
        options={feedingTubeOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("feeding_tube") === "yes" && (
        <div className="subsection-card">
          <DeselectableRadioGroup
            name={"feeding_tube_type"}
            options={feedingTubeTypeOptions}
            form={form}
          />
          <DeselectableRadioGroup
            label={"Position"}
            name={"feedingtube_position"}
            options={tubePositionOptions}
            form={form}
          />

          {dailyReview ? (
            <Form.Item name="feedingtube_start_date">
              <DatePicker
                disabledDate={(current) =>
                  current && current > dayjs().endOf("day")
                }
              />
            </Form.Item>
          ) : (
            <Form.Item name="feedingtube_no_days">
              <InputNumber addonBefore="No.of Days" style={{ width: "15%" }} />
            </Form.Item>
          )}

          <DeselectableRadioGroup
            label={"Tube Aspirations"}
            name={"feedingtube_aspirations"}
            options={tubeAspirationOptions}
            form={form}
          />
        </div>
      )}
    </>
  );
};

export default FeedingTube;
