import { Collapse } from "antd";
import React from "react";
import CentralLine from "./CentralLine";
import PressureSore from "./PressureSore";
import Thrombo from "./Thrombo";

const PreventiveCare = ({ data }) => {
  return (
    <div>
      <Collapse
        expandIconPosition="right"
        className="custom-collapse"
        style={{ marginTop: "10px" }}
        defaultActiveKey={["1", "2", "3"]}
      >
        <Collapse.Panel header="Central Line" key="1">
          <CentralLine data={data?.data?.preventive_care_central_line_care} />
        </Collapse.Panel>
        <Collapse.Panel header="Thromboprophylaxis" key="2">
          <Thrombo data={data?.data?.preventive_care_thromboprophylaxis} />
        </Collapse.Panel>
        <Collapse.Panel header="Pressure Sore Care" key="3">
          <PressureSore data={data?.data?.preventive_care_pressure_sore_care} />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default PreventiveCare;
