import { Button, Col, Row } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";

import FeedingTube from "../OrderSheet/FeedingTube";
import UrinaryCatheter from "../OrderSheet/UrinaryCatheter";
import CentralLine from "../OrderSheet/PreventiveCare/CentralLine";
import Tracheostomy from "../OrderSheet/RespiratoryCare/Tracheostomy";
import { updateDoctorInspectionAPI } from "../../../../services/doctor.services";
import RespiratorySupport from "../OrderSheet/RespiratoryCare/RespiratorySupport";
import { DoctorInitialContext } from "../../InspectionForms/HistoryForm/DoctorHistoryFormProvider";

function findCareItemsInIA(obj, careItemsToFind) {
  const results = {};

  function search(obj) {
    for (const key in obj) {
      if (
        careItemsToFind.includes(key) &&
        (obj[key] === "yes" || obj[key] === true)
      ) {
        results[key] = [{ ...obj, key }];
      }

      if (typeof obj[key] === "object" && obj[key] !== null) {
        search(obj[key]);
      }
    }
  }

  search(obj);
  return results;
}

function CareItems({ setCurrent, initialRefresh }) {
  const ref = useRef(null);
  const [data, setData] = useState({});
  const [action, setAction] = useState({});
  const { formData } = useContext(DoctorInitialContext);

  useEffect(() => {
    if (formData) {
      const careItemsToFind = [
        "central_line",
        "feeding_tube",
        "tracheostomy",
        "urinary_catheter",
        "respiratory_support",
      ];
      const foundItems = findCareItemsInIA(formData, careItemsToFind);

      setData(foundItems);
    }
  }, [formData]);

  function updateIACareItemRecord(record) {
    setAction((prev) => ({ ...prev, ...record }));
  }

  async function updateIaStatus() {
    try {
      const data = Object.keys(action);

      for (const key of data) {
        if (action[key]?.status === "continue") {
          ref.current?.onFinish(action[key]?.data, key);
        }
      }
      await updateDoctorInspectionAPI(formData?.id, {
        care_items_generated: true,
      });
      setCurrent(2);
      initialRefresh();
    } catch (error) {
      console.log(error);
    }
  }

  function careComponent(comp) {
    switch (comp) {
      case "feeding_tube":
        return (
          <FeedingTube
            ref={ref}
            iaAction={action}
            isIaCareItem={true}
            formData={{ data: data?.feeding_tube }}
            updateIACareItemRecord={updateIACareItemRecord}
          />
        );
      case "tracheostomy":
        return (
          <Tracheostomy
            ref={ref}
            iaAction={action}
            isIaCareItem={true}
            formData={{ data: data?.tracheostomy }}
            updateIACareItemRecord={updateIACareItemRecord}
          />
        );
      case "respiratory_support":
        return (
          <RespiratorySupport
            ref={ref}
            iaAction={action}
            isIaCareItem={true}
            formData={{ data: data?.respiratory_support }}
            updateIACareItemRecord={updateIACareItemRecord}
          />
        );
      case "urinary_catheter":
        return (
          <UrinaryCatheter
            ref={ref}
            iaAction={action}
            isIaCareItem={true}
            formData={{ data: data?.urinary_catheter }}
            updateIACareItemRecord={updateIACareItemRecord}
          />
        );
      case "central_line":
        return (
          <CentralLine
            ref={ref}
            iaAction={action}
            isIaCareItem={true}
            formData={{ data: data?.central_line }}
            updateIACareItemRecord={updateIACareItemRecord}
          />
        );

      default:
        break;
    }
  }

  return (
    <Row gutter={[24, 24]} style={{overflow:"auto ",height:"100%", padding:"20px"}}>
      {Object.keys(data)?.map((comp) => (
        <Col span={24}>{careComponent(comp)}</Col>
      ))}
      <Col span={24} style={{textAlign:"right"}}>
        <Button onClick={updateIaStatus} type="primary" >
          Save & Proceed
        </Button>
      </Col>
    </Row>
  );
}

export default CareItems;
