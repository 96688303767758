import React, { useState } from "react";
import {
  eatingAssessmentOptions,
  eatingAssessmentRangeOptions,
} from "../../../Options/speechSwallowOptions";
import { Form, Input, Typography } from "antd";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const EatingAssessmentTool = ({ form }) => {
  const [refresh, setRefresh] = useState(false);

  const calculateScore = () => {
    console.log(form.getFieldValue("eating_assessment"));
    const tValues = form.getFieldValue("eating_assessment");
    const { score, ...fValues } = tValues;
    const result = Object?.values(fValues).reduce(
      (acc, cur) => acc + (cur || 0),
      0
    );
    form.setFieldValue(["eating_assessment", "score"], result);
    return result;
  };

  const score = calculateScore();

  return (
    <>
      <Typography.Title level={5}>
        Eating Assessment Tool (EAT-10): {score}
      </Typography.Title>
      <Form.Item
        name={["eating_assessment", "score"]}
        hidden
        initialValue={score}
      >
        <Input value={score} />
      </Form.Item>
      {eatingAssessmentOptions?.map((option) => (
        <DeselectableRadioGroup
          label={option?.label}
          name={["eating_assessment", option?.value]}
          options={eatingAssessmentRangeOptions}
          form={form}
          setRefresh={setRefresh}
        />
      ))}
    </>
  );
};

export default EatingAssessmentTool;
