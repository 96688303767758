import React, { useContext, useState, useEffect } from "react";
import {
  Checkbox,
  InputNumber,
  Form,
  Radio,
  Typography,
  Row,
  Col,
  Space,
  Input,
} from "antd";
import {
  pastMedicalOptions,
  controlOptions,
} from "../../../Options/historyOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";
const { Text } = Typography;

const PastMedicalHistory = ({ context, form }) => {
  const { formData } = useContext(context);
  const [checkboxes, setCheckboxes] = useState(() =>
    pastMedicalOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] = !!formData?.history?.[checkbox.value];
      return acc;
    }, {})
  );

  useEffect(() => {
    setCheckboxes(
      pastMedicalOptions.reduce((acc, checkbox) => {
        acc[checkbox.value] = !!formData?.history?.[checkbox.value];
        return acc;
      }, {})
    );
  }, [formData]);

  const handleCheckboxChange = (value) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [value]: !prevCheckboxes[value],
    }));
  };

  return (
    <>
      <Text>Past Medical History</Text>
      <Row>
        {pastMedicalOptions?.map((checkbox) => (
          <Col span={24}>
            <div key={checkbox.value}>
              <Form.Item name={checkbox.value} valuePropName="checked">
                <Checkbox onChange={() => handleCheckboxChange(checkbox.value)}>
                  {checkbox.label}
                </Checkbox>
              </Form.Item>
              {checkboxes?.[checkbox.value] && (
                <div className="subsection-card">
                  <Row>
                    <Col span={6}>
                      {checkbox.value === "past_medical_others" && (
                        <Form.Item name={"past_medical_others_name"}>
                          <Input
                            style={{ maxWidth: 200 }}
                            placeholder="Please enter name"
                          />
                        </Form.Item>
                      )}
                      <Form.Item name={`${checkbox.value}_yrs`}>
                        <InputNumber
                          min={0}
                          max={80}
                          step={0.1}
                          stringMode
                          addonBefore="Since"
                          addonAfter="yrs"
                          style={{ width: "180px" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={18}>
                      {/* <Form.Item name={`${checkbox.value}_control`}>
                        <Radio.Group options={controlOptions} />
                      </Form.Item> */}
                      <DeselectableRadioGroup
                        name={`${checkbox.value}_control`}
                        form={form}
                        options={controlOptions}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default PastMedicalHistory;
