import { Modal, Timeline, Card, Tag } from "antd";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../../../../helpers/utility";
import { getNurseReviewDataApi } from "../../../../services/nurse.services";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import ProgressNotesIcon from "../../../../assets/images/nurseIcons/notes.svg";
import NoNurseDataIcon from "../../../../assets/doctorIcons/no-nurse-data.svg";
import dayjs from "dayjs";
import LoadingIndicator from "../../../Loaders/LoadingIndicator";

function NurseData({ nurseModalStatus, setNurseModalStatus, data, id }) {
  const [reviewData, setReviewData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      getNurseReviewDataApi(id)
        .then((res) => {
          setReviewData(res?.data);
          console.log(" nurse review data", res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);
  console.log("review data", reviewData);
  const filterData = reviewData?.data?.length > 0 ? reviewData?.data : [];
  console.log("filtered data", filterData);

  const timeLineData = filterData
  .filter((item) => item !== null && item !== undefined) 
  .map((item) => ({
    startTime: item?.process_time
      ? dayjs(item.process_time).format("DD MMM hh:mm A")
      : "Not added",
    endTime: item?.end_time
      ? dayjs(item.end_time).format("DD MMM hh:mm A")
      : "Not added",
    processedBy: item?.processed_by_name || "Unknown",
    remarks: item?.remarks || "No remarks",
    status: capitalizeFirstLetter(item?.action || "Unknown"),
  }));


  const renderStatusTag = (status) => {
    const color =
      status === "Done" ? "green" : status === "Withheld" ? "orange" : "red";
    return <Tag color={color}>{status}</Tag>;
  };

  return (
    <Modal
      footer={null}
      title="Nurse Data"
      open={nurseModalStatus}
      onCancel={() => setNurseModalStatus(false)}
      bodyStyle={{ maxHeight: "70vh", overflowY: "auto", padding: "20px" }}
      centered
    >
      {loading ? (
        <LoadingIndicator />
      ) : timeLineData.length > 0 ? (
        <Timeline mode="left" style={{ marginTop: 20 }}>
          {timeLineData.map((entry, index) => (
            <Timeline.Item
              key={index}
              color="blue"
              style={{ paddingBottom: "20px" }}
            >
              <Card
                bordered={false}
                style={{
                  marginBottom: 5,
                  backgroundColor: "#f9f9f9",
                  borderLeft: "0px solid #f5864e",
                  borderRadius: "5px",
                  boxShadow: "0px 2px 4px #dbdbdb",
                }}
                styles={{ body: { padding: "0px 18px" } }}
              >
                {entry.endTime !== "Not added" ? (
                  <p>
                    <strong>Start Time :</strong> {entry.startTime}
                  </p>
                ) : (
                  <p>
                    <strong>Process Time :</strong> {entry.startTime}
                  </p>
                )}
                {entry.endTime !== "Not added" && (
                  <p>
                    <strong>End Time :</strong> {entry.endTime}
                  </p>
                )}
                <p>
                  <strong>Processed By :</strong> {entry.processedBy}
                </p>
                <p>
                  <strong>Remarks:</strong> {entry.remarks}
                </p>
                <p>  <strong>Status :</strong> {renderStatusTag(entry.status)}</p>
              </Card>
            </Timeline.Item>
          ))}
        </Timeline>
      ) : (
        <NoDataPage
          title={"No Data Available"}
          subtitle={
            "Please check back later or ensure all relevant data has been recorded."
          }
          image={NoNurseDataIcon}
        />
      )}
    </Modal>
  );
}

export default NurseData;
