import React from "react";
import { Checkbox, Form, Radio } from "antd";
import DeselectableRadioGroup from "./DeselectableRadioGroup";

// This block to be used when a list of checkboxes is to be displayed with radio options for each checkbox
// The radio options are displayed only when the checkbox is checked
// Here is the sample data object to be used.
// checkboxOptions example:
// const cranialNervesAbnormalOptions = [
//     { label: "I (Olfactory N)", value: "i_olfactory_n" },
//     { label: "II (Optic N)", value: "ii_optic_n" },
//     { label: "III (Oculomotor N)", value: "iii_oculomotor_n" },
//     { label: "IV (Trochlear N)", value: "iv_trochlear_n" },
//     { label: "V (Trigeminal N)", value: "v_trigeminal_n" },
//     { label: "VI (Abducens N)", value: "vi_abducens_n" },
//     { label: "VII (Facial N)", value: "vii_facial_n" },
//     { label: "VIII (Auditory N)", value: "viii_auditory_n" },
//     { label: "IX (Glossopharyngeal)", value: "ix_glossopharyngeal" },
//     { label: "X (Vagus N)", value: "x_vagus_n" },
//     { label: "XI (Accessory N)", value: "xi_accessory_n" },
//     { label: "XII (Hypoglossal N)", value: "xii_hypoglossal_n" },
//   ];
// radioOptions example:
//  const cranialNerveSeverityOptions = [
//     {
//       label: "Left",
//       name_key: "left",
//       options: [
//         { label: "Normal", value: "normal" },
//         { label: "Affected", value: "affected" },
//       ],
//     },
//     {
//       label: "Right",
//       name_key: "right",
//       options: [
//         { label: "Normal", value: "normal" },
//         { label: "Affected", value: "affected" },
//       ],
//     },
//   ];

const CheckListFBYRadioItems = ({
  checkBoxOptions = [],
  radioOptions = [],
  checkboxes,
  setCheckboxes,
  keyChain = null,
  form,
}) => {
  const handleCheckboxChange = (value, e) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [value]: e?.target?.checked, // Toggle the checkbox value
    }));
  };

  return (
    <>
      {checkBoxOptions.map((checkbox, index) => (
        <div key={index}>
          <Form.Item
            name={keyChain ? [[keyChain], [checkbox.value]] : checkbox.value}
            valuePropName="checked"
          >
            <Checkbox onChange={(e) => handleCheckboxChange(checkbox.value, e)}>
              {checkbox.label}
            </Checkbox>
          </Form.Item>
          {checkboxes[checkbox.value] &&
            radioOptions.map((radio, radioIndex) => (
              <div
                key={`${index}_${radioIndex}`}
                style={{ marginLeft: "10px", marginRight: "10px" }}
              >
                {/* <Form.Item
                  label={radio.label}
                  id={`${checkbox.value}_${radio.name_key}`}
                  name={
                    keyChain
                      ? [[keyChain], [`${checkbox.value}_${radio.name_key}`]]
                      : `${checkbox.value}_${radio.name_key}`
                  }
                >
                  <Radio.Group options={radio.options} />
                </Form.Item> */}
                <DeselectableRadioGroup
                  label={radio?.label}
                  name={
                    keyChain
                      ? [[keyChain], [`${checkbox.value}_${radio.name_key}`]]
                      : `${checkbox.value}_${radio.name_key}`
                  }
                  options={radio?.options}
                  form={form}
                />
              </div>
            ))}
        </div>
      ))}
    </>
  );
};

export default CheckListFBYRadioItems;
