import React, { useContext, useEffect, useState } from "react";
import { Card, Row, Descriptions, Typography, Space, Col, Button } from "antd";
import textStyles from "../../../styles/Custom/Typography.module.css";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import FacilitiesForm from "./FacilitiesForm";
import { getFacilityDetailApi } from "../../../services/facilities.services";
import { UserContext } from "../../../context/UserContext";
import { FacilityID, Role } from "../../../constants/defaultKeys";
import { getCookie } from "../../../helpers/localStorage";

function FacilityCard({ activeSegment, refresh, refreshPage, params }) {
  const { userDetails } = useContext(UserContext);
  const [isVisible, setIsVisible] = useState(false);
  const [facilityData, setFacilityData] = useState(null);

  const cardDescription = (title, description) => {
    return (
      <Col span={4}>
        <Space direction="vertical">
          <Typography className={textStyles.body1}>{title}</Typography>
          <Typography className={textStyles.body3}>
            {description ? description : "-"}
          </Typography>
          {title === "Facility Name" && (
            <>
              <Space>
                <Typography className={textStyles.body3}>IP</Typography>
                <Typography className={textStyles.body1}>
                  {facilityData?.ip_available ? (
                    <CheckCircleOutlined
                      style={{ color: "green", fontSize: "18px" }}
                    />
                  ) : (
                    <CloseCircleOutlined
                      style={{ color: "red", fontSize: "18px" }}
                    />
                  )}
                </Typography>
                <Typography className={textStyles.body3}>OP</Typography>
                <Typography className={textStyles.body1}>
                  {facilityData?.op_available ? (
                    <CheckCircleOutlined
                      style={{ color: "green", fontSize: "18px" }}
                    />
                  ) : (
                    <CloseCircleOutlined
                      style={{ color: "red", fontSize: "18px" }}
                    />
                  )}
                </Typography>
              </Space>
            </>
          )}
        </Space>
      </Col>
    );
  };
  const defaultRole = userDetails?.currRole || getCookie(Role);
  useEffect(() => {
    const query =
      defaultRole === "Facility_Admin"
        ? Number(getCookie(FacilityID)) || userDetails?.currFacility
        : activeSegment;

    if (query)
      getFacilityDetailApi(query)
        .then((res) => {
          setFacilityData(res?.data);
        })
        .catch((err) => console.log(err));
  }, [
    activeSegment,
    refresh,
    params?.facility,
    Number(getCookie(FacilityID)),
    userDetails?.currFacility,
  ]);

  return (
    <>
      <Card style={{ borderRadius: "16px", border: "1px solid #EAECF0" }}>
        <Row gutter={[12, 12]}>
          {cardDescription("Facility Name", facilityData?.name)}

          {cardDescription(
            "Facility Address",
            `${facilityData?.address || ""} ${facilityData?.city || ""} ${
              facilityData?.state || ""
            } ${facilityData?.pin_code || ""}`
          )}
          {cardDescription("CIN", facilityData?.cin?.cin_no)}
          {cardDescription("GST", facilityData?.gstin?.gst_no)}
          {cardDescription("Contact Number", facilityData?.phone)}
          <Col span={4}>
            <Button onClick={() => setIsVisible(true)}>
              <EditOutlined />
            </Button>
          </Col>
        </Row>
      </Card>{" "}
      {isVisible && (
        <FacilitiesForm
          refreshPage={refreshPage}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          editListData={facilityData}
        />
      )}
    </>
  );
}

export default FacilityCard;
