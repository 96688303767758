import React, { useContext, useEffect, useState } from "react";
import { Form, Checkbox, Input } from "antd";
import { cardiovascularOptions } from "../../Options/clinicalImpressionOptions";

const CardioVascular = ({ context }) => {
  const { formData } = useContext(context);
  const [showCardioVascularOthers, setShowCardioVascularOthers] = useState(
    formData?.clinical_diagnosis?.cardiovascular_diagnosis?.includes("others")
  );

  const handleCardioVascularChange = (selectedOptions) => {
    setShowCardioVascularOthers(selectedOptions?.includes("others"));
  };

  useEffect(() => {
    setShowCardioVascularOthers(
      formData?.clinical_diagnosis?.cardiovascular_diagnosis?.includes("others")
    );
  }, [formData]);

  return (
    <>
      <Form.Item name="cardiovascular_diagnosis" label="CardioVascular">
        <Checkbox.Group
          options={cardiovascularOptions}
          onChange={handleCardioVascularChange}
        />
      </Form.Item>
      {showCardioVascularOthers && (
        <Form.Item
          name="cardiovascular_others"
          
        >
          <Input type="text" placeholder="Please specify" style={{width:"50%"}} />
        </Form.Item>
      )}
    </>
  );
};

export default CardioVascular;
