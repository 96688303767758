import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button, Segmented, Skeleton, Typography } from "antd";
import FacilitiesForm from "./FacilitiesForm";
import btnStyles from "../../../styles/Custom/Button.module.css";
import typoStyles from "../../../styles/Custom/Typography.module.css";
import FacilityCard from "./FacilityCard";
import BedManagement from "./BedManagement";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getFacilityListApi } from "../../../services/facilities.services";
import { PlusOutlined } from "@ant-design/icons";
import CustomLayout from "../../Layout/CustomLayout";
import SideMenu from "../../Layout/CustomLayout/SideMenu";
import { UserContext } from "../../../context/UserContext";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID, Role } from "../../../constants/defaultKeys";
import "./Facilities.css";

const Facilities = () => {
  const { userDetails } = useContext(UserContext);
  const navigate = useNavigate();
  const query = useParams();
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [facilities, setFacilities] = useState(null);
  const activeFacility = params?.facility ? Number(params?.facility) : null;
  const [activeSegment, setActiveSegment] = useState(
    activeFacility || facilities?.[0]?.id
  );
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };
  const onChange = (e) => {
    setActiveSegment(e);
    navigate(`/dashboard/${query?.tab}?facility=${e}`);
  };
  const defaultRole = userDetails?.currRole || getCookie(Role);
  useEffect(() => {
    if (defaultRole !== "Facility_Admin") {
      setLoading(true);

      getFacilityListApi()
        .then((res) => {
          setFacilities(res?.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [refresh]);

  return (
    <CustomLayout
      header={
        <>
          <Typography className={typoStyles.h6}>
            {defaultRole === "Facility_Admin" ? "Facility" : "Facilities"}
          </Typography>
        </>
      }
      sideContent={<SideMenu />}
    >
      <>
        <Row gutter={[12, 12]}>
          <Col span xs={24} sm={24} md={16} lg={20} xl={20}>
            {defaultRole !== "Facility_Admin" && (
              <Segmented
                className="segment-wrap"
                size="large"
                value={activeSegment || facilities?.[0]?.id}
                options={facilities?.map((item) => ({
                  label: item?.name,
                  value: item?.id,
                }))}
                onChange={onChange}
              />
            )}
          </Col>
          {defaultRole !== "Facility_Admin" && (
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={4}
              xl={4}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                onClick={() => setIsVisible(true)}
                className={btnStyles.mdBtn}
              >
                <PlusOutlined /> Facility
              </Button>
            </Col>
          )}
          <Col span={24}>
            <FacilityCard
              data={facilities}
              activeSegment={activeSegment || facilities?.[0]?.id}
              isVisible={isVisible}
              setIsVisible={setIsVisible}
              refresh={refresh}
              refreshPage={refreshPage}
              params={params}
            />
          </Col>
          <Col span={24}>
            <BedManagement id={activeSegment || facilities?.[0]?.id} />
          </Col>
        </Row>
        {/* ) : (
          !loading &&
          defaultRole !== "Facility_Admin" && (
            <Row className="facilities">
              <Col span={24} style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  onClick={() => setIsVisible(true)}
                  className={btnStyles.lgBtn}
                >
                  <PlusOutlined /> Facility
                </Button>
              </Col>
            </Row>
          )
        )} */}
      </>

      {isVisible && (
        <FacilitiesForm
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          refreshPage={refreshPage}
        />
      )}
    </CustomLayout>
  );
};

export default Facilities;
