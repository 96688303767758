import React from "react";
import CommonTable from "./CommonTable";
import dayjs from "dayjs";
import { capitalizeFirstLetter, timeAgo } from "../../../helpers/utility";
import { Typography, Tag, Row, Col } from "antd";
import useTwitterTimestamp from "../../../hooks/useTwitterTimestamp";
import { nebulisationDoseOptions } from "../../IpEMR/Options/careItemsOptions";

const Nebulization = ({ data }) => {
  const customTypography = (title, value) => {
    return (
      <>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontWeight: "lighter",
            fontSize: "10px",
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontSize: title === "Comments" ? "12px" : "14px",
          }}
        >
          {title === "Comments" ? (
            <Tag color="blue">
              {" "}
              <Typography
                style={{
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  fontSize: title === "Comments" ? "14px" : "16px",
                }}
              >
                {value}
              </Typography>
            </Tag>
          ) : (
            value
          )}
        </Typography>
      </>
    );
  };
  const columns = [
    {
      title: "Time Done",
      dataIndex: "process_time",
      key: "process_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <CommonTable
          data={
            Array?.isArray(item?.actions) && Array?.isArray(item?.actions[0])
              ? item?.actions?.flat()
              : item?.actions
          }
          columns={columns}
          titleData={() => (
            <Row>
              <Col span={8}>
                {customTypography(
                  "Name + Dose",
                  nebulisationDoseOptions?.find(
                    (option) => option.value === item?.drug_name
                  )?.label || "-"
                )}
              </Col>
              <Col span={8}>
                {customTypography("Comments", item?.data?.instructions)}
              </Col>
              <Col span={8}>
                {customTypography(
                  "Advised By",
                  capitalizeFirstLetter(item?.advised_by)
                )}
              </Col>
            </Row>
          )}
        />
      ))}
    </>
  );
};
export default Nebulization;
