import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Col,
  Drawer,
  Row,
  Table,
  Typography,
  Image,
  Space,
  Tag,
  Divider,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import { frequencyDisplay, medicineIcons } from "../../../helpers/medicineKeys";
import { getPatientMedicationHistoryAPI } from "../../../services/patient.services";
import dayjs from "dayjs";
import bedImg from "../../../assets/images/patientIcons/bed.svg";

const MedicationHistoryChart = ({
  open,
  setOpen,
  data,
  params,
  patientData,
}) => {
  console.log("Patient data ", patientData);
  const [medHistoryData, setMedHistoryData] = useState([]);
  const ip = params?.patient;
  const fetchMedHistory = async () => {
    getPatientMedicationHistoryAPI(ip)
      .then((res) => {
        setMedHistoryData(res?.data?.data);
      })
      .catch((error) => {
        console.log(`Error: ${error}`);
      });
  };

  useEffect(() => {
    fetchMedHistory();
  }, [ip]);
  // console.log("Med history", medHistoryData);
  const extractTimeSlots = (data = []) =>
    [
      ...new Set(
        data?.flatMap((medicine) =>
          medicine?.actions?.map((action) => action.date)
        )
      ),
    ].sort((a, b) => new Date(a) - new Date(b));

  const timeSlots =
    medHistoryData?.length > 0 ? extractTimeSlots(medHistoryData) : [];

  // console.log("time Slots", timeSlots);
  const filteredData = data?.filter((item) => item?.status !== "Stopped");
  const columns = [
    {
      title: "Medication/Dose/Route",
      fixed: "left",
      render(value, record) {
        return {
          //   props: {
          //     style: { background: value ? "#ECFDF3" : "#FEF3F2" },
          //   },
          children: (
            <Space>
              {/* <Image
                src={medicineIcons[record?.form]}
                preview={false}
                width="20px"
              /> */}
              <Typography>{record?.name || record?.medicine_name}</Typography>
              {/* <Typography>
                {record?.dose}
                {record?.unit}
              </Typography>
              <Tag>{record?.route}</Tag> */}
            </Space>
          ),
        };
      },
      //   render: (record) => (
      //     <Space>
      //       <Image
      //         src={medicineIcons[record?.form]}
      //         preview={false}
      //         width="20px"
      //       />
      //       <Typography>{record?.name || record?.medicine_name}</Typography>
      //       <Typography>
      //         {record?.dose}
      //         {record?.unit}
      //       </Typography>
      //       <Tag>{record?.route}</Tag>
      //     </Space>
      //   ),
      width: 400,
    },
    ...timeSlots?.map((slot) => ({
      title: `${dayjs(slot).format("MMM DD").toUpperCase()}`,
      dataIndex: slot,
      width: 160,
      render: (_, record) => {
        const action = record?.actions?.find((action) => action?.date === slot);
        return <>{action ? frequencyDisplay?.[action?.frequency] : "-"}</>;
      },
    })),
  ];
  return (
    <Drawer
      open={open}
      width={"100%"}
      styles={{ header: { padding: 0 } }}
      onClose={() => setOpen(false)}
      title={
        <Row style={{ padding: "12px 14px" }}>
          <Col>
            <LeftOutlined
              onClick={() => {
                setOpen(false);
              }}
              // onClick={() =>
              //   role === "receptionist"
              //     ? navigate(
              //         `/${role}/${
              //           query?.tab === "ip" ? "in-patients" : query?.tab
              //         }`
              //       )
              //     : navigate(`/${role}/home`)
              // }
              style={{
                fontSize: "18px",
                padding: "0px 10px",
                marginTop: "15px",
              }}
            />
          </Col>
          <Col span={10} style={{ marginLeft: "10px" }}>
            <Space>
              <Typography
                style={{ color: "#101828", fontSize: "16px", fontWeight: 600 }}
              >
                {`${patientData?.patient?.first_name || " "} ${
                  patientData?.patient?.last_name || " "
                }`}
              </Typography>
              {patientData?.bed?.bed_no && (
                <Tag color="orange" style={{ fontWeight: "500" }}>
                  <Image src={bedImg} preview={false} />{" "}
                  {patientData?.bed?.bed_no}
                </Tag>
              )}
            </Space>
            <br />
            <Space style={{ fontWeight: "500" }}>
              <Tag>
                {patientData?.patient?.age} {patientData?.patient?.gender}
              </Tag>
              {patientData?.ip_id && <Tag>IP ID: {patientData?.ip_id}</Tag>}
            </Space>
          </Col>
          <Col span={12} className="flex-start">
            <Typography
              style={{
                marginLeft: "12px",
                fontWeight: "500",
                fontSize: "20px",
              }}
            >
              Medication History
            </Typography>
          </Col>
        </Row>
      }
      closeIcon={null}
    >
      <Table
        dataSource={medHistoryData}
        scroll={{
          x: 1200,
          y: "60vh",
        }}
        pagination={false}
        columns={columns}
        bordered
      />
    </Drawer>
  );
};

export default MedicationHistoryChart;
