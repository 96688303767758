import React, { useContext, useState } from "react";
import CustomLayout from "../../Layout/CustomLayout";
import { Typography, Tabs } from "antd";
import SideMenu from "../../Layout/CustomLayout/SideMenu";
import typoStyles from "../../../styles/Custom/Typography.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import Service from "./service/Service";
import Categories from "./Categories/Categories";
import Packages from "./Packages/Packages";
import { getCookie } from "../../../helpers/localStorage";
import { Role } from "../../../constants/defaultKeys";

const items = [
  {
    key: "categories",
    label: "Categories",
  },
  {
    key: "services",
    label: "Services",
  },
  {
    key: "packages",
    label: "Packages",
  },
];

const Services = () => {
  const { userDetails } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const query = useParams();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [activeTab, setActiveTab] = useState(params?.type || "categories");

  const defaultRole = userDetails?.currRole || getCookie(Role);

  const onChange = (key) => {
    setActiveTab(key);
    if (defaultRole === "Facility_Admin") {
      navigate(
        `/dashboard/${query?.tab}?type=${key}&facility=${params?.facility}`
      );
    } else {
      navigate(`/dashboard/${query?.tab}?type=${key}`);
    }
  };

  return (
    <CustomLayout
      header={
        <div
          style={{
            gap: "5%",
            width: "100%",
          }}
          className="flex-start"
        >
          <Typography className={typoStyles.h6}>Services & Packages</Typography>
          <Tabs
            style={{ width: "80%", paddingTop: "16px" }}
            defaultActiveKey={params?.type}
            items={items}
            onChange={onChange}
          />
        </div>
      }
      sideContent={<SideMenu />}
    >
      {params?.type === "services" ? (
        <Service />
      ) : params?.type === "categories" ? (
        <Categories />
      ) : (
        <Packages />
      )}
    </CustomLayout>
  );
};

export default Services;
