import React from "react";
import { Card, Row, Col, Space, Typography, Tag } from "antd";
import { pressureSoreDressingOptions } from "../../../IpEMR/Options/careItemsOptions";
import { capitalizeFirstLetter } from "../../../../helpers/utility";
import PressureCommonCols from "./PressureCommonCols";
const PressureSore = ({ data }) => {
  const customTypography = (title, value) => {
    return (
      <>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontWeight: "lighter",
            fontSize: "10px",
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontWeight: "lighter",
            fontSize: "14px",
          }}
        >
          {value ? value : "-"}
        </Typography>
      </>
    );
  };
  return (
    <div>
      {data?.map((item) => (
        <Card
          title={
            <Row gutter={[12, 12]}>
              <Col span={8}>
                {customTypography(
                  "Area",

                  `${capitalizeFirstLetter(item?.drug_name)?.replaceAll(
                    "_",
                    " "
                  )} ${
                    item?.data?.do_data?.other_sore
                      ? ` - ` + item?.data?.do_data?.other_sore
                      : ""
                  } `
                )}
              </Col>

              <Col span={8}>
                {customTypography(
                  "Dressing",
                  item?.data?.do_data?.dressing === "others"
                    ? `Others - ${item?.data?.do_data?.other_dressing_area}`
                    : pressureSoreDressingOptions?.find(
                        (option) =>
                          option.value === item?.data?.do_data?.dressing
                      )?.label || "-"
                )}
              </Col>

              {/* <Col span={7}>
              {customTypography("Comments", item?.data?.instructions)}
            </Col> */}
              <Col span={8}>
                {customTypography(
                  "Advised By",
                  capitalizeFirstLetter(item?.advised_by)
                )}
              </Col>
            </Row>
          }
        >
          {item?.pressure_sore_dressing && (
            <PressureCommonCols
              data={item?.pressure_sore_dressing}
              heading="Dressing"
              type="pressure_sore_dressing"
            />
          )}
          {item?.pressure_sore_debridement && (
            <PressureCommonCols
              data={item?.pressure_sore_debridement}
              heading="Debridement"
              type="pressure_sore_debridement"
            />
          )}

          {item?.pressure_sore_vac_management && (
            <PressureCommonCols
              data={item?.pressure_sore_vac_management}
              heading="VAC Management"
              type="pressure_sore_vac_management"
            />
          )}
        </Card>
      ))}
    </div>
  );
};

export default PressureSore;
