import React, { useContext, useEffect } from "react";

import TreatmentPlan from "./TreatmentPlan";
import { PhysioInitialContext } from "../../Physiotherapist/PhysiotherapistInitialInspection/PhysiotherapistInitialAssessmentProvider";

const PhysioTreatmentPlan = ({ form }) => {
  const { formData } = useContext(PhysioInitialContext);
  useEffect(() => {
    form.setFieldsValue(formData?.treatment_plan);
  }, [formData]);
  return (
    <>
      <TreatmentPlan form={form} />
    </>
  );
};

export default PhysioTreatmentPlan;
