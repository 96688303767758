import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { SpeechInitialContext } from "./SpeechSwallowInitialAssessmentProvider";
import SpeechSwallowGeneralExaminationSummary from "../../InspectionSummaries/GeneralExaminationSummary/SpeechSwallowGeneralExaminationSummary";
import SpeechSwallowFunctionalDiagnosisSummary from "../../InspectionSummaries/FunctionalExaminationSummary/SpeechSwallowFunctionalDiagnosisSummary";
import SpeechSwallowScalesSummary from "../../InspectionSummaries/Scales/SpeechSwallowScalesSummary";
import { checkEmpty } from "../../../../helpers/utility";
import { getDoctorInspectionAPI } from "../../../../services/doctor.services";
import DoctorHistorySummary from "../../InspectionSummaries/HistorySummary/DoctorHistorySummary";

const SpeechSwallowInspectionSummary = ({ editId, docId }) => {
  const { formData } = useContext(SpeechInitialContext);
  const [history, setHistory] = useState([]);

  const getHistory = async () => {
    getDoctorInspectionAPI(docId).then((res) => {
      console.log(res?.data?.data);
      setHistory(res?.data?.data);
    });
  };

  useEffect(() => {
    getHistory();
  }, [docId]);

  return (
    <Row className="summaries" gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {checkEmpty(history) && <DoctorHistorySummary formData={history} />}
        {checkEmpty(formData?.functional_diagnosis) && (
          <SpeechSwallowFunctionalDiagnosisSummary formData={formData} />
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {checkEmpty(formData?.general_examination) && (
          <SpeechSwallowGeneralExaminationSummary formData={formData} />
        )}
        {checkEmpty(formData?.scales) && (
          <SpeechSwallowScalesSummary formData={formData} />
        )}
      </Col>
    </Row>
  );
};

export default SpeechSwallowInspectionSummary;
