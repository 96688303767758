import React, { useContext, useEffect, useState } from "react";
import CheckListFBYRadioItems from "../../../../../../blocks/FormItems/CheckListFBYRadioItems";
import {
  nursePressureSoreGradeOptions,
  pressureSoreAreaOptions,
} from "../../../../Options/systemicExamOptions";
import { yesNoOptions } from "../../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const NursePressureSore = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context);

  const [checkboxes, setCheckboxes] = useState([]);
  useEffect(() => {
    const initialCheckboxes = pressureSoreAreaOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          formData?.examination?.pressure_sore_details?.[checkbox.value] ||
          formData?.systemic_examination?.pressure_sore_details?.[
            checkbox.value
          ] ||
          false;
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [formData]);

  return (
    <>
      <DeselectableRadioGroup
        label={"Pressure Sores"}
        name={["pressure_sore_details", "pressure_sores"]}
        options={yesNoOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue(["pressure_sore_details", "pressure_sores"]) ===
        "yes" && (
        <div className="subsection-card">
          <CheckListFBYRadioItems
            checkBoxOptions={pressureSoreAreaOptions}
            radioOptions={nursePressureSoreGradeOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            keyChain={"pressure_sore_details"}
            form={form}
          />
        </div>
      )}
    </>
  );
};

export default NursePressureSore;
