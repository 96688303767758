import React, { useEffect, useState } from "react";
import {
  Col,
  Drawer,
  Image,
  Row,
  Skeleton,
  Space,
  Tag,
  Typography,
} from "antd";
import { patientInvestigationsViewAPI } from "../../../../services/patient.services";
import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import AllImageView from "./AllImageView";
import "./AllImageView.css";
import bedImg from "../../../../assets/images/patientIcons/bed.svg";

const InvestigationGallery = ({ open, setOpen, id, editData, patientData }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(null);

  console.log("patientData---------", patientData);

  useEffect(() => {
    setLoading(true);
    patientInvestigationsViewAPI(
      `patient_id=${id || editData?.ip_admission_id}&ip_admission=${
        id || editData?.ip_admission_id
      }&gallery=true`
    )
      .then((res) => {
        setData(res?.data);
        setFilteredData(res?.data?.filter((item) => item?.id === editData?.id));
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [editData?.id, id]);

  return (
    <Drawer
      width={"100%"}
      open={open}
      onClose={() => setOpen(false)}
      closeIcon={null}
      footer={null}
      headerStyle={{
        padding: "5px 20px",
        boxShadow: "rgb(34, 34, 34) 0px 2px 4px -4px",
      }}
      title={
        <Row
          style={{
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <Space>
              <LeftOutlined
                onClick={() => setOpen(false)}
                style={{
                  fontSize: "18px",
                  padding: "0px 10px",
                  marginTop: "15px",
                }}
              />
              <Typography
                style={{ color: "#101828", fontSize: "16px", fontWeight: 600 }}
              >
                {`${patientData?.patient?.first_name || " "} ${
                  patientData?.patient?.last_name || " "
                }`}
              </Typography>
              {patientData?.bed?.bed_no && (
                <Tag color="orange">
                  <Image src={bedImg} preview={false} />{" "}
                  {patientData?.bed?.bed_no || ""}
                </Tag>
              )}
            </Space>
            <br />
            <Space style={{ marginLeft: "48px", fontWeight: "500" }}>
              {(patientData?.patient?.age || patientData?.patient?.gender) && (
                <Tag>
                  {patientData?.patient?.age} {patientData?.patient?.gender}
                </Tag>
              )}
              {patientData?.ip_id && <Tag>IP ID: {patientData?.ip_id}</Tag>}
            </Space>
          </Col>

          <Col
            xs={20}
            sm={20}
            md={16}
            lg={12}
            xl={12}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography style={{ fontWeight: "normal", fontSize: "16px" }}>
              Investigation Gallery
            </Typography>
          </Col>
          <Col
            xs={2}
            sm={2}
            md={2}
            lg={6}
            xl={6}
            style={{ textAlign: "right" }}
          >
            <CloseOutlined onClick={() => setOpen(false)} />
          </Col>
        </Row>
      }
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <AllImageView data={editData ? filteredData : data} />
      )}
    </Drawer>
  );
};

export default InvestigationGallery;
