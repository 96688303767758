// DoctorAppointments.js
import React, { useContext, useEffect, useState } from "react";
import { Col, Segmented, Row, Button, Form, message } from "antd";
import DoctorLayout from "../../Layout/DoctorLayout";
import btnStyles from "../../../styles/Custom/Button.module.css";
import MySlots from "./MySlots";
import {
  doctorAppointmentsApi,
  doctorUpdateAppointmentsApi,
  getDoctorAppointmentsApi,
} from "../../../services/doctor.services";
import { UserContext } from "../../../context/UserContext";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID } from "../../../constants/defaultKeys";
import AppointmentsView from "./AppointmentsView";
import dayjs from "dayjs";
import PatientTypeContextProvider from "../../Layout/DoctorLayout/PatientTypeContextProvider";
import { useNavigate } from "react-router-dom";

const items = [
  { label: "My Appointments", value: "myAppointments" },
  { label: "My Slots", value: "mySlots" },
];

const DoctorAppointments = () => {
  const navigate = useNavigate();
  const url = new URL(window?.location?.href);
  const { userDetails } = useContext(UserContext);
  const [form] = Form.useForm();
  const [appointmentsData, setAppointmentsData] = useState(null);
  const [activeSegment, setActiveSegment] = useState(
    new URLSearchParams(document?.location?.search)?.get("agenda")
  );
  const [refresh, setRefresh] = useState(false);

  const handleRefresh = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    getDoctorAppointmentsApi(
      `facility=${
        getCookie(FacilityID) || userDetails?.currFacility
      }&doctor_id=${userDetails?.id}`
    )
      .then((res) => {
        const availabilityTimings = res?.data?.data?.availability_timings;

        // Check if availabilityTimings exists
        if (!availabilityTimings) return;

        const formattedTimings = Object.keys(availabilityTimings).reduce(
          (acc, day) => {
            const { availability, availability_timings } =
              availabilityTimings[day];

            acc[day] = {
              availability,
              availability_timings:
                availability_timings.length > 0
                  ? availability_timings.map((range) => [
                      dayjs(range[0], "HH:mm"),
                      dayjs(range[1], "HH:mm"),
                    ])
                  : [[]], // Ensure there's at least one empty range for days with no timings
            };

            return acc;
          },
          {}
        );

        form.setFieldsValue({
          ...formattedTimings,
          slot_duration: res?.data?.data?.slot_duration || 15, // Default to 15 if not present
        });

        setAppointmentsData(res?.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [refresh]);

  const onChange = (e) => {
    url?.searchParams?.set("agenda", e);
    navigate(`${document?.location.pathname}?${url.searchParams.toString()}`, {
      replace: true,
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(document?.location?.search);
    const status = params?.get("agenda");
    setActiveSegment(status);
  }, [url]);

  const onFinish = (values) => {
    const formattedValues = Object.keys(values).reduce((acc, day) => {
      const availability = values[day]?.availability;

      const timings = availability
        ? values[day].availability_timings?.map((range) =>
            range ? [range[0].format("HH:mm"), range[1].format("HH:mm")] : ""
          )
        : [];

      acc[day] = {
        availability,
        availability_timings: timings || [],
      };

      return acc;
    }, {});
    if (appointmentsData && appointmentsData?.availability_timings) {
      doctorUpdateAppointmentsApi(appointmentsData?.id, {
        ...formattedValues,
        slot_duration: values.slot_duration,
        doctor_id: userDetails?.id,
      })
        ?.then((res) => {
          message.success(res?.data?.message);
          handleRefresh();
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
        });
    } else {
      doctorAppointmentsApi(
        `facility=${getCookie(FacilityID) || userDetails?.currFacility}`,
        {
          ...formattedValues,
          slot_duration: values.slot_duration,
          doctor_id: userDetails?.id,
        }
      )
        ?.then((res) => {
          message.success(res?.data?.message);
          handleRefresh();
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <PatientTypeContextProvider>
      <DoctorLayout
        header={true}
        footer={
          activeSegment === "mySlots" && (
            <div className="flex-end" style={{ gap: "24px" }}>
              <Button
                type="primary"
                onClick={() => form.submit()}
                className={btnStyles.mdBtn}
              >
                Confirm
              </Button>
            </div>
          )
        }
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Segmented
              size="medium"
              options={items}
              value={activeSegment}
              onChange={onChange}
            />
          </Col>

          <Col span={24}>
            {activeSegment === "myAppointments" ? (
              <AppointmentsView />
            ) : (
              <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                  Sunday: { availability: false, availability_timings: [[]] },
                  Monday: { availability: true, availability_timings: [[]] },
                  Tuesday: { availability: true, availability_timings: [[]] },
                  Wednesday: { availability: true, availability_timings: [[]] },
                  Thursday: { availability: true, availability_timings: [[]] },
                  Friday: { availability: true, availability_timings: [[]] },
                  Saturday: { availability: true, availability_timings: [[]] },
                }}
              >
                <MySlots form={form} />
              </Form>
            )}
          </Col>
        </Row>
      </DoctorLayout>
    </PatientTypeContextProvider>
  );
};

export default DoctorAppointments;
