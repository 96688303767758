import React from "react";
import { Table, Space, Tag, Typography } from "antd";
import { capitalizeFirstLetter } from "../../../../helpers/utility";
import { tracheostomyOptions } from "../../../IpEMR/Options/careItemsOptions";
import tagStyles from "../../../../styles/Custom/Tag.module.css";
import CommonTable from "../CommonTable";
import dayjs from "dayjs";

const Tracheostomy = ({ data }) => {
  const columns = [
    {
      title: "Catheter Size",
      dataIndex: "catheter_size",
    },
    {
      title: "Suction",
      dataIndex: "suction",
    },
    {
      title: "Suction Pressure (cmh20)",
      dataIndex: "suction_pressure",
    },
    {
      title: "Secretions",
      dataIndex: "secretions",
    },
    {
      title: "Time Done",
      dataIndex: "process_time",
      key: "process_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];
  return (
    <div>
      {data?.map((item) => (
        <CommonTable
          data={item?.actions.flat()}
          columns={columns}
          titleData={() => (
            <>
              <Space>
                <Typography
                  style={{
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                    fontWeight: "lighter",
                    fontSize: "10px",
                  }}
                >
                  Type
                </Typography>{" "}
                <Tag color="blue" className={tagStyles.smRndTag}>
                  Advised By {capitalizeFirstLetter(item?.advised_by)}
                </Tag>
              </Space>
              <Typography
                style={{
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  marginTop: "4px",
                  fontWeight: "lighter",
                  fontSize: "14px",
                }}
              >
                {tracheostomyOptions?.find(
                  (option) => option.value === item?.drug_name
                )?.label || "-"}
              </Typography>
            </>
          )}
        />
      ))}
    </div>
  );
};

export default Tracheostomy;
