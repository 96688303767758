import React from "react";
import CommonTable from "./CommonTable";
import { Space, Typography, Tag } from "antd";
import dayjs from "dayjs";
import { feedingTubeOptions } from "../../IpEMR/Options/careItemsOptions";
import { capitalizeFirstLetter } from "../../../helpers/utility";
import tagStyles from "../../../styles/Custom/Tag.module.css";

const FeedingTube = ({ data }) => {
  const columns = [
    { title: "Position", dataIndex: "position" },
    { title: "Aspiration", dataIndex: "aspiration" },
    { title: "Tube Change", dataIndex: "tube_change" },
    {
      title: "Time Done",
      dataIndex: "process_time",
      key: "process_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];

  return (
    <div>
      {data?.map((item) => (
        <CommonTable
          data={item?.actions?.flat()}
          columns={columns}
          titleData={() => (
            <Space>
              <Typography style={{ fontSize: "16px", fontWeight: "lighter" }}>
                {feedingTubeOptions?.find(
                  (option) => option.value === item?.drug_name
                )?.label || "-"}
              </Typography>
              <Tag className={tagStyles.smRndTag} color="blue">
                Advised by {capitalizeFirstLetter(item?.advised_by)}
              </Tag>
            </Space>
          )}
        />
      ))}
    </div>
  );
};

export default FeedingTube;
