import React from "react";
import { aphasiaSeverityOptions } from "../../../Options/speechSwallowOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const AphasiaSeverityRating = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Aphasia Severity Rating"}
        name={"aphasia_severity_rating"}
        options={aphasiaSeverityOptions}
        form={form}
      />
    </>
  );
};

export default AphasiaSeverityRating;
