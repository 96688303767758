import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, message } from "antd";
import {
  downloadFacilityDietApi,
  getFacilityDietApi,
} from "../../../services/common.services";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserContext";
import FacilityDietTable from "./FacilityDietTable";
import { DownloadOutlined, ExportOutlined } from "@ant-design/icons";

const FacilityDietChart = () => {
  const { userDetails } = useContext(UserContext);
  const [dietData, setDietData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getFacilityDietApi(getCookie(FacilityID) || userDetails?.currFacility)
      .then((res) => {
        setDietData(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [getCookie(FacilityID) || userDetails?.currFacility]);

  const download = () => {
    setBtnLoading(true);
    downloadFacilityDietApi(getCookie(FacilityID) || userDetails?.currFacility)
      .then((response) => response.data)
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "facility_diet.csv";
        a.click();
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong, Please try after sometime.");
        setBtnLoading(false);
      });
  };

  return (
    <Row gutter={[12, 12]}>
      <Col span={24} style={{ textAlign: "right" }}>
        <Button
          icon={<DownloadOutlined />}
          type="primary"
          size="large"
          onClick={download}
          loading={btnLoading}
        >
          Export
        </Button>
      </Col>
      <Col span={24}>
        <FacilityDietTable dietData={dietData} loading={loading} />
      </Col>
    </Row>
  );
};

export default FacilityDietChart;
