import React, { useState } from "react";
import {
  emotionalIntelligenceOptions,
  emotionalIntelligenceScaleOptions,
} from "../../Options/psychologistOptions";
import { Form, List, Typography, Input } from "antd";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const { Title, Paragraph } = Typography;

const EmotionalIntelligence = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  const calculateAggregate = (prefix) => {
    const scaleValues =
      form.getFieldValue(["emotional_intelligence", "scale"]) || {};
    const sum = Object.entries(scaleValues).reduce((acc, [key, value]) => {
      if (key.startsWith(prefix)) {
        return acc + value;
      }
      return acc;
    }, 0);
    form.setFieldsValue({ emotional_intelligence: { [prefix]: sum } });

    return sum;
  };

  const getSummaryText = () => {
    const threshold = 18;
    const values = [
      calculateAggregate("self_awareness"),
      calculateAggregate("self_management"),
      calculateAggregate("social_awareness"),
      calculateAggregate("relationship_management"),
    ];
    const interpretation = values.every((value) => value >= threshold)
      ? "All Good"
      : "Could be Improved";
    form.setFieldsValue({
      emotional_intelligence: { interpretation: interpretation },
    });
    return interpretation;
  };

  return (
    <>
      <Title level={5}>Emotional Intelligence Scale</Title>
      {emotionalIntelligenceOptions?.map((option) => (
        <DeselectableRadioGroup
          label={option?.label}
          name={["emotional_intelligence", "scale", option.value]}
          options={emotionalIntelligenceScaleOptions}
          form={form}
          setRefresh={setRefresh}
        />
      ))}

      <List
        bordered
        dataSource={[
          {
            title: "Self Awareness",
            nameKey: "self_awareness",
            value: calculateAggregate("self_awareness"),
          },
          {
            title: "Self Management",
            nameKey: "self_management",
            value: calculateAggregate("self_management"),
          },
          {
            title: "Social Awareness",
            nameKey: "social_awareness",
            value: calculateAggregate("social_awareness"),
          },
          {
            title: "Relationship Management",
            nameKey: "relationship_management",
            value: calculateAggregate("relationship_management"),
          },
        ]}
        renderItem={(item) => (
          <List.Item>
            <Typography.Text strong>{item.title}:</Typography.Text> {item.value}
            <Form.Item name={["emotional_intelligence", item.nameKey]} hidden>
              <Input />
            </Form.Item>
          </List.Item>
        )}
      />
      <Paragraph>{getSummaryText()}</Paragraph>
      <Form.Item name={["emotional_intelligence", "interpretation"]} hidden>
        <Input />
      </Form.Item>
    </>
  );
};

export default EmotionalIntelligence;
