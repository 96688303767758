import React from "react";
import { Spin, Typography } from "antd";

const LoadingOverlay = ({ message = "Loading...", spinning = true }) => {
  if (!spinning) return null;

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        flexDirection: "column",
      }}
    >
      <Spin />
      <Typography.Text
        style={{
          marginTop: "10px",
          fontSize: "12px",
          color: "#a6a6a6",
          display: "block",
        }}
      >
        {message}
      </Typography.Text>
    </div>
  );
};

export default LoadingOverlay;
