import React, { useContext, useEffect } from "react";
import Therapy from "./Therapy";
import { PhysioInitialContext } from "../../Physiotherapist/PhysiotherapistInitialInspection/PhysiotherapistInitialAssessmentProvider";

const PhysioTherapy = ({ form }) => {
  const { formData } = useContext(PhysioInitialContext);

  useEffect(() => {
    form.setFieldsValue(formData?.therapy);
  }, [formData]);
  return (
    <>
      <Therapy form={form} />
    </>
  );
};

export default PhysioTherapy;
