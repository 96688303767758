import React, { useState } from "react";
import { Tag, Row, Col, Tooltip as AntTooltip, Space } from "antd";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
} from "recharts";
import scalesConfig from "../../../../helpers/physioscaleskeys";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import scaleIcon from "../../../../assets/images/rating-scale 1.svg";
import { ReloadOutlined } from "@ant-design/icons";

const ProgressChart = ({ scaleKey, tags, chartData, subItemKey, height }) => {
  const [selectedKeys, setSelectedKeys] = useState([]);

  const handleTagClick = (key) => {
    setSelectedKeys((prevSelected) =>
      prevSelected.includes(key)
        ? prevSelected.filter((item) => item !== key)
        : [...prevSelected, key]
    );
  };

  console.log("chartData", chartData);

  if (!chartData || chartData.length === 0) {
    return (
      <NoDataPage
        title="No data was recorded for this scale"
        subtitle=""
        image={scaleIcon}
      />
    );
  }

  return (
    <>
      <Row gutter={[8, 8]} justify="center" style={{ marginBottom: 16 }}>
        {tags.map((tagKey, index) => {
          const tagConfig = subItemKey
            ? scalesConfig[scaleKey]?.subItems?.[subItemKey]?.keys?.[tagKey]
            : scalesConfig[scaleKey]?.keys?.[tagKey] || {};

          const isSelected = selectedKeys.includes(tagKey);

          return (
            <Col key={index}>
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "200",
                  backgroundColor: isSelected ? tagConfig?.color : "white",
                  borderColor: tagConfig?.color,
                  color: isSelected ? "white" : tagConfig?.color,
                  cursor: "pointer",
                }}
                onClick={() => handleTagClick(tagKey)}
              >
                {tagConfig?.name || tagKey.replace(/_/g, " ")}
              </Tag>
            </Col>
          );
        })}
        <Col span={2}>
          <AntTooltip title="Clear All">
            <Tag
              style={{ cursor: "pointer" }}
              onClick={() => setSelectedKeys([])}
            >
              <ReloadOutlined />
            </Tag>
          </AntTooltip>
        </Col>
        <Col span={24}>
          <div style={{ width: "100%", height: height }}>
            <ResponsiveContainer>
              <LineChart data={chartData}>
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 10]} />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "rgba(50, 50, 50, 01)",
                    borderRadius: "5px",
                    color: "#ffffff",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                  itemStyle={{
                    color: "#ffffff",
                  }}
                />
                {(selectedKeys.length === 0 ? tags : selectedKeys).map(
                  (key) => {
                    const keyConfig = subItemKey
                      ? scalesConfig[scaleKey]?.subItems?.[subItemKey]?.keys?.[
                          key
                        ]
                      : scalesConfig[scaleKey]?.keys?.[key] || {};

                    return (
                      <Line
                        key={key}
                        type="monotone"
                        dataKey={key}
                        stroke={keyConfig?.color || "#8884d8"}
                        strokeWidth={1.5}
                        connectNulls={true}
                        name={key
                          .replace(/_/g, " ")
                          .replace(/^(\w)/, (c) => c.toUpperCase())}
                      />
                    );
                  }
                )}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ProgressChart;
