import React from "react";
import { Form, Select, Input, Space } from "antd";

const SelectWithOtherInput = ({
  name,
  label = null,
  options,
  form,
  inputPlaceholder = "Specify Other",
  otherFieldName = null,
  otherLabel = null,
  otherValue = "others",
  rules = [],
  inputRules = [],
  selectProps = {},
  inputProps = {},
  spaceSize = "large",
  selectWidth = 200,
}) => {
  const otherName = otherFieldName || `${name}_others`;

  return (
    <Space size={spaceSize}>
      <Form.Item name={name} label={label} rules={rules}>
        <Select
          style={{ width: selectWidth }}
          popupClassName="custom-dropdown"
          {...selectProps}
          options={options}
          onChange={() => {
            if (selectProps.onChange) selectProps.onChange();
          }}
        />
      </Form.Item>
      {form.getFieldValue(name) === otherValue && (
        <Form.Item label={otherLabel} name={otherName} rules={inputRules}>
          <Input placeholder={inputPlaceholder} {...inputProps} />
        </Form.Item>
      )}
    </Space>
  );
};

export default SelectWithOtherInput;
