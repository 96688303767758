import axiosInstance from "../config/axios.config";
export const getPatientListApi = (facility_id, type, page) => {
  if (type === "current") {
    return axiosInstance.get(
      `/appointments/ip-admissions/list/?${facility_id}&list=active&page=${page}`
    );
  } else {
    return axiosInstance.get(
      `/appointments/ip-admissions/list/?${facility_id}&list=discharged&page=${page}`
    );
  }
};
export const getPatientDetailsApi = (admisssionId) => {
  return axiosInstance.get(`inpatient/emr/${admisssionId}/`);
};
export const getIpPatientDetailApi = (admisssionId) => {
  return axiosInstance.get(`accounts/patient-details/${admisssionId}/`);
};
export const medicationPrescribeAPI = (payload) => {
  return axiosInstance.post(
    `inpatient/patient-medications/prescribe/`,
    payload
  );
};
export const aiVisionApi = (params) => {
  return axiosInstance.get(`careservices/ai-vision/read-prescription/`, {
    params: { prescription_urls: params },
  });
};
export const updateMedicationPrescribeAPI = (medication_id, payload) => {
  return axiosInstance.patch(
    `inpatient/patient-medications/update/${medication_id}/`,
    payload
  );
};
export const getpatientMedicationsAPI = (params) => {
  return axiosInstance.get(`inpatient/patient-medications/view/?${params}`);
};
export const patientInvestigationsOrderAPI = (payload) => {
  return axiosInstance.post(`inpatient/patient-investigations/order/`, payload);
};
export const patientInvestigationsOrderUpdateAPI = (
  patient_inv_id,
  payload
) => {
  return axiosInstance.patch(
    `inpatient/patient-investigations/update/${patient_inv_id}/`,
    payload
  );
};
export const patientInvestigationsViewAPI = (params) => {
  return axiosInstance.get(`inpatient/patient-investigations/view/?${params}`);
};
export const deletePatientatientInvestigationsAPI = (inv_id) => {
  return axiosInstance.delete(
    `inpatient/patient-investigations/delete/${inv_id}/`
  );
};
export const deletePatientatientMedicationsAPI = (med_id) => {
  return axiosInstance.delete(
    `inpatient/patient-medications/delete/${med_id}/`
  );
};

export const getPatientMonitoringAPI = (id, params) => {
  return axiosInstance.get(`inpatient/progress-notes/${id}/`, { params });
};

export const createPatientMonitoringAPI = (id, payload) => {
  return axiosInstance.post(`inpatient/progress-notes/${id}/`, payload);
};

export const updatePatientMonitoringAPI = (
  admission_id,
  progress_notes_id,
  payload
) => {
  return axiosInstance.patch(
    `inpatient/progress-notes/${admission_id}/${progress_notes_id}/`,
    payload
  );
};

export const deletePatientMonitoringAPI = (admission_id, progress_notes_id) => {
  return axiosInstance.delete(
    `inpatient/progress-notes/${admission_id}/${progress_notes_id}/`
  );
};
export const getPatientInfoApi = (id) => {
  return axiosInstance.get(`inpatient/patient-info/${id}/`);
};
export const uploadPatientImagesApi = (payload) => {
  return axiosInstance.post(`appointments/add-inpatient-upload/`, payload);
};
export const addCategoryApi = (payload) => {
  return axiosInstance.post(`appointments/category/`, payload);
};
export const getCategoriesListApi = () => {
  return axiosInstance.get(`appointments/category/`);
};
export const getPatientUploadsApi = (params) => {
  return axiosInstance.get(`appointments/get-inpatient-uploads/?${params}`);
};

export const getOutPatientListApi = (facility_id, type, page) => {
  if (type === "week") {
    return axiosInstance.get(
      `appointments/op-admissions/list/?${facility_id}&type=${type}&page=${page}`
    );
  } else {
    return axiosInstance.get(
      `appointments/op-admissions/list/?${facility_id}&page=${page}`
    );
  }
};

export const getPatientMedicationHistoryAPI = (ip_admission) => {
  return axiosInstance.get(`/inpatient/medication-history/${ip_admission}/`);
};
export const getPatientMonitoringChartsApi = (ip_admission, params) => {
  return axiosInstance.get(
    `/inpatient/patient-monitoring-charts/${ip_admission}/?${params}`
  );
};
