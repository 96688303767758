import React, { useContext, useEffect, useMemo, useState } from "react";

import CheckListFBYSelect from "../../../../../../blocks/FormItems/CheckListFBYSelect";
import { getInitialValuesForChild } from "../../../../../../helpers/getInitialValues";
import {
  abnormalBreathZoneOptions,
  auscultationOptions,
  breathSoundsOptions,
  typeOfBreathingOptions,
} from "../../../../Options/systemicExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";
import SelectWithOtherInput from "../../../../../../blocks/FormItems/SelectWithOtherInput";

const RespiratoryForm = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);
  const [checkboxes, setCheckboxes] = useState({});

  // useEffect to update state when initialValues change
  useEffect(() => {
    const initialCheckboxes = abnormalBreathZoneOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          getInitialValuesForChild(
            abnormalBreathZoneOptions,
            initialValues?.systemic_examination !== null &&
              initialValues?.systemic_examination
          )[checkbox.value] || false; // Initialize all checkboxes based on initialValues
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <SelectWithOtherInput
        name={"type_of_breathing"}
        label={"Type of Breathing"}
        form={form}
        otherLabel={" "}
        options={typeOfBreathingOptions}
        selectProps={{
          onChange: () => {
            setRefresh((prev) => !prev);
          },
        }}
      />

      <DeselectableRadioGroup
        label={"Auscultation"}
        name={"ausculation"}
        options={auscultationOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("ausculation") === "abnormal_breath_sounds" && (
        <div className="subsection-card">
          <CheckListFBYSelect
            checkBoxOptions={abnormalBreathZoneOptions}
            selectOptions={breathSoundsOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            form={form}
            selectProps={{
              onChange: () => {
                setRefresh((prev) => !prev);
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default RespiratoryForm;
