import { Form, Input } from "antd";
import React from "react";
import { coughOptions } from "../../Options/RespiratoryTherapistOptions";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const Cough = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Cough"}
        name={["cough", "status"]}
        options={coughOptions}
        form={form}
      />
      <Form.Item name={["cough", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default Cough;
