import React from "react";
import { Card, Table } from "antd";
import dayjs from "dayjs";

const VitalsTable = ({ data }) => {
  const columns = [
    {
      title: "Time Done",
      dataIndex: "action_time",
      key: "action_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
    { title: "Temperature", dataIndex: "temperature", key: "temperature" },
    { title: "Pulse Rate", dataIndex: "pulse", key: "pulse" },
    { title: "Respiratory Rate", dataIndex: "respiration", key: "respiration" },
    {
      title: "Systolic BP",
      dataIndex: "systolic_pressure",
      key: "systolic_pressure",
    },
    {
      title: "Diastolic BP Rate",
      dataIndex: "diastolic_pressure",
      key: "diastolic_pressure",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Processed By",
      dataIndex: "processed_by_name",
      key: "processed_by_name",
    },
  ];
  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default VitalsTable;
