import { Form, Input } from "antd";
import React from "react";
import { respiratoryPatternOptions } from "../../Options/RespiratoryTherapistOptions";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const RespiratoryPattern = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Respiratory Pattern"}
        name={["respiratory_details", "pattern"]}
        options={respiratoryPatternOptions}
        form={form}
      />
      <Form.Item name={["respiratory_details", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default RespiratoryPattern;
