import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Table, Tag } from "antd";
import { formatToINR, getRandomColor } from "../../../../helpers/utility";
import PackagesForm from "./PackagesForm";
import { getCookie } from "../../../../helpers/localStorage";
import { Role } from "../../../../constants/defaultKeys";

const PackageTable = ({ data, loading, refreshPackages, userDetails }) => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  const defaultRole = userDetails?.currRole || getCookie(Role);

  const columns = [
    {
      title: "Package Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a?.name.localeCompare(b?.name),
    },
    {
      title: "Package Details",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a?.name.localeCompare(b?.name),
    },
    {
      title: "Services",
      dataIndex: "package_services",
      key: "package_services",
      width: "20%",

      render: (value) =>
        value?.map((item) => {
          return (
            <Tag
              color={getRandomColor(item?.service?.name)}
              style={{ marginBottom: "4px" }}
              key={item?.investigation?.name}
            >
              {item?.service?.name}
            </Tag>
          );
        }),
    },
    {
      title: "Investigations",
      dataIndex: "package_investigations",
      key: "package_investigations",
      width: "20%",
      render: (value) =>
        value?.map((item) => {
          return (
            <Tag
              color={getRandomColor(item?.investigation?.name)}
              style={{ marginBottom: "4px" }}
              key={item?.investigation?.name}
            >
              {item?.investigation?.name}
            </Tag>
          );
        }),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (value) => (value ? value?.replace("_", " ") : "-"),
    },
    {
      title: "Price (Rs)",
      dataIndex: "total_price",
      key: "total_price",
      render: (value) => formatToINR(value),
      sorter: (a, b) => a?.total_price - b?.total_price,
    },
    ...(defaultRole !== "Facility_Admin"
      ? [
          {
            title: "Action",
            render: (record) => (
              <EditOutlined
                onClick={() => {
                  setOpen(true);
                  setEditData(record);
                }}
              />
            ),
          },
        ]
      : []),
  ];
  return (
    <>
      <Table
        dataSource={data?.data}
        columns={columns}
        pagination={false}
        bordered
        loading={loading}
      />
      {open && (
        <PackagesForm
          visible={open}
          setVisible={setOpen}
          refreshPackages={refreshPackages}
          editData={editData}
        />
      )}
    </>
  );
};

export default PackageTable;
