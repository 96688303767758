import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button, Skeleton, Result, Empty, Input, Spin } from "antd";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { PlusOutlined } from "@ant-design/icons";
import ServiceTable from "./ServiceTable";
import {
  getFacilityServicesApi,
  getServicesApi,
} from "../../../../services/service.services";
import { UserContext } from "../../../../context/UserContext";
import { useLocation } from "react-router-dom";
import { FacilityID, Role } from "../../../../constants/defaultKeys";
import { getCookie } from "../../../../helpers/localStorage";
import ServiceForm from "./ServiceForm";
import { debounce } from "../../../../helpers/utility";

const Service = () => {
  const location = useLocation();
  const { userDetails } = useContext(UserContext);
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [isVisible, setIsVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);

  const defaultRole = userDetails?.currRole || getCookie(Role);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    let queryParams = {};
    if (searchQuery) {
      queryParams.search = searchQuery;
    }
    const queryString = new URLSearchParams(queryParams);
    if (defaultRole !== "Facility_Admin") {
      getServicesApi(queryString)
        .then((res) => {
          setData(res?.data?.message);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [refresh, searchQuery]);

  useEffect(() => {
    let queryParams = {};
    if (searchQuery) {
      queryParams.search = searchQuery;
    }
    const queryString = new URLSearchParams(queryParams);
    setLoading(true);
    if (defaultRole === "Facility_Admin") {
      getFacilityServicesApi(
        Number(getCookie(FacilityID)) || userDetails?.currFacility,
        queryString
      )
        .then((res) => {
          setData(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [
    searchQuery,
    params?.facility,
    userDetails,
    Number(getCookie(FacilityID)) || userDetails?.currFacility,
  ]);

  const onServiceSearch = (value) => {
    setSearchQuery(value?.target?.value);
  };

  return (
    <>
      {loading ? (
        <Spin
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        />
      ) : data?.length > 0 ? (
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Input.Search
              style={{ width: "30%", marginBottom: "10px" }}
              size="large"
              placeholder="Search services"
              onChange={debounce(onServiceSearch, 300)}
            ></Input.Search>
          </Col>
          {defaultRole !== "Facility_Admin" && (
            <Col span={12} style={{ textAlign: "right" }}>
              <Button
                className={btnStyles.lgBtn}
                type="primary"
                onClick={() => setIsVisible(true)}
              >
                <PlusOutlined />
                Service
              </Button>
            </Col>
          )}
          <Col span={24}>
            <ServiceTable
              data={data}
              refreshPage={refreshPage}
              userDetails={userDetails}
              loading={loading}
            />
          </Col>
        </Row>
      ) : (
        <>
          {defaultRole !== "Facility_Admin" ? (
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Col span={8} style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  onClick={() => setIsVisible(true)}
                  className={btnStyles.lgBtn}
                >
                  <PlusOutlined /> Each Service
                </Button>
              </Col>
            </Row>
          ) : (
            <Result icon={<Empty />} />
          )}
        </>
      )}
      {isVisible && (
        <ServiceForm
          open={isVisible}
          setOpen={setIsVisible}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default Service;
