import { Form, Select, Typography, Row, Col } from "antd";
import React from "react";
import {
  babinskisOptions,
  bodypartOptions,
  reflexes,
} from "../../../../Options/systemicExamOptions";

const ReflexesForm = () => {
  return (
    <>
      <Typography>Reflexes</Typography>
      <div className="subsection-card">
        <Row gutter={[16, 16]}>
          {reflexes.map((reflex) => (
            <Col span={24} key={reflex.value}>
              <Typography>{reflex.label}</Typography>
              <Form.Item
                name={`${reflex.value}_left`}
                label="Left"
                style={{ display: "inline-block", width: "calc(50% - 8px)" }}
              >
                <Select
                  options={
                    reflex.label === "Babinski's"
                      ? babinskisOptions
                      : bodypartOptions
                  }
                />
              </Form.Item>
              <Form.Item
                name={`${reflex.value}_right`}
                label="Right"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginLeft: "16px",
                }}
              >
                <Select
                  options={
                    reflex.label === "Babinski's"
                      ? babinskisOptions
                      : bodypartOptions
                  }
                />
              </Form.Item>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default ReflexesForm;
