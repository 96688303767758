import React, { useEffect } from "react";
import { Form, Select, Row, Col, Radio, Button } from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { validateConfig } from "../../../config/validate.config";
import { getCookie } from "../../../helpers/localStorage";
import { Role } from "../../../constants/defaultKeys";

const { Option } = Select;
const rolesWithIsHodQuestion = [
  "Doctor",
  "Nurse",
  "Physiotherapist",
  "Speech_Swallow_Therapist",
  "Respiratory_Therapist",
  "Nutritionist",
  "Psychologist",
];
const rolesWithoutHodField = [
  "Organization_Admin",
  "Organization_Owner",
  "Facility_Admin",
  "Receptionist",
];
const rolesWithFacility = [
  "Doctor",
  "Nurse",
  "Physiotherapist",
  "Speech_Swallow_Therapist",
  "Respiratory_Therapist",
  "Nutritionist",
  "Receptionist",
  "Facility_Admin",
  "Psychologist",
];

const RoleForm = ({
  userRoles,
  editListData,
  selectedRoles,
  setSelectedRoles,
  form,
  facilities,
  params,
  userDetails,
}) => {
  useEffect(() => {
    if (editListData?.roles) {
      const initialSelectedRoles = editListData?.roles?.map(
        (role) => role?.role
      );
      setSelectedRoles(initialSelectedRoles);
    }
  }, [editListData]);

  const defaultRole = userDetails?.currRole || getCookie(Role);

  const filteredWithoutHodRoles =
    defaultRole === "Facility_Admin"
      ? userRoles?.filter(
          (role) =>
            role?.value !== "Organization_Owner" &&
            role?.value !== "Organization_Admin"
        )
      : defaultRole === "Organization_Admin"
      ? userRoles?.filter((role) => role?.value !== "Organization_Owner")
      : userRoles;

  const handleRoleChange = (index, value) => {
    const updatedRoles = [...selectedRoles];
    updatedRoles[index] = value;
    setSelectedRoles(updatedRoles);

    if (editListData) {
      const newValues = [...form.getFieldsValue().roles];
      newValues[index].is_hod = null;
      form.setFieldsValue({ roles: newValues });
    }
  };

  const handleRemove = (name, index) => {
    const updatedRoles = [...selectedRoles];
    updatedRoles.splice(index, 1);
    setSelectedRoles(updatedRoles);

    form.setFieldsValue({
      roles: form.getFieldsValue().roles.filter((_, idx) => idx !== name),
    });
  };

  //filter role if selected
  const getAvailableRoles = (index) => {
    const selectedRoleIds = selectedRoles.filter((_, i) => i !== index);
    const initialRoleIds = editListData?.roles.map((role) => role?.role) || [];
    const allSelectedRoleIds = [...selectedRoleIds, ...initialRoleIds].filter(
      (role, idx, arr) => arr.indexOf(role) === idx // Remove duplicates
    );

    return userRoles.filter((role) => !allSelectedRoleIds.includes(role.value));
  };

  // roles for which is_hod option to be visible
  const shouldDisplayHodField = (role) => {
    return (
      rolesWithIsHodQuestion.includes(role) &&
      !rolesWithoutHodField.includes(role)
    );
  };
  // roles for which facility option to be visible
  const shouldDisplayFacilityField = (role) => {
    return rolesWithFacility.includes(role);
  };

  return (
    <Form.List name="roles">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Row key={key}>
              <Col span={7}>
                <Form.Item
                  label="Role"
                  name={[name, "role"]}
                  rules={validateConfig.isRequired("Role")}
                >
                  <Select
                    style={{ width: "300px" }}
                    placeholder="Choose role"
                    className={inputStyles.mdSelect}
                    optionFilterProp="children"
                    onChange={(value) => handleRoleChange(index, value)}
                  >
                    {filteredWithoutHodRoles.map((role) => (
                      <Option value={role.value} key={role.value}>
                        {role.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7}>
                {shouldDisplayFacilityField(selectedRoles[index]) &&
                  defaultRole !== "Facility_Admin" && (
                    <Form.Item
                      label="Facility"
                      name={[name, "facility"]}
                      rules={validateConfig.isRequired("Facility")}
                    >
                      <Select
                        style={{ width: "300px" }}
                        placeholder="Choose facility"
                        className={inputStyles.mdSelect}
                        optionFilterProp="children"
                      >
                        {facilities?.map((facility) => (
                          <Option value={facility?.id} key={facility?.id}>
                            {facility?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
              </Col>
              {shouldDisplayHodField(selectedRoles[index]) && (
                <Col span={8}>
                  <Form.Item
                    label="Is hod?"
                    {...restField}
                    name={[name, "is_hod"]}
                    rules={[{ required: true, message: "Please choose" }]}
                  >
                    <Radio.Group>
                      <Radio value={true}> Yes </Radio>
                      <Radio value={false}> No </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )}
              <DeleteOutlined onClick={() => handleRemove(name, index)} />
            </Row>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              style={{ width: "50%" }}
              onClick={() => {
                add();
                setSelectedRoles([...selectedRoles, null]); // Add a placeholder for the new role
              }}
              block
              icon={<PlusOutlined />}
            >
              Add role
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default RoleForm;
