import React, { useState, useEffect } from "react";
import { Checkbox, Select, Form, Row, Col } from "antd";
import SelectWithOtherInput from "./SelectWithOtherInput";

const CheckListFBYSelect = ({
  checkBoxOptions = [],
  selectOptions = [],
  checkboxes,
  setCheckboxes,
  selectProps = {},
  form,
}) => {
  const handleCheckboxChange = (value) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [value]: !prevCheckboxes[value], // Toggle the checkbox value
    }));
  };

  return (
    <Row>
      {checkBoxOptions.map((checkbox, index) => (
        <Col span={8} key={index}>
          <Form.Item name={checkbox.value} valuePropName="checked">
            <Checkbox
              checked={checkboxes[checkbox.value]}
              onChange={() => handleCheckboxChange(checkbox.value)}
            >
              {checkbox.label}
            </Checkbox>
          </Form.Item>
          {checkboxes[checkbox.value] && (
            // <Form.Item
            //   style={{ marginLeft: "20px", marginRight: "20px" }}
            //   name={`${checkbox.value}_type`}
            // >
            //   <Select
            //     placeholder="Select"
            //     popupClassName="custom-dropdown"
            //     options={selectOptions}
            //     dropdownStyle={{ whiteSpace: "normal" }}
            //     style={{ marginLeft: "20px", marginRight: "20px", width: 150 }}
            //   />
            // </Form.Item>
            <SelectWithOtherInput
              name={`${checkbox.value}_type`}
              selectProps={selectProps}
              form={form}
              options={selectOptions}
            />
          )}
        </Col>
      ))}
    </Row>
  );
};

export default CheckListFBYSelect;
