import { Card, Form, Radio, Row, Col, Typography } from "antd";
import React from "react";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";

const Language = ({ psych = false, setLanguage }) => {
  const languageFields = [
    {
      label: "Repeat Test 1",
      sequence: "I only know that John is the one to help today",
      value: "language_1",
    },
    {
      label: "Repeat Test 2",
      sequence:
        "The cat always hid under the couch when the dogs were in the room",
      value: "language_2",
    },
    {
      label: "Fluency Test",
      sequence:
        "Name the maximum number of words in one minute that begin with the letter F",
      value: "language_3",
    },
  ];
  const handleChange = (value, fieldValue) => {
    setLanguage((prev) => ({
      ...prev,
      [fieldValue]: value,
    }));
  };

  return (
    <Row
      type="flex"
      align="middle"
      justify="center"
      style={
        {
          // width: psych === true ? "100%" : "85%"
        }
      }
      gutter={[16, 16]}
    >
      {languageFields.map((field) => (
        <Col span={12}>
          <Card key={field.value} style={{ marginBottom: 16 }} bordered={false}>
            <Typography style={{ textAlign: "center" }}>
              <Typography.Text>{field.label}</Typography.Text>
              <br />
              <br />
              <Typography.Text style={{ fontSize: "18px" }}>
                {field.sequence}
              </Typography.Text>
            </Typography>
            <br />
            <Form.Item
              name={
                psych === true
                  ? ["moca", "language_data", field.value]
                  : ["language_data", field.value]
              }
            >
              {/* <Row justify="center">
                <Col> */}
              <Radio.Group
                style={{ width: "100%" }}
                onChange={(e) => handleChange(e?.target?.value, field.value)}
              >
                <Row justify="center" gutter={[8, 0]}>
                  <Col>
                    <Radio.Button value={"yes"} className="like-btn">
                      <LikeOutlined />
                    </Radio.Button>
                  </Col>
                  <Col>
                    <Radio.Button value={"no"} className="dislike-btn">
                      <DislikeOutlined />
                    </Radio.Button>
                  </Col>
                </Row>
              </Radio.Group>
              {/* </Col>
              </Row> */}
            </Form.Item>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Language;
