import React from "react";
import { Typography } from "antd";
import { jvpOptions } from "../../../../Options/systemicExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";
const { Text } = Typography;
const JVP = ({ form }) => {
  return (
    <>
      <Text>JVP</Text>

      <DeselectableRadioGroup name={"jvp"} options={jvpOptions} form={form} />
    </>
  );
};

export default JVP;
