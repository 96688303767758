import React, { useContext } from "react";

import { useParams } from "react-router-dom";

import AccountDetails from "./Details";
import Password from "./Password";
import Experience from "./Experience";
import SideMenu from "../Layout/CustomLayout/SideMenu";
import { UserContext } from "../../context/UserContext";
import ReceptionistSideMenu from "../Layout/CustomLayout/ReceptionistSideMenu";
import { getCookie } from "../../helpers/localStorage";
import { Role } from "../../constants/defaultKeys";

const AccountDashboard = () => {
  const { userDetails } = useContext(UserContext);
  const query = useParams();
  const defaultRole = userDetails?.currRole || getCookie(Role);
  const CustomSideMenu =
    defaultRole === "Receptionist" ? <ReceptionistSideMenu /> : <SideMenu />;

  return (
    <>
      {query?.tab === "details" ? (
        <AccountDetails sideContent={CustomSideMenu} />
      ) : query?.tab === "password" ? (
        <Password sideContent={CustomSideMenu} />
      ) : (
        <Experience sideContent={CustomSideMenu} />
      )}
    </>
  );
};

export default AccountDashboard;
