import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Divider, Row, Spin } from "antd";

import useQuery from "../../../../hooks/useQuery";
import {
  getGraphDataAPI,
  getScalesDataAPI,
} from "../../../../services/physio.services";
import ScaleModal from "./ScaleModal";
import scalesConfig from "../../../../helpers/physioscaleskeys";
import LoadingIndicator from "../../../Loaders/LoadingIndicator";
import LoadingOverlay from "../../../Loaders/LoadingOverlay";

const { Meta } = Card;

const ProgressScales = ({ patientData }) => {
  const params = useQuery();
  const admissionId = params?.patient;

  const [scalesData, setScalesData] = useState([]);
  const [selectedScale, setSelectedScale] = useState(null);
  const [selectedScaleData, setSelectedScaleData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [scaleLoading, setscaleLoading] = useState(false);

  const handleBtnClick = useMemo(
    () => (key) => {
      setSelectedScale(key);
      setscaleLoading(true);
      getGraphDataAPI(admissionId, key)
        .then((res) => {
          setSelectedScaleData(res?.data?.data);
          setIsModalVisible(true);
        })
        .catch((error) => console.error("Error fetching scale data:", error))
        .finally(() => setscaleLoading(false));
    },
    [admissionId]
  );

  const getScalesData = () => {
    setLoading(true);
    getScalesDataAPI(admissionId)
      .then((res) => {
        const mappedData = res?.data?.data?.map((item) => ({
          ...item,
          displayKey: scalesConfig[item?.key]?.shortName || item?.key,
        }));
        setScalesData(mappedData);
      })
      .catch((error) => console.error("Error fetching scales data:", error))
      .finally(() => setLoading(false));
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (admissionId) {
      getScalesData();
    }
  }, [admissionId]);

  return (
    <>
      {isModalVisible && (
        <ScaleModal
          scaleKey={selectedScale}
          isVisible={isModalVisible}
          onClose={handleCloseModal}
          data={selectedScaleData}
          patientData={patientData}
        />
      )}

      <Card style={{ maxWidth: "100%", margin: "auto" }}>
        <Meta
          title={<span style={{ fontWeight: 400 }}>Progress Scales</span>}
        />
        <Divider />
        {loading ? (
          <LoadingIndicator message="Loading scales data, please wait..." />
        ) : (
          <Row
            gutter={[16, 16]}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 20px",
            }}
          >
            {scalesData?.length > 0 &&
              scalesData.map((item, index) => (
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                  key={index}
                  xs={24}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                >
                  <Button
                    className="inv-btn"
                    style={{
                      color: "#7f56d9",
                      border: "1px solid #7F56D9",
                      width: "100px",
                    }}
                    onClick={() => handleBtnClick(item.key)}
                  >
                    {item.displayKey}
                  </Button>
                  <p>{`Records present: ${item?.count}`}</p>
                </Col>
              ))}
          </Row>
        )}
        {scaleLoading && <LoadingOverlay />}
      </Card>
    </>
  );
};

export default ProgressScales;
