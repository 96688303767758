import React from "react";
import PhysiotherapistInitialAssessmentProvider from "../IpEMR/Physiotherapist/PhysiotherapistInitialInspection/PhysiotherapistInitialAssessmentProvider";
import PhysiotherapistInitialInspection from "../IpEMR/Physiotherapist/PhysiotherapistInitialInspection/PhysiotherapistInitialInspection";
import PhysioReviewFormProvider from "../IpEMR/Physiotherapist/PhysiotherapistReview/PhysioReviewFormProvider";
import PhysiotherapistReview from "../IpEMR/Physiotherapist/PhysiotherapistReview/PhysiotherapistReview";
import MedicationHistoryIP from "./MedicationsIp/MedicationHistoryIP";
import PhysioCare from "../IpEMR/Physiotherapist/PhysioCare/PhysioCare";
import ProgressScales from "../IpEMR/Physiotherapist/ProgressScales/ProgressScales";

const PhysiotherapistIP = ({ patientData, params, refreshPage }) => {
  return (
    <>
      {params?.type === "ia" || params?.type === "physiotherapist" ? (
        <PhysiotherapistInitialAssessmentProvider>
          <PhysiotherapistInitialInspection
            refreshPage={refreshPage}
            editId={patientData?.physio_initial_assessment}
            patientData={patientData}
          />
        </PhysiotherapistInitialAssessmentProvider>
      ) : params?.type === "review" ? (
        <PhysioReviewFormProvider>
          <PhysiotherapistReview
            reviewData={patientData?.physio_review}
            refreshPage={refreshPage}
            iAssessment={patientData?.physio_initial_assessment}
            patientData={patientData}
          />
        </PhysioReviewFormProvider>
      ) : params?.type === "progress-scales" ? (
        <ProgressScales
          patientData={patientData?.admission}
          refreshPage={refreshPage}
        />
      ) : params?.type === "physio-care" ? (
        <PhysioCare patientData={patientData} refreshPage={refreshPage} />
      ) : params?.type === "medicine" ? (
        <MedicationHistoryIP patientData={patientData} />
      ) : null}
    </>
  );
};

export default PhysiotherapistIP;
