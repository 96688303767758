import React, { useContext, useEffect, useState } from "react";
import CheckListFBYInput from "../../../../../../blocks/FormItems/CheckListFBYInput";
import { limbExtremityOptions } from "../../../../Options/systemicExamOptions";
import { noYesOptions } from "../../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const NurseFractures = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context);
  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    const initialCheckboxes = limbExtremityOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] = formData?.examination?.fractures?.[checkbox.value];
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [formData]);
  return (
    <>
      <DeselectableRadioGroup
        label={"Fractures"}
        name={["fractures", "status"]}
        options={noYesOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue(["fractures", "status"]) === "yes" && (
        <CheckListFBYInput
          options={limbExtremityOptions}
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          keyChain="fractures"
        />
      )}
    </>
  );
};

export default NurseFractures;
