import React, { useEffect, useState } from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Pagination, Table, Typography } from "antd";
import { getGlobalMedicationApi } from "../../../services/medication.services";

const { Paragraph, Text } = Typography;
const GlobalMedicationTable = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getGlobalMedicationApi(`page=${page}`)
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page]);
  const columns = [
    {
      title: "Medicine",
      key: "name",
      dataIndex: "name",
      sorter: (a, b) => a?.name.localeCompare(b?.name),
    },
    {
      title: "Drug 1 Name",
      key: "drug1_name",
      dataIndex: "drug1_name",
      render: (value) => (value ? value : "-"),
      sorter: (a, b) => {
        const descA = a?.drug1_name || "";
        const descB = b?.drug1_name || "";
        return descA?.localeCompare?.(descB);
      },
    },
    {
      title: "Drug 2 Name",
      key: "drug2_name",
      dataIndex: "drug2_name",
      render: (value) => (value ? value : "-"),
      sorter: (a, b) => {
        const descA = a?.drug2_name || "";
        const descB = b?.drug2_name || "";
        return descA?.localeCompare?.(descB);
      },
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   editable: true,
    //   width: "20%",
    //   render: (value) => (
    //     <Paragraph
    //       ellipsis={{
    //         rows: 2,
    //         expandable: true,
    //         symbol: "more",
    //       }}
    //       style={{ width: 400 }}
    //     >
    //       {value}
    //     </Paragraph>
    //   ),
    // },
    {
      title: "Is antibiotic",
      dataIndex: "is_antiboitic",
      editable: true,
      width: "10%",
      render: (value) =>
        value ? (
          <CheckCircleOutlined style={{ color: "green", fontSize: "18px" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red", fontSize: "18px" }} />
        ),
    },
  ];
  return (
    <>
      <Table
        dataSource={data?.data}
        columns={columns}
        pagination={false}
        loading={loading}
        bordered
        scroll={{ y: "calc(100vh - 230px)" }}
      />
      {data?.total_count > 100 && (
        <div className="flex-center" style={{ paddingTop: "10px" }}>
          <Pagination
            current={page}
            pageSize={100}
            total={data?.total_count}
            onChange={(e) => setPage(e)}
            showSizeChanger={false}
          />
        </div>
      )}
    </>
  );
};

export default GlobalMedicationTable;
