import { Col, Row, Table } from "antd";
import React, { useMemo } from "react";
import {
  maScaleOptions,
  mmtScaleOptions,
  vcgScaleOptions,
} from "../../Options/physioOptions";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import scaleIcon from "../../../../assets/images/rating-scale 1.svg";

const scaleOptionsMap = {
  MAS: maScaleOptions,
  MMT: mmtScaleOptions,
  VCG: vcgScaleOptions,
};

const getScaleOptions = (scaleKey) => {
  return scaleOptionsMap[scaleKey] || [];
};

const getScaleLabel = (score, scaleKey) => {
  const scaleOptions = getScaleOptions(scaleKey);
  const option = scaleOptions.find((item) => item.value === score);
  return option ? option.label : "N/A";
};

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: <strong>LEFT</strong>,
    children: [
      {
        title: "Upper Limb",
        children: [
          {
            title: "Score",
            dataIndex: "upper_left_limb_score",
            key: "upper_left_limb_score",
          },
          {
            title: "Interpretation",
            dataIndex: "upper_left_limb_score",
            key: "upper_left_limb_interpretation",
            render: (score, record) => getScaleLabel(score, record.scaleKey),
          },
        ],
      },
      {
        title: "Lower Limb",
        children: [
          {
            title: "Score",
            dataIndex: "lower_left_limb_score",
            key: "lower_left_limb_score",
          },
          {
            title: "Interpretation",
            dataIndex: "lower_left_limb_score",
            key: "lower_left_limb_interpretation",
            render: (score, record) => getScaleLabel(score, record.scaleKey),
          },
        ],
      },
    ],
  },
  {
    title: <strong>RIGHT</strong>,
    children: [
      {
        title: "Upper Limb",
        children: [
          {
            title: "Score",
            dataIndex: "upper_right_limb_score",
            key: "upper_right_limb_score",
          },
          {
            title: "Interpretation",
            dataIndex: "upper_right_limb_score",
            key: "upper_right_limb_interpretation",
            render: (score, record) => getScaleLabel(score, record.scaleKey),
          },
        ],
      },
      {
        title: "Lower Limb",
        children: [
          {
            title: "Score",
            dataIndex: "lower_right_limb_score",
            key: "lower_right_limb_score",
          },
          {
            title: "Interpretation",
            dataIndex: "lower_right_limb_score",
            key: "lower_right_limb_interpretation",
            render: (score, record) => getScaleLabel(score, record.scaleKey),
          },
        ],
      },
    ],
  },
  {
    title: "Comments",
    dataIndex: "comments",
    key: "comments",
    render: (text) => (
      <div
        style={{
          width: "100px",
          wordWrap: "break-word",
          whiteSpace: "normal",
          maxWidth: "100px",
          overflow: "hidden",
          overflowWrap: "break-word",
          ellipsis: true,
          textOverflow: "ellipsis",
        }}
        ellipsis={{
          rows: 2,
          expandable: true,
          symbol: "more",
        }}
        style={{ width: 150 }}
      >
        {text || "N/A"}
      </div>
    ),
  },
];

const coordinationTestColumns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Adiadochokinesia/Dysdiadokokinesia",
    dataIndex: "adiadochokinesia_or_dysdiadokokinesia",
    key: "adiadochokinesia_or_dysdiadokokinesia",
  },
  {
    title: "Finger to Finger",
    dataIndex: "finger_to_finger",
    key: "finger_to_finger",
  },
  {
    title: "Finger to Nose",
    dataIndex: "finger_to_nose",
    key: "finger_to_nose",
  },
  {
    title: "Heel to Shin",
    dataIndex: "heel_to_shin",
    key: "heel_to_shin",
  },
  {
    title: "Rebound Phenomenon",
    dataIndex: "rebound_phenomenon",
    key: "rebound_phenomenon",
  },
  {
    title: "Romberg's Test",
    dataIndex: "rombergs_test",
    key: "rombergs_test",
  },
  {
    title: "Walking Along Straight Line",
    dataIndex: "walking_along_straight_line",
    key: "walking_along_straight_line",
  },
  {
    title: "Comments",
    dataIndex: "comments",
    key: "comments",
    render: (text) => (
      <div
        style={{
          width: "100px",
          wordWrap: "break-word",
          whiteSpace: "normal",
          maxWidth: "100px",
          overflow: "hidden",
          overflowWrap: "break-word",
          ellipsis: true,
          textOverflow: "ellipsis",
        }}
        ellipsis={{
          rows: 2,
          expandable: true,
          symbol: "more",
        }}
        style={{ width: 220 }}
      >
        {text || "N/A"}
      </div>
    ),
  },
];

const ScaleTable = ({ scaleKey, data }) => {
  const dataSource = useMemo(() => {
    return data.map((item, index) => {
      if (scaleKey === "CoordinationTest") {
        return {
          key: index,
          date: new Date(item?.date).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "short",
            // year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            // second: "2-digit",
            hour12: true,
          }),
          adiadochokinesia_or_dysdiadokokinesia:
            item.adiadochokinesia_or_dysdiadokokinesia || "N/A",
          finger_to_finger: item.finger_to_finger || "N/A",
          finger_to_nose: item.finger_to_nose || "N/A",
          heel_to_shin: item.heel_to_shin || "N/A",
          rebound_phenomenon: item.rebound_phenomenon || "N/A",
          rombergs_test: item.rombergs_test || "N/A",
          walking_along_straight_line:
            item.walking_along_straight_line || "N/A",
          comments: item.comments || "N/A",
        };
      }

      return {
        key: index,
        date: new Date(item?.date).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          // year: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          // second: "2-digit",
          hour12: true,
        }),
        upper_left_limb_score: item.upper_left_limb,
        lower_left_limb_score: item.lower_left_limb,
        upper_right_limb_score: item.upper_right_limb,
        lower_right_limb_score: item.lower_right_limb,
        comments: item.comments,
        scaleKey: scaleKey,
      };
    });
  }, [data, scaleKey]);

  return (
    <Row justify="center">
      <Col>
        <Table
          dataSource={dataSource}
          columns={
            scaleKey === "CoordinationTest" ? coordinationTestColumns : columns
          }
          pagination={false}
          locale={{
            emptyText: (
              <NoDataPage
                title="No data was recorded for this scale"
                subtitle=""
                image={scaleIcon}
              />
            ),
          }}
          style={{
            border: "1px solid white",
            borderRadius: "4px",
            boxShadow: "0px -2px 3px rgba(0, 0, 0, 0.05)",
          }}
        />
      </Col>
    </Row>
  );
};

export default ScaleTable;
