import { Form, Input, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { yesNoOptions } from "../../../../../../constants/defaultKeys";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";
import { indirectSwallowingOptions } from "../../../../Options/speechSwallowOptions";

const IndirectSwallowingTest = ({ form, setIndirect }) => {
  const [refresh, setRefresh] = useState(false);

  const calcScore = () => {
    const fValues = form.getFieldValue("indirect_swallowing");
    const vigilance = fValues?.vigilance;
    const coughing = fValues?.coughing;
    const swallowing_saliva = fValues?.swallowing_saliva;
    const swallowing_saliva_drooling = fValues?.swallowing_saliva_drooling;
    const swallowing_saliva_voice_change =
      fValues?.swallowing_saliva_voice_change;
    const result =
      (vigilance === "no" ? 1 : 0) +
      (coughing === "no" ? 1 : 0) +
      (swallowing_saliva === "no" ? 1 : 0) +
      (swallowing_saliva_drooling === "yes" ? 1 : 0) +
      (swallowing_saliva_voice_change === "yes" ? 1 : 0);
    form.setFieldValue(["indirect_swallowing", "score"], result);
    setIndirect(result);
    return result;
  };

  const score = calcScore();

  return (
    <>
      <Typography>Indirect Swallowing Test: {score}</Typography>
      <Typography>
        {score < 5
          ? "Stop the test see GUSS-Evaluation"
          : "Continue Direct Swallowing Test (Semi Solid)"}
      </Typography>
      <Form.Item
        name={["indirect_swallowing", "score"]}
        hidden={true}
        initialValue={score}
      >
        <Input value={score} />
      </Form.Item>
      <div className="subsection-card">
        {indirectSwallowingOptions?.map((option) => (
          <>
            <DeselectableRadioGroup
              label={option?.label}
              name={["indirect_swallowing", option?.value]}
              options={yesNoOptions}
              form={form}
              setRefresh={setRefresh}
            />
          </>
        ))}
      </div>
    </>
  );
};

export default IndirectSwallowingTest;
