import React, { useState, useCallback } from "react";
import { AutoComplete, Form, Input, Row, Col, Tag, InputNumber } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter, debounce } from "../../helpers/utility";
import { searchPatientsApi } from "../../services/receptionist.services";

const setSearchOptions = (data) => {
  const searchOptions = data?.map((options, index) => {
    return {
      key: options?.record_id,
      value: options?.record_id,
      label: (
        <Row>
          <Col span={10}>
            <span>{options?.mobile}</span>
            <Tag style={{ marginLeft: "8px" }} color="blue">
              {capitalizeFirstLetter(options?.first_name) +
                " " +
                capitalizeFirstLetter(options?.last_name)}
            </Tag>
          </Col>
        </Row>
      ),
    };
  });

  return searchOptions;
};

const PatientMobileSearch = ({
  searchedText,
  setSearchedText,
  setSelectedId,
  id,
  form,
  appointment = false,
}) => {
  const [data, setData] = useState(null);

  const handleSearch = (searchedValue) => {
    {
      searchedValue?.length >= 5 &&
        searchPatientsApi(`mobile=${searchedValue}`)
          .then((res) => {
            setData(setSearchOptions(res?.data));
          })
          .catch((err) => console.log(err));
    }
  };

  const optimizedSearch = useCallback(debounce(handleSearch, 300), []);

  const handleSelect = (value, option) => {
    setSearchedText(value);
    setSelectedId(option?.key);
  };

  return (
    <>
      <Form.Item
        name={appointment ? "patient_mobile" : "mobile"}
        label="Mobile Number"
        rules={[
          { required: true, message: "Please enter mobile number" },
          {
            pattern: /^[0-9]{10}$/,
            message: "Please enter a valid 10-digit phone number",
          },
        ]}
      >
        <AutoComplete
          maxLength={10}
          disabled={id}
          options={data}
          className="search"
          onSearch={optimizedSearch}
          onSelect={handleSelect}
          onChange={(value) => {
            setSearchedText(value);
            optimizedSearch(value);
            setSelectedId(null);
          }}
          value={searchedText}
          style={{ width: "100%"}}
        >
          <Input
            disabled={id}
            // suffix={<SearchOutlined />}
            addonBefore="+91"
            placeholder="10-digit Mobile Number"
            style={{ width: "80%" }}
          />
        </AutoComplete>
      </Form.Item>
    </>
  );
};

export default PatientMobileSearch;
