import React from "react";
import { Table } from "antd";

const FacilityDietTable = ({ dietData, loading }) => {
  // Default columns to an empty array
  let columns = [];

  if (dietData && dietData.length > 0) {
    // Define the desired initial column order
    const initialColumns = ["room_no", "patient_name", "diet_type"];

    // Extract all keys from the first item in dietData and filter out unwanted keys
    const allKeys = Object.keys(dietData[0] || {}).filter(
      (key) => key !== "admission_id"
    );

    // Determine the remaining keys not in the initialColumns
    const remainingKeys = allKeys.filter(
      (key) => !initialColumns.includes(key)
    );

    // Function to format keys as 12-hour time if they are numbers
    const formatKeyAsTime = (key) => {
      if (!isNaN(key)) {
        const hour = Number(key);
        const period = hour === 24 ? "AM" : hour >= 12 ? "PM" : "AM";
        const formattedHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;
        return `${formattedHour}:00 ${period}`;
      }
      return key;
    };

    // Combine initialColumns and remainingKeys to define the final column order
    const columnOrder = [...initialColumns, ...remainingKeys];

    // Filter out columns with empty strings for all rows
    const validColumns = columnOrder.filter((key) =>
      dietData.some((item) => item[key] && item[key].trim() !== "")
    );

    // Generate columns dynamically based on the valid columns
    columns = validColumns.map((key) => ({
      title: formatKeyAsTime(key) // Format the header as time if it's a number
        .replace(/_/g, " ") // Replace underscores with spaces
        .replace(/^\w/, (c) => c.toUpperCase()), // Capitalize the first letter
      dataIndex: key,
      key,
      render: (value) => value || "-", // Display "-" for empty or null values
    }));
  }

  return (
    <div>
      <Table
        columns={columns}
        dataSource={dietData}
        loading={loading}
        pagination={false}
        scroll={{ x: 1000 }}
      />
    </div>
  );
};

export default FacilityDietTable;
