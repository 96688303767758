import React from "react";
import MaintainsOwn from "./MaintainsOwn";
import { Typography } from "antd";
import ArtificialAirway from "./ArtificialAirway";

const AirWay = ({ form }) => {
  return (
    <>
      <Typography>Air Way</Typography>
      <div className="subsection-card">
        <MaintainsOwn />
        <ArtificialAirway form={form} />
      </div>
    </>
  );
};

export default AirWay;
