import { Descriptions, Space } from "antd";
import React from "react";
import {
  getLabelScalesTags,
  getLabelTagInput,
  getLabelTagsArray,
  getLabelTagsWithDescription,
  getPatientConditionTags,
} from "../constructTextFromValues";
import {
  aphasiaOptions,
  articulationOptions,
  diadochokineticOptions,
  phonationOptions,
  speechOptions,
  voiceGrbasOptions,
} from "../../Options/speechSwallowOptions";
import { yesNoOptions } from "../../Options/commonOptions";
import { FileSearchOutlined } from "@ant-design/icons";
import { checkEmpty } from "../../../../helpers/utility";

const SpeechSwallowGeneralExaminationSummary = ({ formData }) => {
  const constructExaminationSummaryItems = () => {
    const aphasiaObject = formData?.general_examination?.aphasia;
    const aphasiaSummaryTags = () => {
      const tags = [];

      aphasiaOptions?.forEach(({ label, value }) => {
        const statusKey = `${value}_status`;
        const commentsKey = `${value}_comments`;

        if (aphasiaObject[statusKey] && aphasiaObject[commentsKey]) {
          tags.push(
            getLabelTagInput(
              label,
              `Status: ${aphasiaObject[statusKey]}, Comments: ${aphasiaObject[commentsKey]}`
            )
          );
        }
      });

      return tags;
    };
    const examinationSummaryItems = [];
    const pushExaminationItem = (key, label, children) => {
      examinationSummaryItems.push({ key, label, children });
    };
    const filteredOptions = speechOptions?.filter(
      (option) =>
        ![
          "voice_grbas_scale",
          "phonation_duration",
          "diadochokinetic_rate",
          "articulation",
          "aphasia",
        ]?.includes(option?.value)
    );
    if (formData?.general_examination) {
      pushExaminationItem(
        "1",
        "General Examination",
        <>
          {checkEmpty(formData?.general_examination?.aphasia) &&
            getLabelTagInput("Aphasia", aphasiaSummaryTags())}
          {formData?.general_examination?.articulation &&
            getLabelTagInput(
              "Articulation",
              getLabelTagsArray(
                articulationOptions,
                formData?.general_examination?.articulation_details
              )
            )}
          {formData?.general_examination?.diadochokinetic_rate &&
            getLabelTagInput(
              "Diadochokinetic rate: |p| |t| |k|",
              getLabelTagsArray(
                diadochokineticOptions,
                formData?.general_examination?.diadochokinetic_rate_details
              )
            )}
          {formData?.general_examination?.phonation_duration &&
            getLabelTagInput(
              "Phonation Duration",
              getLabelTagsWithDescription(
                phonationOptions,
                formData?.general_examination?.phonation_duration_details
              )
            )}
          {checkEmpty(formData?.general_examination?.voice_grabas_scale) &&
            getLabelTagInput(
              "Voice GRBAS scale",
              getLabelScalesTags(
                voiceGrbasOptions,
                formData?.general_examination?.voice_grabas_scale
              )
            )}
          {getPatientConditionTags(filteredOptions, yesNoOptions, formData)}
        </>
      );
    }
    return examinationSummaryItems;
  };
  const examinationSummaryItems = constructExaminationSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={
          <Space>
            <FileSearchOutlined style={{ color: "#7F56D9" }} />
            <span style={{ fontWeight: 500 }}>General Examination</span>
          </Space>
        }
        items={examinationSummaryItems}
        style={{ margin: "0", border: "none", textAlign: "left" }}
        className="initial-insp-desc"
      />
    </>
  );
};

export default SpeechSwallowGeneralExaminationSummary;
