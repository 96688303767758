import React from "react";
import { Typography, Tag, Row, Col } from "antd";
import { capitalizeFirstLetter } from "../../../helpers/utility";
import {
  bloodTypeOptions,
  parentalFluidOptions,
  parentalMedicationOptions,
} from "../../IpEMR/Options/careItemsOptions";
import dayjs from "dayjs";
import CommonTable from "./CommonTable";

const ParentalNutrition = ({ data }) => {
  const columns = [
    {
      title: "Start Time",
      dataIndex: "process_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];

  const customTypography = (title, value) => {
    return (
      <>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontWeight: "lighter",
            fontSize: "10px",
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontWeight: "lighter",
            fontSize: title === "Comments" ? "12px" : "14px",
          }}
        >
          {title === "Comments" ? (
            <Tag color="blue">
              <Typography
                style={{
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  fontSize: title === "Comments" ? "14px" : "16px",
                }}
              >
                {value}
              </Typography>
            </Tag>
          ) : (
            value
          )}
        </Typography>
      </>
    );
  };
  return (
    <div>
      {" "}
      {data?.map((item) => (
        <CommonTable
          data={item?.actions}
          columns={columns}
          titleData={() => (
            <Row gutter={[12, 12]}>
              <Col span={3}>
                {customTypography(
                  "Dose",
                  item?.data?.fluids === "others"
                    ? `Others - ${item?.data?.do_data?.other_fluids}`
                    : parentalFluidOptions?.find(
                        (option) => option.value === item?.data?.fluids
                      )?.label || "-"
                )}
              </Col>
              <Col span={2}>{customTypography("Rate", item?.data?.rate)}</Col>
              <Col span={4}>
                {customTypography(
                  "Medication",
                  item?.data?.do_data?.medication === "others"
                    ? `Others - ${item?.data?.do_data?.other_medication}`
                    : parentalMedicationOptions?.find(
                        (option) => option.value === item?.drug_name
                      )?.label || "-"
                )}
              </Col>
              <Col span={4}>
                {customTypography(
                  "Medication Amount",
                  item?.data?.medication_amount
                )}
              </Col>
              <Col span={5}>
                {customTypography("Comments", item?.data?.instructions)}
              </Col>
              <Col span={4}>
                {customTypography(
                  "Advised By",
                  capitalizeFirstLetter(item?.advised_by)
                )}
              </Col>
            </Row>
          )}
        />
      ))}
    </div>
  );
};

export default ParentalNutrition;
