import React, { useContext, useEffect, useState } from "react";
import { notAssessableAndAssessableOptions } from "../../../Options/commonOptions";
import CheckListFBYRadioItems from "../../../../../blocks/FormItems/CheckListFBYRadioItems";
import {
  hearingOptions,
  leftRightBothOptions,
} from "../../../Options/functionalAsessmentOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const Hearing = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context);
  const [checkboxes, setCheckBoxes] = useState([]);
  useEffect(() => {
    const initialCheckboxes = hearingOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.functional_status?.hearing_details?.[checkbox.value];
      return acc;
    }, {});
    setCheckBoxes(initialCheckboxes);
  }, [formData]);
  return (
    <>
      <DeselectableRadioGroup
        label={"Hearing"}
        name={["hearing_details", "hearing"]}
        options={notAssessableAndAssessableOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue(["hearing_details", "hearing"]) === "assessable" && (
        <div className="subsection-card">
          <CheckListFBYRadioItems
            checkBoxOptions={hearingOptions}
            radioOptions={leftRightBothOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckBoxes}
            keyChain={"hearing_details"}
            form={form}
          />
        </div>
      )}
    </>
  );
};

export default Hearing;
