import { Form, InputNumber, Typography } from "antd";
import React from "react";
import { yesNoOptions } from "../../../Options/commonOptions";
import {
  incontinenceOptions,
  stoolOptions,
} from "../../../Options/functionalAsessmentOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const BowelNeeds = ({ form }) => {
  return (
    <>
      <Typography>Bowel Needs</Typography>
      <div className="subsection-card">
        <DeselectableRadioGroup
          label={"Indicates Urge to Pass Motion"}
          name={["bowel_needs", "indicates_need_to_pass_motion"]}
          options={yesNoOptions}
          form={form}
        />

        <DeselectableRadioGroup
          label={"Incontinence"}
          name={["bowel_needs", "incontinence"]}
          options={incontinenceOptions}
          form={form}
        />

        <DeselectableRadioGroup
          label={"Stools"}
          name={["bowel_needs", "stools"]}
          options={stoolOptions}
          form={form}
        />
        <Form.Item
          label="Days After Last Motion"
          name={["bowel_needs", "days_after_last_motion"]}
        >
          <InputNumber addonAfter="Days" />
        </Form.Item>

        <DeselectableRadioGroup
          label={"Colostomy"}
          name={["bowel_needs", "colostomy"]}
          options={yesNoOptions}
          form={form}
        />
      </div>
    </>
  );
};

export default BowelNeeds;
