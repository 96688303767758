import { Typography } from "antd";
import React, { useState } from "react";
import {
  depthOfBreathingOptions,
  oxygenSupportOptions,
  tracheostomyConditionOptions,
} from "../../../../Options/genExamOptions";
import RespiratoryDistress from "./RespiratoryDistress";
import { noYesOptions } from "../../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const RespiratoryCondition = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <Typography>Respiratory Condition</Typography>
      <div className="subsection-card">
        <DeselectableRadioGroup
          label={"Depth of Breathing"}
          name={"depth_of_breathing"}
          options={depthOfBreathingOptions}
          form={form}
        />

        <RespiratoryDistress form={form} />

        <DeselectableRadioGroup
          label={"Oxygen Support"}
          name={"oxygen_support"}
          options={oxygenSupportOptions}
          form={form}
        />

        <DeselectableRadioGroup
          label={"Tracheostomy"}
          name={"tracheostomy"}
          options={noYesOptions}
          form={form}
          setRefresh={setRefresh}
        />
        {form.getFieldValue("tracheostomy") === "yes" && (
          <div className="subsection-card">
            <DeselectableRadioGroup
              name={"tracheostomy_condition"}
              options={tracheostomyConditionOptions}
              form={form}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default RespiratoryCondition;
