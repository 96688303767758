import React from "react";
import { modeOfArrivalOptions } from "../../../Options/historyOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const ModeOfArrival = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Mode of Arrival"}
        name={"mode_of_arrival"}
        options={modeOfArrivalOptions}
        form={form}
      />
    </>
  );
};

export default ModeOfArrival;
