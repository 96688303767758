import React from "react";
import CommonTable from "./CommonTable";
import dayjs from "dayjs";
import { capitalizeFirstLetter, timeAgo } from "../../../helpers/utility";
import { Typography, Tag, Row, Col } from "antd";

const UrinaryCatheter = ({ data, type }) => {
  const floleysColumns = [
    {
      title: "Time Done",
      dataIndex: "action_time",
      key: "action_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
    {
      title: "Perineal Cleaning",
      dataIndex: "perennial_cleaning",
      key: "perennial_cleaning",
    },
    { title: "Urinary Leakage", dataIndex: "leakage", key: "leakage" },
    { title: "Bladder Irrigation", dataIndex: "irrigation", key: "irrigation" },
    {
      title: "Catheter Change",
      dataIndex: "catheter_change",
      key: "catheter_change",
    },
  ];
  const condomColumns = [
    {
      title: "Time Done",
      dataIndex: "action_time",
      key: "action_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
    {
      title: "Inflammation",
      dataIndex: "inflammation",
      key: "inflammation",
    },
    {
      title: "Catheter Change",
      dataIndex: "catheter_change",
      key: "catheter_change",
    },
  ];

  const customTypography = (title, value) => {
    return (
      <>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontWeight: "lighter",
            fontSize: "10px",
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontWeight: "lighter",
            fontSize: title === "Comments" ? "12px" : "14px",
          }}
        >
          {title === "Comments" ? (
            <Tag color="blue">
              <Typography
                style={{
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  fontSize: title === "Comments" ? "12px" : "14px",
                }}
              >
                {value}
              </Typography>
            </Tag>
          ) : (
            value
          )}
        </Typography>
      </>
    );
  };
  return (
    <>
      {data?.map((item) => (
        <CommonTable
          data={item?.actions?.flat()}
          columns={
            item?.data?.do_data?.urinary_catheter_type === "foleys_catheter"
              ? floleysColumns
              : condomColumns
          }
          titleData={() => (
            <Row>
              <Col span={8}>
                {customTypography(
                  "Type",
                  capitalizeFirstLetter(item?.drug_name)?.replaceAll("_", " ")
                )}
              </Col>
              <Col span={8}>
                {customTypography("Comments", item?.data?.instructions)}
              </Col>
              <Col span={8}>
                {customTypography(
                  "Advised By",
                  capitalizeFirstLetter(item?.advised_by)
                )}
              </Col>
            </Row>
          )}
        />
      ))}
    </>
  );
};

export default UrinaryCatheter;
