import React, { useState } from "react";
import { Form, Input } from "antd";
import { yesNoOptions } from "../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const PastSurgicalHistory = ({ form, context }) => {
  const [refresh, setRefresh] = useState(false);
  return (
    <>
      <DeselectableRadioGroup
        label="Past Surgical History"
        name="surgical_history"
        form={form}
        options={yesNoOptions}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("surgical_history") === "yes" && (
        <Form.Item name="surgical_history_details">
          <Input placeholder="Details" style={{ width: "50%" }} />
        </Form.Item>
      )}
    </>
  );
};

export default PastSurgicalHistory;
