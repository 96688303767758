import React, { useContext, useEffect } from "react";
import AirWay from "./AirWay";
import { Divider } from "antd";
import VentilationStatus from "./VentilationStatus";
import RespiratoryPattern from "./RespiratoryPattern";
import RespiratoryMentalStatus from "./RespiratoryMentalStatus";
import ChestExpansion from "./ChestExpansion";
import BreathSounds from "./BreathSounds";
import CoughEffectiveness from "./CoughEffectiveness";
import ActivityLevel from "./ActivityLevel";
import Oxygen from "./Oxygen";
import Cough from "./Cough";
import TrachealSecretions from "./TrachealSecretions";
import TracheostomyTube from "./TracheostomyTube";

const RespiratoryPatientAssessment = ({ review = false, context, form }) => {
  const { formData } = useContext(context);
  useEffect(() => {
    form.setFieldsValue(formData?.patient_assessment);
  }, [formData]);
  return review ? (
    <>
      <RespiratoryPattern form={form} />
      <Divider />
      <BreathSounds form={form} />
      <Divider />
      <Cough form={form} />
      <Divider />
      <Oxygen form={form} />
      <Divider />
      <TrachealSecretions />
      <Divider />
      <TracheostomyTube form={form} />
    </>
  ) : (
    <>
      <AirWay form={form} />
      <Divider />
      <VentilationStatus form={form} />
      <Divider />
      <RespiratoryPattern form={form} />
      <Divider />
      <RespiratoryMentalStatus form={form} />
      <Divider />
      <ChestExpansion form={form} />
      <Divider />
      <BreathSounds form={form} />
      <Divider />
      <CoughEffectiveness form={form} />
      <Divider />
      <ActivityLevel form={form} />
      <Divider />
      <Oxygen form={form} />
      <Divider />
      <Cough form={form} />
    </>
  );
};

export default RespiratoryPatientAssessment;
