import React, { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import IoModalForm from "./IoModalForm";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import { Button, Col, Row, Space, Table } from "antd";
import dayjs from "dayjs";
import ioIcon from "../../../../assets/images/nurseIcons/io monitoring.svg";

const IoBalance = ({ data, refresh, loading, date }) => {
  const [open, setOpen] = useState(false);
  const [editingKey, setEditingKey] = useState(null);

  const columns = [
    {
      title: "Time",
      dataIndex: "process_time",
      width: "10%",
      render: (value) => (value ? dayjs(value)?.format("hh A") : "-"),
    },
    {
      title: "Oral/Tube",
      dataIndex: "oral_tube_intake",
      width: "10%",
    },
    {
      title: "Flush",
      dataIndex: "flush",
      width: "10%",
    },
    {
      title: "IV",
      dataIndex: "iv_fluids",
      width: "10%",
    },
    {
      title: "Hourly Intake",
      dataIndex: "hourly_intake",
      width: "10%",
    },
    {
      title: "UO",
      dataIndex: "uo",
      width: "10%",
    },
    {
      title: "GI Losses",
      dataIndex: "gi_losses",
      width: "10%",
    },
    {
      title: "Drain",
      dataIndex: "drain",
      width: "10%",
    },
    {
      title: "Hourly Output",
      dataIndex: "hourly_output",
      width: "10%",
    },
    {
      title: "Remarks",
      dataIndex: "additional_info",
      width: "10%",
    },
    {
      title: "Action",
      width: "10%",
      render: (_, record) => {
        return (
          <>
            <Button>
              <Space>
                <EditOutlined
                  onClick={() => {
                    setOpen(true);
                    setEditingKey(record);
                  }}
                  style={{ cursor: "pointer" }}
                />
                Edit
              </Space>
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      {data?.length > 0 ? (
        <>
          <Table
            className="ant-table-container ant-table-header"
            scroll={{ y: "calc(100vh - 240px)", x: "400px" }}
            bordered
            dataSource={data}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
            loading={loading}
          />
          {open && (
            <IoModalForm
              open={open}
              setOpen={setOpen}
              refresh={refresh}
              editData={editingKey}
              date={date}
            />
          )}
        </>
      ) : (
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "70vh",
          }}
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <NoDataPage
              title={"No Monitoring Data Available"}
              subtitle={
                "Please check back later or ensure all relevant data has been recorded."
              }
              image={ioIcon}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default IoBalance;
