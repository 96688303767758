import React, { useState, useEffect, useContext } from "react";
import {
  aphasiaOptions,
  articulationOptions,
  diadochokineticOptions,
  grbasScaleOptions,
  phonationOptions,
  speechOptions,
  voiceGrbasOptions,
} from "../../../../Options/speechSwallowOptions";
import { Checkbox, Form, Input } from "antd";
import { yesNoOptions } from "../../../../Options/commonOptions";
import { SpeechInitialContext } from "../../../../SpeechAndSwallowTherapist/InitialInspection/SpeechSwallowInitialAssessmentProvider";
import CheckListFBYInput from "../../../../../../blocks/FormItems/CheckListFBYInput";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const PatientCondition = ({ form }) => {
  const { formData } = useContext(SpeechInitialContext);
  const [showOptions, setShowOptions] = useState({});
  const [checkboxes, setCheckboxes] = useState({});

  const handleCheckboxChange = (value) => {
    setShowOptions((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  useEffect(() => {
    const initialCheckboxes = speechOptions?.reduce((acc, checkbox) => {
      acc[checkbox.value] = formData?.general_examination?.[checkbox.value];
      return acc;
    }, {});
    setShowOptions(initialCheckboxes);

    const initialBoxes = phonationOptions?.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.general_examination?.phonation_duration_details?.[
          checkbox.value
        ];
      return acc;
    }, {});
    setCheckboxes(initialBoxes);
  }, [formData]);

  const renderDynamicField = (option) => {
    switch (option.value) {
      case "s_z":
        return (
          <Form.Item name={`${option.value}_comments`}>
            <Input placeholder="Comments" />
          </Form.Item>
        );
      case "phonation_duration":
        return (
          <CheckListFBYInput
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            options={phonationOptions}
            keyChain={`${option.value}_details`}
          />
        );
      case "diadochokinetic_rate":
      case "articulation":
        return (
          <Form.Item name={`${option.value}_details`}>
            <Checkbox.Group
              options={
                option.value === "articulation"
                  ? articulationOptions
                  : diadochokineticOptions
              }
            />
          </Form.Item>
        );
      case "voice_grbas_scale":
        return voiceGrbasOptions?.map((grbOption) => (
          <DeselectableRadioGroup
            label={grbOption?.label}
            name={[option.value, grbOption.value]}
            options={grbasScaleOptions}
            form={form}
          />
        ));
      case "aphasia":
        return aphasiaOptions?.map((apOption) => (
          <>
            <DeselectableRadioGroup
              label={apOption?.label}
              name={[option.value, `${apOption.value}_status`]}
              options={yesNoOptions}
              form={form}
            />
            <Form.Item name={[option.value, `${apOption.value}_comments`]}>
              <Input placeholder="Comments" />
            </Form.Item>
          </>
        ));
      default:
        return (
          <>
            <DeselectableRadioGroup
              name={`${option.value}_status`}
              options={yesNoOptions}
              form={form}
            />
            <Form.Item name={`${option.value}_comments`}>
              <Input placeholder="Comments" />
            </Form.Item>
          </>
        );
    }
  };

  return (
    <>
      {speechOptions.map((option) => (
        <React.Fragment key={option.value}>
          <Form.Item name={option.value} valuePropName="checked">
            <Checkbox
              onChange={() => handleCheckboxChange(option.value)}
              checked={showOptions[option.value]}
            >
              {option.label}
            </Checkbox>
          </Form.Item>
          {showOptions[option.value] && (
            <div className="subsection-card">{renderDynamicField(option)}</div>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default PatientCondition;
