import axiosInstance from "../config/axios.config";

export const generateDischargeSummaryApi = (id, params) => {
  return axiosInstance.get(
    `/inpatient/discharge-summary/${id}/?practitioner=${params}`
  );
};
export const getDischargeSummaryApi = (id, params) => {
  return axiosInstance.get(
    `inpatient/get-discharge-summary/${id}/?practitioner=${params}`
  );
};
export const submitDischargeSummaryApi = (id, params, payload) => {
  return axiosInstance.put(
    `inpatient/get-discharge-summary/${id}/?practitioner=${params}`,
    payload
  );
};
export const getEmrDownloadApi = (id, params) => {
  return axiosInstance.get(`inpatient/emr-download/${id}/?${params}`);
};
export const downloadEmrApi = (id, params) => {
  return axiosInstance.get(`inpatient/emr-download/${id}/?${params}`);
};
