import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Image,
  Pagination,
  Space,
  Table,
  Tag,
  Col,
  Tooltip,
  Statistic,
  Row,
  Typography,
} from "antd";
import { capitalizeFirstLetter, formatToINR } from "../../../helpers/utility";
import InPatientModal from "../Inpatients/InPatientModal";
import AddPatientModal from "../AddPatientModal";
import AppointsModal from "../Appointments/AppointsModal";
import ipIcon from "../../../assets/receptionistIcons/ipIcon.svg";
import billingopIcon from "../../../assets/receptionistIcons/billingopIcon.svg";
import appointmentIcon from "../../../assets/receptionistIcons/appointmentIcon.svg";
import btnStyles from "../../../styles/Custom/Button.module.css";
import bedIcon from "../../../assets/images/patientIcons/bed.svg";
import { useNavigate } from "react-router-dom";

const AllPatientTable = ({ data, refresh, setPage, page, loading }) => {
  const navigate = useNavigate();
  const [editPatientModal, setEditPatientModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [addAppointment, setAddAppointment] = useState(false);

  const customImageIcon = (icon) => {
    return (
      <Image src={icon} alt="ipIcon" width={20} height={20} preview={false} />
    );
  };

  const columns = [
    {
      title: "Patient ID",
      key: "patient_id",
      dataIndex: "patient_id",
      width: 100,
    },
    { title: "Phone Number", key: "mobile", dataIndex: "mobile", width: 120 },
    {
      title: "Full Name",
      width: 380,
      render: (_, record) => (
        <Row>
          <Col span={12}>
            {capitalizeFirstLetter(record?.first_name) +
              " " +
              capitalizeFirstLetter(record?.last_name)}
          </Col>
          <Col span={6}>
            <Tag>
              {record?.age} {record?.gender}
            </Tag>
            {/* <Tag>
              {`${record?.age ? record?.age : ""} ${
                record?.gender === "Male"
                  ? "M"
                  : record?.gender === "Female"
                  ? "F"
                  : "Others"
              }`}
            </Tag> */}
          </Col>
          <Col span={6}>
            {record?.is_inpatient && (
              <>
                {record?.ipadmission__bed__bed_no && (
                  <Tag color="orange">
                    <Image src={bedIcon} preview={false} />{" "}
                    {record?.ipadmission__bed__bed_no}
                  </Tag>
                )}
              </>
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: "Notes",
      key: "notes",
      dataIndex: "notes",
      width: 200,
      render: (value) => (value ? <Typography>{value}</Typography> : "-"),
    },
    {
      title: "Balance",
      key: "balance",
      dataIndex: "balance",
      width: 150,
      render: (value, record) =>
        value * -1 < 0 ? (
          <Statistic
            value={formatToINR(value * -1)}
            // prefix="-"
            valueStyle={{ color: "#FD6050", fontSize: "16px" }}
          />
        ) : value * -1 > 0 ? (
          <Statistic
            value={formatToINR(value * -1)}
            prefix="+"
            valueStyle={{ color: "#40C164", fontSize: "16px" }}
          />
        ) : (
          <Statistic
            value={formatToINR(value)}
            valueStyle={{ color: "#40C164", fontSize: "16px" }}
          />
        ),
    },
    {
      title: "Action",
      width: 350,
      render: (_, record) => (
        <>
          {record?.is_inpatient ? (
            <Space>
              <Button
                type="primary"
                ghost
                className={btnStyles.smBtn}
                onClick={() =>
                  navigate(
                    `/receptionist/home?patient=${record?.ipadmission}&type=billing`
                  )
                }
              >
                IP Billing
              </Button>
              <Tooltip title="Edit Patient">
                <Button
                  onClick={() => {
                    setEditPatientModal(true);
                    setEditData(record);
                  }}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            </Space>
          ) : (
            <Space>
              <Tooltip title="IP Admission">
                <Button
                  onClick={() => {
                    setOpen(true);
                    setEditData(record);
                  }}
                >
                  {customImageIcon(ipIcon)}
                  {record?.is_inpatient && (
                    <Badge
                      color="green"
                      status="processing"
                      className="custom-badge-size"
                      style={{
                        position: "absolute",
                        top: -5,
                        right: 3,
                      }}
                    />
                  )}
                </Button>
              </Tooltip>
              <Tooltip title="Book Appointment">
                <Button
                  onClick={() => {
                    setAddAppointment(true);
                    setEditData(record);
                  }}
                >
                  {customImageIcon(appointmentIcon)}
                </Button>
              </Tooltip>

              <Tooltip title="Add Bill">
                <Button
                  onClick={() =>
                    navigate(
                      `/receptionist/billing/?patient=true&patient_id=${record?.id}`
                    )
                  }
                >
                  {customImageIcon(billingopIcon)}
                </Button>
              </Tooltip>
              <Tooltip title="Edit Patient">
                <Button
                  onClick={() => {
                    setEditPatientModal(true);
                    setEditData(record);
                  }}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            </Space>
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Table
        bordered
        columns={columns}
        dataSource={data?.data}
        pagination={false}
        scroll={{ y: "calc(100vh - 300px)", x: "400px" }}
        loading={loading}
      />
      {data?.total_count > 50 && (
        <Col span={24} className="text-center" style={{ paddingTop: "10px" }}>
          <Pagination
            current={page}
            pageSize={50}
            total={data?.total_count}
            onChange={(e) => setPage(e)}
            showSizeChanger={false}
          />
        </Col>
      )}

      {open && (
        <InPatientModal
          open={open}
          setOpen={setOpen}
          editData={editData}
          refresh={refresh}
        />
      )}
      {editPatientModal && (
        <AddPatientModal
          open={editPatientModal}
          setOpen={setEditPatientModal}
          editData={editData}
          refresh={refresh}
        />
      )}
      {addAppointment && (
        <AppointsModal
          open={addAppointment}
          setOpen={setAddAppointment}
          refresh={refresh}
          editData={editData}
        />
      )}
    </>
  );
};

export default AllPatientTable;
