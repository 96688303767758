import React, { useEffect, useState } from "react";
import { Button, Col, Row, Space } from "antd";
import btnStyles from "../../../styles/Custom/Button.module.css";
import { OpenAIOutlined } from "@ant-design/icons";
import { getpatientMedicationsAPI } from "../../../services/patient.services";
import { useLocation } from "react-router-dom";
import AddMedicineModal from "./AddMedicineModal";
import ScanImageDrawer from "./ScanImage/ScanImageDrawer";
import MedicationHistoryChart from "./MedicationHistoryChart";
import AddMedicineTable from "./AddMedicineTable";
import LoadingIndicator from "../../Loaders/LoadingIndicator";
import NoDataPage from "../../NoDataPages/NoDataPage";
import medicineIcon from "../../../assets/images/nurseIcons/medicinebox.svg";

const MedicationHistoryIP = ({ patientData }) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [open, setOpen] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [historyModal, setHistoryModal] = useState(false);
  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    getpatientMedicationsAPI(`ip_admission=${params?.patient}`)
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [refresh]);

  return (
    <>
    {  loading ? (
      <LoadingIndicator message={"Loading data, please wait"} />) :
      data?.length > 0 ? (
      <>
        <>
          <Row gutter={[12, 12]} >
            <Col span={24} style={{ textAlign: "right" }}>
              <Space>
                <Button
                  type="primary"
                  // className={btnStyles.mdBtn}
                  onClick={() => setHistoryModal(true)}
                >
                  Medication History
                </Button>
                {/* <Button
              type="primary"
              ghost
              className={btnStyles.mdBtn}
              onClick={() => setOpen(true)}
            >
              <OpenAIOutlined /> Scan Images
            </Button> */}
              </Space>
            </Col>
            <Col span={24}>
              <AddMedicineTable
                data={data}
                setData={setData}
                refreshPage={refreshPage}
                loading={loading}
              />
            </Col>
          </Row>
        </>
      </>
      ) : (
      <Row gutter={[12, 12]} >
        <Col span={24} style={{ textAlign: "right" }}>
          <Space>
            <Button
              type="primary"
              // className={btnStyles.mdBtn}
              onClick={() => setHistoryModal(true)}
            >
              Medication History
            </Button>
            {/* <Button
              type="primary"
              ghost
              className={btnStyles.mdBtn}
              onClick={() => setOpen(true)}
            >
              <OpenAIOutlined /> Scan Images
            </Button> */}
          </Space>
        </Col>
        <Col span={24}>
          <AddMedicineTable
            data={data}
            setData={setData}
            refreshPage={refreshPage}
            loading={loading}
          />
        </Col>
      </Row>
      )}
      {open && (
        <ScanImageDrawer
          open={open}
          setOpen={setOpen}
          refreshPage={refreshPage}
        />
      )}
      {addModal && (
        <AddMedicineModal
          open={addModal}
          setOpen={setAddModal}
          refreshPage={refreshPage}
        />
      )}
      {historyModal && (
        <MedicationHistoryChart
          open={historyModal}
          setOpen={setHistoryModal}
          data={data}
          params={params}
          patientData={patientData?.admission}
        />
      )}
    </>
  );
};

export default MedicationHistoryIP;
