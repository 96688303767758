import React from "react";
import LimbDeformity from "./LimbDeformity";
import Fractures from "./Fractures";
import Skull from "./Skull";
import Spine from "./Spine";
import JointPain from "./JointPain";
import Contractures from "./Contractures";
import MusculoSkeletalDetails from "./MusculoSkeletalDetails";
import { Divider } from "antd";
import ROMAssessment from "./ROMAssessment";

const MusculoSkeletalForm = ({ context, dailyReview = false, form }) => {
  return (
    <>
      {dailyReview ? (
        <MusculoSkeletalDetails />
      ) : (
        <>
          <LimbDeformity context={context} form={form} />
          <Divider />
          <Fractures context={context} form={form} />
          <Divider />
          <Skull form={form} />
          <Divider />
          <Spine context={context} />
          <Divider />
          <JointPain context={context} form={form} />
          <Divider />
          <Contractures context={context} form={form} />
          {context ? (
            <>
              <Divider />
              <ROMAssessment context={context} form={form} />
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default MusculoSkeletalForm;
