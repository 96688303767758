import React, { useState } from "react";
import { Form, Select } from "antd";
import {
  ambulanceAssistanceOptions,
  ambulanceBedBoundOptions,
  ambulanceOptions,
} from "../../../Options/funcExamOptions";
import AmbulationDetails from "./AmbulationDetails";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";
import SelectWithOtherInput from "../../../../../blocks/FormItems/SelectWithOtherInput";

const Ambulation = ({ context, dailyReview = false, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      {dailyReview ? (
        <AmbulationDetails />
      ) : (
        <>
          <DeselectableRadioGroup
            name={"ambulation"}
            label={"Ambulation"}
            options={ambulanceOptions}
            form={form}
            setRefresh={setRefresh}
          />
          {form.getFieldValue("ambulation") === "assisted" && (
            <SelectWithOtherInput
              name={"ambulation_assistance"}
              options={ambulanceAssistanceOptions}
              form={form}
              selectProps={{
                onChange: () => {
                  setRefresh((prev) => !prev);
                },
              }}
            />
          )}
          {form.getFieldValue("ambulation") === "bed_bound" && (
            <Form.Item name="ambulation_bed_bound">
              <Select
                placeholder="Select"
                options={ambulanceBedBoundOptions}
                style={{ width: 200 }}
              />
            </Form.Item>
          )}
        </>
      )}
    </>
  );
};

export default Ambulation;
