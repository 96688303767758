import React, { useState } from "react";
import {
  Form,
  Checkbox,
  Input,
  InputNumber,
  Row,
  Col,
  Space,
  Typography,
} from "antd";
import {
  cannulaOptions,
  centralLineOptions,
  peripheralCannulaOptions,
} from "../../../../Options/genExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const IVAccess = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const showCannula = form.getFieldValue("peripheral_cannula");

  return (
    <>
      <Typography>IV Access</Typography>
      <Row gutter={16}>
        <Col span={24}>
          <DeselectableRadioGroup
            label={"Peripheral Cannula"}
            name={"peripheral_cannula"}
            options={peripheralCannulaOptions}
            form={form}
            setRefresh={setRefresh}
          />
        </Col>
        {showCannula && showCannula !== "no" && (
          <div className="subsection-card">
            <Space>
              <Form.Item label="Cannula 1 Site" name={`cannula_site1`}>
                <Input placeholder="Cannula 1 Site" />
              </Form.Item>
              <Form.Item label="Cannula 1 Type" name={`cannula_type1`}>
                <Checkbox.Group options={cannulaOptions} />
              </Form.Item>
            </Space>
            {showCannula === "yes_2" && (
              <>
                <Space>
                  <Form.Item label="Cannula 2 Site" name={`cannula_site2`}>
                    <Input placeholder="Cannula 2 Site" />
                  </Form.Item>
                  <Form.Item label="Cannula 2 Type" name={`cannula_type2`}>
                    <Checkbox.Group options={cannulaOptions} />
                  </Form.Item>
                </Space>
              </>
            )}
          </div>
        )}
        <Col span={24}>
          <DeselectableRadioGroup
            label={"Central Line"}
            name={"central_line"}
            options={centralLineOptions}
            form={form}
            setRefresh={setRefresh}
          />
        </Col>
        {form.getFieldValue("central_line") === "yes" && (
          <Col span={10}>
            <Form.Item label="Cumulative Days" name="central_line_days">
              <InputNumber addonBefore="No.of Days" style={{ width: "35%" }} />
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
};

export default IVAccess;
