import React from "react";
import { normalAbnormalOptions } from "../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const SpeechSwallowAssessment = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Speech and Swallow Assessment"}
        name={"speech_and_swallow_assessment"}
        options={normalAbnormalOptions}
        form={form}
      />
    </>
  );
};

export default SpeechSwallowAssessment;
