import React, { useState } from "react";
import { Modal, message, Upload, Button } from "antd";
import { getUploadUrlApi } from "../../../services/common.services";
import { convertToFormData } from "../../../helpers/utility";
import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { updateIpAdmissionApi } from "../../../services/receptionist.services";
const { Dragger } = Upload;
const IpUploadModal = ({
  openModal,
  setOpenModal,
  editData,
  refreshIpPage,
  type,
}) => {
  const [uploadedFileName, setuploadedFileName] = useState([]);

  const beforeUpload = (file) => {
    let file_ext = file.name.split(".").slice(-1)[0];
    const fileType =
      file_ext === "png" ||
      file_ext === "jpg" ||
      file_ext === "jpeg" ||
      file_ext === "svg" ||
      file.type === "application/pdf";
    if (!fileType) {
      message.error(`${file.name} is not a valid file`);
    }
    return fileType ? true : Upload.LIST_IGNORE;
  };

  const uploadFileFunc = async (info, id, type) => {
    if (info.file.status === "done") {
      try {
        const res = await getUploadUrlApi(`path_key=${info?.file?.name}`);
        const formData = convertToFormData(res, info);
        await axios({
          method: "POST",
          url: res?.data?.url,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        setuploadedFileName((prev) => [...prev, res?.data?.fields?.key]);
      } catch (err) {
        console.log(err);
        message.error(err?.response?.data?.message);
      }
    }
  };

  const onSubmit = () => {
    const formData =
      type === "consent"
        ? { consentform: uploadedFileName }
        : { dischargesummary: uploadedFileName };

    if (editData?.id) {
      updateIpAdmissionApi(editData?.id, formData)
        .then((res) => {
          message.success(res?.data?.message);
          refreshIpPage && refreshIpPage();
          setOpenModal(false);
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message);
        });
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleRemoveFile = (fileToRemove) => {
    const filteredFiles = uploadedFileName?.filter(
      (file) => file !== fileToRemove
    );
    setuploadedFileName(filteredFiles);
  };

  return (
    <Modal
      open={openModal}
      onCancel={() => setOpenModal(false)}
      onOk={onSubmit}
    >
      <Dragger
        multiple={true}
        accept=".png,.jpg,.jpeg,.svg,application/pdf"
        beforeUpload={beforeUpload}
        onChange={(info) => uploadFileFunc(info)}
        customRequest={dummyRequest}
        showUploadList={false}
      >
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>

        <p className="ant-upload-text">
          <span style={{ color: "#406AFF" }}>Click to Upload </span> or drag and
          drop
        </p>
        <p className="ant-upload-hint">Pdf,jpg,jpeg,png,svg</p>
      </Dragger>
      {uploadedFileName.map((item, index) => (
        <div key={index}>
          {console.log(item)}
          {item?.replace("curengo/", "")}
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleRemoveFile(item)}
            style={{ marginLeft: "8px" }}
          />
        </div>
      ))}
    </Modal>
  );
};

export default IpUploadModal;
