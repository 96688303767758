import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
  TimePicker,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  deletePhysioCareAPI,
  getTherapiesBySearchAPI,
  physioCareAPI,
  updatePhysioCareAPI,
} from "../../../../services/physio.services";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const TimeFormat = "h:mm a";

const SessionTable = ({
  sessionData,
  setSessionData,
  session,
  date,
  therapies,
  practitioners,
  refreshTables,
  userData,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const admissionID = params?.patient;
  const curTime = dayjs().format(TimeFormat);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  const handleDelete = async (id) => {
    deletePhysioCareAPI(id)
      .then((res) => {
        console.log(res);
        refreshTables();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOk = async () => {
    let data = {};
    await form
      .validateFields()
      .then((values) => {
        console.log(values);
        data = values;
      })
      .catch((error) => {
        console.log(error);
      });
    console.log(data);
    if (!Array.isArray(data?.therapy)) {
      data.therapy = [data?.therapy];
    }
    data.therapy.forEach((option) => {
      const finalData = {
        ...data,
        therapy: option,
        admission_id: admissionID,
        session,
        session_date: date,
        duration: data?.duration / data?.therapy?.length,
      };
      if (editData) {
        updatePhysioCareAPI(editData, finalData)
          .then(() => {
            // setOpen(false);
            // form.resetFields();
            // refreshTables();
          })
          .catch((error) => {
            console.log(`Error:${error}`);
          });
      } else {
        physioCareAPI(finalData)
          .then(() => {
            // setOpen(false);
            // form.resetFields();
            // refreshTables();
          })
          .catch((error) => {
            console.log(`Error:${error}`);
          });
      }
    });
    setOpen(false);
    form.resetFields();
    // refreshTables();
    setTimeout(() => {
      refreshTables();
    }, 1000);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
      render: (_, record, index) => index + 1,
      width: 100,
    },
    {
      title: "Therapy",
      dataIndex: "therapy",
      key: "therapy",
      render: (id) => therapies?.find((option) => option?.value === id)?.label,
    },
    {
      title: "Done By",
      dataIndex: "done_by",
      key: "done_by",
      render: (id) =>
        practitioners?.find((option) => option?.value === id)?.label,
    },
    {
      title: "Time Done",
      dataIndex: "time_done",
      key: "time_done",
      render: (time) => dayjs(time)?.format(TimeFormat),
      width: 200,
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
    },
    {
      title: "Updated by",
      dataIndex: "updated_by",
      key: "updated_by",
      render: (id) =>
        practitioners?.find((option) => option?.value === id)?.label,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (time) => dayjs(time)?.format("h:mm:ss a"),
      width: 200,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => {
              console.log(record);
              setOpen(true);
              setEditData(record?.id);
              form?.setFieldsValue({
                ...record,
                time_done: record?.updated_at
                  ? dayjs(record?.updated_at)
                  : dayjs(),
              });
            }}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            onClick={() => {
              Modal.confirm({
                title: "Confirmation",
                content: "Are you sure you want to Delete",
                onOk: () => {
                  handleDelete(record?.id);
                },
              });
            }}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
      width: 200,
    },
  ];

  const calculateTotalDuration = () => {
    return sessionData?.reduce((acc, cur) => acc + (cur?.duration || 0), 0);
  };

  const totalDuration = calculateTotalDuration();

  return (
    <Row gutter={[0, 8]}>
      <Col span={24} className="flex-end">
        <Button onClick={() => setOpen(true)}>Add Session</Button>
      </Col>
      {sessionData?.length > 0 && (
        <Col span={24}>
          <Typography>
            Total Duration for this session: {totalDuration} min(s)
          </Typography>
        </Col>
      )}
      <Col span={24}>
        <Table
          dataSource={sessionData}
          columns={columns}
          pagination={false}
          scroll={{ x: 1200, y: "40vh" }}
        />
      </Col>
      <Modal
        title={"Physio Session Details"}
        open={open}
        keyboard={false}
        onOk={handleOk}
        onCancel={() => {
          setOpen(false);
          form?.resetFields();
        }}
        width={"50%"}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Name" name={"therapy"}>
            <Select
              showSearch
              mode="multiple"
              options={therapies}
              allowClear
              disabled={editData}
              placeholder="Search to select"
              // onSearch={handleSearch}
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item label="Done By" name="done_by">
            <Select
              showSearch
              options={practitioners}
              allowClear
              placeholder="Search to select"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item label="Duration" name={"duration"}>
            <InputNumber placeholder="in min" />
          </Form.Item>
          <Form.Item label="Comments" name={"comments"}>
            <Input.TextArea rows={4} style={{ width: "70%" }} />
          </Form.Item>
          <Form.Item label="Time Done" name={"time_done"}>
            <TimePicker
              format={TimeFormat}
              needConfirm={false}
              defaultValue={dayjs()}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

export default SessionTable;
