import React, { Suspense, useEffect, useState, useMemo, lazy } from "react";
import { Badge, Col, Image, Radio, Row, Space, Spin } from "antd";
import { nurseCareItemsList } from "../../Options/careItemsOptions";
import { getNurseCommonData } from "../../../../services/nurse.services";
import DailyRoutine from "./DailyRoutine/DailyRoutine";
import useQuery from "../../../../hooks/useQuery";
import dayjs from "dayjs";
import LoadingIndicator from "../../../Loaders/LoadingIndicator";
// import { getBadgeColor } from "../../../../helpers/getBadgeColor";
// Dynamic import map
const componentMap = {
  nebulization: lazy(() => import("./Nebulisation")),
  iv_fluids: lazy(() => import("./IvFluids")),
  blood_products: lazy(() => import("./BloodProducts")),
  parental_nutrition: lazy(() => import("./ParentalNutrition")),
  respiratory_care: lazy(() => import("./RespiratoryCare/RespiratoryCare")),
  urinary_catheter_care: lazy(() => import("./UrinaryCatheter")),
  open_wound_care: lazy(() => import("./WoundCare/WoundCare")),
  preventive_care: lazy(() => import("./PreventiveCare/PreventiveCare")),
  eye_care: lazy(() => import("./EyeCare/EyeCare")),
  feeding_tube: lazy(() => import("./FeedingTube")),
  pressure_sore_care: lazy(() => import("./PressureSore/PressureSore")),
  ostomy_care: lazy(() => import("./Ostomy")),
  physio_position_change: lazy(() => import("./PositionChange")),
};
const dateFormat = "YYYY-MM-DD";
function CareItemTabs({ isSpecialArea = false }) {
  const curDate = dayjs().format(dateFormat);
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({ daily_routine_care: [{}] });
  const [activeRadio, setActiveRadio] = useState("nebulization");
  const [dailyData, setDailyData] = useState({});
  async function getData() {
    try {
      const response = await getNurseCommonData(
        query?.patient,
        `care_items`,
        curDate
      );

      setFormData({ ...response?.data?.data, daily_routine_care: [{}] });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function getdailyCareData() {
    try {
      const response = await getNurseCommonData(
        query?.patient,
        "daily_routine_care",
        curDate
      );

      setDailyData(response?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (query?.patient) getData();
    {
      activeRadio === "daily_routine_care" && getdailyCareData();
    }
  }, [query?.patient, activeRadio]);

  const ActiveComponent = useMemo(
    () => componentMap[activeRadio] || componentMap["nebulization"],
    [activeRadio]
  );

  useEffect(() => {
    if (!loading) {
      // Check if the current activeRadio is valid or needs to be updated
      const availableKeys = [
        ...nurseCareItemsList,
        { label: "Daily Routine", key: "daily_routine_care" },
      ]
        .map(({ key }) => key)
        .filter((key) =>
          Object.keys(formData).some((item) => item.startsWith(key))
        );

      // If the current activeRadio is not valid, update it to the first available key
      if (!availableKeys.includes(activeRadio) && availableKeys.length > 0) {
        setActiveRadio(availableKeys[0]);
      }
    }
  }, [formData, loading]); // Re-run when formData or activeRadio changes

  function imageLoader(name) {
    try {
      return name === activeRadio
        ? require(`../../../../assets/careItemIcons/${name}_light.png`)
        : require(`../../../../assets/careItemIcons/${name}.png`);
    } catch (error) {
      console.error(`Image not found: ${name}`, error);
      return null;
    }
  }

  const getFormDataValue = () => {
    if (formData[activeRadio]) return formData[activeRadio];

    if (Object.keys(formData).some((item) => item.startsWith(activeRadio)))
      return formData;

    return undefined;
  };

  const getBadgeColor = (key) => {
    if (["iv_fluids", "blood_products", "parental_nutrition"].includes(key)) {
      const items = formData[key];
      return items &&
        Array.isArray(items) &&
        items.every((item) =>
          item.actions?.some((action) => action?.is_stopped === true)
        )
        ? "red"
        : "green";
    }

    const activeItems = Object.keys(formData).filter((dataKey) =>
      dataKey.startsWith(key)
    );

    for (const dataKey of activeItems) {
      const items = formData[dataKey];

      if (items && Array.isArray(items)) {
        for (const element of items) {
          if (element?.actions) {
            const isActive = element.actions.some(
              (action) => action?.is_stopped === false
            );
            if (isActive) {
              return "green";
            }
          } else {
            const baseKey = key.split("_").slice(0, 2).join("_"); // Get the base part of the key
            const nestedKeys = Object.keys(element).filter(
              (prop) => prop.startsWith(baseKey) // Check for keys that start with the base key
            );

            for (const nestedKey of nestedKeys) {
              if (element[nestedKey]?.is_stopped === false) {
                return "green";
              }
            }
          }
        }
      } else {
        return "red";
      }
    }
    return "red";
  };

  return (
    <Row>
      <Col span={24}>
        <Radio.Group
          onChange={(e) => {
            setActiveRadio(e.target.value);
          }}
          buttonStyle="solid"
          value={activeRadio} // Keep this to bind with activeRadio state
        >
          {nurseCareItemsList.map(
            ({ label, key }) =>
              Object.keys(formData).some((item) => item.startsWith(key)) && (
                <Radio.Button
                  key={key}
                  value={key}
                  style={{ marginBottom: "10px" }}
                >
                  <Space>
                    <span style={{ fontSize: "12px" }}>{label}</span>
                    <Image
                      preview={false}
                      src={imageLoader(key)}
                      style={{ height: "20px", width: "auto" }}
                    />
                  </Space>
                  <Badge
                    color={getBadgeColor(key)}
                    status="processing"
                    className="custom-badge-size"
                    style={{
                      position: "absolute",
                      top: -10,
                      right: 3,
                    }}
                  />
                </Radio.Button>
              )
          )}
          {!loading && (
            <Radio.Button value="daily_routine_care" key="daily_routine_care">
              <span style={{ fontSize: "12px" }}> Daily Routine</span>
              <Image
                preview={false}
                src={imageLoader("daily_routine_care")}
                style={{ height: "18px", width: "auto", marginBottom: "4px" }}
              />
              <Badge
                color="green"
                status="processing"
                className="custom-badge-size"
                style={{
                  position: "absolute",
                  top: -10,
                  right: 3,
                }}
              />
            </Radio.Button>
          )}
        </Radio.Group>
      </Col>
      <Col span={24}>
        {activeRadio === "daily_routine_care" ? (
          <DailyRoutine formData={dailyData} getData={getdailyCareData} />
        ) : (
          <Suspense fallback={<LoadingIndicator message={"Loading..."} />}>
            {!loading && (
              <ActiveComponent
                formData={getFormDataValue()}
                getData={getData}
                isSpecialArea={isSpecialArea}
                activeRadio={activeRadio}
              />
            )}
          </Suspense>
        )}
      </Col>
    </Row>
  );
}

export default CareItemTabs;
