import React from "react";
import { Result } from "antd";

const NoDataPage = ({ title, subtitle = "", image }) => {
  return (
    <Result
      icon={
        <img src={image} alt="No Data" style={{ width: 100, height: 100 }} />
      }
      title={title}
      subTitle={subtitle}
    />
  );
};

export default NoDataPage;
