import { Form, Input } from "antd";
import React from "react";
import { oxygenRequiredOptions } from "../../Options/RespiratoryTherapistOptions";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const Oxygen = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"O2 Required for SPO2 > 92%"}
        name={["oxygen_required", "status"]}
        options={oxygenRequiredOptions}
        form={form}
      />
      <Form.Item name={["oxygen_required", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default Oxygen;
