import dayjs from "dayjs";
import React from "react";
import CommonTable from "../CommonTable";

const EyeCommonCols = ({ data }) => {
  const columns = [
    {
      title: "Time Done",
      dataIndex: "action_time",
      key: "action_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];
  return (
    <div>
      {data?.map((item) => (
        <CommonTable
          columns={columns}
          data={
            Array?.isArray(item?.actions) && Array?.isArray(item?.actions[0])
              ? item?.actions?.flat()
              : item?.actions
          }
          titleData={() => ""}
        />
      ))}
    </div>
  );
};

export default EyeCommonCols;
