import React, { useContext, useEffect, useMemo, useState } from "react";
import CheckListFBYCheckGroup from "../../../../../../blocks/FormItems/CheckListFBYCheckGroup";
import { getInitialValuesForChild } from "../../../../../../helpers/getInitialValues";
import { noneYesOptions } from "../../../../Options/commonOptions";
import {
  jointPainLocationOptions,
  jointPainSeverityOptions,
} from "../../../../Options/systemicExamOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const JointPain = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context ? context : DoctorInitialContext);
  const initialValues = useMemo(() => formData, [formData]);

  const [checkboxes, setCheckboxes] = useState({});

  // useEffect to update state when initialValues change
  useEffect(() => {
    const initialCheckboxes = jointPainLocationOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          getInitialValuesForChild(
            jointPainLocationOptions,
            initialValues?.systemic_examination !== null &&
              initialValues?.systemic_examination
          )[checkbox.value] || false; // Initialize all checkboxes based on initialValues
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <DeselectableRadioGroup
        name={"joint_pain"}
        label={"Joint Pain"}
        options={noneYesOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("joint_pain") === "yes" && (
        <div className="subsection-card">
          <CheckListFBYCheckGroup
            checkBoxOptions={jointPainLocationOptions}
            checkBoxGroupOptions={jointPainSeverityOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
          />
        </div>
      )}
    </>
  );
};

export default JointPain;
