import React from "react";
import { Card, Row, Col, Space, Typography, Tag } from "antd";
import {
  woundCareAreaOptions,
  woundCareDressingOptions,
} from "../../../IpEMR/Options/careItemsOptions";
import { capitalizeFirstLetter } from "../../../../helpers/utility";
import WoundCareCols from "./WoundCareCols";
const OpenWoundCare = ({ data }) => {
  const customTypography = (title, value) => {
    return (
      <>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontWeight: "lighter",
            fontSize: "10px",
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontWeight: "lighter",
            fontSize: "14px",
          }}
        >
          {value ? value : "-"}
        </Typography>
      </>
    );
  };
  return (
    <div>
      {data?.map((item) => (
        <Card
          style={{ marginTop: "10px" }}
          title={
            <Row gutter={[12, 12]}>
              <Col span={3}>
                {customTypography(
                  "Area",
                  item?.drug_name === "others"
                    ? `Others - ${item?.data?.do_data?.other_wound_area} `
                    : woundCareAreaOptions?.find(
                        (option) => option.value === item?.drug_name
                      )?.label || "-"
                )}
              </Col>

              <Col span={2}>
                {customTypography(
                  "Dressing",
                  item?.data?.do_data?.dressing === "others"
                    ? `Others - ${item?.data?.do_data?.other_dressing_area} `
                    : woundCareDressingOptions?.find(
                        (option) =>
                          option.value === item?.data?.do_data?.dressing
                      )?.label || "-"
                )}
              </Col>

              <Col span={4}>
                {customTypography(
                  "Advised By",
                  capitalizeFirstLetter(item?.advised_by)
                )}
              </Col>
            </Row>
          }
        >
          {item?.open_wound_care_dressing && (
            <WoundCareCols
              data={item?.open_wound_care_dressing}
              heading="Dressing"
              type="open_wound_care_dressing"
            />
          )}
          {item?.open_wound_care_debridement && (
            <WoundCareCols
              data={item?.open_wound_care_debridement}
              heading="Debridement"
              type="open_wound_care_debridement"
            />
          )}
          {item?.open_wound_care_suturing && (
            <WoundCareCols
              data={item?.open_wound_care_suturing}
              heading="Suturing"
              type="open_wound_care_suturing"
            />
          )}
          {item?.open_wound_care_vac_management && (
            <WoundCareCols
              data={item?.open_wound_care_vac_management}
              heading="VAC Management"
              type="open_wound_care_vac_management"
            />
          )}
        </Card>
      ))}
    </div>
  );
};

export default OpenWoundCare;
