import React, { useState, useEffect } from "react";
import { Collapse, Space, Tag, Typography } from "antd";
import { getPatientPackagesApi } from "../../../../services/receptionist.services";
import { formatToINR } from "../../../../helpers/utility";
import PatientPackageTable from "./PatientPackageTable";
import useQuery from "../../../../hooks/useQuery";
import dayjs from "dayjs";

const PatientPackageDetails = ({ refresh }) => {
  const params = useQuery();
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    getPatientPackagesApi(params?.patient)
      .then((res) => {
        setPackages(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  return (
    <>
      {packages?.map((item, index) => (
        <Collapse
          expandIconPosition="right"
          defaultActiveKey={index}
          data={item}
          className="custom-collapse"
          style={{ marginTop: "10px",}}
          
        >
          <Collapse.Panel
          style={{background:"#fff",boxShadow:"0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)"}}
            header={
              <div className="flex-between">
                <Space>
                  <Typography>{item?.package?.name}</Typography>

                  <Tag color="blue">
                    {dayjs(item?.start_date).format("MMM DD")}
                    {item?.end_date &&
                      ` - ${dayjs(item?.end_date).format("MMM DD")}`}
                  </Tag>
                  <Tag color="orange">
                    {formatToINR(item?.package?.total_price - item?.discount)}
                  </Tag>
                </Space>
              </div>
            }
            key={0}
          >
            <PatientPackageTable data={item} />
          </Collapse.Panel>
        </Collapse>
      ))}
    </>
  );
};

export default PatientPackageDetails;
