import React, { useContext, useEffect, useState } from "react";
import { Image, Pagination, Table, Tag, Col, Row, Input } from "antd";
import { getPatientListApi } from "../../../services/receptionist.services";
import { capitalizeFirstLetter } from "../../../helpers/auth/helper";
import bedIcon from "../../../assets/images/patientIcons/bed.svg";
import { debounce } from "../../../helpers/utility";
import { UserContext } from "../../../context/UserContext";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID, Role } from "../../../constants/defaultKeys";

const PatientsTable = () => {
  const { userDetails } = useContext(UserContext);
  const [patientData, setPatientData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const defaultRole = userDetails?.currRole || getCookie(Role);

  useEffect(() => {
    setLoading(true);
    let queryParams = {
      page: page,
      limit: 50,
    };
    if (searchQuery) {
      queryParams.search = searchQuery;
    }
    if (defaultRole === "Facility_Admin") {
      queryParams.facility_id =
        Number(getCookie(FacilityID)) || userDetails?.currFacility;
    }

    const queryString = new URLSearchParams(queryParams);
    getPatientListApi(queryString)
      .then((res) => {
        setLoading(false);
        setPatientData(res?.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [
    page,
    searchQuery,
    Number(getCookie(FacilityID)) || userDetails?.currFacility,
  ]);

  const onContactSearch = (value) => {
    setSearchQuery(value?.target?.value);
  };

  const columns = [
    {
      title: "Patient ID",
      key: "patient_id",
      dataIndex: "patient_id",
      width: 100,
    },
    { title: "Phone Number", key: "mobile", dataIndex: "mobile", width: 120 },
    {
      title: "Full Name",
      width: 380,
      render: (_, record) => (
        <Row>
          <Col span={12}>
            {capitalizeFirstLetter(record?.first_name) +
              " " +
              capitalizeFirstLetter(record?.last_name)}
          </Col>
          <Col span={6}>
            <Tag>
              {record?.age} {record?.gender}
            </Tag>
          </Col>
          <Col span={6}>
            {record?.is_inpatient && (
              <>
                {record?.ipadmission__bed__bed_no && (
                  <Tag color="orange">
                    <Image src={bedIcon} preview={false} />{" "}
                    {record?.ipadmission__bed__bed_no}
                  </Tag>
                )}
              </>
            )}
          </Col>
        </Row>
      ),
    },
  ];
  return (
    <>
      <Input.Search
        style={{ width: "30%", marginBottom: "10px" }}
        size="large"
        placeholder="Search for patient"
        onChange={debounce(onContactSearch, 300)}
      ></Input.Search>
      <Table
        bordered
        columns={columns}
        dataSource={patientData?.data}
        pagination={false}
        scroll={{ y: "calc(100vh - 300px)", x: "400px" }}
        loading={loading}
      />
      {patientData?.total_count > 50 && (
        <Col span={24} className="text-center" style={{ paddingTop: "10px" }}>
          <Pagination
            current={page}
            pageSize={50}
            total={patientData?.total_count}
            onChange={(e) => setPage(e)}
            showSizeChanger={false}
          />
        </Col>
      )}
    </>
  );
};

export default PatientsTable;
