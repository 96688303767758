import React, { memo } from "react";
import { Drawer, Row, Col, Tag, Space, Typography, Image, Card } from "antd";
import scalesConfig from "../../../../helpers/physioscaleskeys";
import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import bedImg from "../../../../assets/images/patientIcons/bed.svg";
import ScaleTable from "./progressTable";
import ProgressChart from "./progressChart";

const getScaleData = (scaleKey, data, subItemKey) => {
  const scale = scalesConfig[scaleKey];
  if (!data || !scale) return [];

  const keysConfig = subItemKey
    ? scalesConfig[scaleKey]?.subItems?.[subItemKey]?.keys
    : scale?.keys;

  return data.map((item) => {
    const formattedDate = item?.date
      ? new Date(item.date).toLocaleString("en-US", {
          day: "2-digit",
          month: "short",
          // year: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          // second: "2-digit",
          hour12: true,
        })
      : "Unknown Date";

    const chartEntry = { name: formattedDate };

    Object.entries(keysConfig || {}).forEach(([key]) => {
      if (item[key] !== undefined) {
        chartEntry[key] = item[key];
      }
    });

    return chartEntry;
  });
};

const ScaleModal = memo(
  ({ patientData, scaleKey, data, isVisible, onClose }) => {
    const scaleType = scalesConfig[scaleKey]?.type;

    return (
      <Drawer
        visible={isVisible}
        closable={false}
        title={
          <Row
            style={{
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Space>
                <LeftOutlined
                  onClick={() => {
                    onClose();
                  }}
                  style={{
                    fontSize: "18px",
                    padding: "0px 10px",
                    marginTop: "15px",
                  }}
                />
                <Typography
                  style={{
                    color: "#101828",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  {`${patientData?.patient?.first_name || " "} ${
                    patientData?.patient?.last_name || " "
                  }`}
                </Typography>
                {patientData?.bed?.bed_no && (
                  <Tag color="orange">
                    <Image src={bedImg} preview={false} />{" "}
                    {patientData?.bed?.bed_no || ""}
                  </Tag>
                )}
              </Space>
              <br />
              <Space style={{ marginLeft: "48px", fontWeight: "500" }}>
                {(patientData?.patient?.age ||
                  patientData?.patient?.gender) && (
                  <Tag>
                    {patientData?.patient?.age} {patientData?.patient?.gender}
                  </Tag>
                )}
                {patientData?.ip_id && <Tag>IP ID: {patientData?.ip_id}</Tag>}
              </Space>
            </Col>

            <Col
              xs={20}
              sm={20}
              md={16}
              lg={12}
              xl={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography style={{ fontWeight: "normal", fontSize: "16px" }}>
                {scalesConfig[scaleKey]?.expandedName}
              </Typography>
            </Col>
            <Col
              xs={2}
              sm={2}
              md={2}
              lg={6}
              xl={6}
              style={{ textAlign: "right" }}
            >
              <CloseOutlined
                onClick={() => {
                  onClose();
                }}
              />
            </Col>
          </Row>
        }
        width="100vw"
        placement="right"
        bodyStyle={{
          backgroundColor: "#F3F5F8",
          maxHeight: "90vh",
          minHeight: "40vw",
          overflowY: "auto",
        }}
        headerStyle={{
          padding: "5px 20px",
          boxShadow: "rgb(34, 34, 34) 0px 2px 4px -4px",
        }}
      >
        <Row
          gutter={[24, 24]}
          style={{ padding: "0 10px", display: "flex", flexWrap: "wrap" }}
        >
          {scaleType === "table" ? (
            <Row justify="center" style={{ width: "100%" }}>
              <Col>
                <ScaleTable scaleKey={scaleKey} data={data} />{" "}
              </Col>
            </Row>
          ) : scaleKey === "ROM" ? (
            Object.entries(scalesConfig[scaleKey]?.subItems || {}).map(
              ([subItemKey, subItemValue]) => {
                const subItemData = data
                  .map((entry) => ({
                    ...entry[subItemKey],
                    date: entry.date,
                  }))
                  .filter((entry) => entry.date);

                return (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    key={subItemKey}
                    style={{ display: "flex" }}
                  >
                    <Card
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Space size="large" direction="vertical">
                        {scalesConfig[scaleKey]?.subItems[subItemKey]?.name}
                        <ProgressChart
                          scaleKey={scaleKey}
                          tags={Object.keys(subItemValue?.keys || {})}
                          chartData={getScaleData(
                            scaleKey,
                            subItemData,
                            subItemKey
                          )}
                          subItemKey={subItemKey}
                          height={300}
                        />
                      </Space>
                    </Card>
                  </Col>
                );
              }
            )
          ) : (
            <Card style={{ width: "100%" }}>
              <ProgressChart
                scaleKey={scaleKey}
                tags={Object.keys(scalesConfig[scaleKey]?.keys || {})}
                chartData={getScaleData(scaleKey, data)}
                height={550}
              />
            </Card>
          )}
        </Row>
      </Drawer>
    );
  }
);

export default ScaleModal;
