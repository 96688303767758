import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { noYesOptions } from "../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const PreviousCounselling = ({ form, context, formData }) => {
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    form.setFieldsValue(formData?.previous_counselling);
    setTimeout(() => {
      setRefresh((prev) => !prev);
    }, 1000);
  }, [formData]);

  return (
    <>
      <DeselectableRadioGroup
        label={"Previous counselling / Psychotherapy detail"}
        name={"previous_counselling"}
        options={noYesOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("previous_counselling") === "yes" && (
        <>
          <Form.Item name={"description"}>
            <Input placeholder="Please enter the details" />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default PreviousCounselling;
