import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button, Skeleton, Empty } from "antd";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { PlusOutlined } from "@ant-design/icons";
import CategoryForm from "./CategoryForm";
import { getCategoryApi } from "../../../../services/service.services";
import CategoryTable from "./CategoryTable";
import { UserContext } from "../../../../context/UserContext";
import { getCookie } from "../../../../helpers/localStorage";
import { Role } from "../../../../constants/defaultKeys";

const Categories = () => {
  const { userDetails } = useContext(UserContext);
  const [isVisible, setIsVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const defaultRole = userDetails?.currRole || getCookie(Role);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    getCategoryApi()
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh]);

  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {data && data?.length > 0 ? (
            <Row gutter={[12, 12]}>
              {defaultRole !== "Facility_Admin" && (
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    onClick={() => setIsVisible(true)}
                    className={btnStyles.lgBtn}
                  >
                    <PlusOutlined /> Categories
                  </Button>
                </Col>
              )}

              <Col span={24}>
                <CategoryTable
                  data={data}
                  setData={setData}
                  refreshPage={refreshPage}
                  loading={loading}
                  userDetails={userDetails}
                />
              </Col>
            </Row>
          ) : (
            <Row className="facilities">
              {defaultRole !== "Facility_Admin" ? (
                <Col span={24} style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => setIsVisible(true)}
                    className={btnStyles.lgBtn}
                  >
                    <PlusOutlined /> Categories
                  </Button>
                </Col>
              ) : (
                <Empty />
              )}
            </Row>
          )}
        </>
      )}

      {isVisible && (
        <CategoryForm
          open={isVisible}
          setOpen={setIsVisible}
          refreshPage={refreshPage}
        />
      )}
    </div>
  );
};

export default Categories;
