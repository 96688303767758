import { Form, InputNumber, Radio, Slider, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { bergScaleOptions } from "../../../Options/physioOptions";
import { notAssessableAndAssessableOptions } from "../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const BergScale = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context);
  const [states, setStates] = useState({});
  useEffect(() => {
    // console.log(
    //   Object.entries(formData?.systemic_examination?.berg_scale)?.filter(
    //     ([key]) => key != "status"
    //   )
    // );
    const { status, ...filteredBerg } =
      formData?.systemic_examination?.berg_scale || {};
    setStates(filteredBerg);
  }, [formData]);

  const calculateScore = () => {
    return Object.values(states)?.reduce((acc, cur) => acc + (cur || 0), 0);
  };

  const handleChange = (value, key) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <div className="physio-scales initial">
      <DeselectableRadioGroup
        label={"Berg Balance Scale"}
        name={["berg_scale", "status"]}
        options={notAssessableAndAssessableOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue(["berg_scale", "status"]) === "assessable" && (
        <>
          <Typography>Score: {calculateScore()}</Typography>

          {bergScaleOptions.map((option) => (
            <Form.Item
              key={option.name}
              label={option.label}
              name={["berg_scale", option.value]}
            >
              {/* <InputNumber
                placeholder={"0 to 4"}
                max={4}
                min={0}
                style={{ width: "100%" }}
                onChange={(value) => handleChange(value, option.value)}
              /> */}
              <Slider
                marks={{ 0: "0", 1: "1", 2: "2", 3: "3", 4: "4" }}
                max={4}
                min={0}
                style={{ width: "70%" }}
                onChange={(value) => handleChange(value, option.value)}
                className="selection-slider"
              />
            </Form.Item>
          ))}
        </>
      )}
    </div>
  );
};

export default BergScale;
