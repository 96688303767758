import { Form, Select, Typography } from "antd";
import React, { useState } from "react";
import {
  moreSubjectiveOptions,
  subjectiveOptions,
} from "../../../Options/functionalAsessmentOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const SubjectiveAssessment = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <Typography>Subjective Assessment</Typography>

      <DeselectableRadioGroup
        name={["subjective_assessment", "vision_status"]}
        label={"Vision"}
        options={subjectiveOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue(["subjective_assessment", "vision_status"]) ===
        "affected" && (
        <>
          <Form.Item name={["subjective_assessment", "vision_affected_status"]}>
            <Select options={moreSubjectiveOptions} style={{ width: "30%" }} />
          </Form.Item>
        </>
      )}

      <DeselectableRadioGroup
        name={["subjective_assessment", "hearing_status"]}
        label={"Hearing"}
        options={subjectiveOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue(["subjective_assessment", "hearing_status"]) ===
        "affected" && (
        <>
          <Form.Item
            name={["subjective_assessment", "hearing_affected_status"]}
          >
            <Select options={moreSubjectiveOptions} style={{ width: "30%" }} />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default SubjectiveAssessment;
