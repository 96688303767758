import React from "react";
import { metabolicStressOptions } from "../../../Options/historyOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const MetabolicStress = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Metabolic Stress"}
        name={"metabolic_stress"}
        options={metabolicStressOptions}
        form={form}
      />
    </>
  );
};

export default MetabolicStress;
