import { Col, DatePicker, Image, Row } from "antd";
import React, { useEffect, useState } from "react";
import PhysioSessions from "./PhysioSessions";
import dayjs from "dayjs";
import calendarImg from "../../../../assets/images/nurseIcons/calender.svg";
import inputStyles from "../../../../styles/Custom/Input.module.css";

const dateFormat = "YYYY-MM-DD";

const PhysioCare = ({ patientData, refreshPage }) => {
  const curDate = dayjs().format(dateFormat);
  const [physioRefresh, setPhysioRefresh] = useState(false);
  const [date, setDate] = useState(curDate);
  const refreshTables = () => {
    setPhysioRefresh((prev) => !prev);
    // refreshPage();
  };
  function disabledDate(current) {
    return current && current > dayjs().endOf("day");
  }
  const onDateChange = (date, dateString) => {
    setDate(date ? dateString : null);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24} className="flex-end">
          <DatePicker
            style={{
              color: "black",
              border: "1px solid #a182e3",
              height: "38px",
              width: "150px",
            }}
            defaultValue={dayjs(curDate)}
            disabledDate={disabledDate}
            className={inputStyles.mdDateInput}
            onChange={onDateChange}
            allowClear={false}
            suffixIcon={<Image width={20} src={calendarImg} preview={false} />}
          />
        </Col>
        <Col span={24}>
          <PhysioSessions
            patientData={patientData}
            date={date}
            physioRefresh={physioRefresh}
            refreshTables={refreshTables}
            refreshPage={refreshPage}
          />
        </Col>
      </Row>
    </>
  );
};

export default PhysioCare;
