import React, { useState } from "react";
import { Form, Input } from "antd";
import { yesNoOptions } from "../../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const Scar = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <DeselectableRadioGroup
        label={"Scar"}
        name={"scar"}
        options={yesNoOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("scar") === "yes" && (
        <Form.Item name="scar_description">
          <Input.TextArea
            rows={3}
            type="text"
            placeholder="Description"
            style={{ width: "100%" }}
          />
        </Form.Item>
      )}
    </>
  );
};

export default Scar;
