import React, { useContext, useEffect, useMemo, useState } from "react";

import CheckListFBYRadioItems from "../../../../../../blocks/FormItems/CheckListFBYRadioItems";
import {
  motorAbnormalRadioItems,
  motorSystemAbnormalOptions,
  motorSystemOptions,
} from "../../../../Options/systemicExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const MotorSystem = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);

  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    const initialCheckboxes = motorSystemAbnormalOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          initialValues?.systemic_examination?.[checkbox.value] || false; // Initialize all checkboxes based on initialValues
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);
  return (
    <>
      <DeselectableRadioGroup
        label={"Motor System"}
        name={"motor_system"}
        options={motorSystemOptions}
        form={form}
        setRefresh={setRefresh}
      />

      {form.getFieldValue("motor_system") === "abnormal" && (
        <div className="subsection-card">
          <CheckListFBYRadioItems
            checkBoxOptions={motorSystemAbnormalOptions}
            radioOptions={motorAbnormalRadioItems}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            form={form}
          />
        </div>
      )}
    </>
  );
};

export default MotorSystem;
