import {
  Col,
  Row,
  Tag,
  Form,
  List,
  Space,
  Avatar,
  Button,
  Select,
  message,
  Skeleton,
  Popconfirm,
  Image,
  Typography,
} from "antd";
import { useContext, useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
} from "@ant-design/icons";

import AddNotes from "./AddNotes";
import useTwitterTimestamp from "../../../hooks/useTwitterTimestamp";
import {
  getPatientMonitoringAPI,
  deletePatientMonitoringAPI,
} from "../../../services/patient.services";
import CustomAvatar from "../../../blocks/Avatars/CustomAvatar";
import filterIcon from "../../../assets/images/nurseIcons/filter.svg";
import NoDataPage from "../../NoDataPages/NoDataPage";
import ProgressNotesIcon from "../../../assets/images/nurseIcons/notes.svg";
import LoadingIndicator from "../../Loaders/LoadingIndicator";
import { UserContext } from "../../../context/UserContext";

function ProgressMonitoring({ patient }) {
  const [form] = Form.useForm();
  const { userDetails } = useContext(UserContext);

  const userId = userDetails?.id;
  const [data, setData] = useState([]);
  const [selectedRole, setSelectedRole] = useState("all");
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState({
    status: false,
    type: "add",
    id: 0,
  });

  async function getData() {
    const params = { role: selectedRole };
    if (selectedRole === "all") {
      delete params.role;
    }
    try {
      const res = await getPatientMonitoringAPI(patient, params);

      setData(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (selectedRole) getData();
  }, [selectedRole]);

  function updateItem(item) {
    form.setFieldsValue(item);
    setIsModalOpen({ status: true, type: "update", id: item?.id });
  }

  function addItem() {
    form.resetFields(["notes"]);
    setIsModalOpen({ status: true, type: "add" });
  }

  async function deleteItem(id) {
    try {
      const res = await deletePatientMonitoringAPI(patient, id);
      message.success(res.data.message);
      getData();
    } catch (error) {
      message.success(error.response.data.message);
    }
  }

  const options = [
    { label: "All", value: "all" },
    { label: "Doctor", value: "Doctor" },
    { label: "Nurse", value: "Nurse" },
    { label: "Physiotherapist", value: "Physiotherapist" },
    { label: "Speech Swallow Therapist", value: "Speech_Swallow_Therapist" },
    { label: "Respiratory Therapist", value: "Respiratory_Therapist" },
    { label: "Nutritionist", value: "Nutritionist" },
    { label: "Psychologist", value: "Psychologist" },
  ];

  function RenderItem(item, index) {
    const timestamp = useTwitterTimestamp(item?.updated_at);
    const showButtons = userId === data?.[index]?.done_by_user_id;
    return (
      <List.Item
        actions={
          showButtons && [
            <Button type="secondary" onClick={() => updateItem(item)}>
              <EditOutlined style={{ color: "blue", fontSize: "14px" }} />
            </Button>,
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this record?"
              onConfirm={async () => await deleteItem(item?.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="secondary">
                <DeleteOutlined style={{ color: "red", fontSize: "14px" }} />
              </Button>
            </Popconfirm>,
          ]
        }
      >
        <Skeleton avatar title={false} loading={loading} active>
          <List.Item.Meta
            avatar={
              <CustomAvatar
                name={`${item?.done_by_first_name} ${item?.done_by_last_name}`}
                indexId={item?.id}
                size="medium"
              />
            }
            title={
              <Space>
                <span>
                  {item?.done_by_first_name} {item?.done_by_last_name}
                </span>
                <Tag color="geekblue" style={{ fontWeight: "normal" }}>
                  {timestamp}
                </Tag>
              </Space>
            }
            description={
              <Typography.Paragraph
              style={{color:"#00000",opacity:0.6}}
                ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
              >
                {item?.notes}
              </Typography.Paragraph>
            }
            // description={item?.notes}
          />
        </Skeleton>
      </List.Item>
    );
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={21} style={{ display: "flex", justifyContent: "end" }}>
        <Space>
          <Image src={filterIcon} preview={false} />
          <Select
            options={options}
            onChange={setSelectedRole}
            defaultValue={selectedRole}
            style={{ minWidth: "230px" }}
          />
          <Button type="primary" onClick={addItem}>
            Add Notes
          </Button>
        </Space>
        {isModalOpen?.status && (
          <AddNotes
            form={form}
            patient={patient}
            getData={getData}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            userDetails={userDetails}
          />
        )}
      </Col>
      <Col lg={{ span: 18, offset: 3 }} md={{ span: 24 }}>
        {loading ? (
          <div>
            <LoadingIndicator
              style={{ marginBottom: "200px" }}
              message="Loading progress data, please wait..."
            />
          </div>
        ) : data?.length > 0 ? (
          <List
            bordered
            dataSource={data}
            itemLayout="horizontal"
            renderItem={RenderItem}
            style={{
              backgroundColor: "#fff",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              maxWidth: "100%",
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
            itemStyle={{
              padding: "10px",
              wordWrap: "break-word",
              whiteSpace: "normal",
              overflow: "hidden",
            }}
          />
        ) : (
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "70vh",
            }}
          >
            <Col span={24} style={{ textAlign: "center" }}>
              <NoDataPage
                title={"Currently, No Progress Notes Have Been Added"}
                subtitle={"Please add new notes or check back later."}
                image={ProgressNotesIcon}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}

export default ProgressMonitoring;
