import React, { useContext, useEffect, useState } from "react";
import { Button, Spin, Row, Col, message } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import {
  generateDischargeSummaryApi,
  getDischargeSummaryApi,
  submitDischargeSummaryApi,
} from "../../../../services/discharge.services";
import { getCookie } from "../../../../helpers/localStorage";
import { Role } from "../../../../constants/defaultKeys";
import useQuery from "../../../../hooks/useQuery";
import { UserContext } from "../../../../context/UserContext";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import dischargeImage from "../../../../assets/images/nurseIcons/dischargeSummary.svg";

export default function DischargeSummary() {
  const { userDetails } = useContext(UserContext);
  const [value, setValue] = useState("");
  const [summaryData, setSummaryData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const params = useQuery();

  const role = getCookie(Role) || userDetails?.currRole;

  useEffect(() => {
    setLoading(true);
    getDischargeSummaryApi(params?.patient, role)
      .then((res) => {
        setSummaryData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [refresh]);

  const handleEditorChange = (content) => {
    setValue(content);
  };

  const handleSubmit = async () => {
    try {
      const response = await submitDischargeSummaryApi(params?.patient, role, {
        html: value,
      });
      message.success(response?.data?.message);
      setRefresh(true);
      console.log("Content submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting content:", error);
      message.error(error?.response?.data?.message);
    }
  };

  const generateSummary = () => {
    setLoading(true);
    generateDischargeSummaryApi(params?.patient, role)
      ?.then((res) => {
        setSummaryData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div style={{ paddingTop: "72px" }}>
      {loading ? (
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <Spin tip="Loading Discharge Summary..." />
          </Col>
        </Row>
      ) : summaryData ? (
        <>
          <Editor
            apiKey="lkphd5y6oow1p3agm73jw0g5smpvkb5h29g5njeznshdrew7"
            init={{
              height: 650,
              plugins: [
                "anchor",
                "autolink",
                "charmap",
                "codesample",
                "emoticons",
                "image",
                "link",
                "lists",
                "media",
                "searchreplace",
                "table",
                "visualblocks",
                "wordcount",
              ],

              content_style: `
              .container {
                max-width: 800px;
                margin: 40px auto;
                padding: 20px;
                background-color: white;
              }
              h1 {
                text-align: center;
                color: #7244d5;
                font-weight: 600;
              }
              h2,
              h3 {
                color: #7244d5;
                margin-top: 20px;
                text-align: left;
              }
              .info-section {
                 display: flex;
                 justify-content: space-between;
                 margin-bottom: 10px;
                 padding-bottom: 20px;
                 border-bottom: 1px solid #e6e6e6;
              }
              .info-section label{
                font-weight: bold;
                color: #333;
              }
              .section {
                margin-bottom: 20px;
                border-bottom: 1px solid #e6e6e6;
              }
              
              .section label {
                font-weight: bold;
                color: #333;
              }
              .section div {
                margin-bottom: 10px;
                font-size: 14px;
              }
              
              table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 10px;
              }
              table,
              th,
              td {
                border: 1px solid #ddd;
              }
              th,
              td {
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f1f1f1;
              }
              td {
                color: #555;
              }
              .footer {
                text-align: center;
                margin-top: 40px;
                font-size: 14px;
                color: #777;
              }
              .footer a {
                color: #7244d5;
                text-decoration: none;
                font-weight: 600;
              }
              .footer a:hover {
                text-decoration: underline;
              }
              .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #e6e6e6;
                padding-bottom: 20px;
              }
              
              .address {
                width: 200px;
                font-size: small;
              }
              .follow-up-advice {
                border-bottom: none;
              }
              
              .custom-list li {
                 margin-bottom: 10px;
              }`,

              toolbar:
                "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
            }}
            initialValue={summaryData}
            onEditorChange={handleEditorChange}
          />
          <div style={{ marginTop: "10px", textAlign: "right" }}>
            <Button onClick={handleSubmit} type="primary" size="large">
              Submit
            </Button>
          </div>
        </>
      ) : (
        <>
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <NoDataPage
                title={"Discharge Summary Not generated"}
                subtitle={"Please click below to generate discharge summary"}
                image={dischargeImage}
              />
              <Button onClick={generateSummary} type="primary" size="large">
                Generate{" "}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
// plugins: [
//   "anchor",
//   "autolink",
//   "charmap",
//   "codesample",
//   "emoticons",
//   "image",
//   "link",
//   "lists",
//   "media",
//   "searchreplace",
//   "table",
//   "visualblocks",
//   "wordcount",
//   "checklist",
//   "mediaembed",
//   "casechange",
//   "export",
//   "formatpainter",
//   "pageembed",
//   "a11ychecker",
//   "tinymcespellchecker",
//   "permanentpen",
//   "powerpaste",
//   "advtable",
//   "advcode",
//   "editimage",
//   "advtemplate",
//   "mentions",
//   "tinycomments",
//   "tableofcontents",
//   "footnotes",
//   "mergetags",
//   "autocorrect",
//   "typography",
//   "inlinecss",
//   "markdown",
//   "importword",
//   "exportword",
//   "exportpdf",
// ],
