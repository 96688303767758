import React from "react";
import { Form, Input, Typography } from "antd";
import { periPulsesVolumeOptions } from "../../../../Options/systemicExamOptions";
import { regularIrregularOptions } from "../../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";
const { Text } = Typography;

const PeripheralPulses = ({ form }) => {
  return (
    <>
      <Text>Peripheral Pulses</Text>

      <DeselectableRadioGroup
        name={"peripheral_volume"}
        options={periPulsesVolumeOptions}
        form={form}
      />

      <DeselectableRadioGroup
        name={"peripheral_regular"}
        options={regularIrregularOptions}
        form={form}
      />
      <Form.Item name="peripheral_desc">
        <Input.TextArea
          rows={2}
          type="text"
          placeholder="Description"
          style={{ width: "70%" }}
        />
      </Form.Item>
    </>
  );
};

export default PeripheralPulses;
