import React, { useState } from "react";
import { Form, InputNumber } from "antd";
import { noYesOptions } from "../../../../Options/commonOptions";
import {
  foleysCatheterTypeOptions,
  urinaryCatheterTypeOptions,
} from "../../../../Options/genExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const UrinaryCatheter = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <DeselectableRadioGroup
        label={"Urinary Catheter"}
        name={"urinary_catheter"}
        options={noYesOptions}
        form={form}
        setRefresh={setRefresh}
      />

      {form.getFieldValue("urinary_catheter") === "yes" && (
        <div className="subsection-card">
          <DeselectableRadioGroup
            name={"urinary_catheter_type"}
            options={urinaryCatheterTypeOptions}
            form={form}
            setRefresh={setRefresh}
          />

          {form.getFieldValue("urinary_catheter_type") ===
            "foleys_catheter" && (
            <DeselectableRadioGroup
              label={"Type"}
              name={"foleys_catheter_type"}
              options={foleysCatheterTypeOptions}
              form={form}
            />
          )}

          <Form.Item label="No of Days on the Catheter" name="days_on_catheter">
            <InputNumber addonBefore="No.of Days" style={{ width: "15%" }} />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default UrinaryCatheter;
