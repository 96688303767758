import React, { useEffect, useContext, useState } from "react";
import { Divider } from "antd";
import ChiefComplaints from "./ChiefComplaints/ChiefComplaints";
import PresentIllness from "./PresentIllness/PresentIllness";
import TreatmentHistory from "./TreatmentHistory/TreatmentHistory";
import PersonalHistory from "./PersonalHistory/PersonalHistory";
import FunctionalCapacity from "./FunctionalCapacity/FunctionalCapacity";
import PastSurgicalHistory from "./PastSurgicalHistory/PastSurgicalHistory";
import Informant from "./Informant/Informant";
import "./HistoryForm.css";
import PastMedicalHistory from "./PastMedicalHistory/PastMedicalHistory";

import KnownAllergies from "./KnownAllergies/KnownAllergies";
import { getDoctorInspectionAPI } from "../../../../services/doctor.services";

const DoctorHistoryForm = ({ form, context, editId, patientData }) => {
  const [refresh, setRefresh] = useState(false);
  const refreshHistory = () => {
    setRefresh((prev) => !prev);
  };
  const { formData } = useContext(context);

  useEffect(() => {
    // const SetHistoryData = async () => {
    form.setFieldsValue(formData?.history);
    // try {
    //   if (editId) {
    //     form.setFieldsValue(formData?.history);
    //   } else {
    //     const response = await getDoctorInspectionAPI(
    //       patientData?.doctor_intial_assessment
    //     );
    //     form.setFieldsValue(response?.data?.data?.history);
    //   }
    //   console.log(form.getFieldsValue());
    // } catch (error) {
    //   console.log("Error while fetching or setting data:", error);
    // }
    // };

    // SetHistoryData();
    setTimeout(() => {
      {
        console.log("history");
        refreshHistory();
      }
    }, 1000);
  }, [editId]);

  return (
    <>
      <Informant context={context} form={form} />
      <Divider />
      <ChiefComplaints context={context} form={form} />
      <Divider />
      <PresentIllness context={context} />
      <Divider />
      <TreatmentHistory context={context} />
      <Divider />
      <PastMedicalHistory context={context} form={form} />
      <Divider />
      <PastSurgicalHistory form={form} context={context} />
      <Divider />
      <PersonalHistory form={form} />
      <Divider />
      <FunctionalCapacity form={form} />
      <Divider />
      <KnownAllergies context={context} />
      <Divider />
    </>
  );
};

export default DoctorHistoryForm;
