import React from "react";
import SpeechSwallowInitialAssessmentProvider from "../IpEMR/SpeechAndSwallowTherapist/InitialInspection/SpeechSwallowInitialAssessmentProvider";
import SpeechSwallowInitialInspection from "../IpEMR/SpeechAndSwallowTherapist/InitialInspection/SpeechSwallowInitialInspection";
import SpeechSwallowReviewFormProvider from "../IpEMR/SpeechAndSwallowTherapist/Review/SpeechSwallowReviewFormProvider";
import SpeechSwallowReview from "../IpEMR/SpeechAndSwallowTherapist/Review/SpeechSwallowReview";

const SpeechAndSwallowTherapistIP = ({ patientData, params, refreshPage }) => {
  return (
    <>
      {params?.type === "ia" || params?.type === "speech_swallow_therapist" ? (
        <SpeechSwallowInitialAssessmentProvider>
          <SpeechSwallowInitialInspection
            refreshPage={refreshPage}
            editId={patientData?.speech_initial_assessment}
            patientData={patientData}
          />
        </SpeechSwallowInitialAssessmentProvider>
      ) : params?.type === "review" ? (
        <SpeechSwallowReviewFormProvider>
          <SpeechSwallowReview
            reviewData={patientData?.speech_and_swallow}
            refreshPage={refreshPage}
            iAssessment={patientData?.speech_initial_assessment}
            patientData={patientData}
          />
        </SpeechSwallowReviewFormProvider>
      ) : null}
    </>
  );
};

export default SpeechAndSwallowTherapistIP;
