import React from "react";
import { Form, Input } from "antd";
import { skullOptions } from "../../../../Options/systemicExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const Skull = ({ form }) => {
  return (
    <>
      {/* <Form.Item label="Skull" name="skull">
        <Radio.Group options={skullOptions} />
      </Form.Item> */}
      <DeselectableRadioGroup
        label={"Skull"}
        name={"skull"}
        options={skullOptions}
        form={form}
      />
      <Form.Item name="skull_description">
        <Input.TextArea
          rows={2}
          type="text"
          placeholder="Description"
          style={{ width: "70%" }}
        />
      </Form.Item>
    </>
  );
};

export default Skull;
