import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Typography,
  Card,
  Divider,
  message,
  Space,
  Dropdown,
  Upload,
  Skeleton,
  Empty,
  Pagination,
} from "antd";
import InvestigationForm from "./InvestigationForm";
import typoStyles from "../../../styles/Custom/Typography.module.css";
import SideMenu from "../../Layout/CustomLayout/SideMenu";
import btnStyles from "../../../styles/Custom/Button.module.css";
import {
  CloudUploadOutlined,
  DownOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import CustomLayout from "../../Layout/CustomLayout";
import {
  handleMasterDownload,
  handleSampleDownload,
  investigationSteps,
} from "../../../helpers/utility";
import {
  getFacilityInvestigationsApi,
  getInvestigationsApi,
  uploadInvestigationsApi,
} from "../../../services/investigation.services";
import InvestigationsTable from "./InvestigationsTable";
import { UserContext } from "../../../context/UserContext";
import { useLocation } from "react-router-dom";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID, Role } from "../../../constants/defaultKeys";

const Investigations = () => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const { userDetails } = useContext(UserContext);
  const [isVisible, setIsVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };
  const defaultRole = userDetails?.currRole || getCookie(Role);
  useEffect(() => {
    setLoading(true);
    if (defaultRole !== "Facility_Admin") {
      getInvestigationsApi(`page=${page}`)
        .then((res) => {
          setData(res?.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [refresh, page]);

  useEffect(() => {
    if (defaultRole === "Facility_Admin") {
      getFacilityInvestigationsApi(
        Number(getCookie(FacilityID)) || userDetails?.currFacility,
        `page=${page}`
      )
        .then((res) => {
          setData(res?.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [params?.facility, userDetails?.currFacility, page, defaultRole]);

  const onChange = async (info) => {
    if (info.file.status === "done") {
      await uploadInvestigationsApi({ investigations: info.file.originFileObj })
        .then((res) => {
          refreshPage();
          message.success(res?.data?.message);
        })
        .catch((err) => {
          console.log(err);
          message.error(err?.response?.data?.message);
        });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const props = {
    beforeUpload: (file) => {
      console.log(file);
      const fileType =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!fileType) {
        message.error(`${file.name} is not a valid file`);
      }
      return fileType ? true : Upload.LIST_IGNORE;
    },
    onChange: onChange,
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const items = [
    {
      label: (
        <Upload
          {...props}
          accept=".xlsx"
          customRequest={dummyRequest}
          showUploadList={false}
        >
          Upload Excel
        </Upload>
      ),
      key: "1",
    },
    {
      label: (
        <div
          className="flex-start"
          style={{ gap: "12px" }}
          onClick={() => {
            setIsVisible(true);
          }}
        >
          <Typography style={{}}>Add Investigation</Typography>
        </div>
      ),
      key: "2",
    },
  ];
  return (
    <CustomLayout
      header={<Typography className={typoStyles.h6}>Investigations</Typography>}
      sideContent={<SideMenu />}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {data && data?.length > 0 ? (
            <>
              <Row gutter={[12, 12]}>
                {defaultRole !== "Facility_Admin" && (
                  <Col span={24} style={{ textAlign: "right" }}>
                    <Dropdown menu={{ items }}>
                      <Button className={btnStyles.lgBtn} type="primary">
                        <Space>
                          <PlusOutlined />
                          Investigation
                          <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                  </Col>
                )}
                <Col span={24}>
                  <InvestigationsTable
                    data={data}
                    refreshPage={refreshPage}
                    userDetails={userDetails}
                  />
                  {data?.total_count > 100 && (
                    <div className="flex-center" style={{ paddingTop: "10px" }}>
                      <Pagination
                        current={page}
                        pageSize={100}
                        total={data?.total_count}
                        onChange={(e) => setPage(e)}
                        showSizeChanger={false}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <>
              {defaultRole === "Facility_Admin" ? (
                <Empty />
              ) : (
                <>
                  <Row
                    style={{
                      display: "flex",

                      justifyContent: "center",
                    }}
                  >
                    <Col span={8} style={{ textAlign: "center" }}>
                      <Card>
                        <Upload
                          {...props}
                          accept=".xlsx"
                          customRequest={dummyRequest}
                          showUploadList={false}
                        >
                          <Button
                            type="primary"
                            className={btnStyles.lgBtn}
                            style={{ marginBottom: "10px" }}
                          >
                            <CloudUploadOutlined /> Upload Excel
                          </Button>
                        </Upload>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Typography.Link onClick={handleMasterDownload}>
                            Download Investigation Master List
                          </Typography.Link>

                          <Typography.Link onClick={handleSampleDownload}>
                            Use an Sample Excel Sheet
                          </Typography.Link>
                          <Divider dashed />
                          <Typography
                            style={{ fontSize: "16px", fontWeight: 600 }}
                          >
                            Some Steps to follow before Going Forward :
                          </Typography>
                          {investigationSteps?.map((step, index) => (
                            <Typography
                              style={{
                                textAlign: "left",
                                fontWeight: 500,
                                fontSize: "16px",
                              }}
                            >
                              {index + 1}. {step}
                            </Typography>
                          ))}
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Col span={8} style={{ textAlign: "center" }}>
                      <Divider>Or</Divider>
                      <Card>
                        <Button
                          type="primary"
                          onClick={() => setIsVisible(true)}
                          className={btnStyles.lgBtn}
                        >
                          <PlusOutlined /> Investigation
                        </Button>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          {isVisible && (
            <InvestigationForm
              open={isVisible}
              setOpen={setIsVisible}
              refreshPage={refreshPage}
            />
          )}
        </>
      )}
    </CustomLayout>
  );
};

export default Investigations;
