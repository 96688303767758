import { Form, Input } from "antd";
import React from "react";
import { coughEffectivenessOptions } from "../../Options/RespiratoryTherapistOptions";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const CoughEffectiveness = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Cough Effectiveness"}
        name={["cough_effectiveness", "status"]}
        options={coughEffectivenessOptions}
        form={form}
      />
      <Form.Item name={["cough_effectiveness", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default CoughEffectiveness;
