import React, { useState } from "react";
import {
  beckAnxietyOptions,
  beckAnxietyScaleOptions,
} from "../../Options/psychologistOptions";
import { Form, List, Typography, Input } from "antd";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const BeckAnxiety = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  const calculateAggregate = () => {
    const scaleValues = form.getFieldValue(["beck_anxiety", "scale"]) || {};
    const sum = Object?.values(scaleValues)?.reduce(
      (acc, cur) => acc + cur || 0,
      0
    );
    form.setFieldsValue({ beck_anxiety: { aggregate: sum } });
    return sum;
  };

  const getAnxietyLevelText = (aggregate) => {
    const interpretation =
      aggregate < 22
        ? "Low Anxiety"
        : aggregate < 36
        ? "Moderate Priority"
        : "Potentially concerning levels of anxiety";
    form.setFieldsValue({ beck_anxiety: { interpretation: interpretation } });
    return interpretation;
  };

  const aggregate = calculateAggregate();
  const anxietyLevelText = getAnxietyLevelText(aggregate);

  return (
    <>
      <Typography.Title level={5}>Beck Anxiety Inventory</Typography.Title>
      {beckAnxietyOptions?.map((option) => (
        <DeselectableRadioGroup
          label={option?.label}
          name={["beck_anxiety", "scale", option.value]}
          options={beckAnxietyScaleOptions}
          form={form}
          setRefresh={setRefresh}
        />
      ))}

      <List bordered>
        <List.Item>
          <Typography.Text strong>
            Aggregate Score: {aggregate}{" "}
          </Typography.Text>
          <Form.Item name={["beck_anxiety", "aggregate"]} hidden>
            <Input />
          </Form.Item>
        </List.Item>
        <List.Item>
          <Typography.Text strong>
            Anxiety Level: {anxietyLevelText}
          </Typography.Text>
          <Form.Item name={["beck_anxiety", "interpretation"]} hidden>
            <Input />
          </Form.Item>
        </List.Item>
      </List>
    </>
  );
};

export default BeckAnxiety;
