import React from "react";
import CommonTable from "./CommonTable";
import dayjs from "dayjs";
import { capitalizeFirstLetter, timeAgo } from "../../../helpers/utility";
import { Typography, Tag, Row, Col } from "antd";
import useTwitterTimestamp from "../../../hooks/useTwitterTimestamp";
import {
  ivFluidOptions,
  ivMedicationOptions,
  nebulisationDoseOptions,
} from "../../IpEMR/Options/careItemsOptions";

const IVFluids = ({ data }) => {
  const customTypography = (title, value) => {
    return (
      <>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontWeight: "lighter",
            fontSize: "10px",
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontWeight: "lighter",
            fontSize: title === "Comments" ? "12px" : "14px",
          }}
        >
          {title === "Comments" ? (
            <Tag color="blue">
              <Typography
                style={{
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  fontSize: title === "Comments" ? "12px" : "16px",
                }}
              >
                {value}
              </Typography>
            </Tag>
          ) : (
            value
          )}
        </Typography>
      </>
    );
  };
  const columns = [
    {
      title: "Time Done",
      dataIndex: "process_time",
      key: "process_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <CommonTable
          data={item?.actions}
          columns={columns}
          titleData={() => (
            <Row gutter={[12, 12]} style={{ padding: "12px" }}>
              <Col span={3}>
                {customTypography(
                  "Dose",
                  item?.data?.fluids === "others"
                    ? `Others - ${item?.data?.do_data?.other_fluid}`
                    : ivFluidOptions?.find(
                        (option) => option.value === item?.data?.fluids
                      )?.label || "-"
                )}
              </Col>

              <Col span={2}>{customTypography("Rate", item?.data?.rate)}</Col>
              <Col span={4}>
                {customTypography(
                  "Medication",
                  item?.drug_name === "others"
                    ? `Others - ${item?.data?.do_data?.other_medication}`
                    : ivMedicationOptions?.find(
                        (option) => option.value === item?.drug_name
                      )?.label || "-"
                )}
              </Col>
              <Col span={4}>
                {customTypography(
                  "Medication Amount",
                  item?.data?.medication_amount
                )}
              </Col>
              <Col span={5}>
                {customTypography("Comments", item?.data?.instructions)}
              </Col>
              <Col span={4}>
                {customTypography(
                  "Advised By",
                  capitalizeFirstLetter(item?.advised_by)
                )}
              </Col>
            </Row>
          )}
        />
      ))}
    </>
  );
};
export default IVFluids;
