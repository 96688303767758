import React from "react";
import { hydrationOptions } from "../../../../Options/genExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const Hydration = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Hydration"}
        name={"hydration"}
        options={hydrationOptions}
        form={form}
      />
    </>
  );
};

export default Hydration;
