import React from "react";
import { cyanosisOptions } from "../../../../Options/genExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const Cyanosis = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Cyanosis"}
        name={"cyanosis"}
        options={cyanosisOptions}
        form={form}
      />
    </>
  );
};

export default Cyanosis;
