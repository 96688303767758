import React, { useContext, useState, useCallback } from "react";
import { AutoComplete, Form, Input, Row, Col, Tag } from "antd";
import { getInvestigationsSearchApi } from "../../../services/investigation.services";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID, Role } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserContext";
import { SearchOutlined } from "@ant-design/icons";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { debounce } from "../../../helpers/utility";

const setSearchOptions = (data) => {
  const searchOptions = data.map((options) => {
    return {
      value: options?.name,
      key: options?.investigation_id,
      name: options?.name,
      label: (
        <Row>
          <Col span={10}>
            <span>{options?.name}</span>
          </Col>
        </Row>
      ),
    };
  });

  return searchOptions;
};
const InvestigationSearch = ({
  searchedText,
  setSearchedText,
  setSelectedId,
}) => {
  const { userDetails } = useContext(UserContext);
  const [data, setData] = useState(null);
  const handleSearch = (searchedValue) => {
    getInvestigationsSearchApi(
      `${searchedValue.toLowerCase()}&facility_id=${
        getCookie(FacilityID) || userDetails?.currFacility
      }`
    )
      .then((res) => {
        setData(setSearchOptions(res?.data));
      })
      .catch((err) => console.log(err));
  };

  const optimizedSearch = useCallback(debounce(handleSearch, 300), []);

  const handleSelect = (value, option) => {
    setSelectedId(option?.key);
  };
  const defaultRole = getCookie(Role) || userDetails?.currRole;
  return (
    <Form.Item name="investigation">
      <AutoComplete
        disabled={["Nurse", "Receptionist"].includes(defaultRole)}
        options={data && data}
        className="search"
        onSearch={optimizedSearch}
        onSelect={handleSelect}
        onChange={(value) => {
          setSearchedText(value);
          optimizedSearch(value);
        }}
        value={searchedText}
      >
        <Input
          size="large"
          prefix={<SearchOutlined />}
          placeholder="Search Investigations"
          className={inputStyles.mdInput}
        />
      </AutoComplete>
    </Form.Item>
  );
};

export default InvestigationSearch;
