import React from "react";
import { Typography } from "antd";
import { yesNoOptions } from "../../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const MeningealSigns = ({ form }) => {
  return (
    <>
      <Typography>Meningeal Signs</Typography>
      <div className="subsection-card">
        <DeselectableRadioGroup
          label={"Neck Stiffness"}
          name={"neck_stiffness"}
          options={yesNoOptions}
          form={form}
        />

        <DeselectableRadioGroup
          label={"Kernig's Sign"}
          name={"kernig_sign"}
          options={yesNoOptions}
          form={form}
        />
      </div>
    </>
  );
};

export default MeningealSigns;
