import { Col, Form, List, Radio, Row, Typography } from "antd";
import React from "react";
import { orientationFieldsOptions } from "../../../../Options/physioOptions";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";

const Orientation = ({ psych = false, setOrientation }) => {
  const handleChange = (value, fieldValue) => {
    setOrientation((prev) => ({
      ...prev,
      [fieldValue]: value,
    }));
  };
  return (
    <>
      <List
        bordered={false}
        style={{ width: psych === true ? "100%" : "600px", padding: "20px" }}
        dataSource={orientationFieldsOptions}
        renderItem={(field) => (
          <List.Item>
            <Row style={{ width: "100%" }} align="middle">
              <Col span={12}>
                <Typography>{field.label}</Typography>
              </Col>
              <Col span={12}>
                <Form.Item
                  key={field.value}
                  name={
                    psych === true
                      ? ["moca", "orientation_data", field.value]
                      : ["orientation_data", field.value]
                  }
                >
                  <Radio.Group
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleChange(e?.target?.value, field.value)
                    }
                  >
                    <Row justify="space-around" align={"middle"}>
                      <Col>
                        <Radio.Button value={"yes"} className="like-btn">
                          <LikeOutlined />
                        </Radio.Button>
                      </Col>
                      <Col>
                        <Radio.Button value={"no"} className="dislike-btn">
                          <DislikeOutlined />
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </>
  );
};

export default Orientation;
