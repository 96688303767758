import { Form, InputNumber, Radio, Typography } from "antd";
import React from "react";
import { yesNoOptions } from "../../../Options/commonOptions";
import {
  catheterOptions,
  incontinenceOptions,
  urineOptions,
} from "../../../Options/functionalAsessmentOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const BladderNeeds = ({ form }) => {
  return (
    <>
      <Typography>Bladder Needs</Typography>
      <div className="subsection-card">
        <DeselectableRadioGroup
          label={"Indicates Need to Void"}
          name={["bladder_needs", "indicates_need_to_void"]}
          options={yesNoOptions}
          form={form}
        />
        <DeselectableRadioGroup
          label={"Incontinence"}
          name={["bladder_needs", "incontinence"]}
          options={incontinenceOptions}
          form={form}
        />
        <DeselectableRadioGroup
          label={"Catheter"}
          name={["bladder_needs", "catheter"]}
          options={catheterOptions}
          form={form}
        />
        <Form.Item label="Since" name={["bladder_needs", "catheter_days"]}>
          <InputNumber addonAfter="Days" style={{ width: "15%" }} />
        </Form.Item>
        <DeselectableRadioGroup
          label={"Color Urine"}
          name={["bladder_needs", "color_urine"]}
          options={urineOptions}
          form={form}
        />
        <Form.Item label="Volume" name={["bladder_needs", "volume"]}>
          <InputNumber addonAfter="ml" />
        </Form.Item>
      </div>
    </>
  );
};

export default BladderNeeds;
