import React, { useState } from "react";
import PainSelection from "../../PhysioScales/VAScaleComponents/PainSelection";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";
import { noYesOptions } from "../../../../Options/commonOptions";

const PainScale = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      {/* <Form.Item label="Pain Scale" name="pain_scale">
        <Radio.Group
          options={painOptions}
          onChange={(e) => setPainSelection(e.target.value)}
        />
      </Form.Item> */}
      <DeselectableRadioGroup
        label={"Pain Scale"}
        name={"pain_scale"}
        options={noYesOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("pain_scale") === "yes" && (
        <PainSelection form={form} />
      )}
      <br />
    </>
  );
};

export default PainScale;
