import React from "react";
import { Form, Typography } from "antd";
import {
  alcoholOptions,
  educationalOptions,
  maritalStatusOptions,
  smokingOptions,
} from "../../../Options/historyOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const { Text } = Typography;

const PersonalHistory = ({ form }) => {
  return (
    <>
      <Text>Personal History</Text>
      <div className="subsection-card">
        <DeselectableRadioGroup
          label="Marital Status"
          name="marital_status"
          options={maritalStatusOptions}
          form={form}
        />
        <DeselectableRadioGroup
          label="Education"
          name="education"
          options={educationalOptions}
          form={form}
        />
        <DeselectableRadioGroup
          label="Smoking"
          name="smoking"
          options={smokingOptions}
          form={form}
        />
        <DeselectableRadioGroup
          label="Alcohol"
          name="alcohol"
          options={alcoholOptions}
          form={form}
        />
      </div>
    </>
  );
};

export default PersonalHistory;
