import React from "react";
import {
  hourlyOptions,
  onceTwiceOptions,
  twiceThriceOptions,
} from "../../Options/physioOptions";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const TreatmentPlan = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Ambulation Schedule"}
        name={"ambulation_schedule"}
        options={twiceThriceOptions}
        form={form}
      />

      <DeselectableRadioGroup
        label={"Recreation Schedule"}
        name={"recreation_schedule"}
        options={onceTwiceOptions}
        form={form}
      />

      <DeselectableRadioGroup
        label={"Position Change Schedule"}
        name={"position_change_schedule"}
        options={hourlyOptions}
        form={form}
      />

      <DeselectableRadioGroup
        label={"Physiotherapy Schedule"}
        name={"physio_schedule"}
        options={onceTwiceOptions}
        form={form}
      />
    </>
  );
};

export default TreatmentPlan;
