import React, { useState } from "react";
import {
  abnormalGaitOptions,
  gaitOptions,
} from "../../../../Options/systemicExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";
import SelectWithOtherInput from "../../../../../../blocks/FormItems/SelectWithOtherInput";

const Gait = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <DeselectableRadioGroup
        label={"Gait"}
        name={"gait"}
        options={gaitOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("gait") === "abnormal" && (
        <SelectWithOtherInput
          name={"gait_details"}
          form={form}
          options={abnormalGaitOptions}
          selectProps={{
            onChange: () => {
              setRefresh((prev) => !prev);
            },
          }}
        />
      )}
    </>
  );
};

export default Gait;
