import React from "react";
import { Card, Table, Typography } from "antd";
import dayjs from "dayjs";

const GrbsTable = ({ data }) => {
  const columns = [
    {
      title: "Time Done",
      dataIndex: "action_time",
      key: "action_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
    { title: "GRBS", dataIndex: "grbs", key: "grbs" },
    { title: "Insulin", dataIndex: "insulin", key: "insulin" },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: 600,
      render: (value) => <Typography>{value}</Typography>,
    },
    {
      title: "Processed By",
      dataIndex: "processed_by_name",
      key: "processed_by_name",
    },
  ];
  return (
    <Table columns={columns} dataSource={data} pagination={false} bordered />
  );
};

export default GrbsTable;
