import { Form, Input, Typography } from "antd";
import React, { useState } from "react";
import {
  deglutitionOptions,
  gussOptions,
} from "../../../../Options/speechSwallowOptions";
import { yesNoOptions } from "../../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const DirectSwallowingTestSolid = ({ form, setSolid }) => {
  const [refresh, setRefresh] = useState(false);

  const calcScore = () => {
    const fValues = form.getFieldValue("direct_swallowing_solid");
    const deglutition = fValues?.deglutition;
    const coughing = fValues?.coughing;
    const drooling = fValues?.drooling;
    const voice_change = fValues?.voice_change;
    const result =
      (deglutition === "swallowing_delayed"
        ? 1
        : deglutition === "swallowing_successful"
        ? 2
        : 0) +
      (coughing === "no" ? 1 : 0) +
      (drooling === "no" ? 1 : 0) +
      (voice_change === "no" ? 1 : 0);
    form.setFieldValue(["direct_swallowing_solid", "score"], result);
    setSolid(result);
    return result;
  };

  const score = calcScore();

  return (
    <>
      <Typography>Direct Swallowing Test (Solid):{score}</Typography>
      <Form.Item
        name={["direct_swallowing_solid", "score"]}
        hidden={true}
        initialValue={score}
      >
        <Input value={score} />
      </Form.Item>
      <div className="subsection-card">
        {gussOptions?.map((option) => (
          <DeselectableRadioGroup
            label={option?.label}
            name={["direct_swallowing_solid", option?.value]}
            options={
              option?.value === "deglutition"
                ? deglutitionOptions
                : yesNoOptions
            }
            form={form}
            setRefresh={setRefresh}
          />
        ))}
      </div>
    </>
  );
};

export default DirectSwallowingTestSolid;
