import React from "react";
import { nmmsOptions } from "../../../../Options/genExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const Icterus = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Icterus"}
        name={"icterus"}
        options={nmmsOptions}
        form={form}
      />
    </>
  );
};

export default Icterus;
