import { Collapse, message } from "antd";
import React, { useEffect, useState } from "react";
import SessionTable from "./SessionTable";
import {
  getPhysioCareAPI,
  getPhysioCareBySession,
  getTherapiesAPI,
  therapiesAPI,
} from "../../../../services/physio.services";
import { useLocation } from "react-router-dom";
import { getPractitionerListApi } from "../../../../services/receptionist.services";
import { userMetaDataApi } from "../../../../services/auth.services";

const { Panel } = Collapse;
const PhysioSessions = ({
  date,
  physioRefresh,
  refreshTables,
  patientData,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const admissionID = params?.patient;
  const facilityID = patientData?.admission?.facility?.id;
  const [therapies, setTherapies] = useState([]);
  const [practitioners, setPractitioners] = useState([]);
  const [session1, setSession1] = useState([]);
  const [session2, setSession2] = useState([]);
  const [session3, setSession3] = useState([]);
  const [userData, setUserData] = useState([]);

  const openEdit = async () => {
    getTherapiesAPI()
      .then((res) => {
        const therapyData = res?.data;
        const therapyOptions = therapyData?.map((option) => ({
          label: option?.name,
          value: option?.id,
        }));
        setTherapies(therapyOptions);
      })
      .catch((error) => {
        console.log(`Error:${error}`);
      });

    getPractitionerListApi(facilityID)
      .then((res) => {
        const pracData = res?.data?.data;
        const uniquePracData = pracData
          ?.filter(
            (user, index, self) =>
              index === self.findIndex((u) => u.user_id === user.user_id)
          )
          ?.map((user) => ({
            label: `${user?.first_name} ${user?.last_name}`,
            value: user?.user_id,
          }));

        setPractitioners(uniquePracData);
      })
      .catch((error) => {
        console.log(`Error:${error}`);
      });

    getPhysioCareBySession(date, admissionID)
      .then((res) => {
        // console.log(res?.data);
        setSession1(res?.data?.session1);
        setSession2(res?.data?.session2);
        setSession3(res?.data?.session3);
      })
      .catch((error) => {
        message.error("Failed to load data");
        console.log(`Error:${error}`);
      });

    // therapiesAPI({
    //   name: "Wheel Chair Training",
    //   description: "Training in wheel chair",
    // })
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((error) => console.log(error));
  };

  useEffect(() => {
    openEdit();
  }, [physioRefresh, admissionID, facilityID, date]);

  useEffect(() => {
    userMetaDataApi().then((res) => {
      setUserData(res?.data);
    });
  }, []);

  return (
    <div className="ia">
      <Collapse defaultActiveKey={["1", "2", "3"]}>
        <Panel header={"Session 1"} key={"1"}>
          <SessionTable
            sessionData={session1}
            setSessionData={setSession1}
            session={"session1"}
            date={date}
            therapies={therapies}
            practitioners={practitioners}
            refreshTables={refreshTables}
            userData={userData?.id}
          />
        </Panel>
        <Panel header={"Session 2"} key={"2"}>
          <SessionTable
            sessionData={session2}
            setSessionData={setSession2}
            session={"session2"}
            date={date}
            therapies={therapies}
            practitioners={practitioners}
            refreshTables={refreshTables}
            userData={userData?.id}
          />
        </Panel>
        <Panel header={"Session 3"} key={"3"}>
          <SessionTable
            sessionData={session3}
            setSessionData={setSession3}
            session={"session3"}
            date={date}
            therapies={therapies}
            practitioners={practitioners}
            refreshTables={refreshTables}
            userData={userData?.id}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default PhysioSessions;
