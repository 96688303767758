import React from "react";
import { functionalOptions } from "../../../Options/historyOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const FunctionalCapacity = ({ form }) => {
  return (
    <DeselectableRadioGroup
      label="Previous Functional Capacity"
      name="functional_capacity"
      options={functionalOptions}
      form={form}
    />
  );
};

export default FunctionalCapacity;
