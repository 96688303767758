import React, { useContext, useRef, useState, useEffect } from "react";
import {
  Drawer,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Button,
  Select,
  message,
  InputNumber,
  Alert,
  Space,
} from "antd";
import btnStyles from "../../styles/Custom/Button.module.css";
import { CloseOutlined } from "@ant-design/icons";
import inputStyles from "../../styles/Custom/Input.module.css";
import { validateConfig } from "../../config/validate.config";
import { UserContext } from "../../context/UserContext";
import { getCookie } from "../../helpers/localStorage";
import { FacilityID } from "../../constants/defaultKeys";
import {
  addPatientApi,
  getPatientDetailsApi,
  updatePatientApi,
} from "../../services/receptionist.services";
import { contactRelationOptions, genderKeys } from "./receptionKeys";
import PatientMobileSearch from "./PatientMobileSearch";

const AddPatientModal = ({ open, setOpen, editData, refresh }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { userDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [patientDetails, setPatientDetails] = useState();

  useEffect(() => {
    if (editData?.id) {
      form.setFieldsValue(editData);
    }
  }, [editData?.id]);

  useEffect(() => {
    if (selectedId) {
      getPatientDetailsApi(selectedId)
        .then((res) => {
          form?.setFieldsValue({
            mobile: res?.data?.mobile,
          });
          setPatientDetails(res?.data);
          // setSearchedText(res?.data?.mobile);
          // setFacilityId(res?.data?.facility);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedId]);

  const onFinish = (values) => {
    setLoading(true);
    const formData = {
      ...values,
      facility: Number(getCookie(FacilityID)) || userDetails?.currFacility,
    };

    if (editData?.id) {
      updatePatientApi(editData?.id, formData)
        .then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          setLoading(false);
          refresh();
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          setLoading(false);
        });
    } else {
      addPatientApi(formData)
        .then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          setLoading(false);
          refresh();
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          setLoading(false);
        });
    }
  };
  return (
    <div>
      <Drawer
        width={"40%"}
        open={open}
        title={
          <Row>
            <Col span={12} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                {editData ? "Edit Basic Details" : "Add Patient Details"}
              </Typography>
            </Col>
            <Col span={12} className="flex-end">
              <CloseOutlined onClick={() => setOpen(false)} />
            </Col>
          </Row>
        }
        footer={
          <div className="flex-end" style={{ gap: "24px" }}>
            <Form.Item>
              <Button
                type="primary"
                ghost
                className={btnStyles.mdBtn}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                htmlType="submit"
                type="primary"
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.submit();
                  }
                }}
              >
                {editData ? "Save" : "Submit"}
              </Button>
            </Form.Item>
          </div>
        }
        onClose={() => setOpen(false)}
        closeIcon={null}
      >
        <Form
          layout="vertical"
          // requiredMark={false}
          onFinish={onFinish}
          form={form}
          ref={formRef}
        >
          <PatientMobileSearch
            searchedText={searchedText}
            setSearchedText={setSearchedText}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            form={form}
          />
          {selectedId && !editData?.id && (
            <Alert
              type="info"
              showIcon
              message={`
              A patient named ${
                patientDetails?.first_name + " " + patientDetails?.last_name
              } is already
               registered with this mobile number. If you would like to add a new patient with the same number, 
               please continue and select the relationship to the existing patient.`}
            ></Alert>
          )}
          {selectedId && !editData?.id && (
            <Form.Item label="Relation with existing patient" name="relation">
              <Select
                options={contactRelationOptions}
                style={{ width: "300px" }}
                className={inputStyles.mdSelect}
              />
            </Form.Item>
          )}
          <Space>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={validateConfig.isRequired("First Name")}
            >
              <Input
                className={inputStyles.mdInput}
                style={{ width: "180px" }}
                placeholder="Enter first name"
              />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={validateConfig.isRequired("Last Name")}
            >
              <Input
                className={inputStyles.mdInput}
                style={{ width: "180px" }}
                placeholder="Enter first name"
              />
            </Form.Item>
          </Space>
          <Space>
            <Form.Item
              label="Age"
              name="age"
              rules={validateConfig.isRequired("Age")}
            >
              <InputNumber
                placeholder="Enter age"
                className={inputStyles.mdNumberInput}
                style={{ width: "180px" }}
              />
            </Form.Item>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "Please select gender" }]}
            >
              <Select
                allowClear
                placeholder="Select Gender"
                className={inputStyles.mdSelect}
                style={{ width: "180px" }}
                options={genderKeys}
              />
            </Form.Item>
          </Space>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ type: "email", message: "Please enter a valid email" }]}
          >
            <Input
              placeholder="Enter email"
              className={inputStyles.mdInput}
              style={{ width: "300px" }}
            />
          </Form.Item>

          <Form.Item
            label="Address"
            name="address"
            rules={validateConfig.isRequired("Address")}
          >
            <Input.TextArea
              placeholder="Enter address"
              className={inputStyles.mdInput}
              style={{ width: "300px" }}
            />
          </Form.Item>
          <Form.Item label="Notes" name="notes">
            <Input.TextArea
              placeholder="Enter notes"
              className={inputStyles.mdInput}
              style={{ width: "300px" }}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default AddPatientModal;
