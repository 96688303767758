import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Image,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { getNurseCommonData } from "../../../services/nurse.services";
import useQuery from "../../../hooks/useQuery";
import inputStyles from "../../../styles/Custom/Input.module.css";
import StatMedicines from "./StatMedicines";
import ScheduledMedicine from "./ScheduledMedicine";
import calenderIcon from "../../../assets/images/nurseIcons/calender.svg";
import NoDataPage from "../../NoDataPages/NoDataPage";
import medicineBoxIcon from "../../../assets/images/nurseIcons/medicinebox.svg";
import LoadingIndicator from "../../Loaders/LoadingIndicator";
import dayjs from "dayjs";
import MedicationHistoryChart from "../MedicationsIp/MedicationHistoryChart";
const dateFormat = "YYYY-MM-DD";

const NurseMedicine = ({ patientData }) => {
  const curDate = dayjs().format(dateFormat);
  const params = useQuery();
  const [medData, setMedData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [date, setDate] = useState(curDate);
  const [statData, setStatData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [historyModal, setHistoryModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const res = await getNurseCommonData(
          params?.patient,
          "medication",
          date
        );
        setMedData(
          res?.data?.data?.filter(
            (data) =>
              data?.frequency !== "If_Required" &&
              data?.frequency !== "Immediately"
          )
        );
        setStatData(
          res?.data?.data?.filter(
            (data) =>
              data?.frequency === "If_Required" ||
              data?.frequency === "Immediately"
          )
        );
        setLoading(false);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [refresh, date]);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  const disabledDate = (current) => current && current > dayjs().endOf("day");

  const onDateChange = (date, dateString) => {
    setDate(date ? dateString : null);
  };

  const extractTimeSlots = (data = []) => [
    ...new Set(
      data?.flatMap((medicine) => medicine.actions.map((action) => action.hour))
    ),
  ];

  const timeSlots = medData?.length > 0 ? extractTimeSlots(medData) : [];
  const sortedTimeSlots = timeSlots.sort((a, b) => a - b);
  return (
    <div>
      <div className="flex-end" style={{ marginBottom: "10px" }}>
        <Space>
          <DatePicker
            style={{
              // color: "#8c8c8c",
              border: "1px solid #a182e3",
              height: "38px",
              width: "150px",
            }}
            disabledDate={disabledDate}
            className={inputStyles.mdDateInput}
            onChange={onDateChange}
            defaultValue={dayjs(curDate)}
            allowClear={false}
            suffixIcon={<Image width={20} src={calenderIcon} preview={false} />}
          />
          <Button
            className="inv-btn"
            style={{ height: "38px" }}
            type="primary"
            // className={btnStyles.mdBtn}
            onClick={() => setHistoryModal(true)}
          >
            Medication History
          </Button>
        </Space>
      </div>

      {/* {statData?.length > 0 && (
        <StatMedicines statData={statData} refreshPage={refreshPage} />
      )} */}
      {loading ? (
        <LoadingIndicator message={"Loading medication data, please wait..."} />
      ) : medData?.length > 0 || statData?.length > 0 ? (
        <>
          <ScheduledMedicine
            medData={medData}
            statData={statData}
            sortedTimeSlots={sortedTimeSlots}
            refreshPage={refreshPage}
            date={date}
            curDate={curDate}
          />
          {historyModal && (
            <MedicationHistoryChart
              open={historyModal}
              setOpen={setHistoryModal}
              data={[...medData, ...statData]}
              params={params}
              patientData={patientData?.admission}
            />
          )}
        </>
      ) : (
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "70vh",
          }}
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <NoDataPage
              title="No Medications Found"
              subtitle="No medications found for the selected date."
              image={medicineBoxIcon}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default NurseMedicine;
