import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AutoComplete, Col, Row, Tag, Typography } from "antd";

import { debounce } from "../../../helpers/utility";
import { patientSearchApi } from "../../../services/search.service";
import { UserContext } from "../../../context/UserContext";
import { SearchOutlined } from "@ant-design/icons";
import { getCookie } from "../../../helpers/localStorage";
import { Role } from "../../../constants/defaultKeys";

const searchResult = (data) =>
  data?.map((item, idx) => {
    return {
      value: item?.id,
      label: (
        <Row key={idx} gutter={[16, 16]} >
        <Col xs={24} sm={8} >
          <Typography.Paragraph ellipsis style={{ marginBottom: "0px" }}>
            {item?.patient_name}
          </Typography.Paragraph>
        </Col>
        <Col xs={24} sm={10} >
          <Tag>IP ID: {item?.ip_id}</Tag>
        </Col>
        <Col
          xs={24}
          sm={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            textAlign: "right",
          }}
        >
          <Tag color={item?.is_active ? "blue" : "cyan"}>
            {item?.is_active ? "Active" : "Discharged"}
          </Tag>
        </Col>
      </Row>
      
      ),
    };
  });

function Search({ selectedFacility }) {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const { userDetails } = useContext(UserContext);

  const defaultRole = userDetails?.currRole || getCookie(Role);
  const role = defaultRole.toLowerCase().replaceAll("_", "-");
  async function handleSearch(value) {
    if (value?.length >= 1)
      try {
        const response = await patientSearchApi({
          search: value,
          facility: selectedFacility,
        });
        setOptions(value ? searchResult(response?.data) : []);
      } catch (error) {
        console.log(error);
      }
  }
console.log("Options ", options);
  function onSelect(value) {
    if(role === "nurse"){
      navigate(`/${role}/home?patient=${value}&type=ia`);

    }else{
      navigate(`/${role}/home?patient=${value}&type=landing`);

    }
    setValue("");
  }

  return (
    <AutoComplete
      suffixIcon={<SearchOutlined />}
      value={value}
      options={options}
      onSelect={onSelect}
      onChange={setValue}
      style={{ width: "600px" }}
      onSearch={debounce(handleSearch, 300)}
      placeholder="Patient Name or Phone Number or Bed No or IP Id"
    />
  );
}

export default Search;
