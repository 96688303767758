import React, { useState } from "react";
import { Table, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import ServiceForm from "./ServiceForm";
import { formatToINR } from "../../../../helpers/utility";
import { getCookie } from "../../../../helpers/localStorage";
import { Role } from "../../../../constants/defaultKeys";
const { Paragraph, Text } = Typography;

const ServiceTable = ({ data, refreshPage, userDetails, loading }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [editListData, setEditListData] = useState(null);

  const defaultRole = userDetails?.currRole || getCookie(Role);
  const columns = [
    {
      title: "Service Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a?.name.localeCompare(b?.name),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => {
        const descA = a?.description || "";
        const descB = b?.description || "";
        return descA?.localeCompare(descB);
      },
      render: (value) => (
        <Paragraph
          ellipsis={{
            rows: 2,
            expandable: true,
            symbol: "more",
          }}
          // style={{ width: 320 }}
        >
          {value}
        </Paragraph>
      ),
    },
    {
      title: "Category",
      dataIndex: ["category", "name"],
      key: "category",
      sorter: (a, b) => a?.category?.name?.localeCompare(b?.category?.name),
    },
    {
      title: "Cash Price (Including tax)",
      dataIndex: "cash_price",
      key: "cash_price",
      sorter: (a, b) => a?.cash_price - b?.cash_price,
      render: (value) => (value ? formatToINR(value) : "-"),
    },
    {
      title: "Insurance Price (Including tax)",
      dataIndex: "insurance_price",
      key: "insurance_price",
      sorter: (a, b) => a?.insurance_price - b?.insurance_price,
      render: (value) => (value ? formatToINR(value) : "-"),
    },
    ...(defaultRole === "Facility_Admin"
      ? []
      : [
          {
            title: "Action",
            render: (_, record) => (
              <EditOutlined
                onClick={() => {
                  setIsVisible(true);
                  setEditListData(record);
                }}
              />
            ),
          },
        ]),
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        scroll={{ y: "calc(100vh - 290px)" }}
        loading={loading}
      />
      {isVisible && (
        <ServiceForm
          open={isVisible}
          setOpen={setIsVisible}
          refreshPage={refreshPage}
          editListData={editListData}
        />
      )}
    </>
  );
};

export default ServiceTable;
