import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  nutritionistInspectionAPI,
  updateNutritionistInspectionAPI,
} from "../../../../services/nutritionist.services";
import { NutritionistInitialContext } from "../../InspectionForms/HistoryForm/NutritionistInitialAssessmentProvider";
import ClinicalImpressionForm from "../../InspectionForms/ClinicalImpressionForm/ClinicalImpressionForm";
import NutritionistHistoryForm from "../../InspectionForms/HistoryForm/NutritionistHistoryForm";
import NutritionistGoalsForm from "../../InspectionForms/Goals/NutritionistGoalsForm";
import NutritionistDietAdvice from "../../InspectionForms/DietAdvice/NutritionistDietAdvice";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import NutritionistClinicalEvaluationForm from "../../InspectionForms/ClinicalEvaluation/NutritionistClinicalEvaluationForm";
import { LeftOutlined } from "@ant-design/icons";
import bedImg from "../../../../assets/images/patientIcons/bed.svg";
import dayjs from "dayjs";
const NutritionistInitialInspectionForm = ({
  setVisible,
  refreshPage,
  initialRefresh,
  editId,
  patientData,
  setConfirmModal,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [clinicalForm] = Form.useForm();
  const [historyForm] = Form.useForm();
  const [clinicalEvaluationForm] = Form.useForm();
  const [goalsForm] = Form.useForm();
  const [dietForm] = Form.useForm();
  const [activeTab, setActiveTab] = useState("1");
  const { formData } = useContext(NutritionistInitialContext);
  const [isDirty, setIsDirty] = useState(false);

  const handleSubmit = async (values, flag) => {
    try {
      if (editId) {
        await updateNutritionistInspectionAPI(editId, values);
        initialRefresh();
      } else {
        await nutritionistInspectionAPI(values);
        refreshPage();
        initialRefresh();
      }

      message.success(`${flag} submitted successfully`);
      if (activeTab === "5") {
        setVisible(false);
      } else {
        const nextTab = (parseInt(activeTab) + 1).toString();
        setActiveTab(nextTab);
      }
      setIsDirty(false);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const onClinicalSubmit = (values) => {
    handleSubmit(
      { clinical_diagnosis: values, admission_id: params?.patient },
      "Clinical Impression/Diagnosis"
    );
  };

  const onHistorySubmit = (values) => {
    handleSubmit({ history: values, admission_id: params?.patient }, "History");
  };
  const onClinicalEvaluationSubmit = (values) => {
    handleSubmit(
      {
        clinical_evaluation: values,
        admission_id: params?.patient,
      },
      "Clinical Evaluation"
    );
  };

  const onGoalsSubmit = (values) => {
    handleSubmit(
      {
        goals: values,
        admission_id: params?.patient,
      },
      "Goals"
    );
  };
  const onDietSubmit = (values) => {
    handleSubmit(
      {
        diet_advice: values,
        admission_id: params?.patient,
        // intial_assessment_done: true,
      },
      "Diet Advice"
    );
  };

  const getFormKey = () => {
    switch (activeTab) {
      case "1":
        return clinicalForm.submit();
      case "2":
        return historyForm.submit();
      case "3":
        return clinicalEvaluationForm.submit();
      case "4":
        return goalsForm.submit();
      case "5":
        return dietForm.submit();
      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    if (isDirty) {
      Modal.confirm({
        title: "Unsaved changes",
        content:
          "You have unsaved changes. Are you sure you want to save these changes?",
        onOk: () => {
          switch (activeTab) {
            case "1":
              clinicalEvaluationForm.validateFields().then((values) => {
                setIsDirty(false);
                onClinicalSubmit(values);
                setActiveTab(key);
              });
            case "2":
              historyForm.validateFields().then((values) => {
                setIsDirty(false);
                onHistorySubmit(values);
                setActiveTab(key);
              });
              break;
            case "3":
              clinicalEvaluationForm.validateFields().then((values) => {
                setIsDirty(false);
                onClinicalEvaluationSubmit(values);
                setActiveTab(key);
              });
              break;
            case "4":
              goalsForm.validateFields().then((values) => {
                setIsDirty(false);
                onGoalsSubmit(values);
                setActiveTab(key);
              });
              break;
            case "5":
              dietForm.validateFields().then((values) => {
                setIsDirty(false);
                onDietSubmit(values);
                setActiveTab(key);
              });
            default:
              setActiveTab(key);
          }
        },
        onCancel: () => {
          setActiveTab(key);
          setIsDirty(false);
        },
      });
    } else {
      setActiveTab(key);
    }
  };

  const onFormValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <Space>
            <LeftOutlined onClick={() => setConfirmModal(true)} />
            <Typography
              style={{ color: "#101828", fontSize: "16px", fontWeight: 600 }}
            >
              {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}{" "}
            </Typography>
            {patientData?.admission?.bed?.bed_no && (
              <Tag color="orange">
                <Image src={bedImg} preview={false} />{" "}
                {patientData?.admission?.bed?.bed_no}
              </Tag>
            )}
          </Space>
          <br />
          <Space style={{ marginTop: "10px" }}>
            <Tag>
              {patientData?.admission?.patient?.age}{" "}
              {patientData?.admission?.patient?.gender}
            </Tag>
            <Tag>IP ID: {patientData?.admission?.ip_id}</Tag>
          </Space>
        </Col>
        <Col span={12}>
          <Tabs
            activeKey={activeTab}
            items={[
              { key: "1", label: "Clinical Impression/ Diagnosis" },
              { key: "2", label: "History" },
              { key: "3", label: "Clinical Evaluation" },
              { key: "4", label: "Goals" },
              { key: "5", label: "Diet Advice" },
            ]}
            onChange={handleTabChange}
          />
        </Col>
        <Col span={6} className="flex-end">
          <Row gutter={[8, 8]} style={{ flexDirection: "column" }}>
            <Col>
              {formData?.submitted_by_first_name && (
                <Tag>
                  Done by: {formData?.submitted_by_first_name}{" "}
                  {formData?.submitted_by_last_name}
                </Tag>
              )}
            </Col>
            <Col>
              <Tag>
                {dayjs(formData?.updated_at || new Date()).format("DD/MM/YYYY")}
              </Tag>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider style={{ margin: "8px 0px" }} />

      <div style={{ height: "90%", overflow: "hidden", position: "relative" }}>
        <div
          style={{
            height: "calc(100% - 38px)",
            overflowY: "auto",
            backgroundColor: "#f6f5ff",
            borderRadius: "5px",
            border: "1px solid #e6e6e6",
          }}
        >
          {activeTab === "1" && (
            <Form
              id="clinical-diagnosis-form"
              form={clinicalForm}
              layout="vertical"
              onFinish={onClinicalSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <ClinicalImpressionForm
                form={clinicalForm}
                formData={formData}
                context={NutritionistInitialContext}
                editId={editId}
                patientData={patientData}
              />
            </Form>
          )}
          {activeTab === "2" && (
            <Form
              id="history-form"
              form={historyForm}
              layout="vertical"
              onFinish={onHistorySubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <NutritionistHistoryForm
                form={historyForm}
                formData={formData}
                editId={editId}
                patientData={patientData}
              />
            </Form>
          )}
          {activeTab === "3" && (
            <Form
              id="clinical-evaluation-form"
              form={clinicalEvaluationForm}
              layout="vertical"
              onFinish={onClinicalEvaluationSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <NutritionistClinicalEvaluationForm
                form={clinicalEvaluationForm}
                formData={formData}
              />
            </Form>
          )}
          {activeTab === "4" && (
            <Form
              id="goals-form"
              form={goalsForm}
              layout="vertical"
              onFinish={onGoalsSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData?.goals}
              className="ip-forms"
            >
              <NutritionistGoalsForm form={goalsForm} formData={formData} />
            </Form>
          )}
          {activeTab === "5" && (
            <Form
              id="diet-advice-form"
              form={dietForm}
              layout="vertical"
              onFinish={onDietSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <NutritionistDietAdvice form={dietForm} formData={formData} />
            </Form>
          )}
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              background: "#fff",
              paddingTop: "10px",
              borderTop: "1px solid #e8e8e8",
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => getFormKey()}
              type="primary"
              className={btnStyles.mdBtn}
            >
              {activeTab === "5" ? "Done" : " Save & Proceed"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NutritionistInitialInspectionForm;
