import React from "react";
import { Typography, Tag, Row, Col } from "antd";
import { capitalizeFirstLetter } from "../../../../helpers/utility";
import { bloodTypeOptions } from "../../../IpEMR/Options/careItemsOptions";
import dayjs from "dayjs";
import { respiratorySupportOptions } from "../../../IpEMR/Options/careItemsOptions";
import CommonTable from "../CommonTable";

const RespiratorySupport = ({ data }) => {
  const columns = [
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (value) => value,
    },
    {
      title: "Start Time",
      dataIndex: "process_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];
  const customTypography = (title, value) => {
    return (
      <>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontWeight: "lighter",
            fontSize: "10px",
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontWeight: "lighter",
            fontSize: title === "Comments" ? "12px" : "14px",
          }}
        >
          {title === "Comments" ? <Tag color="blue">{value}</Tag> : value}
        </Typography>
      </>
    );
  };

  const customHeader = (item) => {
    const drugName = item?.drug_name;
    return (
      <>
        {drugName === "mechanical_ventilation" ||
        drugName === "cpap_support" ||
        drugName === "bipap_support" ? (
          <Row>
            <Col span={5}>
              {customTypography(
                "Type",
                respiratorySupportOptions?.respiratorySupport?.find(
                  (option) => option.value === drugName
                )?.label || "-"
              )}
            </Col>
            <Col span={3}>
              {drugName === "mechanical_ventilation" &&
                customTypography(
                  "Mode",
                  item?.data?.do_data?.[`${drugName}_mode`]
                    ?.toUpperCase()
                    .replace(/_/g, "-")
                )}
            </Col>
            <Col span={3}>
              {drugName === "bipap_support"
                ? customTypography(
                    "EPAP",
                    item?.data?.do_data?.[`${drugName}_epap`]
                  )
                : customTypography(
                    "PEEP",
                    item?.data?.do_data?.[`${drugName}_peep`]
                  )}
            </Col>
            <Col span={3}>
              {drugName === "bipap_support"
                ? customTypography(
                    "IPAP",
                    item?.data?.do_data?.[`${drugName}_ipap`]
                  )
                : customTypography(
                    `${drugName === "cpap_support" ? "PS above PEEP" : "TV"} `,
                    item?.data?.do_data?.[`${drugName}_ps_peep`]
                  )}
            </Col>
            <Col span={3}>
              {customTypography("RR", item?.data?.do_data?.[`${drugName}_rr`])}
            </Col>
            <Col span={3}>
              {customTypography(
                "Fio2",
                item?.data?.do_data?.[`${drugName}_fio2`]
              )}
            </Col>
            <Col span={4}>
              {customTypography(
                "Advised By",
                capitalizeFirstLetter(item?.advised_by)
              )}
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={8}>
              {customTypography(
                "Type",
                respiratorySupportOptions?.respiratorySupport?.find(
                  (option) => option.value === item?.drug_name
                )?.label || "-"
              )}
            </Col>
            <Col span={8}>
              {customTypography(
                "Rate",
                item?.data?.do_data?.[`respiratory_support_type_${drugName}`]
              )}
            </Col>
            <Col span={8}>
              {customTypography(
                "Advised By",
                capitalizeFirstLetter(item?.advised_by)
              )}
            </Col>
          </Row>
        )}
      </>
    );
  };
  return (
    <div>
      {data?.map((item) => (
        <>
          <CommonTable
            data={item?.actions.flat()}
            columns={columns}
            titleData={() => customHeader(item)}
          />
        </>
      ))}
    </div>
  );
};

export default RespiratorySupport;
