import ProjectRoutes from "./routes/Routes";
import * as Sentry from "@sentry/react";
import "./App.css";
import ErrorPage from "./components/ErrorPages/ErrorPage";

function App() {
  function FallbackComponent() {
    return <ErrorPage />;
  }

  return (
    <Sentry.ErrorBoundary fallback={<FallbackComponent />}>
      <ProjectRoutes />
    </Sentry.ErrorBoundary>
  );
}

export default App;
