import { Collapse } from "antd";
import React from "react";
import EyeCommonCols from "./EyeCommonCols";

const EyeCare = ({ data }) => {
  return (
    <div>
      <Collapse
        expandIconPosition="right"
        defaultActiveKey={["1", "2", "3", "4", "5"]}
      >
        <Collapse.Panel header="Saline Wash" key="1">
          <EyeCommonCols data={data?.data?.eye_care_saline_wash} />
        </Collapse.Panel>
        <Collapse.Panel header="Eye Drops" key="2">
          <EyeCommonCols data={data?.data?.eye_care_eye_drops} />
        </Collapse.Panel>
        <Collapse.Panel header="Eye Gel" key="3">
          <EyeCommonCols data={data?.data?.eye_care_eye_gel_ointment} />
        </Collapse.Panel>
        <Collapse.Panel header="Tarsorrhaphy" key="4">
          <EyeCommonCols data={data?.data?.eye_care_trasorrhaphy} />
        </Collapse.Panel>
        <Collapse.Panel
          header="Eye Guard
"
          key="5"
        >
          <EyeCommonCols data={data?.data?.eye_care_eye_patching_guard} />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default EyeCare;
