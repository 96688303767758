import React from "react";
import { builtOptions } from "../../../../Options/genExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const Built = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        options={builtOptions}
        name={"built"}
        label={"Built"}
        form={form}
      />
    </>
  );
};

export default Built;
