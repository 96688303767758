import React from "react";
import CommonTable from "../CommonTable";
import dayjs from "dayjs";

const DailyCommonCols = ({ data }) => {
  const columns = [
    {
      title: "Time Done",
      dataIndex: "process_time",
      key: "process_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
    {
      title: "Time Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];
  return (
    <div>
      <CommonTable data={data} columns={columns} />
    </div>
  );
};

export default DailyCommonCols;
