import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Drawer,
  Typography,
  Steps,
  Space,
  Tag,
  Image,
  Tabs,
} from "antd";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import InitialInspectionForm from "./InitialInspectionForm";
import InitialInspectionSummary from "./InitialInspectionSummary";
import { DoctorInitialContext } from "../../InspectionForms/HistoryForm/DoctorHistoryFormProvider";
import { getDoctorInspectionAPI } from "../../../../services/doctor.services";
import { CloseOutlined, EditOutlined, LeftOutlined } from "@ant-design/icons";
import CareItems from "../CareItems/CareItems";
import InvestigationsInitial from "../../Investigations/InvestigationsInitial";
import MedicinesInitial from "../MedicinesInitial.js/MedicinesInitial";
import ClosePopup from "./ClosePopup";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import bedImg from "../../../../assets/images/patientIcons/bed.svg";
import assessmentIcon from "../../../../assets/images/nurseIcons/assessment.svg";
import { initialTabs } from "../../../../helpers/medicineKeys";
import { getCookie } from "../../../../helpers/localStorage";
import { Role } from "../../../../constants/defaultKeys";
import { UserContext } from "../../../../context/UserContext";

const items = [
  {
    title: "Initial Assessment",
  },
  {
    title: "Care Items",
  },
  {
    title: "Initial Medicine",
  },
  {
    title: "Initial Investigation",
  },
];
const InitialInspection = ({ refreshPage, editId, patientData }) => {
  const { userDetails } = useContext(UserContext);
  const [visible, setVisible] = useState(false);
  const { formData, setFormData } = useContext(DoctorInitialContext);
  const [initialRefresh, setInitialRefresh] = useState(false);
  const [current, setCurrent] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const setInitialAssessmentRefresh = () => {
    setInitialRefresh((prev) => !prev);
  };

  const openEdit = async () => {
    if (editId) {
      try {
        const response = await getDoctorInspectionAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    }
  };

  useEffect(() => {
    openEdit();
  }, [editId, initialRefresh]);

  // const onChange = (value) => {
  //   setCurrent(value);
  // };
  // console.log(patientData);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const editRole = getCookie(Role) || userDetails?.currRole;

  return (
    <>
      {editRole === "Doctor" ? (
        <>
          {editId ? (
            <Row gutter={[12, 8]} style={{ marginTop: "0px" }}>
              <Col span={24} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    setVisible(true);
                    setAddModal(false);
                  }}
                  className={`${btnStyles.lgBtn} inv-btn`}
                  style={{ fontWeight: 500 }}
                >
                  <EditOutlined />
                  Edit
                </Button>
              </Col>
              <Col span={24} style={{ textAlign: "center" }}>
                <InitialInspectionSummary editId={editId} />
              </Col>
            </Row>
          ) : (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "80vh",
              }}
            >
              <Col span={24} style={{ textAlign: "center" }}>
                <NoDataPage
                  title={"Initial Assessment Not Yet Submitted "}
                  subtitle={"Kindly, upload an initial assessment here"}
                  image={assessmentIcon}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    setVisible(true);
                    setAddModal(true);
                  }}
                  style={{ fontWeight: 500 }}
                  className={`${btnStyles.lgBtn} btn-scale`}
                >
                  Initial Assessment Form
                </Button>
              </Col>
            </Row>
          )}
          {visible && (
            <Drawer
              styles={{ body: { overflow: "hidden", paddingTop: "0" } }}
              open={visible}
              width="100%"
              title={
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "20px",
                    }}
                  >
                    <LeftOutlined
                      style={{ fontSize: "20px" }}
                      onClick={() => setConfirmModal(true)}
                    />
                  </Col>
                  <Col span={4}>
                    <Space>
                      <Typography
                        style={{
                          color: "#101828",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      >
                        {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}{" "}
                      </Typography>
                      {patientData?.admission?.bed?.bed_no && (
                        <Tag color="orange" style={{ fontWeight: 500 }}>
                          <Image src={bedImg} preview={false} />{" "}
                          {patientData?.admission?.bed?.bed_no}
                        </Tag>
                      )}
                    </Space>
                    <br />
                    <Space style={{ marginTop: "10px", fontWeight: 500 }}>
                      <Tag>
                        {patientData?.admission?.patient?.age}{" "}
                        {patientData?.admission?.patient?.gender}
                      </Tag>
                      <Tag>IP ID: {patientData?.admission?.ip_id}</Tag>
                    </Space>
                  </Col>
                  {!formData?.care_items_generated || addModal ? (
                    <Col
                      span={16}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Steps
                        current={current}
                        items={items}
                        //  onChange={onChange}
                      />
                    </Col>
                  ) : (
                    <Col
                      span={16}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tabs
                        style={{ fontWeight: "500" }}
                        activeKey={activeTab}
                        items={initialTabs}
                        onChange={handleTabChange}
                      />
                    </Col>
                  )}
                  {/* <Col span={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "20px",
                  justifyContent: "flex-end",
                }}
              >
                
                <CloseOutlined         
                  style={{ fontSize: "20px" }}
                  onClick={() => setConfirmModal(true)}
                />
              </Col> */}
                </Row>
              }
              footer={null}
              onClose={() => setVisible(false)}
              closeIcon={null}
              keyboard={false}
            >
              {!formData?.care_items_generated || addModal ? (
                <>
                  {current === 0 && (
                    <InitialInspectionForm
                      visible={visible}
                      setVisible={setVisible}
                      refreshPage={refreshPage}
                      initialRefresh={setInitialAssessmentRefresh}
                      editId={editId}
                      setCurrent={setCurrent}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  )}
                  {current === 1 && (
                    <CareItems
                      setCurrent={setCurrent}
                      initialRefresh={setInitialAssessmentRefresh}
                    />
                  )}
                  {current === 2 && (
                    <MedicinesInitial setCurrent={setCurrent} />
                  )}
                  {current === 3 && (
                    <InvestigationsInitial
                      setCurrent={setCurrent}
                      setVisible={setVisible}
                    />
                  )}
                </>
              ) : (
                <InitialInspectionForm
                  visible={visible}
                  setVisible={setVisible}
                  refreshPage={refreshPage}
                  initialRefresh={setInitialAssessmentRefresh}
                  editId={editId}
                  setCurrent={setCurrent}
                  patientData={patientData}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              )}

              {confirmModal && (
                <ClosePopup
                  open={confirmModal}
                  setOpen={setConfirmModal}
                  setVisible={setVisible}
                />
              )}
            </Drawer>
          )}
        </>
      ) : (
        <Col span={24} style={{ textAlign: "center" }}>
          {editId ? (
            <InitialInspectionSummary editId={editId} />
          ) : (
            <NoDataPage
              title={"Doctor Initial Assessment Not Yet Submitted "}
              image={assessmentIcon}
            />
          )}
        </Col>
      )}
    </>
  );
};

export default InitialInspection;
