import React from "react";
import { Table } from "antd";
import { formatToINR } from "../../../../helpers/utility";

const PatientPackageTable = ({ data }) => {
  const tableData = [
    ...(data?.package?.package_services || []),
    ...(data?.package?.package_investigations || []),
    data?.package?.room_type_name && data?.package?.room_price
      ? {
          id: "room",
          name: data?.package?.room_type_name,
          price: data?.package?.room_price,
        }
      : null,
  ];

  const columns = [
    {
      title: "Item",
      dataIndex: "name",
      key: "name",
      render: (_, record) =>
        record?.service?.name || record?.investigation?.name || record?.name,
    },
    {
      title: "Type",
      render: (_, record) =>
        record?.investigation?.name
          ? "Investigation"
          : record?.service?.name
          ? "Service"
          : record?.name
          ? "Room Type"
          : "-",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (value) => formatToINR(value || data?.package?.room_price),
    },
  ];
  return (
    <div>
      <Table
        dataSource={tableData}
        columns={columns}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default PatientPackageTable;
