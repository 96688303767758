import React from "react";
import { Radio, Form } from "antd";

const DeselectableRadioGroup = ({
  options,
  name,
  form,
  label = null,
  setRefresh = () => {},
  radioProps = {},
}) => {
  const handleRadioClick = (value) => {
    // console.log(value);
    const currentValue = form.getFieldValue(name);
    if (Array.isArray(name)) {
      const nestedFieldUpdate = name.reduceRight(
        (acc, key, index) =>
          index === name.length - 1
            ? { [key]: currentValue === value ? null : value }
            : { [key]: acc },
        {}
      );

      form.setFieldsValue(nestedFieldUpdate);
    } else {
      form.setFieldsValue({ [name]: currentValue === value ? null : value });
    }
    console.log(form.getFieldValue(name));
    setRefresh((prev) => !prev);
  };

  return (
    <Form.Item label={label} name={name}>
      <Radio.Group {...radioProps}>
        {options.map((option) => (
          <Radio
            key={option.value}
            value={option.value}
            onClick={() => handleRadioClick(option.value)}
          >
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default DeselectableRadioGroup;
