import React, { useContext, useEffect, useState } from "react";
import {
  Tabs,
  Form,
  message,
  Drawer,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Space,
  Tag,
  Image,
  Divider,
} from "antd";
import ClinicalImpressionForm from "../../InspectionForms/ClinicalImpressionForm/ClinicalImpressionForm";
import DrGeneralExaminationFormDR from "../../InspectionForms/Examination/GeneralExaminationForm/DrGeneralExaminationFormDR";
import DrSystemicExaminationFormDR from "../../InspectionForms/Examination/SystemicExaminationForm/DrSystemicExaminationFormDR";
import DrHistoryFormDR from "../../InspectionForms/HistoryForm/DrHistoryFormDR";
import {
  doctorDailyReviewAPI,
  getDoctorDailyReviewAPI,
  getDoctorInspectionAPI,
  getLatestDailyReviewAPI,
  updateDoctorDailyReviewAPI,
} from "../../../../services/doctor.services";
import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import { DailyReviewContext } from "./DailyReviewFormProvider";
import { useLocation } from "react-router-dom";
import bedImg from "../../../../assets/images/patientIcons/bed.svg";

const DailyReviewForm = ({
  editId,
  visible,
  setVisible,
  refreshPage,
  iAssessment,
  addMode,
  setAddMode,
  setActiveSegment,
  reviewData,
  setDailyReviewRefresh,
  dailyRefresh,
  segmentData,
  patientData,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [historyForm] = Form.useForm();
  const [generalForm] = Form.useForm();
  const [systemicForm] = Form.useForm();
  const [clinicalForm] = Form.useForm();
  const { formData, setFormData } = useContext(DailyReviewContext);
  const [activeTab, setActiveTab] = useState("1");
  const [isDirty, setIsDirty] = useState(false);
  const newReview = reviewData?.length === 0;
  const reviewId = reviewData?.[reviewData.length - 1]?.id;
  const newReviewId = addMode === false && reviewId ? reviewId : null;

  const reviewDate =
    segmentData?.find(
      (option) => option.value === editId && option.value !== "others"
    )?.label ||
    new Date().toLocaleDateString("en-US", { month: "short", day: "2-digit" });

  const handleSubmit = async (values, flag) => {
    try {
      if (editId || newReviewId) {
        await updateDoctorDailyReviewAPI(editId || newReviewId, values);
        setDailyReviewRefresh();
      } else {
        await doctorDailyReviewAPI(values);
        refreshPage();
        setAddMode(false);
        setDailyReviewRefresh();
      }

      message.success(`${flag} submitted successfully`);

      setIsDirty(false);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const onHistorySubmit = (values) => {
    handleSubmit(
      {
        ...formData,
        history: values,
        admission_id: params?.patient,
      },
      "History"
    );
  };
  const onGeneralSubmit = (values) => {
    handleSubmit(
      {
        ...formData,
        general_examination: values,
        admission_id: params?.patient,
      },
      "General Examination"
    );
  };
  const onSystemicSubmit = (values) => {
    handleSubmit(
      {
        ...formData,
        systemic_examination: values,
        admission_id: params?.patient,
      },
      "Systemic Examination"
    );
  };
  const onClinicalSubmit = (values) => {
    handleSubmit(
      {
        ...formData,
        clinical_diagnosis: values,
        admission_id: params?.patient,
      },
      "Clinical Diagnosis"
    );
  };

  const openEdit = async () => {
    if (editId && newReview === false) {
      try {
        const response = await getDoctorDailyReviewAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        message.error("Unable to Fetch Data");
      }
    } else if (newReview === false) {
      try {
        const response = await getLatestDailyReviewAPI(params?.patient);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        message.error("Unable to Fetch Data");
      }
    } else {
      try {
        const response = await getDoctorInspectionAPI(iAssessment);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error:${error}`);
      }
    }
  };

  useEffect(() => {
    openEdit();
  }, [editId, dailyRefresh]);

  const getFormKey = () => {
    switch (activeTab) {
      case "1":
        return clinicalForm.submit();
      case "2":
        return historyForm.submit();
      case "3":
        return generalForm.submit();
      case "4":
        return systemicForm.submit();
      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    if (isDirty) {
      switch (activeTab) {
        case "1":
          clinicalForm.validateFields().then((values) => {
            setIsDirty(false);
            onClinicalSubmit(values);
            setActiveTab(key);
          });
          break;
        case "2":
          historyForm.validateFields().then((values) => {
            setIsDirty(false);
            onHistorySubmit(values);
            setActiveTab(key);
          });
          break;
        case "3":
          generalForm.validateFields().then((values) => {
            setIsDirty(false);
            onGeneralSubmit(values);
            setActiveTab(key);
          });
          break;
        case "4":
          systemicForm.validateFields().then((values) => {
            setIsDirty(false);
            onSystemicSubmit(values);
            setActiveTab(key);
          });
          break;
        default:
          setActiveTab(key);
      }
    } else {
      setActiveTab(key);
    }
  };

  const onFormValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <div>
      <Drawer
        styles={{ body: { overflow: "hidden" } }}
        open={visible}
        width="100%"
        footer={
          <div style={{ textAlign: "right" }}>
            {activeTab !== "4" && (
              <Button
                onClick={() => {
                  getFormKey();
                  setVisible(false);
                }}
              >
                Save & Close
              </Button>
            )}
            {"  "}

            <Button
              onClick={() => {
                getFormKey();
                const nextTab = (parseInt(activeTab) + 1).toString();
                if (activeTab === "4") {
                  setVisible(false);
                } else {
                  setActiveTab(nextTab);
                }
              }}
              type="primary"
            >
              {activeTab === "4" ? "Done" : " Save & Proceed"}
            </Button>
          </div>
        }
        onClose={() => {
          setVisible(false);
          setActiveSegment(reviewData[0]?.id);
        }}
        closeIcon={null}
        keyboard={false}
      >
        <Row>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <LeftOutlined
              onClick={() => {
                Modal.confirm({
                  title: "Confirmation",
                  content: "Are you sure you want to close?",
                  onOk: () => {
                    setVisible(false);
                    setActiveSegment(reviewData[0]?.id);
                  },
                });
              }}
              style={{ fontSize: "20px", cursor: "pointer" }}
            />
          </Col>
          <Col span={6}>
            <Space>
              <Typography
                style={{ color: "#101828", fontSize: "16px", fontWeight: 600 }}
              >
                {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}{" "}
              </Typography>
              {patientData?.admission?.bed?.bed_no && (
                <Tag color="orange">
                  <Image src={bedImg} preview={false} />
                  <span style={{ marginLeft: "5px", fontWeight: "500" }}>
                    {patientData?.admission?.bed?.bed_no}
                  </span>
                </Tag>
              )}
            </Space>
            <br />
            <Space style={{ marginTop: "10px" }}>
              <Tag>
                {patientData?.admission?.patient?.age}{" "}
                {patientData?.admission?.patient?.gender}
              </Tag>
              <Tag>IP ID: {patientData?.admission?.ip_id}</Tag>
            </Space>
          </Col>
          <Col span={14}>
            <Tabs
              activeKey={activeTab}
              items={[
                { key: "1", label: "Clinical Diagnosis" },
                { key: "2", label: "History" },
                { key: "3", label: "General Examination" },
                { key: "4", label: "Systemic Examination" },
              ]}
              onChange={handleTabChange}
            />
          </Col>
          <Col
            span={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            {reviewDate}
          </Col>
          <Col
            span={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <CloseOutlined
              onClick={() => {
                Modal.confirm({
                  title: "Confirmation",
                  content: "Are you sure you want to close?",
                  onOk: () => {
                    setVisible(false);
                  },
                });
              }}
            />
          </Col>
        </Row>
        <Divider style={{ margin: "0px", marginTop: "8px", padding: "0px" }} />

        {activeTab === "1" && (
          <Form
            id="daily-review-clinical-form"
            form={clinicalForm}
            layout="vertical"
            onFinish={onClinicalSubmit}
            onValuesChange={onFormValuesChange}
            initialValues={formData}
            className="ip-forms ip-forms-review"
          >
            <ClinicalImpressionForm
              form={clinicalForm}
              context={DailyReviewContext}
              editId={editId}
              patientData={patientData}
            />
          </Form>
        )}

        {activeTab === "2" && (
          <Form
            id="daily-review-history-form"
            form={historyForm}
            layout="vertical"
            onFinish={onHistorySubmit}
            onValuesChange={onFormValuesChange}
            initialValues={formData}
            className="ip-forms ip-forms-review"
          >
            <DrHistoryFormDR form={historyForm} />
          </Form>
        )}

        {activeTab === "3" && (
          <Form
            id="daily-review-general-form"
            form={generalForm}
            layout="vertical"
            onFinish={onGeneralSubmit}
            onValuesChange={onFormValuesChange}
            initialValues={formData}
            className="ip-forms ip-forms-review"
          >
            <DrGeneralExaminationFormDR form={generalForm} />
          </Form>
        )}

        {activeTab === "4" && (
          <Form
            id="general-form"
            form={systemicForm}
            layout="vertical"
            onFinish={onSystemicSubmit}
            onValuesChange={onFormValuesChange}
            initialValues={formData}
            className="ip-forms ip-forms-review"
          >
            <DrSystemicExaminationFormDR form={systemicForm} />
          </Form>
        )}
      </Drawer>
    </div>
  );
};

export default DailyReviewForm;
