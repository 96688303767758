import { Collapse } from "antd";
import React from "react";
import ChestPhysiotherapy from "./ChestPhysiotherapy";
import RespiratorySupport from "./RespiratorySupport";
import Tracheostomy from "./Tracheostomy";

const RespiratoryCare = ({ data }) => {
  return (
    <div>
      <Collapse
        expandIconPosition="right"
        className="custom-collapse"
        style={{ marginTop: "10px" }}
        defaultActiveKey={["1", "2", "3"]}
      >
        <Collapse.Panel header="Respiratory Support View" key="1">
          <RespiratorySupport
            data={data?.data?.respiratory_care_respiratory_support}
          />
        </Collapse.Panel>
        <Collapse.Panel header="Tracheostomy Care" key="2">
          <Tracheostomy data={data?.data?.respiratory_care_tracheostomy_care} />
        </Collapse.Panel>
        <Collapse.Panel header="Chest Physiotherapy View" key="3">
          <ChestPhysiotherapy
            data={data?.data?.respiratory_care_chest_physiotherapy}
          />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default RespiratoryCare;
