import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Skeleton, Space, Image } from "antd";
import btnStyles from "../../../styles/Custom/Button.module.css";
import InvestigationModal from "./InvestigationModal";
import { patientInvestigationsViewAPI } from "../../../services/patient.services";
import InvestigationTable from "./InvestigationTable";
import { FileSearchOutlined, UploadOutlined } from "@ant-design/icons";
import InvestigationsUploadModal from "./InvestigationsUploadModal";
import InvestigationGallery from "./InvestigationGallery/InvestigationGallery";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import NoDataPage from "../../NoDataPages/NoDataPage";
import InvestigationIcon from "../../../assets/images/nurseIcons/investigation.svg";
import LoadingIndicator from "../../Loaders/LoadingIndicator";

import galleryIcon from "../../../assets/doctorIcons/gallery.svg";
import investigationIcon from "../../../assets/doctorIcons/investigation.svg";
import { getCookie } from "../../../helpers/localStorage";
import { Role } from "../../../constants/defaultKeys";
const InvestigationsIP = ({ patientData }) => {
  const { userDetails } = useContext(UserContext);
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [open, setOpen] = useState(false);
  const [investigationData, setInvestigationData] = useState(null);
  const [uploadModal, setUploadModal] = useState(false);
  const [galleryModal, setGalleryModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    patientInvestigationsViewAPI(
      `patient_id=${params?.patient}&ip_admission=${params?.patient}`
    )
      .then((res) => {
        setInvestigationData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [refresh]);

  const defaultRole = getCookie(Role) || userDetails?.currRole;

  return (
    <>
      {loading ? (
        <LoadingIndicator
          message={"Loading investigation data, please wait..."}
        />
      ) : investigationData && investigationData?.length > 0 ? (
        <Row gutter={[12, 12]}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Space>
              <Button
                style={{ fontWeight: "500", height: "40px", color: "#7f56d9" }}
                type="link"
                ghost
                className={`${btnStyles.mdBtn}`}
                onClick={() => setUploadModal(true)}
              >
                <UploadOutlined /> Initial Investigation
              </Button>
              <Button
                style={{ fontWeight: "500", height: "40px" }}
                type="primary"
                ghost
                className={`${btnStyles.mdBtn} inv-btn`}
                onClick={() => setGalleryModal(true)}
              >
                <Space>
                  <Image
                    src={galleryIcon}
                    preview={false}
                    width={20}
                    height={20}
                  />
                </Space>
                Investigation Gallery
              </Button>
              {!["Nurse", "Receptionist"].includes(defaultRole) && (
                <Button
                  type="primary"
                  style={{ fontWeight: "500", height: "40px" }}
                  className={`${btnStyles.mdBtn} inv-btn`}
                  onClick={() => setOpen(true)}
                >
                  <Space>
                    <Image
                      src={investigationIcon}
                      preview={false}
                      width={20}
                      height={20}
                    />
                  </Space>
                  Request Investigation
                </Button>
              )}
            </Space>
          </Col>
          <Col span={24}>
            <InvestigationTable
              data={investigationData}
              refreshPage={refreshPage}
              id={params?.patient}
              patientData={patientData}
            />
          </Col>
        </Row>
      ) : (
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "80vh",
          }}
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <NoDataPage
              title={"No Investigation has been done ! "}
              subtitle={"Kindly, upload an initial investigation here"}
              image={InvestigationIcon}
            />
            <Space>
              {defaultRole !== "Nurse" && (
                <Button
                  style={{ fontWeight: "500", height: "40px" }}
                  type="primary"
                  ghost
                  className={btnStyles.mdBtn}
                  onClick={() => setOpen(true)}
                >
                  {" "}
                  <Space>
                    <FileSearchOutlined />
                  </Space>
                  Request Investigation
                </Button>
              )}
              <Button
                style={{ fontWeight: "500", height: "40px" }}
                type="primary"
                className={`${btnStyles.mdBtn} inv-btn`}
                onClick={() => setUploadModal(true)}
              >
                <UploadOutlined /> Initial Investigation
              </Button>
            </Space>
          </Col>
        </Row>
      )}

      {open && (
        <InvestigationModal
          open={open}
          setOpen={setOpen}
          refreshPage={refreshPage}
          id={params?.patient}
        />
      )}
      {uploadModal && (
        <InvestigationsUploadModal
          open={uploadModal}
          setOpen={setUploadModal}
          refreshPage={refreshPage}
          id={params?.patient}
        />
      )}
      {galleryModal && (
        <InvestigationGallery
          open={galleryModal}
          setOpen={setGalleryModal}
          refreshPage={refreshPage}
          id={params?.patient}
          patientData={patientData}
        />
      )}
    </>
  );
};

export default InvestigationsIP;
