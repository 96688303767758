import React from "react";
import { Form, Input } from "antd";
import {
  abdomenAppearanceOptions,
  bowelMovementOptions,
} from "../../../../Options/systemicExamOptions";
import { presentAbsentOptions } from "../../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const AbdomenForm = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Appearance"}
        name={"appearance"}
        options={abdomenAppearanceOptions}
        form={form}
      />

      <DeselectableRadioGroup
        label={"Bowel Sounds"}
        name={"bowel_sounds"}
        options={presentAbsentOptions}
        form={form}
      />

      <DeselectableRadioGroup
        label={"Bowel Movement"}
        name={"bowel_movement"}
        options={bowelMovementOptions}
        form={form}
      />
      <Form.Item name="last_bowel_movement">
        <Input.TextArea
          rows={2}
          type="text"
          placeholder="Last Bowel Movement"
        />
      </Form.Item>
    </>
  );
};

export default AbdomenForm;
