import React, { useContext, useEffect, useMemo, useState } from "react";

import CheckListFBYRadioItems from "../../../../../../blocks/FormItems/CheckListFBYRadioItems";
import {
  cranialNerveSeverityOptions,
  cranialNervesAbnormalOptions,
  cranialNervesOptions,
} from "../../../../Options/systemicExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const CranialNerves = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);

  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    const initialCheckboxes = cranialNervesAbnormalOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          initialValues?.systemic_examination?.[checkbox.value] || false; // Initialize all checkboxes based on initialValues
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <DeselectableRadioGroup
        label={"Cranial Nerves"}
        name={"cranial_nerves"}
        options={cranialNervesOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("cranial_nerves") === "abnormal" && (
        <div className="subsection-card">
          <CheckListFBYRadioItems
            checkBoxOptions={cranialNervesAbnormalOptions}
            radioOptions={cranialNerveSeverityOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            form={form}
          />
        </div>
      )}
    </>
  );
};

export default CranialNerves;
