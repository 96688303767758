import React, { useContext } from "react";
import { DoctorInitialContext } from "../../InspectionForms/HistoryForm/DoctorHistoryFormProvider";
import {
  getFractureSummary,
  getLabelTag,
  getLabelTagInput,
  getLabelTagTable,
  getLabelTagYesNO,
  getLabelTagsWithCheckGroup,
  getLabelTagsWithDescription,
  getLabelTagsWithRadioOptions,
  getLabelTagsWithSelectItems,
} from "../constructTextFromValues";
import {
  abdomenAppearanceOptions,
  abnormalBreathZoneOptions,
  abnormalGaitOptions,
  auscultationOptions,
  bowelMovementOptions,
  breathSoundsOptions,
  coordinationOptions,
  cranialNerveSeverityOptions,
  cranialNervesAbnormalOptions,
  cranialNervesOptions,
  fractureAreasOptions,
  gaitOptions,
  heartSoundsOptions,
  jointPainLocationOptions,
  jointPainSeverityOptions,
  jvpOptions,
  leftRightOptions,
  limbDeformityOptions,
  mentalAbnormalOptions,
  mentalStatusOptions,
  motorAbnormalRadioItems,
  motorSystemAbnormalOptions,
  motorSystemOptions,
  periPulsesVolumeOptions,
  reflexes,
  sensoryExtremitiesOptions,
  sensorySystemAbnormalOptions,
  sensorySystemOptions,
  skullOptions,
  softTissueInjuryAreaOptions,
  spineOptions,
  typeOfBreathingOptions,
} from "../../Options/systemicExamOptions";
import respiratoryIcon from "../../../../assets/doctorIcons/lungs.svg";
import cardioIcon from "../../../../assets/doctorIcons/cardio-vascular.svg";
import abdomenIcon from "../../../../assets/doctorIcons/stomach.svg";
import musculoSkeletalIcon from "../../../../assets/doctorIcons/anatomy.svg";
import centralNervousSystemIcon from "../../../../assets/doctorIcons/cns.svg";
import skinIcon from "../../../../assets/doctorIcons/skin.svg";
import { Descriptions, Image, Space, Tag } from "antd";
import {
  noneYesOptions,
  normalAbsentOptions,
  positiveNegativeOptions,
  presentAbsentOptions,
  regularIrregularOptions,
  yesNoOptions,
} from "../../Options/commonOptions";
import { FileSearchOutlined } from "@ant-design/icons";

const DrSystemicExaminationSummary = () => {
  const { formData } = useContext(DoctorInitialContext);
  // console.log("systemic examination", formData?.systemic_examination);

  const getReflexesSummary = () => {
    const reflexOptions = reflexes
      .map((reflex) => {
        const leftValue =
          formData?.systemic_examination?.[reflex.value + "_left"];
        const rightValue =
          formData?.systemic_examination?.[reflex.value + "_right"];

        if (leftValue || rightValue) {
          let labelCreated = `${reflex.label} Left: ${
            leftValue || "N/A"
          }, Right: ${rightValue || "N/A"}`;
          return (
            <Tag bordered={false} key={reflex.value}>
              {labelCreated}
            </Tag>
          );
        }
        return null;
      })
      .filter(Boolean); // Remove any null values

    return reflexOptions.length === 0 ? null : reflexOptions;
  };

  const constructskinSummaryItems = () => {
    const skinSummaryItems = [];
    if (formData?.systemic_examination?.pressure_sore?.length > 0) {
      skinSummaryItems.push({
        key: "1",
        label: "Pressure Sore",
        children: getLabelTagTable(
          formData?.systemic_examination?.pressure_sore
        ),
      });
    }
    if (formData?.systemic_examination?.open_wounds?.length > 0) {
      skinSummaryItems.push({
        key: "2",
        label: "Open Wounds",
        children: getLabelTagTable(formData?.systemic_examination?.open_wounds),
      });
    }
    if (
      getLabelTagsWithDescription(
        softTissueInjuryAreaOptions,
        formData?.systemic_examination?.soft_tissue_injury
      )
    ) {
      skinSummaryItems.push({
        key: "3",
        label: "Soft Tissue Injury",
        children: getLabelTagsWithDescription(
          softTissueInjuryAreaOptions,
          formData?.systemic_examination?.soft_tissue_injury
        ),
      });
    }
    if (formData?.systemic_examination?.scar) {
      skinSummaryItems.push({
        key: "4",
        label: "Scar",
        children: (
          <>
            {getLabelTagYesNO(formData?.systemic_examination?.scar)}
            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.scar_description
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.surgical_incision) {
      skinSummaryItems.push({
        key: "5",
        label: "Surgical Incision",
        children: (
          <>
            {getLabelTagYesNO(
              formData?.systemic_examination?.surgical_incision
            )}
            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.surgical_incision_description
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.swellings) {
      skinSummaryItems.push({
        key: "6",
        label: "Swellings",
        children: (
          <>
            {getLabelTagYesNO(formData?.systemic_examination?.swellings)}
            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.swelling_description
            )}
          </>
        ),
      });
    }
    return skinSummaryItems;
  };

  const constructCardioVascularSystemSummaryItems = () => {
    const cardioVascularSystemSummaryItems = [];
    if (
      formData?.systemic_examination?.peripheral_regular ||
      formData?.systemic_examination?.peripheral_volume ||
      formData?.systemic_examination?.peripheral_desc
    ) {
      cardioVascularSystemSummaryItems.push({
        key: "1",
        label: "Peripheral Pulses",
        children: (
          <>
            {getLabelTag(
              regularIrregularOptions,
              formData?.systemic_examination?.peripheral_regular
            )}
            {getLabelTag(
              periPulsesVolumeOptions,
              formData?.systemic_examination?.peripheral_volume
            )}
            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.peripheral_desc
            )}
          </>
        ),
      });
    }
    if ((jvpOptions, formData?.systemic_examination?.jvp)) {
      cardioVascularSystemSummaryItems.push({
        key: "2",
        label: "JVP",
        children: getLabelTag(jvpOptions, formData?.systemic_examination?.jvp),
      });
    }
    if (
      getLabelTagsWithDescription(
        heartSoundsOptions,
        formData?.systemic_examination
      )
    ) {
      cardioVascularSystemSummaryItems.push({
        key: "3",
        label: "Heart Sounds",
        children: getLabelTagsWithDescription(
          heartSoundsOptions,
          formData?.systemic_examination
        ),
      });
    }
    return cardioVascularSystemSummaryItems;
  };

  const constructRespiratorySystemSummaryItems = () => {
    const respiratorySystemSummaryItems = [];

    if (formData?.systemic_examination?.type_of_breathing) {
      respiratorySystemSummaryItems.push({
        key: "1",
        label: "Type of Breathing",
        children: (
          <>
            {getLabelTag(
              typeOfBreathingOptions,
              formData?.systemic_examination?.type_of_breathing
            )}
            {getLabelTagInput(
              "Other details",
              formData?.systemic_examination?.type_of_breathing_others
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.ausculation) {
      respiratorySystemSummaryItems.push({
        key: "2",
        label: "Auscultation",
        children: (
          <>
            {getLabelTag(
              auscultationOptions,
              formData?.systemic_examination?.ausculation
            )}
            {getLabelTagsWithSelectItems(
              abnormalBreathZoneOptions,
              breathSoundsOptions,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    return respiratorySystemSummaryItems;
  };

  const constructAbdomenSummaryItems = () => {
    const abdomenSummaryItems = [];

    if (formData?.systemic_examination?.appearance) {
      abdomenSummaryItems.push({
        key: "1",
        label: "Appearance",
        children: getLabelTag(
          abdomenAppearanceOptions,
          formData?.systemic_examination?.appearance
        ),
      });
    }
    if (formData?.systemic_examination?.bowel_sounds) {
      abdomenSummaryItems.push({
        key: "2",
        label: "Bowel Sounds",
        children: getLabelTag(
          presentAbsentOptions,
          formData?.systemic_examination?.bowel_sounds
        ),
      });
    }
    if (formData?.systemic_examination?.bowel_movement) {
      abdomenSummaryItems.push({
        key: "3",
        label: "Bowel Movement",
        children: getLabelTag(
          bowelMovementOptions,
          formData?.systemic_examination?.bowel_movement
        ),
      });
    }
    if (formData?.systemic_examination?.last_bowel_movement) {
      abdomenSummaryItems.push({
        key: "4",
        label: "Last Bowel Moment",
        children: (
          <Tag bordered={false} key={"last_bowel_movement"}>
            {formData?.systemic_examination?.last_bowel_movement}
          </Tag>
        ),
      });
    }
    return abdomenSummaryItems;
  };

  const constructMusculoSkeletalSummaryItems = () => {
    const musculoSkeletalSummaryItems = [];
    if (formData?.systemic_examination?.limb_deformity) {
      musculoSkeletalSummaryItems.push({
        key: "1",
        label: "Limb Deformity",
        children: (
          <>
            {getLabelTagYesNO(formData?.systemic_examination?.limb_deformity)}
            {getLabelTagsWithDescription(
              limbDeformityOptions,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.fractures) {
      musculoSkeletalSummaryItems.push({
        key: "2",
        label: "Fractures",
        children: (
          <>
            {getLabelTagYesNO(formData?.systemic_examination?.fractures)}
            {getFractureSummary(
              fractureAreasOptions,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.skull) {
      musculoSkeletalSummaryItems.push({
        key: "3",
        label: "Skull",
        children: (
          <>
            {getLabelTag(skullOptions, formData?.systemic_examination?.skull)}
            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.skull_description
            )}
          </>
        ),
      });
    }
    if (
      getLabelTagsWithDescription(spineOptions, formData?.systemic_examination)
    ) {
      musculoSkeletalSummaryItems.push({
        key: "4",
        label: "Spine",
        children: getLabelTagsWithDescription(
          spineOptions,
          formData?.systemic_examination
        ),
      });
    }
    if (formData?.systemic_examination?.joint_pain) {
      musculoSkeletalSummaryItems.push({
        key: "5",
        label: "Joint Pain",
        children: (
          <>
            {getLabelTag(
              noneYesOptions,
              formData?.systemic_examination?.joint_pain
            )}
            {getLabelTagsWithCheckGroup(
              jointPainLocationOptions,
              jointPainSeverityOptions,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.contractures) {
      musculoSkeletalSummaryItems.push({
        key: "6",
        label: "Contractures",
        children: (
          <>
            {getLabelTagYesNO(formData?.systemic_examination?.contractures)}
            {/* Elbow: */}
            {getLabelTagInput(
              "Elbow",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_elbow
              )
            )}
            {/* Wrist: */}
            {getLabelTagInput(
              "Wrist",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_wrist
              )
            )}
            {/* Shoulder: */}
            {getLabelTagInput(
              "Shoulder",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_shoulder
              )
            )}
            {/* Hip: */}
            {getLabelTagInput(
              "Hip",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_hip
              )
            )}
            {/* Knee: */}
            {getLabelTagInput(
              "Knee",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_knee
              )
            )}
            {/* Ankle: */}
            {getLabelTagInput(
              "Ankle",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_ankle
              )
            )}
          </>
        ),
      });
    }
    return musculoSkeletalSummaryItems;
  };

  const constructCentralNervousSystemSummaryItems = () => {
    const centralNervousSystemSummaryItems = [];

    if (formData?.systemic_examination?.mental_status) {
      centralNervousSystemSummaryItems.push({
        key: "1",
        label: "Mental Status",
        children: (
          <>
            {getLabelTag(
              mentalStatusOptions,
              formData?.systemic_examination?.mental_status
            )}
            {getLabelTag(
              mentalAbnormalOptions,
              formData?.systemic_examination?.mental_status_details
            )}
            {getLabelTagInput(
              "Others details",
              formData?.systemic_examination?.mental_status_details_others
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.cranial_nerves) {
      centralNervousSystemSummaryItems.push({
        key: "2",
        label: "Cranial Nerves",
        children: (
          <>
            {getLabelTag(
              cranialNervesOptions,
              formData?.systemic_examination?.cranial_nerves
            )}
            {getLabelTagsWithRadioOptions(
              cranialNervesAbnormalOptions,
              cranialNerveSeverityOptions,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.sensory_system) {
      centralNervousSystemSummaryItems.push({
        key: "3",
        label: "Sensory System",
        children: (
          <>
            {getLabelTag(
              sensorySystemOptions,
              formData?.systemic_examination?.sensory_system
            )}
            {/* Sensation: */}
            {getLabelTagInput(
              "Sensation",

              getLabelTagsWithRadioOptions(
                sensorySystemAbnormalOptions,
                sensoryExtremitiesOptions,
                formData?.systemic_examination
              )
            )}

            {/* Tactile Localization: */}
            {/* 2 Point Discrimination: */}
            {getLabelTagInput(
              "Tactile Localization\n2 point Discrimination",
              getLabelTag(
                normalAbsentOptions,
                formData?.systemic_examination?.two_point_discrimination
              )
            )}
            {/* Stereognosis: */}
            {getLabelTagInput(
              "Stereognosis",
              getLabelTag(
                normalAbsentOptions,
                formData?.systemic_examination?.stereognosis
              )
            )}
            {/* Graphesthesia: */}
            {getLabelTagInput(
              "Graphesthesia",
              getLabelTag(
                normalAbsentOptions,
                formData?.systemic_examination?.graphesthesia
              )
            )}
            {/* Romberg's Sign: */}
            {getLabelTagInput(
              "Romberg's Sign",
              getLabelTag(
                positiveNegativeOptions,
                formData?.systemic_examination?.rombergs_sign
              )
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.motor_system) {
      centralNervousSystemSummaryItems.push({
        key: "4",
        label: "Motor System",
        children: (
          <>
            {getLabelTag(
              motorSystemOptions,
              formData?.systemic_examination?.motor_system
            )}
            {getLabelTagsWithRadioOptions(
              motorSystemAbnormalOptions,
              motorAbnormalRadioItems,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    if (getReflexesSummary()) {
      centralNervousSystemSummaryItems.push({
        key: "5",
        label: "Reflexes",
        children: getReflexesSummary(),
      });
    }
    if (formData?.systemic_examination?.coordination) {
      centralNervousSystemSummaryItems.push({
        key: "6",
        label: "Co-ordination",
        children: (
          <>
            {getLabelTag(
              coordinationOptions,
              formData?.systemic_examination?.coordination
            )}
            {getLabelTagInput(
              "Finger-to-nose test",
              formData?.systemic_examination?.finger_to_nose_test
            )}
            {getLabelTagInput(
              "Finger-to-finger test",
              formData?.systemic_examination?.finger_to_finger_test
            )}
            {getLabelTagInput(
              "Adiadokokinesia or dysdiadokokinesia",
              formData?.systemic_examination?.adiadokokinesia
            )}
            {getLabelTagInput(
              "Rebound phenomena",
              formData?.systemic_examination?.rebound_phenomena
            )}
            {getLabelTagInput(
              "Walking along a straight line",
              formData?.systemic_examination?.walking_along_a_straight_line
            )}
            {getLabelTagInput(
              "Rombergs test",
              formData?.systemic_examination?.rombergs_test
            )}
            {getLabelTagInput(
              "Heel on shin test",
              formData?.systemic_examination?.heel_on_shin_test
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.gait) {
      centralNervousSystemSummaryItems.push({
        key: "7",
        label: "Gait",
        children: (
          <>
            {getLabelTag(gaitOptions, formData?.systemic_examination?.gait)}
            {getLabelTag(
              abnormalGaitOptions,
              formData?.systemic_examination?.gait_details
            )}
            {getLabelTagInput(
              "Others Details",
              formData?.systemic_examination?.gait_details_others
            )}
          </>
        ),
      });
    }
    if (
      formData?.systemic_examination?.neck_stiffness ||
      formData?.systemic_examination?.kernig_sign
    ) {
      centralNervousSystemSummaryItems.push({
        key: "8",
        label: "Meningeal Signs",
        children: (
          <>
            {/* Neck Stiffness: */}
            {getLabelTagInput(
              "Neck Stiffness",
              getLabelTag(
                yesNoOptions,
                formData?.systemic_examination?.neck_stiffness
              )
            )}
            {/* Kernig's Sign */}
            {getLabelTagInput(
              "Kernig's Sign",
              getLabelTag(
                yesNoOptions,
                formData?.systemic_examination?.kernig_sign
              )
            )}
          </>
        ),
      });
    }
    return centralNervousSystemSummaryItems;
  };

  const skinSummaryItems = constructskinSummaryItems();
  const cardioVascularSystemSummaryItems =
    constructCardioVascularSystemSummaryItems();
  const respiratorySystemSummaryItems =
    constructRespiratorySystemSummaryItems();
  const abdomenSummaryItems = constructAbdomenSummaryItems();
  const musculoSkeletalSummaryItems = constructMusculoSkeletalSummaryItems();
  const centralNervousSystemSummaryItems =
    constructCentralNervousSystemSummaryItems();
  return (
    <div>
      {/* <Typography.Title level={5}>Systemic Examination</Typography.Title> */}
      {skinSummaryItems.length > 0 && (
        <Descriptions
          style={{ marginTop: "40px", textAlign: "left" }}
          bordered
          title={
            <span style={{ fontWeight: "500", fontSize: "16px" }}>
              <span style={{ display: "inline-block", marginRight: "5px" }}>
                <Image src={skinIcon} width="18px" preview={false} />
              </span>
              Skin{" "}
            </span>
          }
          column={1}
          items={skinSummaryItems}
          className="initial-insp-desc"
        />
      )}
      {cardioVascularSystemSummaryItems.length > 0 && (
        <Descriptions
          style={{ marginTop: "40px", textAlign: "left" }}
          bordered
          title={
            <span style={{ fontWeight: "500", fontSize: "16px" }}>
              <span style={{ display: "inline-block", marginRight: "5px" }}>
                <Image src={cardioIcon} width="18px" preview={false} />
              </span>
              Cardio Vascular system{" "}
            </span>
          }
          column={1}
          items={cardioVascularSystemSummaryItems}
          className="initial-insp-desc"
        />
      )}
      {respiratorySystemSummaryItems.length > 0 && (
        <Descriptions
          style={{ marginTop: "40px", textAlign: "left" }}
          bordered
          title={
            <span style={{ fontWeight: "500", fontSize: "16px" }}>
              <span style={{ display: "inline-block", marginRight: "5px" }}>
                <Image src={respiratoryIcon} width="18px" preview={false} />
              </span>
              Respiratory System
            </span>
          }
          column={1}
          items={respiratorySystemSummaryItems}
          className="initial-insp-desc"
        />
      )}
      {abdomenSummaryItems.length > 0 && (
        <Descriptions
          style={{ marginTop: "40px", textAlign: "left" }}
          bordered
          title={
            <span style={{ fontWeight: "500", fontSize: "16px" }}>
              <span style={{ display: "inline-block", marginRight: "5px" }}>
                <Image src={abdomenIcon} width="18px" preview={false} />
              </span>
              Abdomen
            </span>
          }
          column={1}
          items={abdomenSummaryItems}
          className="initial-insp-desc"
        />
      )}
      {musculoSkeletalSummaryItems.length > 0 && (
        <Descriptions
          bordered
          title={
            <span style={{ fontWeight: "500", fontSize: "16px" }}>
              <span style={{ display: "inline-block", marginRight: "5px" }}>
                <Image src={musculoSkeletalIcon} width="18px" preview={false} />
              </span>
              Musculo Skeletal
            </span>
          }
          column={1}
          items={musculoSkeletalSummaryItems}
          className="initial-insp-desc"
        />
      )}
      {centralNervousSystemSummaryItems.length > 0 && (
        <Descriptions
          style={{ marginTop: "40px", textAlign: "left" }}
          bordered
          title={
            <span style={{ fontWeight: "500", fontSize: "16px" }}>
              <span style={{ display: "inline-block", marginRight: "5px" }}>
                <Image
                  src={centralNervousSystemIcon}
                  width="18px"
                  preview={false}
                />
              </span>
              Central Nervous System
            </span>
          }
          column={1}
          items={centralNervousSystemSummaryItems}
          className="initial-insp-desc"
        />
      )}
    </div>
  );
};

export default DrSystemicExaminationSummary;
