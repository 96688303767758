import React, { useState } from "react";
import { Form, Input, Space } from "antd";
import { validateConfig } from "../../../../../config/validate.config";
import {
  informantOptions,
  relationOptions,
} from "../../../Options/historyOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";
import SelectWithOtherInput from "../../../../../blocks/FormItems/SelectWithOtherInput";

const Informant = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <DeselectableRadioGroup
        label={"Informant"}
        name={"informant"}
        form={form}
        options={informantOptions}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("informant") === "relative" && (
        <div className="subsection-card">
          <Space size="large">
            <SelectWithOtherInput
              name={"relation_with_patient"}
              label={"Relation With Patient"}
              otherLabel={" "}
              options={relationOptions}
              form={form}
              selectProps={{
                onChange: () => {
                  setRefresh((prev) => !prev);
                },
              }}
            />

            <Form.Item
              name="informant_name"
              label="Name"
              // rules={validateConfig.isRequired("Informant Name")}
            >
              <Input type="text" maxLength={50} />
            </Form.Item>
          </Space>
        </div>
      )}
    </>
  );
};

export default Informant;
