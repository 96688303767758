import React from "react";
import { appetiteOptions } from "../../../Options/historyOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const AppetitePriorAdmission = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Appetite Prior to Admission"}
        name={"appetite_prior_to_admission"}
        options={appetiteOptions}
        form={form}
      />
    </>
  );
};

export default AppetitePriorAdmission;
