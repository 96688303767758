import React, { useState } from "react";
import {
  dietOptions,
  entrealDietOptions,
  entrealFeedTypeOptions,
  oralDietOptions,
  oralDietTypeOptions,
} from "../../Options/dietAdviceOptions";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const TypeOfDiet = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <DeselectableRadioGroup
        label={"Type of Diet"}
        name={["diet_details", "diet"]}
        options={dietOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue(["diet_details", "diet"]) === "oral" && (
        <div className="subsection-card">
          <DeselectableRadioGroup
            label={"Type of Diet"}
            name={["diet_details", "type_of_diet"]}
            options={oralDietOptions}
            form={form}
          />

          <DeselectableRadioGroup
            label={"Type of Oral Diet"}
            name={["diet_details", "type_of_oral_diet"]}
            options={oralDietTypeOptions}
            form={form}
          />
        </div>
      )}

      {form.getFieldValue(["diet_details", "diet"]) === "enteral" && (
        <div className="subsection-card">
          <DeselectableRadioGroup
            label={"Type of Diet"}
            name={["diet_details", "type_of_diet"]}
            options={entrealDietOptions}
            form={form}
          />

          <DeselectableRadioGroup
            label={"Type of Enteral Feed"}
            name={["diet_details", "type_of_enteral_feed"]}
            options={entrealFeedTypeOptions}
            form={form}
          />
        </div>
      )}
    </>
  );
};

export default TypeOfDiet;
