import React, { useState, useCallback, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  AutoComplete,
  Input,
  Select,
  TimePicker,
  InputNumber,
} from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { SearchOutlined } from "@ant-design/icons";
import { getCookie } from "../../../helpers/localStorage";
import { ORGANISATONID } from "../../../constants/defaultKeys";
import { searchMedicationApi } from "../../../services/medication.services";
import { debounce } from "../../../helpers/utility";
import {
  dosageTiming,
  medicineFrequency,
  medicineRoutes,
} from "../../../helpers/medicineKeys";
import dayjs from "dayjs";
const { Option } = Select;

const setSearchOptions = (data) => {
  const searchOptions = data.map((options) => {
    return {
      value: options?.name,
      key: options?.record_id,
      name: options?.name,
      drug: options?.drug1_name,
      label: (
        <Row>
          <Col span={10}>
            <span>{options?.name}</span>
          </Col>
        </Row>
      ),
    };
  });
  return searchOptions;
};

const format = "hh A";

const defaultTimes = {
  Once_Daily: ["08 AM"],
  Twice_Daily: ["08 AM", "08 PM"],
  Thrice_Daily: ["08 AM", "01 PM", "08 PM"],
  Four_Times_Daily: ["08 AM", "01 PM", "04 PM", "08 PM"],
};
const SearchMedicine = ({
  setSelectedId,
  form,
  selectedFrequency,
  setSelectedFrequency,
  initial,
  editData,
}) => {
  const [data, setData] = useState(null);
  const [searchedText, setSearchedText] = useState("");
  const [times, setTimes] = useState([]);

  useEffect(() => {
    if (editData?.id) {
      form.setFieldsValue({
        ...editData,
        name: editData?.medicine_name || editData?.name,
        duration: "",
        comments: "",
        schedule: {
          times: editData?.schedule?.times?.map((time) => dayjs(time, "HH")),
        },
      });
      setSelectedId(editData?.medicine);
      setSelectedFrequency(editData?.frequency);
    }
  }, [editData?.id]);

  const handleSearch = (searchedValue) => {
    searchMedicationApi(
      `query=${searchedValue.toLowerCase()}&organization_id=${getCookie(
        ORGANISATONID
      )}`
    )
      .then((res) => {
        setData(setSearchOptions(res?.data));
      })
      .catch((err) => console.log(err));
  };

  const optimizedSearch = useCallback(debounce(handleSearch, 300), []);

  const handleSelect = (value, option) => {
    setSelectedId(option?.key);
    form.setFieldsValue({ name: option?.value, drug: option?.drug });
  };

  const handleFrequencyChange = (value) => {
    setSelectedFrequency(value);
    const defaultTimeValues = defaultTimes[value] || [];
    setTimes(defaultTimeValues.map((time) => dayjs(time, format)));
    form.setFieldsValue({
      schedule: { times: defaultTimeValues.map((time) => dayjs(time, format)) },
    });
  };
  const handleTimeChange = (time, index) => {
    const newTimes = [...times];
    newTimes[index] = time;
    setTimes(newTimes);
    form.setFieldsValue({
      schedule: { times: newTimes },
    });
  };

  const renderTimePickers = () => {
    console.log("Working...", selectedFrequency);
    let numPickers = 0;

    if (selectedFrequency === "Once_Daily") {
      numPickers = 1;
    } else if (selectedFrequency === "Twice_Daily") {
      numPickers = 2;
    } else if (selectedFrequency === "Thrice_Daily") {
      numPickers = 3;
    } else if (selectedFrequency === "Four_Times_Daily") {
      numPickers = 4;
    }

    const timePickers = Array.from({ length: numPickers }, (_, index) => (
      <Col span={12} key={index}>
        <Form.Item
          name={["schedule", "times", index]}
          rules={[{ required: true, message: "Please select times" }]}
        >
          <TimePicker
            needConfirm={false}
            value={times[index]}
            format={format}
            onChange={(time) => handleTimeChange(time, index)}
          />
        </Form.Item>
      </Col>
    ));

    return (
      <Row gutter={[8, 8]} style={{}}>
        {timePickers}
      </Row>
    );
  };

  return (
    <>
      <Col
        xs={24}
        sm={12}
        md={6}
        style={{ padding: "0 10px", marginTop: "20px" }}
      >
        <Form.Item
          label="Medicine"
          className="medicine-search"
          labelCol={{ span: 24 }}
          name="name"
          rules={[
            {
              required: true,
              message: (
                <span style={{ color: "red" }}>Please enter medicine</span>
              ), // Customize color
            },
          ]}
          style={{ marginBottom: "24px" }}
        >
          <AutoComplete
            options={data && data}
            className="search"
            onSearch={optimizedSearch}
            onSelect={handleSelect}
            onChange={(value) => {
              setSearchedText(value);
              optimizedSearch(value);
            }}
            value={searchedText}
          >
            <Input
              size="large"
              prefix={<SearchOutlined />}
              placeholder="Search Medicine"
              style={{ width: "100%" }}
              className={inputStyles.mdInput}
            />
          </AutoComplete>
        </Form.Item>
      </Col>

      <Col
        xs={24}
        sm={12}
        md={4}
        style={{ padding: "0 10px", marginTop: "20px" }}
      >
        <Form.Item
          label="Frequency"
          labelCol={{ span: 24 }}
          name="frequency"
          rules={[{ required: true, message: "Please choose frequency" }]}
        >
          <Select
            placeholder="Choose Frequency"
            onChange={handleFrequencyChange}
            style={{ width: "100%" }}
            className={inputStyles.mdSelect}
          >
            {medicineFrequency?.map((item) => (
              <Option value={item?.value} key={item?.value}>
                {item?.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <>{renderTimePickers()}</>
      </Col>

      <Col
        xs={24}
        sm={12}
        md={3}
        style={{ padding: "0 10px", marginTop: "20px" }}
      >
        <Form.Item label="Timing" labelCol={{ span: 24 }} name="dosage_timing">
          <Select
            allowClear
            style={{ width: "100%" }}
            className={inputStyles.mdSelect}
            placeholder="Timing"
          >
            {dosageTiming?.map((item) => (
              <Option value={item?.value} key={item?.value}>
                {item?.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col
        xs={24}
        sm={12}
        md={3}
        style={{ padding: "0 10px", marginTop: "20px" }}
      >
        <Form.Item label="Route" labelCol={{ span: 24 }} name="route">
          <Select
            allowClear
            className={inputStyles.mdSelect}
            style={{ width: "100%" }}
            placeholder="Route"
          >
            {medicineRoutes?.map((item) => (
              <Option value={item?.value} key={item?.value}>
                {item?.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col
        xs={24}
        sm={12}
        md={2}
        style={{ textAlign: "center", padding: "0 10px", marginTop: "20px" }}
      >
        {initial ? (
          <Form.Item
            label="Days Left"
            labelCol={{ span: 24 }}
            name="days_left_initial"
          >
            <InputNumber
              className={inputStyles.mdNumberInput}
              placeholder="Days left"
              style={{ width: "100%" }}
            />
          </Form.Item>
        ) : (
          <Form.Item
            label="Duration"
            labelCol={{ span: 24 }}
            name="duration"
            rules={[{ required: true, message: "Duration is required!" }]}
          >
            <InputNumber
              className={inputStyles.mdNumberInput}
              placeholder="Duration"
              style={{ width: "100%" }}
            />
          </Form.Item>
        )}
      </Col>

      <Col
        xs={24}
        sm={12}
        md={4}
        style={{ padding: "0 10px", marginTop: "20px" }}
      >
        <Form.Item label="Comments" labelCol={{ span: 24 }} name="comments">
          <Input.TextArea
            placeholder="Comments"
            className={inputStyles.mdInput}
            rows={2}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default SearchMedicine;
