import { Checkbox, Form } from "antd";
import React, { useState } from "react";
import {
  gLSymptomsTimelineOptions,
  gLSymptomsTypeOptions,
} from "../../../Options/historyOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const GLSymptoms = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <DeselectableRadioGroup
        label={"GL Symptoms"}
        name={["gl_symptoms_details", "symptoms_timeline"]}
        options={gLSymptomsTimelineOptions}
        form={form}
        setRefresh={setRefresh}
      />

      {form.getFieldValue(["gl_symptoms_details", "symptoms_timeline"]) ===
        "greater_than_2_weeks" && (
        <div className="subsection-card">
          <Form.Item name={["gl_symptoms_details", "symptoms"]}>
            <Checkbox.Group options={gLSymptomsTypeOptions} />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default GLSymptoms;
