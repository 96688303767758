import { Typography } from "antd";
import React from "react";
import { regularIrregularOptions } from "../../../../Options/commonOptions";
import { pulseVolumeOptions } from "../../../../Options/genExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const Pulse = ({ form }) => {
  return (
    <>
      <Typography>Pulse</Typography>
      <div className="subsection-card">
        <DeselectableRadioGroup
          name={["pulse_details", "pulse_regular"]}
          options={regularIrregularOptions}
          form={form}
        />

        <DeselectableRadioGroup
          name={["pulse_details", "pulse_regular"]}
          options={pulseVolumeOptions}
          form={form}
        />
      </div>
    </>
  );
};

export default Pulse;
