import React, { useContext } from "react";
import { Role } from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserContext";
import { getCookie } from "../../helpers/localStorage";
import { PatientTypeContext } from "../Layout/DoctorLayout/PatientTypeContextProvider";
import InPatientsCard from "./InPatientsCard";
import OutPatientsCard from "./OutPatientsCard";

const PatientCards = () => {
  const { userDetails } = useContext(UserContext);
  const defaultRole = userDetails?.currRole || getCookie(Role);
  const role = defaultRole.toLowerCase().replaceAll("_", "-");

  const { patientType } = useContext(PatientTypeContext);
  return (
    <>
      {patientType === "outPatients" ? (
        <OutPatientsCard userDetails={userDetails} role={role} />
      ) : (
        <InPatientsCard userDetails={userDetails} role={role} />
      )}
    </>
  );
};

export default PatientCards;
