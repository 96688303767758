import { Form, Input } from "antd";
import React, { useState } from "react";
import { noYesOptions } from "../../Options/commonOptions";
import { nutritionalRiskType } from "../../Options/clinicalEvaluationOptions";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const NutritionalRisk = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <DeselectableRadioGroup
        label={"Nutritional Risk"}
        name={["nutritional_risk_details", "risk"]}
        options={noYesOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue(["nutritional_risk_details", "risk"]) === "yes" && (
        <div className="subsection-card">
          <DeselectableRadioGroup
            label={"Type"}
            name={["nutritional_risk_details", "risk_type"]}
            options={nutritionalRiskType}
            form={form}
          />
          <Form.Item name={["nutritional_risk_details", "risk_description"]}>
            <Input placeholder="Reason for Risk" autoComplete="off" />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default NutritionalRisk;
