import React from "react";
import { yesNoOptions } from "../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const PatientFamilyEducation = ({ form }) => {
  return (
    <DeselectableRadioGroup
      label={"Patient Family Education"}
      name={"patient_family_education"}
      options={yesNoOptions}
      form={form}
    />
  );
};

export default PatientFamilyEducation;
