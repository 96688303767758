import { Collapse } from "antd";
import React from "react";
import DailyCommonCols from "./DailyCommonCols";

const DailyRoutine = ({ data }) => {
  return (
    <div>
      <Collapse
        expandIconPosition="right"
        className="custom-collapse"
        defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
      >
        <Collapse.Panel header="Bath Sponge" key="1">
          <DailyCommonCols data={data?.bath_sponge} />
        </Collapse.Panel>
        <Collapse.Panel header="Bowel" key="2">
          <DailyCommonCols data={data?.bowel} />
        </Collapse.Panel>
        <Collapse.Panel header="Hair Care" key="3">
          <DailyCommonCols data={data?.hair_care} />
        </Collapse.Panel>
        <Collapse.Panel header="Nail Care" key="4">
          <DailyCommonCols data={data?.nail_care} />
        </Collapse.Panel>
        <Collapse.Panel header="Oral Care" key="5">
          <DailyCommonCols data={data?.oral_care} />
        </Collapse.Panel>
        <Collapse.Panel header="Skin Care" key="6">
          <DailyCommonCols data={data?.skin_care} />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default DailyRoutine;
