import React, { useContext, useEffect } from "react";
import ClinicalFindings from "./ClinicalFindings";
import { RespiratoryInitialContext } from "../../RespiratoryTherapist/InitialInspection/RespiratoryTherapistInitialAssessmentProvider";

const RespiratoryTherapistChartAssessment = ({ form }) => {
  const { formData } = useContext(RespiratoryInitialContext);
  useEffect(() => {
    form.setFieldsValue(formData?.chart_assessment);
  }, [formData]);
  return <ClinicalFindings form={form} />;
};

export default RespiratoryTherapistChartAssessment;
