import { Descriptions, Image, Space } from "antd";
import React, { useContext } from "react";
import {
  getLabelTagInput,
  getLabelTagsArray,
} from "../../InspectionSummaries/constructTextFromValues";
import {
  cardiovascularOptions,
  neurologicalExaminationOptions,
  orthopedicOptions,
  respiratoryOptions,
  traumaOptions,
} from "../../Options/clinicalImpressionOptions";
import { DailyReviewContext } from "../../Doctor/DailyReview/DailyReviewFormProvider";
import diagnosisIcon from "../../../../assets/doctorIcons/diagnosis.svg";
const DrClinicalImpressionSummaryDR = () => {
  const { formData } = useContext(DailyReviewContext);
  const clinicalImpressionSummaryDRItems = [];
  const constructDrClinicalImpressionSummaryDR = () => {
    if (
      getLabelTagsArray(
        neurologicalExaminationOptions,
        formData?.clinical_diagnosis?.neurological_examination
      ) ||
      formData?.clinical_diagnosis?.neurological_examination_others
    ) {
      clinicalImpressionSummaryDRItems.push({
        key: "1",
        label: "Neurological Examination",
        children: (
          <>
            {getLabelTagsArray(
              neurologicalExaminationOptions,
              formData?.clinical_diagnosis?.neurological_examination
            )}
            {getLabelTagInput(
              "Other Details",
              formData?.clinical_diagnosis?.neurological_examination_others
            )}
          </>
        ),
      });
    }
    if (
      getLabelTagsArray(
        respiratoryOptions,
        formData?.clinical_diagnosis?.respiratory_diagnosis
      ) ||
      formData?.clinical_diagnosis?.respiratory_others
    ) {
      clinicalImpressionSummaryDRItems.push({
        key: "2",
        label: "Respiratory",
        children: (
          <>
            {getLabelTagsArray(
              respiratoryOptions,
              formData?.clinical_diagnosis?.respiratory_diagnosis
            )}
            {getLabelTagInput(
              "Other Details",
              formData?.clinical_diagnosis?.respiratory_others
            )}
          </>
        ),
      });
    }
    if (
      getLabelTagsArray(
        cardiovascularOptions,
        formData?.clinical_diagnosis?.cardiovascular_diagnosis
      ) ||
      formData?.clinical_diagnosis?.cardiovascular_others
    ) {
      clinicalImpressionSummaryDRItems.push({
        key: "3",
        label: "Cardiovascular",
        children: (
          <>
            {getLabelTagsArray(
              cardiovascularOptions,
              formData?.clinical_diagnosis?.cardiovascular_diagnosis
            )}
            {getLabelTagInput(
              "Other Details",
              formData?.clinical_diagnosis?.cardiovascular_others
            )}
          </>
        ),
      });
    }
    if (
      getLabelTagsArray(
        orthopedicOptions,
        formData?.clinical_diagnosis?.orthopedic_diagnosis
      )
    ) {
      clinicalImpressionSummaryDRItems.push({
        key: "4",
        label: "Orthopedic",
        children: getLabelTagsArray(
          orthopedicOptions,
          formData?.clinical_diagnosis?.orthopedic_diagnosis
        ),
      });
    }
    if (
      getLabelTagsArray(
        traumaOptions,
        formData?.clinical_diagnosis?.trauma_diagnosis
      ) ||
      formData?.clinical_diagnosis?.trauma_others
    ) {
      clinicalImpressionSummaryDRItems.push({
        key: "5",
        label: "Trauma Diagnosis",
        children: (
          <>
            {getLabelTagsArray(
              traumaOptions,
              formData?.clinical_diagnosis?.trauma_diagnosis
            )}
            {getLabelTagInput(
              "Other Details",
              formData?.clinical_diagnosis?.trauma_others
            )}
          </>
        ),
      });
    }
    if (formData?.clinical_diagnosis?.clinical_diagnosis_notes) {
      clinicalImpressionSummaryDRItems.push({
        key: "6",
        label: "Clinical Diagnosis",
        children: getLabelTagInput(
          "Diagnosis",
          formData?.clinical_diagnosis?.clinical_diagnosis_notes
        ),
      });
    }
    if (formData?.clinical_diagnosis?.final_diagnosis_notes) {
      clinicalImpressionSummaryDRItems.push({
        key: "7",
        label: "Final Diagnosis",
        children: getLabelTagInput(
          "Diagnosis",
          formData?.clinical_diagnosis?.final_diagnosis_notes
        ),
      });
    }
    return clinicalImpressionSummaryDRItems;
  };
  return (
    <div>
      <Descriptions
        bordered
        title={
          <span style={{ display: "flex", alignItems: "center" }}>
            <Image src={diagnosisIcon} width={18} preview={false} />

            <span style={{ fontSize: "16px", fontWeight: "normal" }}>
              Clinical Impression/Diagnosis
            </span>
          </span>
        }
        column={1}
        items={constructDrClinicalImpressionSummaryDR()}
        className="initial-insp-desc"
      />
    </div>
  );
};

export default DrClinicalImpressionSummaryDR;
