import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button, Typography, Skeleton, Empty } from "antd";
import btnStyles from "../../../styles/Custom/Button.module.css";
import DietForm from "./DietForm";
import { PlusOutlined } from "@ant-design/icons";
import { getDietApi } from "../../../services/diet.services";
import DietTableEdit from "./DietTableEdit";
import CustomLayout from "../../Layout/CustomLayout";
import SideMenu from "../../Layout/CustomLayout/SideMenu";
import typoStyles from "../../../styles/Custom/Typography.module.css";
import { UserContext } from "../../../context/UserContext";
import { getCookie } from "../../../helpers/localStorage";
import { Role } from "../../../constants/defaultKeys";

const DietMenu = () => {
  const { userDetails } = useContext(UserContext);
  const [isVisible, setIsVisible] = useState(false);
  const [dietData, setDietData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    getDietApi(`page=${page}`)
      .then((res) => {
        setDietData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh, page]);
  const defaultRole = userDetails?.currRole || getCookie(Role);
  return (
    <CustomLayout
      header={<Typography className={typoStyles.h6}>Diet Menu</Typography>}
      sideContent={<SideMenu />}
    >
      <>
        {dietData?.length < 0 ? (
          <Row className="facilities">
            {defaultRole === "Facility_Admin" ? (
              <Empty />
            ) : (
              <Col span={24} style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  onClick={() => setIsVisible(true)}
                  className={btnStyles.lgBtn}
                >
                  <PlusOutlined /> Diet Menu
                </Button>
              </Col>
            )}
          </Row>
        ) : (
          <Row gutter={[12, 12]}>
            {defaultRole !== "Facility_Admin" && (
              <Col span={24} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  onClick={() => setIsVisible(true)}
                  className={btnStyles.lgBtn}
                >
                  <PlusOutlined /> Menu Item
                </Button>
              </Col>
            )}

            <Col span={24}>
              <DietTableEdit
                data={dietData}
                setData={setDietData}
                refreshPage={refreshPage}
                loading={loading}
                page={page}
                setPage={setPage}
                userDetails={userDetails}
              />
            </Col>
          </Row>
        )}
      </>

      {isVisible && (
        <DietForm
          open={isVisible}
          setOpen={setIsVisible}
          refreshPage={refreshPage}
        />
      )}
    </CustomLayout>
  );
};

export default DietMenu;
