import { Button, Col, Row, Segmented, Space } from "antd";
import React, { useEffect, useState } from "react";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { PlusOutlined } from "@ant-design/icons";
import SpeechSwallowReviewSummary from "./SpeechSwallowReviewSummary";
import SpeechSwallowReviewForm from "./SpeechSwallowReviewForm";
import dayjs from "dayjs";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import noReview from "../../../../assets/doctorIcons/noReview.svg";
import LoadingIndicator from "../../../Loaders/LoadingIndicator";

const SpeechSwallowReview = ({
  refreshPage,
  reviewData,
  iAssessment,
  patientData,
}) => {
  const [visible, setVisible] = useState(false);
  const [activeSegment, setActiveSegment] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [addMode, setAddMode] = useState(false);
  // const [newReview, setNewReview] = useState(false);
  const [dailyRefresh, setDailyRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const setDailyReviewRefresh = () => {
    setDailyRefresh((prev) => !prev);
  };

  useEffect(() => {
    if (reviewData) setLoading(false);
    if (reviewData?.length > 0) {
      setActiveSegment(reviewData[0]?.id);
    }
  }, [reviewData]);

  const segmentData = reviewData?.map((item) => ({
    label: `${dayjs(item?.created_at).format("MMM DD")}, ${dayjs(
      item?.created_at
    ).format("h:mm A")}`,
    value: item?.id,
  }));

  const handleAddReview = () => {
    setEditMode(false);
    setActiveSegment(null);
    setVisible(true);
    setAddMode(true);
    // setNewReview(reviewData.length === 0);
  };

  const handleEditReview = () => {
    setEditMode(true);
    setVisible(true);
  };

  return (
    <>
      {loading ? (
        <LoadingIndicator message={"Loading..."} />
      ) : (
        <>
          {reviewData?.length > 0 ? (
            <Row gutter={[12, 12]}>
              <Col span={24} style={{ textAlign: "right" }}></Col>
              <Space>
                <Button
                  type="primary"
                  onClick={handleAddReview}
                  className={`${btnStyles.lgBtn} inv-btn `}
                >
                  <PlusOutlined /> Review
                </Button>
                <Segmented
                  className="segment-wrap"
                  value={activeSegment}
                  options={segmentData}
                  onChange={setActiveSegment}
                  size="small"
                />
              </Space>
              <Col span={24} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  onClick={handleEditReview}
                  className={`${btnStyles.lgBtn} inv-btn `}
                  disabled={!activeSegment}
                >
                  Edit
                </Button>
              </Col>
              <Col span={24} style={{ textAlign: "center" }}>
                <SpeechSwallowReviewSummary
                  editId={activeSegment}
                  dailyRefresh={setDailyReviewRefresh}
                />
              </Col>
            </Row>
          ) : (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "80vh",
              }}
            >
              <Col span={24} style={{ textAlign: "center" }}>
                <NoDataPage
                  title={"Review Not Yet Submitted "}
                  subtitle={"Kindly, upload review here"}
                  image={noReview}
                />
                <Button
                  type="primary"
                  onClick={handleAddReview}
                  className={`${btnStyles.lgBtn} inv-btn `}
                >
                  Review Form
                </Button>
              </Col>
            </Row>
          )}
        </>
      )}
      {visible && (
        // <DailyReviewFormProvider>
        <SpeechSwallowReviewForm
          visible={visible}
          setVisible={setVisible}
          refreshPage={refreshPage}
          editId={editMode ? activeSegment : null}
          // newReview={reviewData.length === 0}
          iAssessment={iAssessment}
          // reviewId={reviewId}
          addMode={addMode}
          setAddMode={setAddMode}
          setActiveSegment={setActiveSegment}
          reviewData={reviewData}
          setDailyReviewRefresh={setDailyReviewRefresh}
          dailyRefresh={dailyRefresh}
          segmentData={segmentData}
          patientData={patientData}
        />
        // </DailyReviewFormProvider>
      )}
    </>
  );
};

export default SpeechSwallowReview;
