import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Form,
  Table,
  InputNumber,
  Checkbox,
  Select,
  Button,
  Space,
} from "antd";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import styles from "../../../../styles/Custom/Dashboard/Dashboard.module.css";

const priceTagList = [
  { name: "With tax", id: true },
  { name: "Without tax", id: false },
];
const FacilityServiceModal = ({
  visible,
  setVisible,
  facilities,
  formValues,
  setFacilityPrice,
  editListData,
  facilityPrice,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  console.log(editListData?.facility_services);
  const initialFacilities = facilities?.map((facility) => {
    const existingFacility = editListData?.facility_services?.find(
      (item) => item.facility?.id === facility.id
    );
    return {
      ...facility,
      is_available: existingFacility?.is_available ?? true, // default to true if not set
      cash_withtax: existingFacility?.cash_withtax ?? false, // default to false if not set
      insurance_withtax: existingFacility?.insurance_withtax ?? false, // default to false if not set
      cash_price: existingFacility?.cash_price ?? formValues?.cash_price, // default to formValues.cash_price if not set
      insurance_price:
        existingFacility?.insurance_price ?? formValues?.insurance_price, // default to formValues.insurance_price if not set
    };
  });
  useEffect(() => {
    console.log(facilityPrice, initialFacilities, "ddddddddddddddddd");
    form.setFieldsValue({
      facilities: facilityPrice ? facilityPrice : initialFacilities,
    });
  }, [facilities, editListData, form]);

  const customInputForm = (index, key, initialValue, taxkey, taxInValue) => {
    return (
      <Space.Compact>
        <Form.Item
          name={["facilities", index, key]}
          rules={[
            {
              message: "Please enter a valid price which is less than 999999/-",
              pattern: new RegExp("^[0-9]{0,6}(\\.[0-9]+)?$"),
            },
          ]}
          initialValue={initialValue}
        >
          <InputNumber
            placeholder="Enter price...."
            style={{ width: "100%" }}
            className={inputStyles.mdNumberInput}
          />
        </Form.Item>
        <Form.Item
          name={["facilities", index, taxkey]}
          style={{ width: "50%" }}
          initialValue={taxInValue}
        >
          <Select className={inputStyles.mdSelect} defaultValue={false}>
            {priceTagList?.map((data) => {
              return (
                <Select.Option key={data?.id} value={data?.id}>
                  {data?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Space.Compact>
    );
  };

  const columns = [
    {
      title: "Facilities",
      dataIndex: "name",
      key: "name",

      render: (value, record, index) => {
        // Check if the record has 'name' directly, otherwise check for the nested 'facility' object
        const facilityName =
          value || (record?.facility && record?.facility?.name) || record?.id;
        return facilityName;
      },
    },
    {
      title: "Is Service available",
      dataIndex: "is_available",
      key: "is_available",

      render: (_, record, index) => (
        <Form.Item
          name={["facilities", index, "is_available"]}
          valuePropName="checked"
          initialValue={true} // Default value for the checkbox
        >
          <Checkbox />
        </Form.Item>
      ),
    },

    {
      title: "Facility Cash Price",
      dataIndex: "cash_price",
      key: "cash_price",

      render: (_, record, index) =>
        customInputForm(
          index,
          "cash_price",
          formValues?.cash_price || editListData?.cash_price,
          "cash_withtax",
          formValues?.cash_withtax || editListData?.cash_withtax
        ),
    },
    {
      title: "Facility Insurance Price",
      dataIndex: "insurance_price",
      key: "insurance_price",

      render: (_, record, index) =>
        customInputForm(
          index,
          "insurance_price",
          formValues?.insurance_price || editListData?.insurance_price,
          "insurance_withtax",
          formValues?.insurance_withtax || editListData?.insurance_withtax
        ),
    },
  ];

  const onFinish = (values) => {
    const formData = values?.facilities?.map((facilityData, index) => {
      // Check for existing facility in editListData
      const existingFacility = editListData?.facility_services?.find(
        (item) => item.facility?.id === facilities[index]?.id
      );

      if (existingFacility) {
        return {
          ...facilityData,
          facility: existingFacility.facility?.id, // Use existing facility ID
          id: existingFacility.id, // Use existing facility service ID
        };
      }

      // For new facilities, ensure facility ID exists in facilities array
      const facilityFromList = facilities[index];
      if (!facilityFromList || !facilityFromList.id) {
        return null; // Skip invalid entries
      }

      return {
        ...facilityData,
        facility: facilityFromList.id, // Use facility ID from facilities list
      };
    });

    // Filter out null entries
    const cleanedData = formData.filter((item) => item !== null);

    setFacilityPrice(cleanedData);
    setVisible(false);
  };

  return (
    <>
      <Modal
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={"80%"}
        className={styles.test}
        title=" Facilitywise Availability and Pricing"
        footer={
          <div className="flex-end" style={{ gap: "24px" }}>
            <Form.Item>
              <Button
                type="primary"
                ghost
                className={btnStyles.mdBtn}
                onClick={() => setVisible(false)}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.submit();
                  }
                }}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        }
      >
        <Form
          ref={formRef}
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={{
            facilities: facilities?.map(() => ({
              is_available: true,
              cash_withtax: false,
              insurance_withtax: false,
            })),
          }}
        >
          <Table
            columns={columns}
            dataSource={
              editListData &&
              editListData?.facility_services &&
              editListData?.facility_services?.length > 0
                ? facilities.map((facility) => {
                    // Check if facility already exists in editListData
                    const existingFacility =
                      editListData.facility_services.find(
                        (item) => item.facility?.id === facility.id
                      );
                    // Use the existing data if available, or fallback to the new facility
                    return existingFacility || facility;
                  })
                : facilities
            }
            pagination={false}
          />
        </Form>
      </Modal>
    </>
  );
};

export default FacilityServiceModal;
{
  /* <div
style={{
  display: "flex",
  gap: "12px",
  paddingTop: "12px",
  justifyContent: "flex-end",
}}
>
<Form.Item>
  <Button
    type="primary"
    ghost
    className={btnStyles.mdBtn}
    onClick={() => setVisible(false)}
  >
    Cancel
  </Button>
</Form.Item>
<Form.Item>
  <Button
    htmlType="submit"
    type="primary"
    className={btnStyles.mdBtn}
  >
    Save
  </Button>
</Form.Item>
</div> */
}
