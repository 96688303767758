import React, { useContext } from "react";
import {
  getLabelTag,
  getLabelTagInput,
  getLabelTagsWithDescription,
  getLabelTagsWithSelectItems,
} from "../../InspectionSummaries/constructTextFromValues";
import { Descriptions, Image, Tag, Typography } from "antd";
import {
  abdomenAppearanceOptions,
  abnormalBreathZoneOptions,
  auscultationOptions,
  bowelMovementOptions,
  breathSoundsOptions,
  heartSoundsOptions,
  jvpOptions,
  periPulsesVolumeOptions,
  typeOfBreathingOptions,
} from "../../Options/systemicExamOptions";
import {
  presentAbsentOptions,
  regularIrregularOptions,
} from "../../Options/commonOptions";
import { DailyReviewContext } from "../../Doctor/DailyReview/DailyReviewFormProvider";
import cardioIcon from "../../../../assets/doctorIcons/cardio-vascular.svg";
import respiratoryIcon from "../../../../assets/doctorIcons/lungs.svg";
import abdomenIcon from "../../../../assets/doctorIcons/stomach.svg";
import musculoSkeletalIcon from "../../../../assets/doctorIcons/anatomy.svg";
import centralNervousSystemIcon from "../../../../assets/doctorIcons/cns.svg";
import ambulationIcon from "../../../../assets/doctorIcons/ambulance.svg";

const DrSystemicExaminationSummaryDR = () => {
  const { formData } = useContext(DailyReviewContext);

  const constructCardioVascularSystemSummaryDRItems = () => {
    const cardioVascularSystemSummaryDRItems = [];

    if (
      formData?.systemic_examination?.peripheral_regular ||
      formData?.systemic_examination?.peripheral_volume ||
      formData?.systemic_examination?.peripheral_desc
    ) {
      cardioVascularSystemSummaryDRItems.push({
        key: "1",
        label: "Peripheral Pulses",
        children: (
          <>
            {getLabelTag(
              regularIrregularOptions,
              formData?.systemic_examination?.peripheral_regular
            )}
            {getLabelTag(
              periPulsesVolumeOptions,
              formData?.systemic_examination?.peripheral_volume
            )}

            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.peripheral_desc
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.jvp) {
      cardioVascularSystemSummaryDRItems.push({
        key: "2",
        label: "JVP",
        children: getLabelTag(jvpOptions, formData?.systemic_examination?.jvp),
      });
    }
    if (
      getLabelTagsWithDescription(
        heartSoundsOptions,
        formData?.systemic_examination
      )
    ) {
      cardioVascularSystemSummaryDRItems.push({
        key: "3",
        label: "Heart Sounds",
        children: getLabelTagsWithDescription(
          heartSoundsOptions,
          formData?.systemic_examination
        ),
      });
    }
    return cardioVascularSystemSummaryDRItems;
  };

  const constructRespiratorySystemSummaryDRItems = () => {
    const respiratorySystemSummaryDRItems = [];

    if (formData?.systemic_examination?.type_of_breathing) {
      respiratorySystemSummaryDRItems.push({
        key: "1",
        label: "Type of Breathing",
        children: (
          <>
            {getLabelTag(
              typeOfBreathingOptions,
              formData?.systemic_examination?.type_of_breathing
            )}
            {getLabelTagInput(
              "Other details",
              formData?.systemic_examination?.type_of_breathing_others
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.ausculation) {
      respiratorySystemSummaryDRItems.push({
        key: "2",
        label: "Auscultation",
        children: getLabelTag(
          auscultationOptions,
          formData?.systemic_examination?.ausculation
        ),
      });
    }
    if (
      getLabelTagsWithSelectItems(
        abnormalBreathZoneOptions,
        breathSoundsOptions,
        formData?.systemic_examination
      )
    ) {
      respiratorySystemSummaryDRItems.push({
        key: "3",
        label: "Abnormality",
        children: getLabelTagsWithSelectItems(
          abnormalBreathZoneOptions,
          breathSoundsOptions,
          formData?.systemic_examination
        ),
      });
    }
    return respiratorySystemSummaryDRItems;
  };
  const constructAbdomenSummaryDRItems = () => {
    const abdomenSummaryDRItems = [];

    if (formData?.systemic_examination?.appearance) {
      abdomenSummaryDRItems.push({
        key: "1",
        label: "Appearance",
        children: getLabelTag(
          abdomenAppearanceOptions,
          formData?.systemic_examination?.appearance
        ),
      });
    }
    if (formData?.systemic_examination?.bowel_sounds) {
      abdomenSummaryDRItems.push({
        key: "2",
        label: "Bowel Sounds",
        children: getLabelTag(
          presentAbsentOptions,
          formData?.systemic_examination?.bowel_sounds
        ),
      });
    }
    if (formData?.systemic_examination?.last_bowel_movement) {
      abdomenSummaryDRItems.push({
        key: "3",
        label: "Bowel Movement",
        children: getLabelTag(
          bowelMovementOptions,
          formData?.systemic_examination?.bowel_movement
        ),
      });
    }
    if (formData?.systemic_examination?.last_bowel_movement) {
      abdomenSummaryDRItems.push({
        key: "4",
        label: "Last Bowel Moment",
        children: (
          <Tag
            bordered={false}
            className="break-line-tag"
            key={"last_bowel_movement"}
          >
            {formData?.systemic_examination?.last_bowel_movement}
          </Tag>
        ),
      });
    }

    return abdomenSummaryDRItems;
  };

  const constructMusculoSkeletalSummaryDRItems = () => {
    const musculoSkeletalSummaryDRItems = [];

    if (formData?.systemic_examination?.musculo_skeletal_data) {
      musculoSkeletalSummaryDRItems.push({
        key: "5",
        label: "Musculo Skeletal System",
        children: getLabelTagInput(
          "Details",
          formData?.systemic_examination?.musculo_skeletal_data
        ),
      });
    }
    return musculoSkeletalSummaryDRItems;
  };
  const constructCentralNervousSystemSummaryItems = () => {
    const centralNervousSystemSummaryDRItems = [];

    if (formData?.systemic_examination?.cns_data) {
      centralNervousSystemSummaryDRItems.push({
        key: "6",
        label: "Central Nervous System",
        children: getLabelTagInput(
          "Details",
          formData?.systemic_examination?.cns_data
        ),
      });
    }
    return centralNervousSystemSummaryDRItems;
  };
  const constructAmbulationSummaryDRItem = () => {
    const ambulationSummaryDRItem = [];

    if (formData?.systemic_examination?.ambulation_desc) {
      ambulationSummaryDRItem.push({
        key: "7",
        label: "Ambulation",
        children: getLabelTagInput(
          "Details",
          formData?.systemic_examination?.ambulation_desc
        ),
      });
    }
    return ambulationSummaryDRItem;
  };
  const cardioVascularSystemSummaryDRItems =
    constructCardioVascularSystemSummaryDRItems();
  const respiratorySystemSummaryDRItems =
    constructRespiratorySystemSummaryDRItems();
  const abdomenSummaryDRItems = constructAbdomenSummaryDRItems();
  const musculoSkeletalSummaryDRItems =
    constructMusculoSkeletalSummaryDRItems();
  const centralNervousSystemSummaryDRItems =
    constructCentralNervousSystemSummaryItems();
  const ambulationSummaryDRItems = constructAmbulationSummaryDRItem();

  return (
    <div>
      {cardioVascularSystemSummaryDRItems?.length > 0 && (
        <Descriptions
          bordered
          title={
            <span style={{ fontWeight: "500", fontSize: "16px" }}>
              <span style={{ display: "inline-block", marginRight: "5px" }}>
                <Image src={cardioIcon} width="18px" preview={false} />
              </span>
              Cardio Vascular System
            </span>
          }
          column={1}
          items={cardioVascularSystemSummaryDRItems}
          style={{ padding: "10px", textAlign: "left" }}
          className="initial-insp-desc"
        />
      )}
      {respiratorySystemSummaryDRItems?.length > 0 && (
        <Descriptions
          bordered
          title={
            <span style={{ fontWeight: "500", fontSize: "16px" }}>
              <span style={{ display: "inline-block", marginRight: "5px" }}>
                <Image src={respiratoryIcon} width="18px" preview={false} />
              </span>
              Respiratory System
            </span>
          }
          column={1}
          items={respiratorySystemSummaryDRItems}
          style={{ padding: "10px", textAlign: "left" }}
          className="initial-insp-desc"
        />
      )}
      {abdomenSummaryDRItems?.length > 0 && (
        <Descriptions
          bordered
          title={
            <span style={{ fontWeight: "500", fontSize: "16px" }}>
              <span style={{ display: "inline-block", marginRight: "5px" }}>
                <Image src={abdomenIcon} width="18px" preview={false} />
              </span>
              Abdomen
            </span>
          }
          column={1}
          items={abdomenSummaryDRItems}
          style={{ padding: "10px", textAlign: "left" }}
          className="initial-insp-desc"
        />
      )}
      {musculoSkeletalSummaryDRItems?.length > 0 && (
        <Descriptions
          bordered
          title={
            <span style={{ fontWeight: "500", fontSize: "16px" }}>
              <span style={{ display: "inline-block", marginRight: "5px" }}>
                <Image src={musculoSkeletalIcon} width="18px" preview={false} />
              </span>
              Musculo Skeletal
            </span>
          }
          column={1}
          items={musculoSkeletalSummaryDRItems}
          style={{ padding: "10px", textAlign: "left" }}
          className="initial-insp-desc"
        />
      )}
      {centralNervousSystemSummaryDRItems?.length > 0 && (
        <Descriptions
          bordered
          title={
            <span style={{ fontWeight: "500", fontSize: "16px" }}>
              <span style={{ display: "inline-block", marginRight: "5px" }}>
                <Image
                  src={centralNervousSystemIcon}
                  width="18px"
                  preview={false}
                />
              </span>
              Central Nervous System
            </span>
          }
          column={1}
          items={centralNervousSystemSummaryDRItems}
          style={{ padding: "10px", textAlign: "left" }}
          className="initial-insp-desc"
        />
      )}
      {ambulationSummaryDRItems?.length > 0 && (
        <Descriptions
          bordered
          title={
            <span style={{ fontWeight: "500", fontSize: "16px" }}>
              <span style={{ display: "inline-block", marginRight: "5px" }}>
                <Image src={ambulationIcon} width="18px" preview={false} />
              </span>
              Ambulation
            </span>
          }
          column={1}
          items={ambulationSummaryDRItems}
          style={{ padding: "10px", textAlign: "left" }}
          className="initial-insp-desc"
        />
      )}
    </div>
  );
};

export default DrSystemicExaminationSummaryDR;
