import React, { useState, useEffect, useContext } from "react";
import { Card, Row, Col, Button, Typography, Space, Segmented } from "antd";
import btnStyles from "../../../styles/Custom/Button.module.css";
import textStyles from "../../../styles/Custom/Typography.module.css";
import BedForm from "./BedForm";
import Buildings from "./FloorPlan/Buildings";
import RoomTypeForm from "./RoomTypeForm";
import {
  getAllBedsListApi,
  getRoomTypeApi,
} from "../../../services/beds.services";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";
import BedTable from "./BedTable";
import { UserContext } from "../../../context/UserContext";
import { getCookie } from "../../../helpers/localStorage";
import { Role } from "../../../constants/defaultKeys";

const BedManagement = ({ id }) => {
  const { userDetails } = useContext(UserContext);
  const [isVisible, setIsVisible] = useState(false);
  const [activeSegment, setActiveSegment] = useState("Floor Plan");
  const [open, setOpen] = useState(false);
  const items = ["Floor Plan", "Beds Table"];
  const [refresh, setRefresh] = useState(false);
  const [roomTypes, setRoomTypes] = useState([]);
  const [bedList, setBedList] = useState([]);
  const [bedRefresh, setBedRefresh] = useState(false);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };
  const refreshBedPage = () => {
    setBedRefresh((prev) => !prev);
  };
  useEffect(() => {
    getRoomTypeApi()
      .then((res) => setRoomTypes(res?.data))
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  useEffect(() => {
    if (id) {
      getAllBedsListApi(id)
        .then((res) => setBedList(res?.data))
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, bedRefresh, activeSegment]);

  const onChange = (e) => {
    setActiveSegment(e);
  };
  const defaultRole = userDetails?.currRole || getCookie(Role);
  return (
    <>
      <Card
        title={
          <Space>
            <Typography>Bed Management</Typography>{" "}
            <Segmented
              options={items}
              value={activeSegment}
              onChange={onChange}
            />
          </Space>
        }
        extra={
          defaultRole === "Facility_Admin" ? null : (
            <span
              onClick={() => setOpen(true)}
              style={{ cursor: "pointer", fontSize: "11px", fontWeight: 600 }}
            >
              <SettingOutlined /> Manage Room types
            </span>
          )
        }
        style={{
          borderRadius: "16px",
          border: "1px solid #EAECF0",
        }}
      >
        {activeSegment === "Beds Table" ? (
          <>
            {bedList && bedList?.length > 0 ? (
              <Row gutter={[12, 12]}>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    onClick={() => setIsVisible(true)}
                    className={btnStyles.lgBtn}
                  >
                    <PlusOutlined /> Bed
                  </Button>
                </Col>
                <Col span={24}>
                  <BedTable
                    data={bedList}
                    refreshBedPage={refreshBedPage}
                    facilityId={id}
                  />
                </Col>
              </Row>
            ) : (
              <Row
                style={{
                  height: "40vh",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Col span={6} style={{ textAlign: "center" }}>
                  <Space direction="vertical">
                    {/* <Typography className={textStyles.body3}>
                      Lorem ipsum dolor sit amet consectetur. Eget et ipsum sed
                      orci dictum.
                    </Typography> */}

                    <Button
                      type="primary"
                      onClick={() => setIsVisible(true)}
                      className={btnStyles.lgBtn}
                    >
                      <PlusOutlined /> Bed
                    </Button>
                  </Space>
                </Col>
              </Row>
            )}
          </>
        ) : (
          <Row>
            <Col span={24}>
              <Buildings facilityId={id} refreshRoom={refresh} />
            </Col>
          </Row>
        )}
      </Card>
      {isVisible && (
        <BedForm
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          facilityId={id}
          refreshBedPage={refreshBedPage}
        />
      )}
      {open && (
        <RoomTypeForm
          open={open}
          setOpen={setOpen}
          editListData={roomTypes}
          refresh={refresh}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default BedManagement;
