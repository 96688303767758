import { UserOutlined } from "@ant-design/icons";
import { Avatar, Flex, Tooltip, Typography } from "antd";
import React from "react";
import typographyStyles from "../../styles/Custom/Typography.module.css";
import { colors } from "../../helpers/utility";

function CustomAvatar({
  indexId = 0,
  name,
  url,
  displayName = false,
  size = 40,
}) {
  function stringToNumber(str) {
    let hash = 0;
    for (let i = 0; i < str?.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
    }
    return Math.abs(hash);
  }

  const numericUserId = stringToNumber(String(indexId));
  const colorIndex = numericUserId % 24;
  const selectedColor = colors[colorIndex];

  return (
    <Flex align="center">
      <Tooltip title={name}>
        <Avatar
          style={{
            backgroundColor: url ? "" : selectedColor,
            cursor: "pointer",
          }}
          src={url && url !== "" ? url : null}
          size={size}
        >
          {name ? name?.slice(0, 1).toUpperCase() : <UserOutlined />}
        </Avatar>
      </Tooltip>
      {displayName && (
        <Typography
          ellipsis={true}
          className={typographyStyles.body3}
          style={{ marginLeft: "10px" }}
        >
          {name}
        </Typography>
      )}
    </Flex>
  );
}

export default CustomAvatar;
