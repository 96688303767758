import React, { useContext, useEffect, useState } from "react";
import { Button, Row, Col, Drawer } from "antd";
import { NurseInitialContext } from "../../InspectionForms/HistoryForm/NurseInitialAssessmentFormProvider";
import NurseInitialInspectionForm from "./NurseInitialInspectionForm";
import { getNurseInspectionAPI } from "../../../../services/nurse.services";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import NurseInitialInspectionSummary from "./NurseInitialInspectionSummary";
import { EditOutlined } from "@ant-design/icons";
import ClosePopup from "../../Doctor/InitialInspection/ClosePopup";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import assessmentIcon from "../../../../assets/images/nurseIcons/assessment.svg";
import useQuery from "../../../../hooks/useQuery";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { getCookie } from "../../../../helpers/localStorage";
import { Role } from "../../../../constants/defaultKeys";

const NurseInitialInspection = ({ refreshPage, editId, patientData }) => {
  const { userDetails } = useContext(UserContext);
  const [visible, setVisible] = useState(false);
  const { formData, setFormData } = useContext(NurseInitialContext);
  const [initialRefresh, setInitialRefresh] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();

  const setInitialAssessmentRefresh = () => {
    setInitialRefresh((prev) => !prev);
  };

  const openEdit = async () => {
    if (editId) {
      try {
        const response = await getNurseInspectionAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    }
  };

  const handleEditClick = () => {
    setVisible(true);
    setAddModal(false);
    const query = new URLSearchParams(location.search);
    query.set("edit", "t");
    navigate(`?${query.toString()}`);
  };
  const removeUrlParams = () => {
    const query = new URLSearchParams(location.search);
    query.delete("tab");
    query.delete("edit");
    navigate(`?${query.toString()}`);
  };

  useEffect(() => {
    openEdit();
    if (query.edit === "t") {
      setVisible(true);
    }
  }, [editId, initialRefresh]);

  console.log("formData", formData);
  const editRole = getCookie(Role) || userDetails?.currRole;
  return (
    <>
      {editRole === "Nurse" ? (
        <>
          {" "}
          {editId ? (
            <Row>
              <Col span={24} style={{ textAlign: "right" }}>
                <Button
                  className="inv-btn"
                  type="primary"
                  style={{
                    color: "white",
                    marginRight: "15px",
                  }}
                  onClick={handleEditClick}
                >
                  <EditOutlined />
                  Edit
                </Button>
              </Col>
              <Col span={24} style={{ textAlign: "left" }}>
                <NurseInitialInspectionSummary editId={editId} />
              </Col>
            </Row>
          ) : (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "80vh",
              }}
            >
              <Col span={24} style={{ textAlign: "center" }}>
                <NoDataPage
                  title={"No assessment has been done"}
                  subtitle={
                    "Kindly, fill the following form to continue initial assessment"
                  }
                  image={assessmentIcon}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    setVisible(true);
                    setAddModal(true);
                  }}
                  className={`${btnStyles.lgBtn} inv-btn`}
                >
                  Initial Assessment Form
                </Button>
              </Col>
            </Row>
            // <LoadingIndicator message={"Loading inspection data, please wait"} />
          )}
          {visible && (
            <Drawer
              open={visible}
              width="100%"
              footer={null}
              onClose={() => setVisible(false)}
              closeIcon={null}
              keyboard={false}
            >
              <NurseInitialInspectionForm
                visible={visible}
                setVisible={setVisible}
                setConfirmModal={setConfirmModal}
                refreshPage={refreshPage}
                initialRefresh={setInitialAssessmentRefresh}
                editId={editId}
                patientData={patientData}
                removeUrlParams={removeUrlParams}
              />
              {confirmModal && (
                <ClosePopup
                  open={confirmModal}
                  setOpen={setConfirmModal}
                  setVisible={setVisible}
                  removeUrlParams={removeUrlParams}
                />
              )}
            </Drawer>
          )}
        </>
      ) : (
        <Col span={24} style={{ textAlign: "left" }}>
          {editId ? (
            <NurseInitialInspectionSummary editId={editId} />
          ) : (
            <NoDataPage
              title={"Nurse Initial Assessment Not Yet Submitted "}
              image={assessmentIcon}
            />
          )}
        </Col>
      )}

      {/* <NurseInitialInspectionForm /> */}
    </>
  );
};

export default NurseInitialInspection;
