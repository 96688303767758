import { Form, Radio, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { notAssessableAndAssessableOptions } from "../../../../Options/commonOptions";
import TCMItems from "../../PhysioScales/TCMScaleComponents/TCMItems";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const TCMAssessment = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context);

  const [states, setStates] = useState({});
  const handleChange = (value, key) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };
  useEffect(() => {
    setStates(
      Object?.fromEntries(
        Object?.entries(formData?.systemic_examination?.tcms || {})?.filter(
          ([key]) =>
            [
              "selective_movement_control",
              "sitting_balance",
              "sitting_to_standing",
            ]?.includes(key)
        )
      ) || {}
    );
  }, [formData]);
  const score = Object.values(states).reduce((acc, cur) => acc + cur, 0);
  return (
    <>
      <Typography>Trunk Control Management Scale</Typography>
      <Typography>Score: {score}</Typography>

      <DeselectableRadioGroup
        name={["tcms", "status"]}
        options={notAssessableAndAssessableOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue(["tcms", "status"]) === "assessable" && (
        <div className="subsection-card">
          <TCMItems handleChange={handleChange} nameKey={"tcms"} />
        </div>
      )}
    </>
  );
};

export default TCMAssessment;
