import { Col, Form, List, Radio, Row, Typography } from "antd";
import React from "react";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
import { memoryFieldsOptions } from "../../../../Options/physioOptions";

const Memory = ({ psych = false }) => {
  return (
    <>
      <List
        bordered={false}
        header={
          <Row style={{ width: "100%", textAlign: "center" }} align="middle">
            <Col span={12}></Col>
            <Col span={6}>
              <Typography.Text strong>First Test</Typography.Text>
            </Col>
            <Col span={6}>
              <Typography.Text strong>Second Test</Typography.Text>
            </Col>
          </Row>
        }
        style={{ width: psych === true ? "100%" : "600px", padding: "20px" }}
        dataSource={memoryFieldsOptions}
        renderItem={(field) => (
          <List.Item>
            <Row style={{ width: "100%" }} align="middle">
              <Col span={12}>
                <Typography>{field.label}</Typography>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={
                    psych === true
                      ? ["moca", "memory_data", `${field.value}_1`]
                      : ["memory_data", `${field.value}_1`]
                  }
                  key={`${field.value}_1`}
                >
                  <Radio.Group style={{ width: "100%" }}>
                    <Row justify="center" gutter={[8, 0]} align={"middle"}>
                      <Col>
                        <Radio.Button value={"yes"} className="like-btn">
                          <LikeOutlined />
                        </Radio.Button>
                      </Col>
                      <Col>
                        <Radio.Button value={"no"} className="dislike-btn">
                          <DislikeOutlined />
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={
                    psych === true
                      ? ["moca", "memory_data", `${field.value}_2`]
                      : ["memory_data", `${field.value}_2`]
                  }
                  key={`${field.value}_2`}
                >
                  <Radio.Group style={{ width: "100%" }}>
                    <Row justify="center" gutter={[8, 0]}>
                      <Col>
                        <Radio.Button value={"yes"} className="like-btn">
                          <LikeOutlined />
                        </Radio.Button>
                      </Col>
                      <Col>
                        <Radio.Button className="dislike-btn" value={"no"}>
                          <DislikeOutlined />
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </>
  );
};

export default Memory;
