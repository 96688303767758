import { Col, Collapse, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SkinForm from "./SkinForm/SkinForm";
import CardioForm from "./CardioForm/CardioForm";
import RespiratoryForm from "./RespiratoryForm/RespiratoryForm";
import MusculoSkeletalForm from "./MusculoSkeletalForm/MusculoSkeletalForm";
import CNSForm from "./CNSForm/CNSForm";
import { PhysioInitialContext } from "../../../Physiotherapist/PhysiotherapistInitialInspection/PhysiotherapistInitialAssessmentProvider";
const { Panel } = Collapse;

const PhysioSystemicExaminationForm = ({ form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(PhysioInitialContext);
  useEffect(() => {
    form.setFieldsValue(formData?.systemic_examination);
    setTimeout(() => setRefresh((prev) => !prev), 1000);
  }, [formData]);
  return (
    <div className="ia">
      {/* <Row gutter={[16, 16]} className="ia">
      <Col md={24} xl={12}> */}
      <Collapse defaultActiveKey={["1"]}>
        <Panel header="Skin" key="1">
          <SkinForm context={PhysioInitialContext} form={form} />
        </Panel>
      </Collapse>
      <Collapse defaultActiveKey={["2"]}>
        <Panel header="Cardio Vascular System" key="2">
          <CardioForm context={PhysioInitialContext} form={form} />
        </Panel>
      </Collapse>
      <Collapse defaultActiveKey={["3"]}>
        <Panel header="Respiratory System" key="3">
          <RespiratoryForm context={PhysioInitialContext} form={form} />
        </Panel>
      </Collapse>
      <Collapse defaultActiveKey={["4"]}>
        <Panel header="Musculo Skeletal System" key="4">
          <MusculoSkeletalForm context={PhysioInitialContext} form={form} />
        </Panel>
      </Collapse>
      <Collapse defaultActiveKey={["5"]}>
        <Panel header="Neurological Assessment" key="5">
          <CNSForm context={PhysioInitialContext} form={form} />
        </Panel>
      </Collapse>
      {/* </Col>
      <Col md={24} xl={12}> */}

      {/* </Col>
    </Row> */}
    </div>
  );
};

export default PhysioSystemicExaminationForm;
