import { Flex, Layout, Typography, Image, Select, Avatar } from "antd";
import React, { useContext, useState } from "react";
import typoStyles from "../../../styles/Custom/Typography.module.css";
import UserSettings from "./UserSettings";
import { UserContext } from "../../../context/UserContext";
import { getCookie, setCookie } from "../../../helpers/localStorage";
import { useLocation } from "react-router-dom";
import { FacilityID, Role } from "../../../constants/defaultKeys";
import "./CustomLayout.css";
import Search from "./Search";
import { redirectToDashboard } from "../../../helpers/auth/helper";

const { Header, Footer, Content, Sider } = Layout;
const { Option } = Select;

const CommonLayout = ({
  children,
  header,
  footer,
  sideContent,
  headerExtra = true,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [collapsed, setCollapsed] = useState(false);
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [selectedFacility, setSelectedFacility] = useState(
    Number(getCookie(FacilityID)) || userDetails?.currFacility
  );
  const [selectedRole, setSelectedRole] = useState(
    getCookie(Role) || userDetails?.activeOrg?.roles?.[0]?.role
  );
  const handleFacilityChange = (value) => {
    const lastUnderscoreIndex = value.indexOf("_");

    // Extract role by slicing up to the last underscore
    const facility_id = value.slice(0, lastUnderscoreIndex);

    // Extract facility ID by slicing after the last underscore
    const role = value.slice(lastUnderscoreIndex + 1)?.replaceAll(" ", "_");

    // Update facility ID and role states separately
    setSelectedFacility(value);
    setSelectedRole(role);

    redirectToDashboard(userDetails?.activeOrg?.domain, role);
    setCookie(
      FacilityID,
      facility_id,
      `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
      "/"
    );
    setCookie(Role, role, `.${process.env.REACT_APP_PUBLIC_DOMAIN}`, "/");

    setUserDetails((prev) => ({
      ...prev,
      currFacility: facility_id,
      currRole: role,
    }));
  };

  const facilityVisible = userDetails?.currRole || getCookie(Role);

  console.log(facilityVisible);

  return (
    <Layout className={"layoutContent"}>
      {sideContent && (
        <Sider
          className={collapsed ? `siderCollapse` : `siderNoCollapse`}
          collapsed={collapsed}
          onCollapse={(e) => setCollapsed(e)}
          collapsible
          breakpoint="md"
        >
          <Flex
            style={{
              height: "64px",
              justifyContent: collapsed ? "center" : "flex-start",
              alignItems: "center",
              paddingInline: "24px",
            }}
          >
            <Typography
              className={collapsed ? typoStyles.subtitle2 : typoStyles.h6}
            >
              {userDetails?.activeOrg?.org_logo ? (
                <Image
                  preview={false}
                  src={userDetails?.activeOrg?.org_logo}
                  alt={"Logo"}
                  style={{
                    width: "50px",
                    marginTop: "10px",
                  }}
                />
              ) : collapsed ? (
                <Avatar
                  size={45}
                  style={{
                    fontSize: "20px",
                    background: userDetails?.activeOrg?.color,
                  }}
                >
                  {userDetails?.activeOrg?.org_name?.slice(0, 1).toUpperCase()}
                </Avatar>
              ) : (
                userDetails?.activeOrg?.org_name
              )}
            </Typography>
          </Flex>
          <div style={{ height: "calc(100vh - 69px)", paddingBlock: "24px" }}>
            {React.cloneElement(sideContent, { collapsed })}
          </div>
        </Sider>
      )}
      <Layout
        className={
          sideContent
            ? collapsed
              ? "contentPushWithSideandCollapse"
              : "contentPushWithSideandNoCollapse"
            : "contentPushWithoutSide"
        }
      >
        {header && (
          <Header
            className={`headerStyle ${
              sideContent
                ? collapsed
                  ? "headerWithSideAndCollapse"
                  : "headerWithSideAndNoCollapse"
                : "headerStylesWithoutSide"
            }`}
          >
            <div
              className="flex-between"
              style={{ height: "64px", paddingInline: "24px" }}
            >
              <div className="tabWebDisplay" style={{ width: "100%" }}>
                {header}
              </div>
              {headerExtra && (
                <div className="flex-end" style={{ gap: "24px" }}>
                  {/* <Search selectedFacility={selectedFacility} /> */}
                  {(facilityVisible === "Receptionist" ||
                    facilityVisible === "Facility_Admin") && (
                    <Select
                      value={`${selectedFacility}_${selectedRole.replaceAll(
                        "_",
                        " "
                      )}`}
                      onChange={handleFacilityChange}
                      style={{ width: "200px" }}
                    >
                      {userDetails?.activeOrg?.roles?.map((facility) => (
                        <Option
                          value={`${
                            facility?.facility_id
                          }_${facility?.role?.replaceAll("_", " ")}`}
                          key={`${
                            facility?.facility_id
                          }_${facility?.role?.replaceAll("_", " ")}`}
                        >
                          {`${
                            facility?.facility
                          } - ${facility?.role?.replaceAll("_", " ")}`}
                        </Option>
                      ))}
                    </Select>
                  )}
                  <UserSettings />
                </div>
              )}
            </div>
          </Header>
        )}

        <Content
          className={header ? "contentStyle" : "contentNoHeaderStyle"}
          style={{
            paddingBottom: footer ? "132px" : "32px",
          }}
        >
          {children}
        </Content>
        {footer && (
          <Footer
            className={`footerStyle ${
              sideContent
                ? collapsed
                  ? "footerStyleWithCollapsedSide"
                  : "footerStyleWithNOCollapsedSide"
                : "footerStyleWithOutSide"
            }`}
          >
            {footer}
          </Footer>
        )}
      </Layout>
    </Layout>
  );
};

export default CommonLayout;
