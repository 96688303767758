import { Form, Input } from "antd";
import React from "react";
import { activityLevelOptions } from "../../Options/RespiratoryTherapistOptions";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const ActivityLevel = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Activity Level"}
        name={["activity_level", "status"]}
        options={activityLevelOptions}
        form={form}
      />
      <Form.Item name={["activity_level", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default ActivityLevel;
