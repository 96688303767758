import React, { useContext, useEffect, useState } from "react";
import { Form, Checkbox, Input } from "antd";
import { neurologicalExaminationOptions } from "../../Options/clinicalImpressionOptions";

const NeurologicalSystem = ({ context }) => {
  const { formData } = useContext(context);
  const [showNeurologicalOthers, setShowNeurologicalOthers] = useState(
    formData?.clinical_diagnosis?.neurological_examination?.includes("others")
  );
  const handleNeurologicalExaminationChange = (selectedOptions) => {
    setShowNeurologicalOthers(selectedOptions?.includes("others"));
  };

  useEffect(() => {
    setShowNeurologicalOthers(
      formData?.clinical_diagnosis?.neurological_examination?.includes("others")
    );
  }, [formData]);

  return (
    <>
      <Form.Item
        label="Neurological Examination"
        name="neurological_examination"
      >
        <Checkbox.Group
      
          // options={neurologicalExaminationOptions}
          onChange={handleNeurologicalExaminationChange}
        >
          {neurologicalExaminationOptions.map((option) => (
            <div style={{ minWidth: "240px", padding: "6px" }}>
              <Checkbox key={option.value} value={option.value}         
              >
                {option.label}
              </Checkbox>
            </div>
          ))}
        </Checkbox.Group>
      </Form.Item>
      {showNeurologicalOthers && (
        <Form.Item
          name="neurological_examination_others"
       
        >
          <Input placeholder="Please specify" style={{width:"50%"}} />
        </Form.Item>
      )}
    </>
  );
};

export default NeurologicalSystem;
