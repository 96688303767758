import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Input,
  message,
  Typography,
  Image,
  Row,
  Col,
} from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import btnStyles from "../../../styles/Custom/Button.module.css";
import textStyles from "../../../styles/Custom/Typography.module.css";
import {
  emailLoginApi,
  SetPasswordApi,
  userMetaDataApi,
} from "../../../services/auth.services";
import {
  ACCESS_TOKEN,
  ACTIVEDOMAIN,
  ORGANISATONID,
  REFRESH_TOKEN,
  PASSWORD_SET_TOKEN_SECRET_KEY,
  Secret_Key,
} from "../../../constants/defaultKeys";
import { setCookie } from "../../../helpers/localStorage";
import logo from "../../../assets/images/curengo.svg";
import { redirectToDashboard } from "../../../helpers/auth/helper";
import { UserContext } from "../../../context/UserContext";
import ChooseOrgModal from "../Login/ChooseOrgModal";
import jwtEncode from "jwt-encode";
import useQuery from "../../../hooks/useQuery";

function SetPassword({ orgID }) {
  const [loading, setLoading] = useState(false);
  const { setUserDetails } = useContext(UserContext);
  const [openModal, setOpenModal] = useState(false);
  const [orgDetails, setOrgDetails] = useState({});
  const params = useQuery();
  const secretKey = Secret_Key;
  console.log(params?.id);
  const onFinish = async (values) => {
    setLoading(true);
    try {
      const dataToEncode = {
        user_id: Number(params?.id),
      };

      // Create the token
      const token = jwtEncode(dataToEncode, secretKey, {
        algorithm: "HS256",
        expiresIn: "1h",
      });

      const payload = {
        password: values.password,
        confirm_password: values.confirm_password,
      };

      const resp = await SetPasswordApi(token, payload);
      if (resp) {
        getUserDetails();
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const getUserDetails = async () => {
    try {
      let resp = await userMetaDataApi();
      if (orgID) {
        setCookie(
          ORGANISATONID,
          orgID,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        const activeOrg = orgID
          ? resp?.data?.organizations?.filter(
              (org) => org?.org_id == orgID
            )?.[0]
          : {};

        setUserDetails({
          ...resp?.data,
          activeOrg: activeOrg,
          currFacility: activeOrg?.roles?.[0]?.facility_id,
          currRole: activeOrg?.roles?.[0]?.role,
        });
        if (activeOrg?.domain) {
          setCookie(
            ACTIVEDOMAIN,
            activeOrg?.domain,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          redirectToDashboard(activeOrg?.domain, activeOrg?.roles?.[0]?.role);
        }
      } else if (resp?.data?.organizations?.length == 1) {
        let orgId = resp?.data?.organizations?.[0].org_id;
        setCookie(
          ORGANISATONID,
          orgId,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        const activeOrg = orgId
          ? resp?.data?.organizations?.filter(
              (org) => org?.org_id == orgId
            )?.[0]
          : {};
        setUserDetails({
          ...resp?.data,
          activeOrg: activeOrg,
          currFacility: activeOrg?.roles?.[0]?.facility_id,
          currRole: activeOrg?.roles?.[0]?.role,
        });
        if (activeOrg?.domain) {
          setCookie(
            ACTIVEDOMAIN,
            activeOrg?.domain,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          redirectToDashboard(activeOrg?.domain, activeOrg?.roles?.[0]?.role);
        }
      } else {
        setOpenModal(true);
        setUserDetails({
          ...resp?.data,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading && setLoading(false);
    }
  };
  const onCancel = () => {
    setOpenModal(false);
  };
  return (
    <>
      <ChooseOrgModal
        openModal={openModal}
        closable={true}
        onCancel={onCancel}
      />
      <div className="parentContainer">
        <Image
          preview={false}
          alt="Logo"
          src={orgDetails?.logo || logo}
          style={{
            height: "80px",
            width: "auto",
            marginLeft: "20%",
            cursor: "pointer",
          }}
          className="mobileHidden"
        />
        <div className="webHidden">
          <Image
            preview={false}
            alt="Logo"
            src={orgDetails?.logo || logo}
            style={{
              height: "100px",
              width: "auto",
              paddingLeft: "20%",
              cursor: "pointer",
            }}
          />
        </div>
        <Row
          style={{
            minHeight: "calc(100vh - 400px)",
            justifyContent: "center",
          }}
        >
          <Col
            xs={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
            style={{
              alignSelf: "center",
            }}
          >
            {" "}
            <Typography
              className={`text-center `}
              style={{ paddingTop: "12px", paddingBottom: "32px" }}
            >
              <br />
              <Typography
                className={`text-center ${textStyles.h5}`}
                // style={{ marginTop: "24px" }}
              >
                Set Your Password
              </Typography>
            </Typography>{" "}
            <Form
              autoComplete="off"
              onFinish={onFinish}
              layout="vertical"
              requiredMark={false}
              style={{ width: "100%" }}
            >
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                  {
                    message:
                      "Password must have at least 8 characters, including uppercase, lowercase, digit, and special character.",
                    pattern: new RegExp(
                      "^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                    ),
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter new password"
                  className={inputStyles.mdInput}
                />
              </Form.Item>
              <Form.Item
                label="Confirm password"
                name="confirm_password"
                rules={[
                  { required: true, message: "Please confirm your password!" },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The new passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm new password"
                  className={inputStyles.mdInput}
                />
              </Form.Item>
              <br />
              <Form.Item style={{ width: "100%", margin: "auto" }}>
                <Button
                  loading={loading}
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  className={btnStyles.mdBtn}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SetPassword;
