import React, { useContext, useEffect } from "react";
import { Card, Divider } from "antd";
import Hydration from "./GeneralExaminationComponents/Hydration";
import LevelOfConsciousness from "./GeneralExaminationComponents/LevelOfConsciousness";
import Pupils from "./GeneralExaminationComponents/Pupils";
import Pallor from "./GeneralExaminationComponents/Pallor";
import Icterus from "./GeneralExaminationComponents/Icterus";
import Cyanosis from "./GeneralExaminationComponents/Cyanosis";
import Edema from "./GeneralExaminationComponents/Edema";
import PainScale from "./GeneralExaminationComponents/PainScale";
import VitalsAdmission from "./GeneralExaminationComponents/VitalsAdmission";
import Gcs from "./GeneralExaminationComponents/Gcs";
import { DailyReviewContext } from "../../../Doctor/DailyReview/DailyReviewFormProvider";
import PhysioGcs from "./GeneralExaminationComponents/PhysioGcs";

const DrGeneralExaminationFormDR = ({ form }) => {
  const { formData } = useContext(DailyReviewContext);
  useEffect(() => {
    form.setFieldsValue(formData?.general_examination);
  }, [formData]);
  return (
    <Card>
      {/* <Form
        className="ip-forms"
        id="general-examination-form"
        layout="vertical"
      > */}
      <LevelOfConsciousness context={DailyReviewContext} form={form} />
      <Divider />
      <PhysioGcs formData={formData} />
      <Divider />

      <Pupils context={DailyReviewContext} form={form} />
      <Divider />

      <Hydration form={form} />
      <Divider />
      <Pallor form={form} />
      <Divider />

      <Icterus form={form} />
      <Divider />

      <Cyanosis form={form} />
      <Divider />

      <Edema context={DailyReviewContext} />
      <Divider />

      <VitalsAdmission />
      <Divider />

      <PainScale context={DailyReviewContext} form={form} />
      {/* </Form> */}
    </Card>
  );
};

export default DrGeneralExaminationFormDR;
