import { Form, Input } from "antd";
import React from "react";
import { breathSoundOptions } from "../../Options/RespiratoryTherapistOptions";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const BreathSounds = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Breath Sounds"}
        name={["breath_sounds", "status"]}
        options={breathSoundOptions}
        form={form}
      />
      <Form.Item name={["breath_sounds", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default BreathSounds;
