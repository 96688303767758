import { Form, Input } from "antd";
import React from "react";
import { ventilationStatusOptions } from "../../Options/RespiratoryTherapistOptions";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const VentilationStatus = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Ventilation Status"}
        name={["ventilation_details", "status"]}
        options={ventilationStatusOptions}
        form={form}
      />
      <Form.Item name={["ventilation_details", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default VentilationStatus;
