import React, { useContext, useEffect, lazy, Suspense } from "react";
import { PhysioReviewContext } from "./PhysioReviewFormProvider";
import { getPhysioReviewAPI } from "../../../../services/physio.services";
import { Col, message, Row } from "antd";
import PhysioFunctionalDiagnosisSummary from "../../InspectionSummaries/FunctionalExaminationSummary/PhysioFunctionalDiagnosisSummary";
import PhysioTreatmentPlanSummary from "../../InspectionSummaries/TreatmentPlan/PhysioTreatmentPlanSummary";
import PhysioScalesReviewSummary from "../../InspectionSummaries/Scales/PhysioScalesReviewSummary";
import { checkEmpty } from "../../../../helpers/utility";

// const PhysioScalesReviewSummary = lazy(() =>
//   import("../../InspectionSummaries/Scales/PhysioScalesReviewSummary")
// );

const PhysioReviewSummary = ({ editId, visible }) => {
  const { formData, setFormData } = useContext(PhysioReviewContext);
  const openEdit = async () => {
    console.log(editId);
    if (editId) {
      try {
        const response = await getPhysioReviewAPI(editId);
        setFormData(response.data.data);
        console.log(response?.data?.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        message.error("Unable to Fetch Data");
      }
    }
  };

  useEffect(() => {
    openEdit();
  }, [editId]);

  return (
    <Row className="summaries" gutter={[16, 16]}>
      <Col md={24} lg={12}>
        {checkEmpty(formData?.functional_diagnosis) && (
          <PhysioFunctionalDiagnosisSummary formData={formData} />
        )}
        {checkEmpty(formData?.treatment_plan) && (
          <PhysioTreatmentPlanSummary formData={formData} />
        )}
      </Col>
      <Col md={24} lg={12}>
        {checkEmpty(formData?.scales) && (
          // <Suspense fallback={<div>Loading Scales Summary...</div>}>
          <PhysioScalesReviewSummary
            formData={formData}
            editId={editId}
            visible={visible}
          />
          // </Suspense>
        )}
      </Col>
    </Row>
  );
};

export default PhysioReviewSummary;
