import { Button, Col, Divider, Form, Input, Modal, Row, message } from "antd";
import { useContext } from "react";
import {
  createPatientMonitoringAPI,
  updatePatientMonitoringAPI,
} from "../../../services/patient.services";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID, Role } from "../../../constants/defaultKeys";
const { TextArea } = Input;

function AddNotes({
  patient,
  form,
  getData,
  isModalOpen,
  setIsModalOpen,
  userDetails,
}) {
  const userRole = getCookie(Role) || userDetails?.currRole;
  const facilityId = Number(getCookie(FacilityID)) || userDetails?.currFacility;
  async function onFinish(values) {
    try {
      const res =
        isModalOpen?.type === "add"
          ? await createPatientMonitoringAPI(patient, {
              role: userRole,
              facility_id: facilityId,
              ...values,
            })
          : await updatePatientMonitoringAPI(patient, isModalOpen?.id, values);
      message.success(res?.data?.message);
      setIsModalOpen({ status: false, type: "add", id: 0 });
      getData();
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  }

  return (
    <Modal
      footer={null}
      title={isModalOpen?.type === "add" ? "Add Notes" : "Update Notes"}
      open={isModalOpen}
      onCancel={() => setIsModalOpen({ status: false, type: "add", id: 0 })}
    >
      <Form form={form} name="notes" layout="vertical" onFinish={onFinish}>
        <Divider />
        <Row style={{ marginTop: "18px" }}>
          <Col span={24}>
            <Form.Item
              label="Progress Notes"
              name="notes"
              rules={[
                {
                  required: true,
                  message: "Please input progress notes!",
                },
              ]}
            >
              <TextArea
                style={{ minHeight: "100px" }}
                placeholder="Add Progress Notes"
              />
            </Form.Item>
          </Col>
          <Divider />
          <Col span={24} className="flex-end">
            <Form.Item wrapperCol={{ span: 4 }}>
              <Button className="inv-btn" type="primary" htmlType="submit">
                {isModalOpen?.type === "add" ? "Add" : "Update"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddNotes;
