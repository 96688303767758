import React, { useContext, useEffect, useMemo, useState } from "react";
import { Typography } from "antd";

import CheckListFBYRadioItems from "../../../../../../blocks/FormItems/CheckListFBYRadioItems";
import {
  sensoryExtremitiesOptions,
  sensorySystemAbnormalOptions,
  sensorySystemOptions,
} from "../../../../Options/systemicExamOptions";
import {
  normalAbsentOptions,
  positiveNegativeOptions,
} from "../../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";
const { Text } = Typography;
const SensorySystem = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);

  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    const initialCheckboxes = sensorySystemAbnormalOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          initialValues?.systemic_examination?.[checkbox.value] || false; // Initialize all checkboxes based on initialValues
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <DeselectableRadioGroup
        label={"Sensory System"}
        name={"sensory_system"}
        options={sensorySystemOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("sensory_system") === "assessable" && (
        <div className="subsection-card">
          <CheckListFBYRadioItems
            checkBoxOptions={sensorySystemAbnormalOptions}
            radioOptions={sensoryExtremitiesOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            form={form}
          />

          <Text>Tactile Localization</Text>
          <div style={{ marginLeft: "20px" }}>
            <DeselectableRadioGroup
              label={"2 Point Discrimination"}
              name={"two_point_discrimination"}
              options={normalAbsentOptions}
              form={form}
            />

            <DeselectableRadioGroup
              label={"Stereognosis"}
              name={"stereognosis"}
              options={normalAbsentOptions}
              form={form}
            />

            <DeselectableRadioGroup
              label={"Graphesthesia"}
              name={"graphesthesia"}
              options={normalAbsentOptions}
              form={form}
            />
          </div>

          <DeselectableRadioGroup
            label={"Romberg's Sign"}
            name={"rombergs_sign"}
            options={positiveNegativeOptions}
            form={form}
          />
        </div>
      )}
    </>
  );
};

export default SensorySystem;
