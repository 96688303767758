import React, { useContext, useEffect, useState } from "react";
import CheckListFBYInput from "../../../../../../blocks/FormItems/CheckListFBYInput";
import { Form, Radio } from "antd";
import { noYesOptions } from "../../../../Options/commonOptions";
import { limbExtremityOptions } from "../../../../Options/systemicExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const Paralysis = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context);
  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    const initialCheckboxes = limbExtremityOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.examination?.paralysis?.[checkbox.value] || false;
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [formData]);

  return (
    <>
      <DeselectableRadioGroup
        label={"Paralysis"}
        name={["paralysis", "status"]}
        options={noYesOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue(["paralysis", "status"]) === "yes" && (
        <CheckListFBYInput
          options={limbExtremityOptions}
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          keyChain={"paralysis"}
        />
      )}
    </>
  );
};

export default Paralysis;
