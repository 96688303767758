import React from "react";
import CommonTable from "../CommonTable";
import dayjs from "dayjs";

const PressureCommonClos = ({ data, heading, type }) => {
  const dressingColumns = [{ title: "Grade", dataIndex: "grade" }];
  const debridementColumns = [
    { title: "Debridement", dataIndex: "debridment" },
  ];

  const vacColumns = [
    { title: "Drinage in ml", dataIndex: "drainage" },
    { title: "Color", dataIndex: "color" },
    { title: "VAC Change", dataIndex: "vac_change" },
  ];
  const commonColumns = [
    {
      title: "Time Done",
      dataIndex: "process_time",
      key: "process_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];

  const finalColumns =
    type === "pressure_sore_dressing"
      ? [...dressingColumns, ...commonColumns]
      : type === "pressure_sore_debridement"
      ? [...debridementColumns, ...commonColumns]
      : [...vacColumns, ...commonColumns];
  return (
    <div>
      <CommonTable
        data={data?.nurse_data}
        columns={finalColumns}
        titleData={() => heading}
      />
    </div>
  );
};

export default PressureCommonClos;
