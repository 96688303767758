import React, { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Row,
  Col,
  Pagination,
  Space,
  Table,
  Tag,
  Upload,
  message,
  Badge,
  Image,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  capitalizeFirstLetter,
  convertToFormData,
  daysPassed,
  timeAgo,
} from "../../../helpers/utility";
import bedIcon from "../../../assets/images/patientIcons/bed.svg";
import InPatientModal from "./InPatientModal";
import { getUploadUrlApi } from "../../../services/common.services";
import axios from "axios";
import { updateIpAdmissionApi } from "../../../services/receptionist.services";
import IpUploadModal from "./IpUploadModal";

const IpPatientTable = ({ data, refreshIpPage, page, setPage, loading }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [uploadedFileName, setuploadedFileName] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState("consent");

  const beforeUpload = (file) => {
    const fileType = file.type === "application/pdf";
    if (!fileType) {
      message.error(`${file.name} is not a valid file`);
    }
    return fileType ? true : Upload.LIST_IGNORE;
  };

  const uploadFileFunc = async (info, id, type) => {
    if (info.file.status === "done") {
      try {
        const res = await getUploadUrlApi(`path_key=${info?.file?.name}`);
        const formData = convertToFormData(res, info);
        await axios({
          method: "POST",
          url: res?.data?.url,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        setuploadedFileName((prev) => [...prev, res?.data?.fields?.key]);
        onUploading(id, res?.data?.fields?.key, type);
      } catch (err) {
        console.log(err);
        message.error(err?.response?.data?.message);
      }
    }
  };

  const onUploading = (id, uploadFile, type) => {
    const formData =
      type === "consent"
        ? { consentform: [uploadFile] }
        : { dischargesummary: [uploadFile] };

    if (id) {
      updateIpAdmissionApi(id, formData)
        .then((res) => {
          message.success(res?.data?.message);
          refreshIpPage && refreshIpPage();
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message);
        });
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const columns = [
    {
      title: "Patient ID",
      key: "patient_id",
      dataIndex: "patient_id",
      width: 100,
    },
    { title: "Admission ID", key: "ip_id", dataIndex: "ip_id", width: 100 },
    {
      title: "Full Name",
      dataIndex: "patient_name",
      width: 320,
      render: (_, record) => (
        <Row>
          <Col span={10}>{capitalizeFirstLetter(record?.patient_name)}</Col>

          <Col span={7}>
            <Tag>
              {record?.patient_age} {record?.patient_gender}
            </Tag>
          </Col>
          <Col span={1}>
            {record?.is_active === true ? (
              <Badge
                color="green"
                status="processing"
                style={{ color: "green" }}
              />
            ) : (
              <Badge status="default" />
            )}
          </Col>
          <Col span={6}>
            {record?.is_active === true ? (
              <Tag color="green">{daysPassed(record?.date_of_admission)}</Tag>
            ) : (
              <Tag>{timeAgo(record?.discharge_date)}</Tag>
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: "Consultant Name",
      key: "doctor_name",
      dataIndex: "doctor_name",
      width: 100,
    },
    {
      title: "Consent Form",
      render: (value, record) => (
        <>
          {record?.consentform?.length > 0 ? (
            <Tag color="green">Submitted</Tag>
          ) : (
            <Button
              onClick={() => {
                setOpenModal(true);
                setEditData(record);
                setType("consent");
              }}
            >
              Upload
            </Button>
          )}
        </>
      ),
      width: 120,
    },
    {
      title: "Discharge Summary",
      render: (value, record) => (
        <>
          {record?.dischargesummary?.length > 0 ? (
            <Tag color="green">Submitted</Tag>
          ) : (
            <Button
              onClick={() => {
                setOpenModal(true);
                setEditData(record);
                setType("discharge");
              }}
            >
              Upload
            </Button>
          )}
        </>
      ),
      width: 120,
    },
    {
      title: "Bed Assignment",
      render: (value, record) => (
        <>
          {record?.bed_name ? (
            <Space>
              {/* <Tag color="blue" className={tagStyles.smRndTag}> */}
              <Tag color="orange">
                <Image src={bedIcon} preview={false} /> {record?.bed_name}
              </Tag>
            </Space>
          ) : (
            <Button
              disabled={!record?.is_active}
              onClick={() =>
                navigate(
                  `/receptionist/ip?patient=${record?.id}&type=bed-management`
                )
              }
              danger
            >
              Allot Bed
            </Button>
          )}
        </>
      ),
      width: 120,
    },
    {
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            ghost
            onClick={() =>
              navigate(`/receptionist/ip?patient=${record?.id}&type=packages`)
            }
          >
            View
          </Button>
          {record?.is_active && (
            <Button
              onClick={() => {
                setOpen(true);
                setEditData(record);
              }}
            >
              <EditOutlined />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  // Adding rowClassName to differentiate rows without bed assignment

  return (
    <div>
      <Table
        loading={loading}
        dataSource={data?.data}
        columns={columns}
        pagination={false}
        scroll={{ y: "calc(100vh - 300px)" }}
        style={{ marginTop: "10px" }}
        // rowClassName={(record) => (record?.bed_name ? "" : "bed-row")}
      />
      {data?.total_count > 50 && (
        <Col span={24} className="text-center" style={{ paddingTop: "10px" }}>
          <Pagination
            current={page}
            pageSize={50}
            total={data?.total_count}
            onChange={(e) => setPage(e)}
            showSizeChanger={false}
          />
        </Col>
      )}

      {open && (
        <InPatientModal
          open={open}
          setOpen={setOpen}
          ipData={editData}
          refreshIpPage={refreshIpPage}
        />
      )}
      {openModal && (
        <IpUploadModal
          setOpenModal={setOpenModal}
          openModal={openModal}
          editData={editData}
          refreshIpPage={refreshIpPage}
          type={type}
        />
      )}
    </div>
  );
};

export default IpPatientTable;
