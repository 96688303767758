import React from "react";
import JVP from "./JVP";
import PeripheralPulses from "./PeripheralPulses";
import HeartSounds from "./HeartSounds";
import { Divider } from "antd";

const CardioForm = ({ context, form }) => {
  return (
    <>
      <PeripheralPulses form={form} />
      <Divider />
      <JVP form={form} />
      <Divider />
      <HeartSounds context={context} form={form} />
    </>
  );
};

export default CardioForm;
