import React from "react";
import { respiratoryDistressOptions } from "../../../../Options/genExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const RespiratoryDistress = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Respiratory Distress"}
        name={"respiratory_distress"}
        options={respiratoryDistressOptions}
        form={form}
      />
    </>
  );
};

export default RespiratoryDistress;
