import React, { useState } from "react";
import { notAssessableAndAssessableOptions } from "../../../Options/commonOptions";
import { speechTypeOptions } from "../../../Options/functionalAsessmentOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const Speech = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <DeselectableRadioGroup
        label={"Speech"}
        name={["speech_details", "speech"]}
        options={notAssessableAndAssessableOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue(["speech_details", "speech"]) === "assessable" && (
        <div className="subsection-card">
          <DeselectableRadioGroup
            name={["speech_details", "speech_type"]}
            options={speechTypeOptions}
            form={form}
          />
        </div>
      )}
    </>
  );
};

export default Speech;
