export const showAddFormCodes = new Set([
  "iv_fluids",
  "nebulization",
  "pressure_sore",
  "blood_products",
  "parental_nutrition",
]);

export const bloodTypeOptions = [
  { label: "Whole Blood", value: "whole_blood" },
  { label: "PRBC", value: "prbc" },
  { label: "FFP", value: "ffp" },
  { label: "RDP", value: "rdp" },
  { label: "SDP", value: "sdp" },
  { label: "Cryo Ppt", value: "cryo_ppt" },
  { label: "5% Albumin", value: "albumin_5_percent" },
  { label: "25% Albumin", value: "albumin_25_percent" },
  { label: "Others", value: "others" },
];

export const eyeGelOptions = [
  { label: "6th Hourly", value: "6_hourly" },
  { label: "8th Hourly", value: "8_hourly" },
  { label: "12th Hourly", value: "12_hourly" },
];

export const salineWashOptions = [
  { label: "4th Hourly", value: "4_hourly" },
  ...eyeGelOptions,
];
export const eyeDropsOptions = [
  { label: "2nd Hourly", value: "2_hourly" },
  ...salineWashOptions,
];

export const feedingTubeOptions = [
  { label: "NG Tube", value: "ng_tube" },
  { label: "NJ Tube", value: "nj_tube" },
  { label: "PEG", value: "peg" },
  { label: "OG Tube", value: "og_tube" },
  { label: "Gastrostomy Tube", value: "gastrostomy_tube" },
  { label: "Jejunostomy Tube", value: "jejunostomy_tube" },
];

export const grbsOptions = [
  { label: "Hourly", value: "hourly" },
  { label: "2nd Hourly", value: "2_hourly" },
  { label: "4th Hourly", value: "4_hourly" },
  { label: "6th Hourly", value: "6_hourly" },
  { label: "8th Hourly", value: "8_hourly" },
  { label: "12th Hourly", value: "12_hourly" },
];

export const ivFluidOptions = [
  { label: "0.9% Sodium Chloride", value: "sodium_chloride_0_9_percent" },
  { label: "RL", value: "rl" },
  { label: "0.45% Sodium Chloride", value: "sodium_chloride_0_45_percent" },
  { label: "0.33% Sodium Chloride", value: "sodium_chloride_0_33_percent" },
  { label: "3% Sodium Chloride", value: "sodium_chloride_3_percent" },
  { label: "5% Dextrose", value: "dextrose_5_percent" },
  { label: "10% Dextrose", value: "dextrose_10_percent" },
  { label: "25% Dextrose", value: "dextrose_25_percent" },
  { label: "Plasmalyte", value: "plasmalyte" },
  { label: "10% Mannitol", value: "mannitol_10_percent" },
  { label: "20% Mannitol", value: "mannitol_20_percent" },
  { label: "100ml NS", value: "ns_100ml" },
  { label: "Others", value: "others" },
];

export const ivMedicationOptions = [
  { label: "Hai_u", value: "hai_u" },
  { label: "Magnesium", value: "magnesium" },
  { label: "Kcl", value: "kcl" },
  { label: "Otksi", value: "otksi" },
  { label: "Celecel", value: "celecel" },
  { label: "Multivitamin", value: "multivitamin" },
  { label: "Others", value: "others" },
];

export const ivMedicationUnitOptions = [
  { label: "ml", value: "ml" },
  { label: "gms", value: "gms" },
  { label: "U", value: "u" },
];

export const nebulisationDoseOptions = [
  {
    label: "Duolin Respules (1.25mg + 500 mcg)",
    value: "duolin_respules_125mg_500_mcg",
  },
  {
    label: "Duolin Respules (0.63mg + 500 mcg)",
    value: "duolin_respules_063mg_500_mcg",
  },
  { label: "Budesonide (0.5mg)", value: "budesonide_05mg" },
  { label: "Budesonide (1mg)", value: "budesonide_1mg" },
  { label: "Salbutamol (2.5mg)", value: "salbutamol_25mg" },
  { label: "Foracort (20mcg + 1 mg)", value: "foracort_20mcg_1_mg" },
  { label: "Foracort (20mcg + 0.5mg)", value: "foracort_20mcg_05mg" },
  { label: "Ipratropium (500mcg)", value: "ipratropium_500mcg" },
  { label: "Sodium Chloride 3%", value: "sodium_chloride_3" },
  { label: "Sodium Chloride 0.9%", value: "sodium_chloride_09" },
  { label: "N Acetyle Cysteine (10%)", value: "n_acetyle_cysteine_10" },
  { label: "N Acetyle Cysteine (20%)", value: "n_acetyle_cysteine_20" },
  { label: "Colistin (1.5 mu)", value: "colistin_15_mu" },
  { label: "Colistin (1 mu)", value: "colistin_1_mu" },
];

export const nebulisationFrequencyOptions = [
  { label: "OD", value: "od" },
  { label: "BD", value: "bd" },
  { label: "TDS", value: "tds" },
  { label: "QID", value: "qid" },
  { label: "SOS", value: "sos" },
  { label: "2nd Hourly", value: "2_hourly" },
  { label: "4th Hourly", value: "4_hourly" },
];

export const ostomyOptions = [
  { label: "Healthy", value: "healthy" },
  { label: "Inflamed", value: "inflamed" },
  { label: "Infected", value: "infected" },
  { label: "Ulcerated", value: "ulcerated" },
];

export const parentalFluidOptions = [
  { label: "Kabiven Central", value: "kabiven_central" },
  { label: "Kabiven Peripheral", value: "kabiven_peripheral" },
  { label: "Tna Central", value: "tna_central" },
  { label: "Tna Peripheral", value: "tna_peripheral" },
  { label: "Oliclinomel Central", value: "oliclinomel_central" },
  { label: "25% Dextrose", value: "dextrose_25_percent" },
  { label: "20% Albumin", value: "albumin_20_percent" },
  { label: "10% Nephrosteril", value: "nephrosteril_10_percent" },
  { label: "10% Aminosteril", value: "aminosteril_10_percent" },
  { label: "8% Hipster", value: "hipster_8_percent" },
  { label: "10% Intralipid", value: "intralipid_10_percent" },
  { label: "20% Intralipid", value: "intralipid_20_percent" },
  { label: "30% Intralipid", value: "intralipid_30_percent" },
  { label: "Others", value: "others" },
];

export const parentalMedicationOptions = [
  { label: "Inj. Hai", value: "inj_hai" },
  { label: "Inj. Magnesium", value: "inj_magnesium" },
  { label: "Inj. Kci", value: "inj_kci" },
  { label: "Inj. Otksi", value: "inj_otksi" },
  { label: "Inj. Celecel", value: "inj_celecel" },
  { label: "Inj. Multivitamin", value: "inj_multivitamin" },
  { label: "Others", value: "others" },
];

export const parentalMedicationUnitOptions = [
  { label: "ml", value: "ml" },
  { label: "gms", value: "gms" },
  { label: "U", value: "u" },
];

export const pressureSoreOdSOSAllOptions = [
  { label: "OD", value: "od" },
  { label: "BD", value: "bd" },
  { label: "TDS", value: "tds" },
  { label: "SOS", value: "sos" },
];
export const pressureSoreDressingOptions = [
  //saline, medicated, others
  { label: "Saline", value: "saline" },
  { label: "Medicated", value: "medicated" },
  { label: "Others", value: "others" },
];

export const pressureSoreAreaOptions = [
  { label: "Left Scapular", value: "left_scapular" },
  { label: "Right Scapular", value: "right_scapular" },
  { label: "Left Arm", value: "left_arm" },
  { label: "Right Arm", value: "right_arm" },
  { label: "Left Forearm", value: "left_forearm" },
  { label: "Right Forearm", value: "right_forearm" },
  { label: "Sacral", value: "sacral" },
  { label: "Left Gluteal", value: "left_gluteal" },
  { label: "Right Gluteal", value: "right_gluteal" },
  { label: "Left Hamstring", value: "left_hamstring" },
  { label: "Right Hamstring", value: "right_hamstring" },
  { label: "Left Heel", value: "left_heel" },
  { label: "Right Heel", value: "right_heel" },
  { label: "Others", value: "others" },
];

export const pressureSoreGradeOptions = [
  //Grade 1, Grade 2, Grade 3, Grade 4
  { label: "Grade 1", value: "grade_1" },
  { label: "Grade 2", value: "grade_2" },
  { label: "Grade 3", value: "grade_3" },
  { label: "Grade 4", value: "grade_4" },
];

export const pressureSoreCareOptions = [
  { label: "Alfa Bed", value: "alfa_bed" },
  { label: "Air Bed", value: "air_bed" },
  { label: "Nimbus Bed", value: "nimbus_bed" },
  { label: "Others", value: "others" },
];

export const pressureSoreThromboprophylaxisOptions = [
  { label: "TED Stockings", value: "ted_stockings" },
  { label: "Crepe Bandage", value: "crepe_bandage" },
  {
    label: "Mechanical Compression Device",
    value: "mechanical_compression_device",
  },
];

export const respiratorySupportOptions = {
  respiratorySupport: [
    { label: "Oxygen-Nasal Prongs", value: "oxygen_nasal_prongs" },
    { label: "Thermo Ventilator", value: "thermo_ventilator" },
    { label: "Oxygen-Hudson Mask", value: "oxygen_hudson_mask" },
    { label: "Oxygen-Hi Flow Mask", value: "oxygen_hi_flow_mask" },
    { label: "Oxygen-Rebreathing Mask", value: "oxygen_rebreathing_mask" },
    { label: "BiPAP Support", value: "bipap_support" },
    { label: "CPAP Support", value: "cpap_support" },
    { label: "Mechanical Ventilation", value: "mechanical_ventilation" },
  ],
  mechanicalVentilation: [
    { label: "VCV", value: "vcv" },
    { label: "PCV", value: "pcv" },
    { label: "SIMV-VCV", value: "simv_vcv" },
    { label: "SIMV-PCV", value: "simv_pcv" },
  ],
};

export const specialConsultantOptions = [
  { label: "Physician", value: "physician" },
  { label: "Psychiatrist", value: "psychiatrist" },
  { label: "Orthopedics", value: "orthopedics" },
  { label: "Neuro Physician", value: "neuro_physician" },
  { label: "Neuro Surgeon", value: "neuro_surgeon" },
  { label: "Cardiologist", value: "cardiologist" },
  { label: "Pulmonologist", value: "pulmonologist" },
  { label: "Plastic Surgeon", value: "plastic_surgeon" },
  { label: "Others", value: "others" },
];

export const urinaryCatheterTypeOptions = [
  { label: "Foleys catheter", value: "foleys_catheter" },
  { label: "Condom catheter", value: "condom_catheter" },
];

export const urinaryFoleysCatheterOptions = [
  { label: "Rubber", value: "rubber" },
  { label: "Silicon", value: "silicon" },
];

export const vitalOptions = [
  { label: "Hourly", value: "hourly" },
  { label: "2nd Hourly", value: "2_hourly" },
  { label: "4th Hourly", value: "4_hourly" },
  { label: "6th Hourly", value: "6_hourly" },
  { label: "8th Hourly", value: "8_hourly" },
  { label: "12th Hourly", value: "12_hourly" },
];

export const woundCareOdSOSOptions = [
  { label: "OD", value: "od" },
  { label: "SOS", value: "sos" },
];
export const woundCareDressingOptions = [
  //saline, medicated, others
  { label: "Saline", value: "saline" },
  { label: "Medicated", value: "medicated" },
  { label: "Others", value: "others" },
];

export const woundCareAreaOptions = [
  { label: "Scalp", value: "scalp" },
  { label: "Face", value: "face" },
  { label: "Chest", value: "chest" },
  { label: "Abdomen", value: "abdomen" },
  { label: "Back", value: "back" },
  { label: "Hips", value: "hips" },
  { label: "Left Shoulder", value: "left_shoulder" },
  { label: "Right Shoulder", value: "right_shoulder" },
  { label: "Left Arm", value: "left_arm" },
  { label: "Right Arm", value: "right_arm" },
  { label: "Left Forearm", value: "left_forearm" },
  { label: "Right Forearm", value: "right_forearm" },
  { label: "Left Hand", value: "left_hand" },
  { label: "Right Hand", value: "right_hand" },
  { label: "Left Buttock", value: "left_buttock" },
  { label: "Right Buttock", value: "right_buttock" },
  { label: "Left Thigh", value: "left_thigh" },
  { label: "Right Thigh", value: "right_thigh" },
  { label: "Left Leg", value: "left_leg" },
  { label: "Right Leg", value: "right_leg" },
  { label: "Left Foot", value: "left_foot" },
  { label: "Right Foot", value: "right_foot" },
  { label: "Others", value: "others" },
];

export const respiratoryCareHourlyOptions = [
  { label: "4th Hourly", value: "4_hourly" },
  { label: "6th Hourly", value: "6_hourly" },
  { label: "8th Hourly", value: "8_hourly" },
];

export const careItemsList = [
  { label: "Nebulisation", key: "nebulization" },
  { label: "IV Fluids", key: "iv_fluids" },
  { label: "Blood Products", key: "blood_products" },
  { label: "Parental Nutrition", key: "parental_nutrition" },
  { label: "Respiratory Care", key: "respiratory_care" },
  { label: "Urinary Catheter", key: "urinary_catheter_care" },
  { label: "Wound Care", key: "open_wound_care" },
  { label: "Preventive Care", key: "preventive_care" },
  { label: "Eye Care", key: "eye_care" },
  { label: "Feeding Tube", key: "feeding_tube" },
  { label: "Pressure Sore", key: "pressure_sore" },
  { label: "Ostomy Care", key: "ostomy_care" },
  // { label: "Special Consultations", key: "special_consultations" },
];
export const nurseCareItemsList = [
  { label: "Nebulisation", key: "nebulization" },
  { label: "IV Fluids", key: "iv_fluids" },
  { label: "Blood Products", key: "blood_products" },
  { label: "Parental Nutrition", key: "parental_nutrition" },
  { label: "Respiratory Care", key: "respiratory_care" },
  { label: "Urinary Catheter", key: "urinary_catheter_care" },
  { label: "Wound Care", key: "open_wound_care" },
  { label: "Preventive Care", key: "preventive_care" },
  { label: "Eye Care", key: "eye_care" },
  { label: "Feeding Tube", key: "feeding_tube" },
  { label: "Pressure Sore", key: "pressure_sore_care" },
  { label: "Ostomy Care", key: "ostomy_care" },
  { label: "Position Change", key: "physio_position_change" },
  // { label: "Daily Routine", key: "daily_routine_care" },
  // { label: "Special Consultations", key: "special_consultations" },
];

export const tracheostomyOptions = [
  { label: "Non-Fenestrated", value: "non_fenestrated" },
  { label: "Fenestrated", value: "fenestrated" },
];
export const bagEmptyChangeOptions = [
  { label: "Done", value: "Done" },
  { label: "Not Done", value: "Not Done" },
];
export const localAppearanceOptions = [
  { label: "Healthy", value: "Healthy" },
  { label: "Inflammed", value: "Inflammed" },
  { label: "Infected", value: "Infected" },
  { label: "Ulcerated", value: "Ulcerated" },
];
export const positionOptions = [
  { label: "Inplace", value: "Inplace" },
  { label: "Displace", value: "Displace" },
];
export const aspirationOptions = [
  { label: "<20ml", value: "<20ml" },
  { label: ">20ml", value: ">20ml" },
];
export const tubeChangeOptions = [
  { label: "Done", value: "Done" },
  { label: "Not Done", value: "Not Done" },
];
export const secCultureOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
export const suctionOptions = [
  { label: "Open", value: "Open" },
  { label: "Closed", value: "Closed" },
];
export const colorOptions = [
  { label: "Blood", value: "Blood" },
  { label: "Serosanguinous", value: "Serosanguinous" },
  { label: "Serous", value: "Serous" },
  { label: "Purulent", value: "Purulent" },
  { label: "Other", value: "Other" },
];
export const gradeOptions = [
  { label: "Grade 1", value: "Grade 1" },
  { label: "Grade 2", value: "Grade 2" },
  { label: "Grade 3", value: "Grade 3" },
  { label: "Grade 4", value: "Grade 4" },
];
