import React, { useContext, useState } from "react";
import { Modal, Select } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { FacilityID, Role } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserContext";
import { getCookie, setCookie } from "../../../helpers/localStorage";
import { redirectToDashboard } from "../../../helpers/auth/helper";

const { Option } = Select;

const SwitchRole = () => {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const params = useParams();
  const url = new URL(window?.location?.href);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(params?.tab === "true" && true);
  const [selectedRole, setSelectedRole] = useState(
    getCookie(Role) || userDetails?.activeOrg?.roles?.[0]?.role
  );

  const handleRoleChange = (value) => {
    const lastUnderscoreIndex = value.lastIndexOf("_");

    // Extract role by slicing up to the last underscore
    const role = value.slice(0, lastUnderscoreIndex);

    // Extract facility ID by slicing after the last underscore
    const facility_id = value.slice(lastUnderscoreIndex + 1);

    redirectToDashboard(userDetails?.activeOrg?.domain, role);
    setSelectedRole(value);
    setCookie(Role, role, `.${process.env.REACT_APP_PUBLIC_DOMAIN}`, "/");
    setCookie(
      FacilityID,
      facility_id,
      `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
      "/"
    );
    setUserDetails((prev) => ({
      ...prev,
      currRole: role,
      currFacility: facility_id,
    }));
  };

  return (
    <Modal
      open={visible}
      footer={null}
      onCancel={() => {
        setVisible(false);
        navigate(-1);
      }}
    >
      <Select
        defaultValue={selectedRole?.replaceAll("_", " ")} // Replace underscores with spaces
        onChange={handleRoleChange}
        style={{ width: "200px" }}
      >
        {userDetails?.activeOrg?.roles?.map((role) => (
          <Option
            value={`${role?.role}_${role?.facility_id}`}
            key={`${role?.role}_${role?.facility_id}`}
          >
            {role?.facility
              ? `${role?.role?.replaceAll("_", " ")} - ${role?.facility}`
              : role?.role?.replaceAll("_", " ")}
          </Option>
        ))}
      </Select>
    </Modal>
  );
};

export default SwitchRole;
