import React from "react";
import { nutritionalRatingOptions } from "../../Options/clinicalEvaluationOptions";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const NutritionalScreeningRating = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Nutritional Screen Rating"}
        name={"nutritional_screen_rating"}
        options={nutritionalRatingOptions}
        form={form}
      />
    </>
  );
};

export default NutritionalScreeningRating;
