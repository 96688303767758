import React, { useState } from "react";
import {
  consiousnessOptions,
  moreConsiousnessOptions,
} from "../../../../Options/genExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const LevelOfConsciousness = ({ form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <DeselectableRadioGroup
        label={"Level of consciousness"}
        name={"level_of_consciousness"}
        options={consiousnessOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("level_of_consciousness") === "p" && (
        <DeselectableRadioGroup
          name={"level_of_consciousness_options"}
          options={moreConsiousnessOptions}
          form={form}
        />
      )}
    </>
  );
};

export default LevelOfConsciousness;
