import { Form, Input } from "antd";
import React, { useState } from "react";
import { noYesOptions } from "../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const FoodAllergies = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <DeselectableRadioGroup
        label={"Food Allergies"}
        name={["allergy_details", "food_allergies"]}
        options={noYesOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue(["allergy_details", "food_allergies"]) === "yes" && (
        <Form.Item name={["allergy_details", "allergy_description"]}>
          <Input autoComplete="off" />
        </Form.Item>
      )}
    </>
  );
};

export default FoodAllergies;
