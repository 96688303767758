import React, { useContext, useEffect, useMemo, useState } from "react";
import CheckListFBYInput from "../../../../../../blocks/FormItems/CheckListFBYInput";
import { noYesOptions } from "../../../../Options/commonOptions";
import { limbDeformityOptions } from "../../../../Options/systemicExamOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";
const LimbDeformity = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context ? context : DoctorInitialContext);
  const initialValues = useMemo(() => formData, [formData]);
  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    const initialCheckboxes = limbDeformityOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        initialValues?.systemic_examination?.[checkbox.value] || false; // Initialize all checkboxes based on initialValues
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [formData]);

  return (
    <>
      <DeselectableRadioGroup
        label={"Limb Deformity"}
        name={"limb_deformity"}
        options={noYesOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("limb_deformity") === "yes" && (
        <div className="subsection-card">
          <CheckListFBYInput
            options={limbDeformityOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
          />
        </div>
      )}
    </>
  );
};

export default LimbDeformity;
