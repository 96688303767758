import React, { useState } from "react";
import { Form, Card, Button, message, Row, Col } from "antd";
import {
  morningKeys,
  afternoonKeys,
  eveningKeys,
  mergeDietData,
  customTag,
  transformData,
} from "../nutritionistKeys";
import DietEditTable from "./DietEditTable";
import {
  deleteDietItems,
  updatePatientDietItemsApi,
} from "../../../../../services/nutritionist.services";
import useQuery from "../../../../../hooks/useQuery";
import btnStyles from "../../../../../styles/Custom/Button.module.css";
import { EyeOutlined } from "@ant-design/icons";

const DietEditChart = ({ dietEditData, refresh, setEditState, dietType }) => {
  const params = useQuery();
  const [form] = Form.useForm();
  const keyData = [...morningKeys, ...afternoonKeys, ...eveningKeys];
  const diet = [
    dietEditData?.morning || [],
    dietEditData?.afternoon || [],
    dietEditData?.evening || [],
  ].flat();
  const mergedData = mergeDietData(keyData, diet);
  const [dietData, setDietData] = useState(mergedData);

  const handleTableChange = (updatedData) => {
    setDietData(updatedData);
  };

  const calculateTotals = (data) => {
    if (!data || data.length === 0) {
      return {
        carbohydrates: 0,
        proteins: 0,
        fats: 0,
        calories: 0,
        sodium: 0,
        potassium: 0,
      };
    }
    return data?.reduce(
      (totals, item) => ({
        proteins: (totals.proteins || 0) + (item.proteins || 0),
        calories: (totals.calories || 0) + (item.calories || 0),
        sodium: (totals.sodium || 0) + (item.sodium || 0),
        potassium: (totals.potassium || 0) + (item.potassium || 0),
        carbohydrates: (totals.carbohydrates || 0) + (item.carbohydrates || 0),
      }),
      {}
    );
  };

  const mapToCustomTag = (data, color) => {
    return Object.entries(data).map(([key, value]) =>
      customTag(key, value, color)
    );
  };

  const totals = calculateTotals(dietData);

  const combinedTotals = {
    proteins: totals.proteins,
    calories: totals.calories,
    sodium: totals.sodium,
    potassium: totals.potassium,
    carbohydrates: totals.carbohydrates,
  };

  async function onFinish(values) {
    const removed = removeUnmatchedDietItems(values, diet);

    if (removed?.length > 0) {
      deleteDietItems(params?.patient, removed)
        ?.then((res) => {
          message.success(res?.data?.message);
          updatePatientDietItemsApi(params?.patient, transformData(values))
            .then((res) => {
              // message.success(res?.data?.message);
              refresh();
              setEditState(false);
            })
            .catch((err) => console.log(err?.response?.data?.message));
        })
        .catch((err) => console.log(err));
    } else {
      updatePatientDietItemsApi(params?.patient, transformData(values))
        .then((res) => {
          message.success(res?.data?.message);
          refresh();
          setEditState(false);
        })
        .catch((err) => console.log(err?.response?.data?.message));
    }
  }

  function removeUnmatchedDietItems(formData, dietData) {
    let result = {};

    // Iterate over each time key in formData
    Object.keys(formData).forEach((timeKey) => {
      const formSlotData = formData[timeKey];

      // Iterate over each slot in dietData

      const dietSlotData = diet;

      if (Array.isArray(dietSlotData)) {
        // Find the matching time in the corresponding dietData slot
        const secondTimeSlot = dietSlotData.find(
          (slot) => slot.time === parseInt(timeKey.split(":")[0], 10)
        );

        if (secondTimeSlot) {
          // Get the diet__id values from formData and dietData for the current slotType
          const firstDietIds = formSlotData?.items || [];

          // Find the ids that are in dietData but not in formData
          const removedDietIds = secondTimeSlot.items.filter(
            (item) => !firstDietIds.includes(item.diet__id)
          );

          // If there are removed diet ids, store them in the result
          if (removedDietIds.length > 0) {
            result[timeKey] = result[timeKey] || {}; // Initialize if not already
            result[timeKey] = removedDietIds; // Store removed items
          }
        }
      }
    });

    // Flatten the result and return the IDs of removed items
    return Object.values(result).flatMap((timeData) =>
      Object.values(timeData)
        .flat()
        .map((item) => item.id)
    );
  }

  return (
    <Form form={form} onFinish={onFinish}>
      <DietEditTable
        titleData={() => (
          <>
            <Row>
              <Col span={14}>
                Diet Items {mapToCustomTag(combinedTotals, "purple")}
              </Col>
              <Col span={4}>Diet Type: {dietType}</Col>
              <Col span={6} style={{ textAlign: "right" }}>
                <EyeOutlined
                  onClick={() => setEditState(false)}
                  style={{ fontSize: "20px" }}
                />
              </Col>
            </Row>
          </>
        )}
        data={mergedData}
        form={form}
        refresh={refresh}
        onChange={handleTableChange}
      />

      <Form.Item style={{ textAlign: "right", marginTop: "10px" }}>
        <Button type="primary" htmlType="submit" className={btnStyles.mdBtn}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DietEditChart;
