import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../context/UserContext";
import useQuery from "../../../../hooks/useQuery";
import {
  getLatestNutritionRiskAssessmentAPI,
  getPatientDietItemsApi,
} from "../../../../services/nutritionist.services";
import DietView from "./DietView";
import NurtritionistDietChart from "./NurtritionistDietChart";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import dietImage from "../../../../assets/images/nurseIcons/diet.svg";
import LoadingIndicator from "../../../Loaders/LoadingIndicator";
import { Col, Row } from "antd";
import { getCookie } from "../../../../helpers/localStorage";
import { Role } from "../../../../constants/defaultKeys";
const NutritionistDiet = () => {
  const { userDetails } = useContext(UserContext);
  const params = useQuery();
  const [dietData, setDietData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dietType, setDietType] = useState(null);

  const defaultRole = userDetails?.currRole || getCookie(Role);
  const role = defaultRole.toLowerCase()?.replaceAll("_", "-");

  const refreshDietData = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    getPatientDietItemsApi(params?.patient)
      .then((res) => {
        setDietData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    getLatestNutritionRiskAssessmentAPI(params?.patient)
      .then((res) => {
        const diet =
          res?.data?.data?.functional_diagnosis?.diet_details?.diet || "";
        setDietType(diet.charAt(0).toUpperCase() + diet.slice(1));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params?.patient]);

  return (
    <>
      {loading ? (
        <LoadingIndicator message={"Loading diet data, please wait..."} />
      ) : (
        <>
          {dietData && Object.keys(dietData).length !== 0 ? (
            <DietView
              dietData={dietData}
              refresh={refreshDietData}
              dietType={dietType}
            />
          ) : role === "nutritionist" ? (
            <NurtritionistDietChart
              refresh={refreshDietData}
              dietType={dietType}
            />
          ) : (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "80vh",
              }}
            >
              <Col span={24} style={{ textAlign: "center" }}>
                <NoDataPage
                  title="No Diet Found"
                  subtitle="It looks like there are no diets at the moment."
                  image={dietImage}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default NutritionistDiet;
