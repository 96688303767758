import React from "react";
import { Typography, Tag, Row, Col } from "antd";
import CommonTable from "../CommonTable";
import dayjs from "dayjs";
import { capitalizeFirstLetter } from "../../../../helpers/utility";
const CentralLine = ({ data }) => {
  const columns = [
    {
      title: "Dressing",
      dataIndex: "dressing",
      key: "dressing",
      render: (value) => value,
    },
    {
      title: "Hep Saine Flush",
      dataIndex: "saline_flush",
    },
    {
      title: "Patent",
      dataIndex: "patent",
    },
    {
      title: "Time Done",
      dataIndex: "process_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];
  const customTypography = (title, value) => {
    return (
      <>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontWeight: "lighter",
            fontSize: "12px",
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            fontWeight: "lighter",
            fontSize: title === "Comments" ? "12px" : "14px",
          }}
        >
          {title === "Comments" ? (
            <Tag color="blue">
              <Typography
                style={{
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  fontSize: title === "Comments" ? "12px" : "14px",
                }}
              >
                {value}
              </Typography>
            </Tag>
          ) : (
            value
          )}
        </Typography>
      </>
    );
  };
  return (
    <div>
      {data?.map((item) => (
        <>
          <CommonTable
            data={item?.actions.flat()}
            columns={columns}
            titleData={() => (
              <Row>
                <Col span={8}>{customTypography("Type", item?.drug_name)}</Col>
                <Col span={8}>
                  {customTypography("Comments", item?.data?.instructions)}
                </Col>
                <Col span={8}>
                  {customTypography(
                    "Advised By",
                    capitalizeFirstLetter(item?.advised_by)
                  )}
                </Col>
              </Row>
            )}
          />
        </>
      ))}
    </div>
  );
};

export default CentralLine;
